

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    
  },
  
  gridList: {
    // width: 90,
    // height: 90
  },

  subheader: {
    width: '100%',
  },

    
  singleImageContainer:{
    display: "inline-block",
    // display: "flex",
    verticalAlign: "middle",
    // height: 90
  },

  imageStyle: {
    margin: "auto",
    display: "inline-block",
    maxWidth: 200,
    maxHeight: 200,
    paddingLeft: 10,
    minWidth: 200
  },
  imageContainer:{
    width: 450,
    height: 200,
    display: "block",
    maxWidth: 449,
    minWidth: 400,
    maxHeight: 200,
    minHeight: 200,
  }

});


export default styles;