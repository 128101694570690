
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

class ScrollButton extends React.Component {
    constructor(props) {
      super();
  
      this.state = {
          intervalId: 0
      };
    }
  
    scrollStep() {
      if (window.pageYOffset === 0) {
          clearInterval(this.state.intervalId);
      }
      window.scroll(0, window.pageYOffset - 200);
    }
    
    scrollToTop() {
      let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
      this.setState({ intervalId: intervalId });
    }
    
    render () {
        const { classes } = this.props;
        return <button title='Back to top' className={classes.scroll}
                 onClick={ () => { this.scrollToTop(); }}>
                  <span className='arrow-up glyphicon glyphicon-chevron-up'>^</span>
                </button>;
     }
  } 


export default withStyles(styles)(ScrollButton);