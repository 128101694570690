import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Logout from "@material-ui/icons/PowerSettingsNew";
import Button from "@material-ui/core/IconButton";
import styles from "./styles";
import { logout } from "@Utils/helpers.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { unAuthenticate } from "@Actions/authActions";
import { removeDomains } from "@Actions/domainsActions";
import { removeCases } from "@Actions/casesActions";
import { removeMedia } from "@Actions/mediaActions";
import { removeAllTags } from "@Actions/tagsActions";
import { translate } from "react-i18next";

class LogoutBadge extends Component {
  constructor(props) {
    super();
  }

  logoutClicked() {
    logout();
    this.props.actionUnAuthenticate();
    this.props.actionRemoveCases();
    this.props.actionRemoveDomains();
    this.props.actionRemoveMedia();
    this.props.actionRemoveAllTags();
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Button
          title={t("_logout")}
          className={classes.button}
          onClick={() => this.logoutClicked()}
        >
          <Logout className={classes.mainIcon} />
        </Button>
      </div>
    );
  }
}

LogoutBadge.propTypes = {
  classes: PropTypes.object.isRequired,
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionUnAuthenticate: unAuthenticate,
      actionRemoveCases: removeCases,
      actionRemoveDomains: removeDomains,
      actionRemoveMedia: removeMedia,
      actionRemoveAllTags: removeAllTags,
    },
    dispatch
  );
}

export default connect(
  null,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(LogoutBadge)));
