const styles = theme => ({
  root:{
    
  },
  loader: {
    margin: "0px 15px 20px 15px"
  }
});


export default styles;