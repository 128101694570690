import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import UserService from '@Services/User';
import {showSnackbar} from "@Actions/snackbarActions";
import styles from './styles';

class PasswordChange extends Component {
  constructor(props) {
    super();
  }

  state = {
    newPassword: '',
    repeatPassword: '',
    isUpdating: false,
  };

  handleUpdatePassword = () => {
    const { t } = this.props;
    if(this.state.newPassword.trim() === '' || this.state.repeatPassword.trim() === ''){
      this.props.actionShowSnackbar({message: t("_please_fill_all_the_fields"), variant:"warning" });
      return;
    }else if(this.state.repeatPassword.trim() !== this.state.newPassword.trim()){
      this.props.actionShowSnackbar({message: t("_passwords_are_not_match"), variant:"warning" });
      return;
    }else{}

    this.setState({ isUpdating: true });
    UserService.updatePassword(this.state.newPassword).then(
      (response) => {
        this.setState({ isUpdating: false,
          newPassword: '',
          repeatPassword: '', 
        });
        this.props.actionShowSnackbar({message: t("_password_updated_successfully"), variant:"success" });
      }
    ).catch((error) => {
      this.setState({ isUpdating: false });
      // this.props.actionShowSnackbar({ message: error.message, variant: "error" });
    }); 
  }

  handleTextFieldChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <input 
          type="text"
          value="username"
          id="username"
          autoComplete="username"
          className={classes.username}
          readOnly
        />
        <TextField
          value={this.state.newPassword}
          fullWidth
          id="newPassword"
          label={t('_new_password')} 
          className={classes.textField}
          margin="dense"
          type="password"
          onChange={this.handleTextFieldChange('newPassword')}
        />
        <TextField
          value={this.state.repeatPassword}
          fullWidth
          id="repeatPassword"
          label={t('_repeat_password')} 
          className={classes.textField}
          margin="dense"
          type="password"
          onChange={this.handleTextFieldChange('repeatPassword')}
        />
        <LinearProgress className={classes.updateProgress} color="secondary" style={{visibility: this.state.isUpdating ? 'visible' : 'hidden' }}/>
        <Button onClick={this.handleUpdatePassword} color="primary" className={classes.updatePasswordBtn}>
          {t('_update_password')} 
        </Button>
      </div>
    );
  }

}

PasswordChange.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {};
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {
      actionShowSnackbar: showSnackbar
    }, dispatch);
}

export default connect(mapStateToProps,matchDispatchToProps)(withStyles(styles)(translate("translations")(PasswordChange)));