import React, { Component } from "react";

import { authenticate } from "@Actions/authActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isNull, atou } from "@Utils/helpers.js";
import LoginService from "@Services/Login";

class RemoteLogin extends Component {
  componentDidMount() {
    let params = this.props.location.search.substr(1).split(/&/g);

    this.redirectUrl = decodeURIComponent(
      params.find((x) => x.startsWith("redirTo=")).split("=")[1]
    );
    let tokenPart = params.find((x) => x.startsWith("token="));
    if (tokenPart) this.token = decodeURIComponent(tokenPart.split("=")[1]);

    let adminTokenPart = params.find((x) => x.startsWith("admintoken="));
    if (adminTokenPart) {
      this.adminToken = decodeURIComponent(adminTokenPart.split("=")[1]);
      // when we have a admin token we have a loginfor as well
      let loginforPart = params.find((x) => x.startsWith("loginfor="));
      if (loginforPart) {
        this.loginFor = decodeURIComponent(loginforPart.split("=")[1]);
      }
    }

    if (this.adminToken) {
      this.impersonate();
    } else {
      this.loginAndRedirect();
    }
  }

  impersonate() {
    // set token to use it in request
    localStorage.setItem("Token", this.adminToken);

    LoginService.getImpersonationToken(this.loginFor)
      .then((response) => {
        this.loginAndRedirect(response.token);
      })
      .catch((e) => {
        console.error("was not able to impersonate", e);
      });
  }

  loginAndRedirect(forcedToken) {
    let path = this.redirectUrl;
    var userData;
    var activeToken ;
    if (forcedToken) {
      activeToken = forcedToken;
      console.log("Remotelogin with impersonation.");
    } else {
      activeToken = this.token;
     
    }
    userData = JSON.parse(atou(activeToken.split(".")[1]));
    localStorage.setItem("LoginName", userData.username);
    localStorage.setItem("ownParty", userData.ownParty);
    localStorage.setItem("Token", activeToken);
    localStorage.setItem("Id", userData.userid);
    localStorage.setItem("UserName", userData.username);
    localStorage.setItem("Name", userData.Name);
    localStorage.setItem("FirstName", userData.userFirstName);
    localStorage.setItem("LastName", userData.userLastName);
    localStorage.setItem("Email", userData.email);
    localStorage.setItem("CultureID", userData.cultureId);
    localStorage.setItem("CustomerID", userData.customerID);
    localStorage.setItem("AvatarID", userData.avatarID);
    localStorage.setItem("AvatarImage", "");
    localStorage.setItem("IAT", userData.iat);
    localStorage.setItem("CD", userData.cd);
    localStorage.setItem("RoleID", userData.userRoleID);
    localStorage.setItem(
      "media_info_config",
      JSON.stringify(userData.media_info_config)
    );
    if (isNull(localStorage.getItem("SelectedFilters"))) {
      localStorage.setItem(
        "SelectedFilters",
        JSON.stringify({ domain: 0, case: 0, media: 0 })
      );
    }
    this.props.actionAuthenticate();
    window.location.replace(path);
  }

  render() {
    return <div></div>;
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionAuthenticate: authenticate,
    },
    dispatch
  );
}

export default connect(null, matchDispatchToProps)(RemoteLogin);
