import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import styles from './styles';

class AvatarBadge extends Component {
  constructor(props) {
    super();
  }
  
  imageError(e){
    e.target.src = require('@Assets/images/empty.png');
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Button className={classes.button}>
          <Avatar
            onError={this.imageError}
            alt="Adelle Charles"
            src={this.props.avatar}
            className={classes.avatar}
          />
          <div  className={classes.infoContainer}>
            <div className={classes.avatarName}>{localStorage.getItem("FirstName") + " " + localStorage.getItem("LastName")}</div>
            <div className={classes.avatarDetails} title={localStorage.getItem("UserName")}>{localStorage.getItem("UserName")}</div>
          </div>
        </Button>
      </div>
    );
  }
}

AvatarBadge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AvatarBadge);