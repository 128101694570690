import { 
  GET_COLLECTION_PARTNERS, 
  GET_USERS, 
  GET_CASE_STATES,
  GET_CASE_TYPES,
  UPDATE_USER_AVATAR
} from '@Types/actionTypes';

export function getCollectionPartners(data){

  return {
    type : GET_COLLECTION_PARTNERS,
    payload : data
  }
}

export function getUsers(data){

  return {
    type : GET_USERS,
    payload : data
  }
}

export function getCaseStates(data){

  return {
    type : GET_CASE_STATES,
    payload : data
  }
}

export function getCaseTypes(data){
  return {
    type: GET_CASE_TYPES,
    payload : data
  }
}

export function updateUserAvatar(avatarId){

  return {
    type : UPDATE_USER_AVATAR,
    payload : avatarId
  }
}
