import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import UploadImage from "./UploadImage";
import { setToolbar } from "@Actions/toolbarActions";
import { getMedia, getMediaMore } from "@Actions/mediaActions";
import {
  initCurrentFilterType,
  updateCurrentFilterId,
} from "@Actions/filterActions";
import { setNavbar1, setNavbar2, setNavbar3 } from "@Actions/navbarActions";
import { showSnackbar } from "@Actions/snackbarActions";
import styles from "./styles";
import { translate } from "react-i18next";
import MediaItem from "@Components/MediaItem";
import MediaService from "@Services/Media";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DialogPopup from "@Components/DialogPopup";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";

import InfiniteScroll from "react-infinite-scroller";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoadingBg from "@Components/LoadingBg";
import { getSelectedFilters } from "@Utils/helpers";
import { removeSearch } from "@Actions/searchActions";

class Media extends Component {
  constructor(props) {
    super(props);
    this.props.actionRemoveSearch();
    this.props.hideLoading();
    this.props.showLoading();
    this.props.setToolbar(2);
    this.props.actionInitCurrentFilterType("media");
    setTimeout(() => {
      this.props.actionUpdateCurrentFilterId(getSelectedFilters()["media"]);
    }, 3000);

    this.props.setNavbar1({ name: this.props.t("_image"), link: "/media" });
    this.props.setNavbar2(null);
    this.props.setNavbar3(null);
  }

  state = {
    open: false,
    hasMore: false,
    isLoading: true,
    lbgMessage: "",
    lbgAnimated: true,
    isScrollReseted: false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    if (
      this.props.mediaReducer == null ||
      this.props.location.search === "?r" ||
      this.props.searchReducer.search.class == null
    ) {
      MediaService.getMedia({
        pageNum: 1,
        filter: getSelectedFilters()["media"],
      })
        .then((response) => {
          this.props.actionGetMedia({ results: response });
          this.props.hideLoading();
          this.setState({ hasMore: true });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false, hasMore: true });
          this.setState({ lbgAnimated: false, lbgMessage: error.message });
          this.props.actionShowSnackbar({
            message: error.message,
            variant: "error",
          });
          this.props.hideLoading();
        });
    } else {
      this.setState({ hasMore: true });
      this.props.hideLoading();
    }
    // resetAllScroll();
    setTimeout(() => {
      window.scrollTo(0, -300);
    }, 1000);
  }

  populateMedia() {
    if (!this.props.mediaReducer.results) return <></>;
    var list = [];
    _.forOwn(this.props.mediaReducer.results, function (value, key) {
      list.push(<MediaItem mediaId={key} key={key} data={value} />);
    });
    return list;
  }

  loadMore = (page) => {
    if (!this.props.mediaReducer.results) return;
    page = Object.keys(this.props.mediaReducer.results).length / 20 + 1;
    if (
      this.props.mediaReducer == null ||
      this.props.mediaReducer.results === {} ||
      Object.keys(this.props.mediaReducer).length === 0
    ) {
      if (this.state.isScrollReseted === false) {
        this.setState({ hasMore: true });
      }
    }
    if (this.state.isLoading) {
      return;
    }

    if ((page - 1) * 20 > Object.keys(this.props.mediaReducer.results).length) {
      this.setState({ hasMore: false });
    } else {
      this.setState({
        hasMore: false,
        isLoading: true,
        isScrollReseted: false,
      });
      MediaService.getMedia({
        pageNum: page,
        filter: getSelectedFilters()["media"],
      })
        .then((response) => {
          this.props.actionGetMediaMore({ results: response });
          this.setState({ hasMore: true });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
        });
    }
  };

  render() {
    const { classes, t } = this.props;
    var hasReallyMore = true;
    if (this.props.mediaReducer && this.props.mediaReducer.results) {
      var page = Object.keys(this.props.mediaReducer.results).length / 20 + 1;
      var reqPage = Math.floor(
        Object.keys(this.props.mediaReducer.results).length / 20 + 1
      );
      if (page > reqPage) hasReallyMore = false;
    }
    if (
      this.props.mediaReducer == null ||
      this.props.mediaReducer.results === {} ||
      this.props.total === "counting...."
    ) {
      // if(this.props.mediaReducer.results === {}) this.setState({lbgAnimated : false});
      if (this.state.isScrollReseted === false) {
        this.setState({ hasMore: true, isScrollReseted: true });
      }
      return (
        <div className={classes.root}>
          <LoadingBg
            pageType="media"
            message={this.state.lbgMessage}
            animated={this.state.lbgAnimated}
          />
        </div>
      );
    } else if (this.props.mediaReducer && this.props.mediaReducer.total === 0) {
      if (this.state.isScrollReseted === false) {
        this.setState({ hasMore: true, isScrollReseted: true });
      }
      return (
        <div className={classes.root}>
         <Button
          variant="contained"
          color="secondary"
          siz="large"
          onClick={this.handleClickOpen}
          className={classes.uploadButton}
        >
          {t("_add_images")}
          <CloudUploadIcon className={classes.rightIcon} />
        </Button>
          <LoadingBg
            pageType="media"
            message={this.props.t("_no_data_found")}
            animated={false}
          />
         <DialogPopup
          classes={{ paper: classes.FSDialogPaper }}
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.fSToolbar}>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {t("_image_upload")}
              </Typography>
              <Button color="inherit" onClick={this.handleClose}>
                {t("_close")}
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.FSPopupRoot}>
            <UploadImage />
          </div>
        </DialogPopup>
        </div>
      );
    } else {
    }

    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="secondary"
          siz="large"
          onClick={this.handleClickOpen}
          className={classes.uploadButton}
        >
          {t("_add_images")}
          <CloudUploadIcon className={classes.rightIcon} />
        </Button>

        <div className={classes.mediaListContainer}>
          <InfiniteScroll
            pageStart={
              this.props.mediaReducer.results
                ? Math.floor(
                    Object.keys(this.props.mediaReducer.results).length / 20
                  )
                : 0
            }
            loadMore={this.loadMore}
            hasMore={hasReallyMore}
            loader={
              <div className={classes.loader} key={0}>
                <LinearProgress color="secondary" />
              </div>
            }
          >
            {this.populateMedia()}
          </InfiniteScroll>
          {!this.state.hasMore && this.state.isLoading && (
            <div className={classes.loader} key={1}>
              <LinearProgress color="secondary" />
            </div>
          )}
        </div>
        <DialogPopup
          classes={{ paper: classes.FSDialogPaper }}
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
        >
          <AppBar className={classes.appBar}>
            <Toolbar className={classes.fSToolbar}>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {t("_image_upload")}
              </Typography>
              <Button color="inherit" onClick={this.handleClose}>
                {t("_close")}
              </Button>
            </Toolbar>
          </AppBar>
          <div className={classes.FSPopupRoot}>
            <UploadImage />
          </div>
        </DialogPopup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mediaReducer: state.mediaReducer,
    filterReducer: state.filterReducer,
    searchReducer: state.searchReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      setToolbar,
      setNavbar1,
      setNavbar2,
      setNavbar3,

      actionRemoveSearch: removeSearch,

      actionGetMedia: getMedia,
      actionGetMediaMore: getMediaMore,
      actionInitCurrentFilterType: initCurrentFilterType,
      actionUpdateCurrentFilterId: updateCurrentFilterId,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(Media)));
