import { SHOW_SNACKBAR, HIDE_SNACKBAR } from '@Types/actionTypes';

const initialSnackbar = {open: false, message: "", variant: "info"};
const snackReducer = (state = initialSnackbar, action) => {

  switch (action.type) {
    
    case SHOW_SNACKBAR:
      state = {...state, open: true, message: action.payload.message, variant: action.payload.variant};
      break; 
    
    case HIDE_SNACKBAR:
      state = {...state, open: false};
      break; 

    default:
      break;
  }
  
  return state;
};

export default snackReducer;