// import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root:{
    width: 500,
    minWidth: 500,
    maxWidth: 500,
    height: 400,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,
    // backgroundColor: '#e5675e'    
  },

  filterNameFormControl: {
    width: 480,
    marginBottom: 10,
    display:"block"
  },

  filterNameTextField: {
    display: "inline-block",
    width: 335,
    '& > div':{
      width: 325
    }
  },

  duplicateBtnContainer:{
    display: "inline-block",
    // width: 120,
    paddingTop: 10,
    // paddingRight: 10
  },
  removeBtnContainer:{
    display: "inline-block",
    // width: 100,
    paddingTop: 10
  },
  saveBtnContainer:{
    display: "inline-block",
    // width: 100,
    paddingTop: 10
  },

  addIcon: {
    marginRight: theme.spacing.unit,
  },

  addBtnContainer:{
    textAlign: "right",
    padding: "10px 20px 10px 0px",
  },


  alloranyFormControl:{
    display: "inline-block",
    float: "left"
  },

  removeIcon: {
    // marginRight: theme.spacing.unit,
  },
  duplicateIcon: {
    // marginRight: theme.spacing.unit,
  },
  saveIcon: {
    // marginRight: theme.spacing.unit,
  },

  noFilterSelected:{
    textAlign : "center",
    width: "100%",
    paddingTop: 15
  },

  noFilterDefined:{
    textAlign: "center"
  },

  addRuleButton:{
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent"
    }
  
  }
});


export default styles;