import {
  GET_CASES,
  GET_CASES_MORE,
  GET_CASES_SEARCH,
  GET_CASES_SEARCH_MORE,
  GET_CASE,
  UPDATE_CASE,
  REMOVE_CASES,
  REMOVE_CASES_TAG,
  ADD_CASES_TAG,
  DELETE_CASES_NOTE,
  UPDATE_CASES_STATUS,
  ADD_CASES_NOTE,
  ADD_CASES_DOC,
  UPDATE_CASES_COLLECTION_PARTNER,
  UPDATE_CASES_FEES,
  UPDATE_CASE_FINDING_LICENCE_FEE,
  GET_CASE_FEES,
  DECREASE_UNREAD_COUNT,
} from "@Types/actionTypes";
import _ from "lodash";
import { getIndexBy } from "@Utils/helpers";

const casesReducer = (state = null, action) => {
  switch (action.type) {
    case GET_CASES:
    case GET_CASES_SEARCH:
      state = null;
      if (!action.payload.results.results)
        state = Object.assign({}, state, action.payload.results, {
          results: {},
        });
      else state = Object.assign({}, state, action.payload.results);
      break;

    case GET_CASES_MORE:
    case GET_CASES_SEARCH_MORE:
      if (action.payload.results.total !== 0)
        state = {
          total: action.payload.results.total,
          results: _.merge(state.results, action.payload.results.results),
          allowedBulkActions: action.payload.results.allowedBulkActions,
        };
      break;

    case REMOVE_CASES:
      state = null;
      break;

    case GET_CASE:
      state = { total: 1, results: {} };
      state.results[action.payload.info.caseId] = action.payload;
      break;

    case UPDATE_CASE:
      if (!state) {
        state = { total: 1, results: {} };
        state.results[action.payload.info.caseId] = action.payload;
      } else if (!state.results[action.payload.caseId]) {
        state = {
          ...state,
          results: {
            ...state.results,
            [action.payload.caseId]: action.payload.results,
          },
        };
      } else {
        state = {
          ...state,
          results: {
            ...state.results,
            [action.payload.caseId]: action.payload.results,
          },
        };
      }
      break;

    case ADD_CASES_TAG:
      // var updatedTagsA = state.results[action.payload.caseId].tags;
      // updatedTagsA.push(action.payload.tag);
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            tags: [
              ...state.results[action.payload.caseId].tags,
              action.payload.tag,
            ],
          },
        },
      };
      break;

    case REMOVE_CASES_TAG:
      const index = getIndexBy(
        state.results[action.payload.caseId].tags,
        "id",
        action.payload.tag.id
      );

      let newArray = state.results[action.payload.caseId].tags.slice();
      newArray.splice(index, 1);

      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            tags: newArray,
          },
        },
      };
      break;

    case UPDATE_CASES_STATUS:
      // if(action.payload.data && action.payload.data.route && (action.payload.status === 2 || action.payload.status === "2")){
      //   state = {...state, results:
      //     {...state.results, [action.payload.caseId]:
      //       {...state.results[action.payload.caseId],
      //         info:
      //         {...state.results[action.payload.caseId].info,
      //           route: action.payload.data.route
      //         }
      //       }
      //     }
      //   } ;
      // }else{

      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            info: {
              ...state.results[action.payload.caseId].info,
              status: action.payload.status,
            },
          },
        },
      };
      // }
      break;

    case ADD_CASES_NOTE:
      var newNote = {
        id: new Date(),
        note: action.payload.note,
        date: new Date().toISOString(),
        owner:
          localStorage.getItem("FirstName") +
          " " +
          localStorage.getItem("LastName"),
      };

      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            notes: [newNote, ...state.results[action.payload.caseId].notes],
          },
        },
      };
      break;

    case DELETE_CASES_NOTE:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            notes: state.results[action.payload.caseId].notes.filter(
              (x) => x.id !== action.payload.data.id
            ),
          },
        },
      };
      break;

    case ADD_CASES_DOC:
      var newDoc = {
        id: action.payload.data.uploadedAt,
        uploadedAt: action.payload.data.uploadedAt,
        fileName: action.payload.data.fileName,
        fileSize: action.payload.data.fileSize,
        documentType: action.payload.data.documentType,
      };

      state.results[action.payload.caseId].document.documents.unshift(newDoc);
      break;

    case UPDATE_CASES_COLLECTION_PARTNER:
      console.log(action.payload);
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            lawyer: action.payload.data,
            info: {
              ...state.results[action.payload.caseId].info,
              collectionPartner: action.payload.data.name,
              route:
                action.payload.name === "sales"
                  ? "sales"
                  : action.payload.data.name,
            },
          },
        },
      };
      break;

    case UPDATE_CASES_FEES:
      // data:
      // caseId
      // data:
      //    licenceFee
      //    researchFee
      //    totalPaymentRequested

      // var newState = store.getState().casesReducer;
      // newState.results[data.caseId].fees = data.data;

      state = action.payload;
      break;

    case UPDATE_CASE_FINDING_LICENCE_FEE:
      // get old value
      //{ caseId: this.props.caseId, findingId: this.props.data.findingId, value: this.state.newFeeValue }
      //calculate new value
      break;

    case GET_CASE_FEES:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            fees: action.payload.fees,
            info: {
              ...state.results[action.payload.caseId].info,
              licenseSum: action.payload.fees.licenceFee,
            },
          },
        },
      };

      break;
    case DECREASE_UNREAD_COUNT:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: {
            ...state.results[action.payload.caseId],
            info: {
              ...state.results[action.payload.caseId].info,
              numUnreadMessages:
                state.results[action.payload.caseId].info.numUnreadMessages - 1,
            },
          },
        },
      };
      break;
    default:
      break;
  }

  return state;
};

export default casesReducer;
