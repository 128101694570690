import { grey, blue } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft:15,
    marginRight:15,
    marginTop:5,
  },
  headingTitle: {
    width:80,
    fontSize: 16,
    color: blue[700],
    margin:5,
    fontWeight:600
  },
  headingCount: {
    fontSize: 14,
    margin:5,
    marginLeft: 15,
  },
  panelRoot:{
    backgroundColor: grey[300]
  },
  content:{
    display:"block"
  },
  noNewItem:{
    textAlign: "center",
    fontSize: 16,
  },
  loader: {
    margin: "0px 15px 20px 15px"
  }
});

export default styles;