const imageWidth = 596;
const styles = theme => ({
  root:{
  },
  imagesContainer:{
    position:'relative',
    width:imageWidth,
    height:500,
    backgroundColor:'#000'
  },

  imageDiv:{
    position:'absolute',
    textAlign: 'center',
    height:500,
    width:imageWidth,
    '& img':{
      height: '100%', 
      width: '100%', 
      objectFit: 'contain'
    }
  },
  sliderContainer:{
    padding: 10
  },
  sliderRoot:{
    width:'auto !important'
  }
});


export default styles;