import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root:{
    // marginTop:35,
    paddingBottom:20
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  appBar: {
    position: 'relative',
    backgroundColor:grey[900]
  },
  flex: {
    flex: 1,
  },
  FSToolbar:{
    minWidth:850
  },
  FSPopupRoot:{
    width:880,
    marginTop:15,
    marginLeft:'auto',
    marginRight:'auto',
    padding:10,
  },
  FSDialogPaper:{
    backgroundColor:grey[50],
  },
  mediaListContainer:{
    padding:"15px 0"
  },
  loader: {
    margin: "0px 15px 20px 15px"
  },
  uploadButton: {
    textTransform: "initial",
    color: "#f50057",
    backgroundColor: "#fff",
    border: "1px solid #f50057",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#f50057",
      border: "1px solid transparent"
    },
  }
});


export default styles;