import {red, grey, blue, green} from '@material-ui/core/colors';

const styles = theme => ({
  root: {

  },
  linkRoot:{
    width:300,
  },
  title:{
    color: "#666",
    /* font-size: 13px; */
    /* font-weight: bold; */
    width: 210,
    fontWeight: 700
  },
  subTitle:{
    display: "inline-block",
    fontSize: 12,
    // lineHeight: "12px",
    color: "#666",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: 200, 
    whiteSpace: "nowrap",
  }, 
  tooltip: {
    maxWidth: 'none',
  },
  logLink:{
    textDecoration:'none'
  },
  listItemMain:{
    width: 300,
  },
  listItemText:{
    width: 300,
  },
  dateText:{
    textAlign:"right",
    fontSize:9,
    width: 200,
    display: "inline-block"
  },
  redIcon:{
    color: red[700]
  },
  blueIcon:{
    color: blue[700]
  },
  greenIcon:{
    color: green[700]
  },
  greyIcon:{
    color: grey[700]
  }
});


export default styles;