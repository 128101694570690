import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Setting from '@Services/Setting';
import LinearProgress from '@material-ui/core/LinearProgress';
import {showSnackbar} from "@Actions/snackbarActions";
import {updateUserAvatar} from '@Actions/globalActions';
import styles from './styles';

class AvatarChange extends Component {
  constructor(props) {
    super(props);

    this.onFilesSelected = this.onFilesSelected.bind(this);
    
    this.state = {
      imageData : {},
      isUploading: false,
    }
  }

  imageError(e){
    e.target.src = require('@Assets/images/empty.png');
  }

  onFilesSelected(event){

    this.setState({ isUploading: true});

    var inputFile = event.target.files[0];

    Setting.updateAvatar(inputFile).then(
      (response) => {
        console.log(response);
        this.props.actionUpdateUserAvatar(response.avatarId);
        this.setState({ 
          isUploading: false,     
          imageData: {},
        });
        this.props.actionShowSnackbar({message: "Avatar updated Successfully!", variant:"success" });
        
      }
    ).catch((error) => {
      this.setState({ isUpdating: false });
      // this.props.actionShowSnackbar({ message: error.message, variant: "error" });
    });; 
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <GridList cellHeight={290} className={classes.gridList} cols={1}>

            <GridListTile key={1} cols={1} classes={{tile:classes.tileStyle}} >
              <img src={this.props.globalReducer.userInfo.avatar} alt="" className={classes.imageStyle} onError={this.imageError}/>
            </GridListTile>

          </GridList>
        </div>
        <LinearProgress className={classes.uploadProgress} color="secondary" style={{visibility: this.state.isUploading ? 'visible' : 'hidden' }}/>
        <input
          accept="image/*"
          className={classes.input}
          id="inputFile"
          type="file"
          onChange={this.onFilesSelected}
        />
        <label htmlFor="inputFile">
          <Button variant="contained" component="span" color="primary" fullWidth className={classes.changeAvatarBtn} disabled={this.state.isUploading ? true : false }>
            {t("_change_avatar")}
          </Button>
        </label>
      </div>
    );
  }

}

AvatarChange.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {
    globalReducer : state.globalReducer
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {
      actionUpdateUserAvatar: updateUserAvatar,
      actionShowSnackbar: showSnackbar
    }, dispatch);
}

export default connect(mapStateToProps,matchDispatchToProps)(translate("translations")(withStyles(styles)(AvatarChange)));