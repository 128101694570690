const Config = {
  AD: {
    id: "AD",
    name: "AD",
    display: true,
    fullname: "Andorra",
  },
  AE: {
    id: "AE",
    name: "AE",
    display: true,
    fullname: "United Arab Emirates",
  },
  AL: {
    id: "AL",
    name: "AL",
    display: true,
    fullname: "Albania",
  },
  AM: {
    id: "AM",
    name: "AM",
    display: true,
    fullname: "Armenia",
  },
  AR: {
    id: "AR",
    name: "AR",
    display: true,
    fullname: "Argentina",
  },
  AT: {
    id: "AT",
    name: "AT",
    display: true,
    fullname: "Austria",
  },
  AU: {
    id: "AU",
    name: "AU",
    display: true,
    fullname: "Australia",
  },
  AZ: {
    id: "AZ",
    name: "AZ",
    display: true,
    fullname: "Azerbaijan",
  },
  BA: {
    id: "BA",
    name: "BA",
    display: true,
    fullname: "Bosnia and Herzegovina",
  },
  BD: {
    id: "BD",
    name: "BD",
    display: true,
    fullname: "Bangladesch",
  },
  BE: {
    id: "BE",
    name: "BE",
    display: true,
    fullname: "Belgium",
  },
  BG: {
    id: "BG",
    name: "BG",
    display: true,
    fullname: "Bulgaria",
  },
  BM: {
    id: "BM",
    name: "BM",
    display: true,
    fullname: "Bermuda",
  },
  BR: {
    id: "BR",
    name: "BR",
    display: true,
    fullname: "Brasilien",
  },
  CA: {
    id: "CA",
    name: "CA",
    display: true,
    fullname: "Canada",
  },
  CH: {
    id: "CH",
    name: "CH",
    display: true,
    fullname: "Switzerland",
  },
  CL: {
    id: "CL",
    name: "CL",
    display: true,
    fullname: "Chile",
  },
  CN: {
    id: "CN",
    name: "CN",
    display: true,
    fullname: "China",
  },
  CO: {
    id: "CO",
    name: "CO",
    display: true,
    fullname: "Colombia",
  },
  CW: {
    id: "CW",
    name: "CW",
    display: true,
    fullname: "Curaçao",
  },
  CY: {
    id: "CY",
    name: "CY",
    display: true,
    fullname: "Cyprus",
  },
  CZ: {
    id: "CZ",
    name: "CZ",
    display: true,
    fullname: "Czechia",
  },
  DE: {
    id: "DE",
    name: "DE",
    display: true,
    fullname: "Germany",
  },
  DK: {
    id: "DK",
    name: "DK",
    display: true,
    fullname: "Denmark",
  },
  DO: {
    id: "DO",
    name: "DO",
    display: true,
    fullname: "Dominican Republic",
  },
  EC: {
    id: "EC",
    name: "EC",
    display: true,
    fullname: "Ecuador",
  },
  EE: {
    id: "EE",
    name: "EE",
    display: true,
    fullname: "Estonia",
  },
  EG: {
    id: "EG",
    name: "EG",
    display: true,
    fullname: "Egypt",
  },
  ES: {
    id: "ES",
    name: "ES",
    display: true,
    fullname: "Spain",
  },
  FI: {
    id: "FI",
    name: "FI",
    display: true,
    fullname: "Finland",
  },
  FR: {
    id: "FR",
    name: "FR",
    display: true,
    fullname: "France",
  },
  GB: {
    id: "GB",
    name: "GB",
    display: true,
    fullname: "United Kingdom of Great Britain and Northern Ireland",
  },
  GE: {
    id: "GE",
    name: "GE",
    display: true,
    fullname: "Georgia",
  },
  GF: {
    id: "GF",
    name: "GF",
    display: true,
    fullname: "French Guiana",
  },
  GG: {
    id: "GG",
    name: "GG",
    display: true,
    fullname: "Guernsey",
  },
  GP: {
    id: "GP",
    name: "GP",
    display: true,
    fullname: "Guadeloupe",
  },
  GR: {
    id: "GR",
    name: "GR",
    display: true,
    fullname: "Greece",
  },
  GT: {
    id: "GT",
    name: "GT",
    display: true,
    fullname: "Guatemala",
  },
  HK: {
    id: "HK",
    name: "HK",
    display: true,
    fullname: "Hong Kong",
  },
  HR: {
    id: "HR",
    name: "HR",
    display: true,
    fullname: "Croatia",
  },
  HU: {
    id: "HU",
    name: "HU",
    display: true,
    fullname: "Hungary",
  },
  ID: {
    id: "ID",
    name: "ID",
    display: true,
    fullname: "Indonesia",
  },
  IE: {
    id: "IE",
    name: "IE",
    display: true,
    fullname: "Ireland",
  },
  IL: {
    id: "IL",
    name: "IL",
    display: true,
    fullname: "Israel",
  },
  IN: {
    id: "IN",
    name: "IN",
    display: true,
    fullname: "India",
  },
  IR: {
    id: "IR",
    name: "IR",
    display: true,
    fullname: "Iran",
  },
  IS: {
    id: "IS",
    name: "IS",
    display: true,
    fullname: "Iceland",
  },
  IT: {
    id: "IT",
    name: "IT",
    display: true,
    fullname: "Italy",
  },
  JO: {
    id: "JO",
    name: "JO",
    display: true,
    fullname: "Jordan",
  },
  JP: {
    id: "JP",
    name: "JP",
    display: true,
    fullname: "Japan",
  },
  KE: {
    id: "KE",
    name: "KE",
    display: true,
    fullname: "Kenya",
  },
  KH: {
    id: "KH",
    name: "KH",
    display: true,
    fullname: "Cambodia",
  },
  KN: {
    id: "KN",
    name: "KN",
    display: true,
    fullname: "Saint Kitts and Nevis",
  },
  KP: {
    id: "KP",
    name: "KP",
    display: true,
    fullname: "Democratic People's Republic of Korea",
  },
  KR: {
    id: "KR",
    name: "KR",
    display: true,
    fullname: "Republic of Korea",
  },
  KY: {
    id: "KY",
    name: "KY",
    display: true,
    fullname: "Cayman Islands",
  },
  LB: {
    id: "LB",
    name: "LB",
    display: true,
    fullname: "Lebanon",
  },
  LI: {
    id: "LI",
    name: "LI",
    display: true,
    fullname: "Liechtenstein",
  },
  LK: {
    id: "LK",
    name: "LK",
    display: true,
    fullname: "Sri Lanka",
  },
  LT: {
    id: "LT",
    name: "LT",
    display: true,
    fullname: "Lithuania",
  },
  LU: {
    id: "LU",
    name: "LU",
    display: true,
    fullname: "Luxembourg",
  },
  LV: {
    id: "LV",
    name: "LV",
    display: true,
    fullname: "Latvia",
  },
  MA: {
    id: "MA",
    name: "MA",
    display: true,
    fullname: "Morocco",
  },
  ME: {
    id: "ME",
    name: "ME",
    display: true,
    fullname: "Montenegro",
  },
  MH: {
    id: "MH",
    name: "MH",
    display: true,
    fullname: "Marshall Islands",
  },
  MK: {
    id: "MK",
    name: "MK",
    display: true,
    fullname: "Republic of North Macedonia",
  },
  MT: {
    id: "MT",
    name: "MT",
    display: true,
    fullname: "Malta",
  },
  MX: {
    id: "MX",
    name: "MX",
    display: true,
    fullname: "Mexico",
  },
  MY: {
    id: "MY",
    name: "MY",
    display: true,
    fullname: "Malaysia",
  },
  NA: {
    id: "NA",
    name: "NA",
    display: true,
    fullname: "Namibia",
  },
  NL: {
    id: "NL",
    name: "NL",
    display: true,
    fullname: "Netherlands",
  },
  NO: {
    id: "NO",
    name: "NO",
    display: true,
    fullname: "Norway",
  },
  NP: {
    id: "NP",
    name: "NP",
    display: true,
    fullname: "Nepal",
  },
  NZ: {
    id: "NZ",
    name: "NZ",
    display: true,
    fullname: "New Zealand",
  },
  PA: {
    id: "PA",
    name: "PA",
    display: true,
    fullname: "Panama",
  },
  PF: {
    id: "PF",
    name: "PF",
    display: true,
    fullname: "French Polynesia",
  },
  PH: {
    id: "PH",
    name: "PH",
    display: true,
    fullname: "Philippines",
  },
  PK: {
    id: "PK",
    name: "PK",
    display: true,
    fullname: "Pakistan",
  },
  PL: {
    id: "PL",
    name: "PL",
    display: true,
    fullname: "Poland",
  },
  PR: {
    id: "PR",
    name: "PR",
    display: true,
    fullname: "Puerto Rico",
  },
  PT: {
    id: "PT",
    name: "PT",
    display: true,
    fullname: "Portugal",
  },
  PY: {
    id: "PY",
    name: "PY",
    display: true,
    fullname: "Paraguay",
  },
  RO: {
    id: "RO",
    name: "RO",
    display: true,
    fullname: "Romania",
  },
  RS: {
    id: "RS",
    name: "RS",
    display: true,
    fullname: "Serbia",
  },
  RU: {
    id: "RU",
    name: "RU",
    display: true,
    fullname: "Russian Federation",
  },
  SA: {
    id: "SA",
    name: "SA",
    display: true,
    fullname: "Saudi Arabia",
  },
  SC: {
    id: "SC",
    name: "SC",
    display: true,
    fullname: "Seychelles",
  },
  SE: {
    id: "SE",
    name: "SE",
    display: true,
    fullname: "Sweden",
  },
  SG: {
    id: "SG",
    name: "SG",
    display: true,
    fullname: "Singapore",
  },
  SI: {
    id: "SI",
    name: "SI",
    display: true,
    fullname: "Slovenia",
  },
  SK: {
    id: "SK",
    name: "SK",
    display: true,
    fullname: "Slovakia",
  },
  SM: {
    id: "SM",
    name: "SM",
    display: true,
    fullname: "San Marino",
  },
  TH: {
    id: "TH",
    name: "TH",
    display: true,
    fullname: "Thailand",
  },
  TN: {
    id: "TN",
    name: "TN",
    display: true,
    fullname: "Tunisia",
  },
  TR: {
    id: "TR",
    name: "TR",
    display: true,
    fullname: "Turkey",
  },
  TW: {
    id: "TW",
    name: "TW",
    display: true,
    fullname: "Taiwan",
  },
  TZ: {
    id: "TZ",
    name: "TZ",
    display: true,
    fullname: "Tanzania",
  },
  UA: {
    id: "UA",
    name: "UA",
    display: true,
    fullname: "Ukraine",
  },
  UG: {
    id: "UG",
    name: "UG",
    display: true,
    fullname: "Uganda",
  },
  UM: {
    id: "UM",
    name: "UM",
    display: true,
    fullname: "United States Minor Outlying Islands",
  },
  US: {
    id: "US",
    name: "US",
    display: true,
    fullname: "United States of America",
  },
  UY: {
    id: "UY",
    name: "UY",
    display: true,
    fullname: "Uruguay",
  },
  VN: {
    id: "VN",
    name: "VN",
    display: true,
    fullname: "Viet Nam",
  },
  YE: {
    id: "YE",
    name: "YE",
    display: true,
    fullname: "Yemen",
  },
  ZA: {
    id: "ZA",
    name: "ZA",
    display: true,
    fullname: "South Africa",
  },
};

export default Config;
