import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DialogPopup from "@Components/DialogPopup";
import Paper from "@material-ui/core/Paper";
import InfoIcon from "@material-ui/icons/Info";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { showMediaTags } from "@Utils/helpers.js";
import DomainsService from "@Services/Domains";
import FindingsService from "@Services/Findings";
import { translate } from "react-i18next";
import Button from "@material-ui/core/Button";
import styles from "./styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { updateDomain, removeDomain } from "@Actions/domainsActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import { CDNurl } from "@Configs/globalConfig";
import { showSnackbar } from "@Actions/snackbarActions";
import { getFindingsNew } from "@Actions/findingsActions";
import allIptcFields from "@Configs/mediaInfoConfig";
import { Link } from "react-router-dom";

class CaseCreationButton extends Component {
  constructor(props) {
    super();
  }

  state = {
    popupOpen: false,
    isCreating: false,
    dropdownOpen: false,
    collectionPartnerList: [],
    showAbortedCaseWarning: false,

    findingList: [],
    findings: [],
    checked: [],

    route: "later", //  (collectionpartnerguid,sales,later)

    reviewAfterDocumentation: false,
    skipDocumentation: false,
    saveAsDraft: false,
    activeStep: 0,
  };

  handleSelectAll = () => {
    var newChecked = [];
    var newFindings = [];
    this.state.findingList.map((value, index) => {
      newChecked.push(index);
      newFindings.push(value);
      return null;
    });
    console.log(this.state.findingList.length);
    console.log(newChecked.length);
    this.setState({ checked: newChecked, findings: newFindings });
  };

  handleToggleCheckbox = (selectedFindingIndex) => () => {
    const { checked, findings, findingList } = this.state;
    const currentIndex = checked.indexOf(selectedFindingIndex);
    const newChecked = [...checked];
    const newFindongs = [...findings];

    if (currentIndex === -1) {
      newChecked.push(selectedFindingIndex);
      newFindongs.push(findingList[selectedFindingIndex]);
    } else {
      newChecked.splice(currentIndex, 1);
      newFindongs.splice(currentIndex, 1);
    }
    console.log(newChecked, newFindongs);

    this.setState({
      checked: newChecked,
      findings: newFindongs,
    });
  };

  populateFindings = () => {
    const { classes, t } = this.props;
    return (
      <List className={classes.root}>
        {this.state.findingList.map((value, index) => (
          <ListItem
            key={index}
            role={undefined}
            dense
            button
            onClick={this.handleToggleCheckbox(index)}
          >
            <Checkbox
              checked={this.state.checked.indexOf(index) !== -1}
              tabIndex={-1}
              disableRipple
            />

            <div className={classes.findingDetailsCOntainer}>
              <div className={classes.findingNumber}>{value.searchNumber}</div>
              <div className={classes.itemUrlLinkContainer}>
                <a
                  className={classes.itemUrlLink}
                  target="_blank"
                  href={value.foundImageUrl}
                  rel="noopener noreferrer"
                >
                  {t("_found_image_url")}
                </a>
                <b> | </b>
                <a
                  className={classes.itemUrlLink}
                  target="_blank"
                  href={value.foundPageUrl}
                  rel="noopener noreferrer"
                >
                  {t("_found_page_url")}
                </a>
              </div>
              {value.searchimage_iptc_fields.map((item, index) => (
                <div className={classes.iptcData} key={index}>
                  <b>{allIptcFields[item.id].name}: </b>
                  {item.value}
                </div>
              ))}
              {showMediaTags() && (
                <div className={classes.tags}>
                  <b>Tags: </b>
                  {Array.prototype.map
                    .call(value.searchImage_tags, function (item) {
                      return item.Name;
                    })
                    .join(",")}
                </div>
              )}
            </div>

            <div className={classes.imageContainer}>
              <GridList
                cellHeight={60}
                cols={1}
                className={
                  this.state.checked.indexOf(index) !== -1
                    ? classes.gridListSelected
                    : ""
                }
              >
                <GridListTile
                  key={1}
                  cols={1}
                  classes={{ tile: classes.tileStyle }}
                >
                  <img
                    src={
                      CDNurl +
                      value.targetImage +
                      "?h=120&t=" +
                      localStorage.getItem("Token")
                    }
                    alt=""
                    className={classes.imageStyle}
                  />
                </GridListTile>
              </GridList>
            </div>
          </ListItem>
        ))}
      </List>
    );
  };

  populateAbortedCases = () => {
    var list = [];
    var { t } = this.props;
    _.forOwn(
      this.props.domainsReducer.results[this.props.cardData.id].cases,
      function (value, key) {
        /* 
          value = {id:"00000000-0000-0000-0000-000000000000",
                  refNumber:"abc-1234",
                  status:1,
                  abortionReason: "reason",
                  abortionParty: "customer"
                  }
        */

        // we only accept abortions of lawyer and customer, if pixray it must be ls_no_contact, HackedPage, ls_hacked_page, ls_incomplete_contact
        if (value.status === 11 || value.status === "11")
          if (
            (value.abortionParty === "Customer" && value.researchDate) ||
            value.abortionParty === "Collection Partner" ||
            value.abortionReason === "ls_no_contact" ||
            value.abortionReason === "HackedPage" ||
            value.abortionReason === "ls_hacked_page" ||
            value.abortionReason === "ls_incomplete_contact"
          )
            list.push(
              <>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {" "}
                        <Link target="case_show_page" to={"/case/" + value.id}>
                          <div>{value.refNumber}</div>
                        </Link>
                      </td>
                      <td>
                        ({t("_aborted_by") + " " + t("_" + value.abortionParty)}
                        {value.abortionReason ? "" : ")"}
                      </td>
                      <td>
                        {value.abortionReason
                          ? ", " +
                            t("_Reason") +
                            ": " +
                            t("_" + value.abortionReason + "_short") +
                            ")"
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            );
      }
    );
    return (
      <>
        <div style={{ marginBottom: "10px" }}>
          {t("_list_of_aborted_cases")}:
        </div>
        <div style={{ maxHeight: 200 }}>
          <List>{list.map((value) => value)}</List>
        </div>
      </>
    );
  };
  handleSave = () => {
    this.setState({ saveAsDraft: true }, function () {
      this.handleNext();
    });
  };

  handleNext = () => {
    var data = {
      findings: this.state.findings,
      skipDocumentation: this.state.skipDocumentation,
      reviewAfterDocumentation: this.state.reviewAfterDocumentation,
      route: this.state.route,
      saveAsDraft: this.state.saveAsDraft,
    };

    console.log(data);

    if (this.state.activeStep === 1) {
      // finish
      this.setState((state) => ({ isCreating: true }));

      DomainsService.createDomainsCase(data)
        .then((response) => {
          const allFindingsUsed =
            this.state.findingList.length === this.state.findings.length;
          this.props.onActionClicked(
            this.props.cardData.type,
            this.props.actionType,
            data
          );

          this.setState({
            findings: [],
            isCreating: false,
            popupOpen: false,
            activeStep: 0,
            checked: [],
            route: "later",
            reviewAfterDocumentation: false,
            skipDocumentation: false,
            saveAsDraft: false,
          });

          this.props.actionShowSnackbar({
            message: this.props.t("_case_created_successfully") + "!",
            variant: "success",
          });

          if (this.props.cardData.page !== "domain" && allFindingsUsed) {
            this.props.actionRemoveDomain({ domainId: this.props.cardData.id });
            // this.props.actionuUpdateDomain({domainId: this.props.cardData.id, results: response});
          } else {
            DomainsService.getDomain({ domainId: this.props.cardData.id }).then(
              (response) => {
                this.props.actionuUpdateDomain({
                  domainId: this.props.cardData.id,
                  results: response,
                });

                var data = {
                  domainId: this.props.cardData.id,
                  pageNum: 1,
                };
                FindingsService.getFindingsDomain(data)
                  .then((response) => {
                    var filteredRes = _.filter(
                      response.results,
                      function (item) {
                        return item.type === "new";
                      }
                    );
                    response.info.findingCount = filteredRes.length;
                    filteredRes = _.keyBy(filteredRes, "id");
                    response.results = filteredRes;

                    this.props.actionGetFindingsNew({
                      results: response,
                      domainId: this.props.cardData.id,
                    });
                  })
                  .catch((error) => {
                    this.props.actionShowSnackbar({
                      message: this.props.t(
                        "_something_went_wrong_please_try_again_later"
                      ),
                      variant: "error",
                    });
                    console.log(error);
                  });
              }
            );
          }
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
          this.setState({
            findings: [],
            isCreating: false,
            popupOpen: false,
            activeStep: 0,
            checked: [],
            route: "later",
            reviewAfterDocumentation: false,
            skipDocumentation: false,
            saveAsDraft: false,
          });
        });
    } else {
      // next
      this.setState((state) => ({ activeStep: 1 }));
    }
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: 0,
    }));
  };

  handleDropdownChange = (event) => {
    this.setState({ route: event.target.value });
  };
  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };
  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handlePopupAbortedCaseOK = () => {
    var data = {
      domainId: this.props.cardData.id,
    };
    this.setState({ showAbortedCaseWarning: false });
    this.getFindingsAndOpenPopup(data);
  };

  handlePopupOpen = () => {
    var data = {
      domainId: this.props.cardData.id,
    };

    // check if this case has already aborted cases
    if (
      this.props.cardData.numAbortedCases &&
      this.props.cardData.numAbortedCases > 0
    ) {
      this.setState({ showAbortedCaseWarning: true });
    } else {
      this.getFindingsAndOpenPopup(data);
    }
  };

  handlePopupClose = () => {
    this.setState({ popupOpen: false, isCreating: false, findings: [] });
  };

  getFindingsAndOpenPopup(data) {
    FindingsService.getFindingsDomain(data)
      .then((response) => {
        var arr = _.filter(_.values(response.results), { type: "new" });
        this.setState({ findingList: arr });
        if (this.state.findingList && this.state.findingList.length === 1) {
          var a = [];
          a.push(0);
          this.setState({
            checked: a,
            findings: arr,
          });
        }
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });

    this.setState({
      collectionPartnerList: this.props.globalReducer.collectionPartners,
      route:
        this.props.globalReducer.collectionPartners.length > 0
          ? "later"
          : "sales",
    });

    this.setState({ popupOpen: true });
  }

  render() {
    const { classes, t } = this.props;
    const selectfindings = t("_select_findings");
    const selectactions = t("_select_action");
    const steps = [selectfindings, selectactions];
    const { activeStep } = this.state;

    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={this.handlePopupOpen}
        >
          {t("_create_case_with_new_findings")}
        </Button>

        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.popupOpen}
          onClose={this.handlePopupClose}
        >
          <DialogTitle>{t("_create_case")}</DialogTitle>

          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.stepperRoot}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>

            <div
              className={classes.findingRoot}
              style={{ display: activeStep === 0 ? "block" : "none" }}
            >
              <div className={classes.selectAllContainer}>
                <div className={classes.selectAllText}>
                  {this.state.checked.length} / {this.state.findingList.length}{" "}
                  {t("_items_selected")}
                </div>
                <Button
                  onClick={this.handleSelectAll}
                  color="primary"
                  className={classes.selectAllBtn}
                >
                  {t("_select_all")}
                </Button>
              </div>
              <div className={classes.findingListRoot}>
                {this.populateFindings()}
              </div>
            </div>

            <div
              className={classes.actionRoot}
              style={{ display: activeStep === 1 ? "block" : "none" }}
            >
              <div className={classes.collectionPartnerSelect}>
                <p className={classes.routeTitle}>{t("_route_to")}</p>
                <form autoComplete="off">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="type-select">
                      {t("_select_route")}
                    </InputLabel>
                    <Select
                      disabled={this.state.isCreating ? true : false}
                      className={classes.selectInput}
                      open={this.state.dropdownOpen}
                      onClose={this.handleDropdownClose}
                      onOpen={this.handleDropdownOpen}
                      value={this.state.route}
                      onChange={this.handleDropdownChange}
                      inputProps={{
                        name: "select_route",
                        id: "select_route-select",
                      }}
                    >
                      <MenuItem key={-2} value={"later"}>
                        {""}
                      </MenuItem>
                      <MenuItem key={-1} value={"sales"}>
                        {t("_sales_team")}
                      </MenuItem>
                      {this.state.collectionPartnerList.map((row, index) => {
                        return (
                          <MenuItem key={index} value={row.id}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </form>
              </div>

              <div className={classes.switchSelect}>
                {localStorage.getItem("ShowSkipDocumentation") === "true" && (
                  <div className={classes.switchContainer}>
                    <FormControlLabel
                      classes={{ label: classes.switchLabel }}
                      control={
                        <Switch
                          className={classes.switch}
                          classes={{
                            checked: classes.checked,
                            bar: classes.bar,
                          }}
                          disabled={this.state.isCreating}
                          checked={this.state.skipDocumentation}
                          onChange={(event, checked) =>
                            this.setState({ skipDocumentation: checked })
                          }
                        />
                      }
                      label={t("_skip_documentation")}
                    />
                  </div>
                )}

                <div className={classes.switchContainer}>
                  <FormControlLabel
                    classes={{ label: classes.switchLabel }}
                    control={
                      <Switch
                        className={classes.switch}
                        classes={{
                          checked: classes.checked,
                          bar: classes.bar,
                        }}
                        disabled={this.state.isCreating}
                        checked={this.state.reviewAfterDocumentation}
                        onChange={(event, checked) =>
                          this.setState({ reviewAfterDocumentation: checked })
                        }
                      />
                    }
                    label={t("_review_after_documentation")}
                  />
                </div>
                <div className={classes.switchContainer}>
                  <Paper
                    className={classes.infoPaper}
                    style={{
                      visibility: this.state.reviewAfterDocumentation
                        ? "hidden"
                        : "visible",
                      display: this.state.reviewAfterDocumentation
                        ? "none"
                        : "inline-flex",
                    }}
                  >
                    <InfoIcon className={classes.customInfoIcon}></InfoIcon>
                    <Typography>{t("_skip_review_info")}</Typography>
                  </Paper>
                </div>
              </div>
            </div>
            <LinearProgress
              className={classes.createProgress}
              color="secondary"
              style={{
                visibility: this.state.isCreating ? "visible" : "hidden",
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handlePopupClose}
              color="secondary"
              disabled={this.state.isCreating ? true : false}
            >
              {t("_cancel")}
            </Button>
            <div>
              {activeStep === 1 && (
                <span>
                  <Button
                    onClick={this.handleBack}
                    className={classes.backButton}
                    disabled={this.state.isCreating ? true : false}
                  >
                    {t("_back")}
                  </Button>
                  <Button
                    onClick={this.handleSave}
                    className={classes.backButton}
                    disabled={this.state.isCreating ? true : false}
                  >
                    {t("_save_as_draft")}
                  </Button>
                </span>
              )}
              <Button
                color="primary"
                onClick={this.handleNext}
                disabled={
                  this.state.checked.length === 0 ||
                  this.state.isCreating ||
                  (activeStep === 1 && this.state.route === "later")
                    ? true
                    : false
                }
              >
                {activeStep === 1 ? t("_finish") : t("_next")}
              </Button>
            </div>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          classes={{ paper: classes.contentRoot }}
          open={this.state.showAbortedCaseWarning}
          onClose={this.handleAbortedCasePopupClose}
        >
          <DialogTitle>
            {t("_case_creation_aborted_case_warn_title")}
          </DialogTitle>

          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.abortedCasesWarning}>
              {t("_case_creation_aborted_case_warn_text")}
            </div>
            {this.state.showAbortedCaseWarning && (
              <div>{this.populateAbortedCases()}</div>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ showAbortedCaseWarning: false });
              }}
              color="secondary"
            >
              {t("_cancel")}
            </Button>
            <Button onClick={this.handlePopupAbortedCaseOK} color="primary">
              {t("_next")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

CaseCreationButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    globalReducer: state.globalReducer,
    domainsReducer: state.domainsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionGetFindingsNew: getFindingsNew,
      actionShowSnackbar: showSnackbar,
      actionuUpdateDomain: updateDomain,
      actionRemoveDomain: removeDomain,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(CaseCreationButton)
  )
);
