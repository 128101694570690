import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { getLogs, addLog } from "@Actions/logsActions";
import Grid from '@material-ui/core/Grid';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import LogItem from '@Components/LogItem';
import List from '@material-ui/core/List';
import UserService from '@Services/User';
import {LogsInterval} from '@Configs/globalConfig';

import styles from './styles';

class LogView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logIsLoading: true
    }

    var startTime = 0;
    UserService.getLogs(startTime).then(
      (response) => {
        props.actionGetLogs(response);
        this.setState({logIsLoading: false});
      }
    ).catch((error) => {
      console.log(error);
      this.setState({logIsLoading: false});
    });

    this.logInterval = setInterval(() => {
      // startTime = Date.now() - LogsInterval;
      if(!this.state.logIsLoading){
        this.setState({logIsLoading: true});
        UserService.getLogs(startTime).then(
          (response) => {
            // props.actionAddLog(response);
            props.actionGetLogs(response);
            this.setState({logIsLoading: false});
          }).catch((error) => {
            // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
            console.log(error);
          });
      }

    }, LogsInterval);
  }

  populateLogs(){
    const { classes} = this.props;
    return(
      <Grid>
      <div className={classes.demo}>
        <List dense={true} className={classes.listMain}>
          {this.props.logsReducer && this.props.logsReducer.data && Array.isArray(this.props.logsReducer.data) && this.props.logsReducer.data.map(option => (
            <div key={option.id + Math.random().toString()}>
              <LogItem data={option}/>
              <Divider/>
            </div>
          ))}
        </List>
      </div>
    </Grid>
    );
  }
  

  render() {
    const { classes} = this.props;

    if(this.props.logsReducer.data == null)
      return (<div></div>);

    return (

        this.props.logsReducer.toggle?
          <div className={classNames(classes.rightMenuContainer, classes.rightMenuContainerShow)}>
            <Paper className={classes.root} innerRef={(node) => { this.node = node; }}>
              {this.populateLogs()}
            </Paper>
          </div>
          :
          <div className={classNames(classes.rightMenuContainer)}>
            <Paper className={classes.root}>
              {this.populateLogs()}
            </Paper>
          </div>
      
    );
  }
}

LogView.propTypes = {
  classes: PropTypes.object.isRequired,
};



function mapStateToProps(state){
  return {
    logsReducer : state.logsReducer
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    actionGetLogs: getLogs,
    actionAddLog: addLog
  },dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(LogView));