const Config = {
  whitelist: {
    id: "whitelist",
    name: "whitelist",
    display: true,
  },
  platform: {
    id: "platform",
    name: "platform",
    display: true,
  },
};

export default Config;
