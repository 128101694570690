import {
  GET_FINDINGS_NEW,
  GET_FINDINGS_TRASH,
  GET_FINDINGS_LEGAL,
  GET_FINDINGS_CASE,
  REMOVE_NEW_FINDINGS,
  UPDATE_FINDINGS_CASE_STATUS,
  MOVE_FINDINGS_TO_NEW,
  MOVE_FINDINGS_TO_TRASH,
  MOVE_FINDINGS_TO_LEGAL,
  MOVE_FINDING_BACK_TO_CASE,
  DELETE_FINDING,
} from '@Types/actionTypes';
var srcType;
const findingReducer = (
  state = { cases: {}, new: null, trash: null, legal: null },
  action
) => {
  switch (action.type) {
    case GET_FINDINGS_NEW:
      state = { ...state, new: action.payload.results };
      break;

    case GET_FINDINGS_TRASH:
      state = { ...state, trash: action.payload.results };
      break;

    case GET_FINDINGS_LEGAL:
      state = { ...state, legal: action.payload.results };
      break;

    case GET_FINDINGS_CASE:
      state = {
        ...state,
        cases: {
          ...state.cases,
          [action.payload.results.info.caseId]: action.payload.results,
        },
      };
      break;

    case REMOVE_NEW_FINDINGS:
      state.new = null;
      break;

    case UPDATE_FINDINGS_CASE_STATUS:
      if (state.cases[action.payload.caseId]) {
        state = {
          ...state,
          cases: {
            ...state.cases,
            [action.payload.caseId]: {
              ...state.cases[action.payload.caseId],
              info: {
                ...state.cases[action.payload.caseId].info,
                status: action.payload.status,
              },
            },
          },
        };
      }
      break;

    case MOVE_FINDINGS_TO_NEW:
      srcType = action.payload.type;
      state = {
        ...state,
        new: {
          ...state.new,
          info: {
            ...state.new.info,
            findingCount: state.new.info.findingCount + 1,
          },
          results: {
            ...state.new.results,
            [action.payload.findingId]:
              state[srcType].results[action.payload.findingId],
          },
        },
        [srcType]: {
          ...state[srcType],
          info: {
            ...state[srcType].info,
            findingCount: state[srcType].info.findingCount - 1,
          },
        },
      };
      delete state[srcType].results[action.payload.findingId];

      break;

    case MOVE_FINDINGS_TO_TRASH:
      srcType = action.payload.type;
      state = {
        ...state,
        trash: {
          ...state.trash,
          info: {
            ...state.trash.info,
            findingCount: state.trash.info.findingCount + 1,
          },
          results: {
            ...state.trash.results,
            [action.payload.findingId]:
              state[srcType].results[action.payload.findingId],
          },
        },
        [srcType]: {
          ...state[srcType],
          info: {
            ...state[srcType].info,
            findingCount: state[srcType].info.findingCount - 1,
          },
        },
      };
      delete state[srcType].results[action.payload.findingId];
      break;

    case MOVE_FINDINGS_TO_LEGAL:
      srcType = action.payload.type;
      state = {
        ...state,
        legal: {
          ...state.legal,
          info: {
            ...state.legal.info,
            findingCount: state.legal.info.findingCount + 1,
          },
          results: {
            ...state.legal.results,
            [action.payload.findingId]:
              state[srcType].results[action.payload.findingId],
          },
        },
        [srcType]: {
          ...state[srcType],
          info: {
            ...state[srcType].info,
            findingCount: state[srcType].info.findingCount - 1,
          },
        },
      };
      delete state[srcType].results[action.payload.findingId];

      break;

    case MOVE_FINDING_BACK_TO_CASE:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.caseId]: action.payload.results,
        },
      };
      break;

    case DELETE_FINDING:
      state = {
        ...state,
        new: {
          ...state.new,
          info: {
            ...state.new.info,
            findingCount: state.new.info.findingCount - 1,
          },
        },
      };
      break;

    default:
      break;
  }
  return state;
};

export default findingReducer;
