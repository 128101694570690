import { 
  GET_ALL_MEDIA_TAGS,
  GET_ALL_CASE_TAGS,
  GET_ALL_DOMAIN_TAGS,
  UPDATE_ALL_MEDIA_TAGS,
  UPDATE_ALL_CASE_TAGS,
  UPDATE_ALL_DOMAIN_TAGS,
  REMOVE_ALL_TAGS
} from '@Types/actionTypes';


const tagsReducer = (state = null, action) => {
  switch (action.type) {
            
    case GET_ALL_DOMAIN_TAGS:
      state = {...state, domainTags:action.payload}
      break;  

    case GET_ALL_CASE_TAGS:
      state = {...state, caseTags:action.payload}
      break;   
    
    case GET_ALL_MEDIA_TAGS:
      state = {...state, mediaTags:action.payload}
      break;  
      
    case UPDATE_ALL_DOMAIN_TAGS:
      state = {...state, domainTags: [...state.domainTags, action.payload]}
      break; 
    
    case UPDATE_ALL_MEDIA_TAGS:
      state = {...state, mediaTags: [...state.mediaTags, action.payload]}
      break; 
        
    case UPDATE_ALL_CASE_TAGS:
      state = {...state, caseTags: [...state.caseTags, action.payload]}
      break; 

    case REMOVE_ALL_TAGS:
      state = null
      break;       

    default:
      break;
  }

  
  return state;
};

export default tagsReducer;