import { grey } from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    padding: '15px',
  },
  messageContainer: {
    height: '200px',
    overflowY: 'scroll',
    margin: '10px 0',
  },
  tblTitle: {
    width: '100%',
    fontSize: 11,
    borderSpacing: 0,
    borderBottom: '1px solid #C9C9C9',
    paddingBottom: 5,
    color: grey[800],
  },
  trLeft: {
    fontWeight: 600,
    paddingTop: 10,
    fontSize: 12,
  },
});

export default styles;
