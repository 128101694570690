import React, { Component } from "react";
import DomainCard from "@Components/cards/DomainCard";
import CaseCard from "@Components/cards/CaseCard";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MediaItem from "@Components/MediaItem";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Base64 from "base-64";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { showSnackbar } from "@Actions/snackbarActions";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setToolbar } from "@Actions/toolbarActions";
import LoadingBg from "@Components/LoadingBg";
import DomainsService from "@Services/Domains";
import CasesService from "@Services/Cases";
import MediaService from "@Services/Media";
import { translate } from "react-i18next";
import { setSearch, removeSearch } from "@Actions/searchActions";
import {
  initCurrentFilterType,
  updateCurrentFilterId,
} from "@Actions/filterActions";
import {
  getDomainsSearch,
  getDomainsSearchMore,
  removeDomains,
} from "@Actions/domainsActions";
import {
  getCasesSearch,
  getCasesSearchMore,
  removeCases,
} from "@Actions/casesActions";
import {
  getMediaSearch,
  getMediaSearchMore,
  removeMedia,
} from "@Actions/mediaActions";
import { setNavbar1, setNavbar2, setNavbar3 } from "@Actions/navbarActions";

import { shortenString } from "@Utils/helpers.js";
import styles from "./styles";

class Search extends Component {
  constructor(props) {
    super(props);

    this.props.hideLoading();
    this.props.showLoading();

    this.props.setToolbar(false);

    var params = props.match.params.param;
    var searchData = JSON.parse(Base64.decode(decodeURIComponent(params)));

    var searchApiParams = Base64.encode(
      JSON.stringify({
        class: searchData.search.class.value,
        subclass: searchData.search.subclass.value,
        value: searchData.search.value,
      })
    );

    this.props.actionInitCurrentFilterType(searchData.search.class.value);

    this.state = {
      hasMore: false,
      searchData,
      isLoading: true,
      searchApiParams,
      detailsOpenedFor: undefined,
    };

    console.log(searchData);

    this.props.actionSetSearch(searchData.search);

    this.props.setNavbar1({
      name:
        this.props.t("_search") +
        ": " +
        this.state.searchData.search.class.name +
        " - " +
        this.state.searchData.search.subclass.name +
        " - " +
        shortenString(this.state.searchData.search.value, 30),
      link: "/search/" + props.match.params.param,
    });
    this.props.setNavbar2(null);
    this.props.setNavbar3(null);
  }

  componentWillUnmount() {
    if (this.state.searchData.search.class.value === "domain") {
      localStorage.setItem("posDomains", window.pageYOffset);
    }
    if (this.state.searchData.search.class.value === "case") {
      localStorage.setItem("posCases", window.pageYOffset);
    }
  }

  isNewSearchRequest() {
    return !(
      localStorage.getItem("isSearch") === "1" &&
      ((this.state.searchData.search.class.value === "domain" &&
        this.props.domainsReducer &&
        Object.keys(this.props.domainsReducer.results).length > 1) ||
        (this.state.searchData.search.class.value === "case" &&
          this.props.casesReducer &&
          Object.keys(this.props.casesReducer.results).length > 1) ||
        (this.state.searchData.search.class.value === "media" &&
          this.props.mediaReducer &&
          Object.keys(this.props.mediaReducer.results).length > 1))
    );
  }

  componentDidMount() {
    if (!this.isNewSearchRequest()) {
      setTimeout(() => {
        if (this.state.searchData.search.class.value === "domain") {
          window.scrollTo(0, localStorage.getItem("posDomains"));
        }
        if (this.state.searchData.search.class.value === "case") {
          window.scrollTo(0, localStorage.getItem("posCases"));
        }
        this.setState({ hasMore: true });
      }, 1000);
      this.props.hideLoading();
      localStorage.setItem("isSearch", "0");
    } else {
      if (this.state.searchData.search.class.value === "domain") {
        this.props.actionRemoveDomains();
        DomainsService.getDomainsSearch({
          param: this.state.searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetDomainsSearch({ results: response });
            this.props.hideLoading();
            window.scrollTo(0, 0);
            this.setState({ hasMore: true });
          })
          .catch((error) => {
            this.setState({ hasMore: true });
            this.setState({ lbgAnimated: false, lbgMessage: error.message });
            this.props.actionShowSnackbar({
              message: error.message,
              variant: "error",
            });
            this.props.hideLoading();
          });
      } else if (this.state.searchData.search.class.value === "case") {
        this.props.actionRemoveCases();
        CasesService.getCasesSearch({
          param: this.state.searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetCasesSearch({ results: response });
            this.props.hideLoading();
            window.scrollTo(0, 0);
            this.setState({ hasMore: true });
          })
          .catch((error) => {
            this.setState({ hasMore: true });
            this.setState({ lbgAnimated: false, lbgMessage: error.message });
            this.props.actionShowSnackbar({
              message: error.message,
              variant: "error",
            });
            this.props.hideLoading();
          });
      } else {
        this.props.actionRemoveMedia();
        MediaService.getMediaSearch({
          param: this.state.searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetMediaSearch({ results: response });
            this.props.hideLoading();
            window.scrollTo(0, 0);
            this.setState({ hasMore: true });
          })
          .catch((error) => {
            this.setState({ hasMore: true });
            this.setState({ lbgAnimated: false, lbgMessage: error.message });
            this.props.actionShowSnackbar({
              message: error.message,
              variant: "error",
            });
            this.props.hideLoading();
          });
      }
    }
  }

  loadMore = (page) => {
    // alert("here")
    if (this.state.searchData.search.class.value === "domain") {
      if (
        (page - 1) * 20 >
        Object.keys(this.props.domainsReducer.results).length
      ) {
        this.setState({ hasMore: false });
      } else {
        setTimeout(() => {
          this.setState({ hasMore: false });
          this.setState({ isLoading: true });
          DomainsService.getDomainsSearch({
            param: this.state.searchApiParams,
            pageNum: page,
            filter: this.props.filterReducer.searchFilterId,
          })
            .then((response) => {
              this.props.actionGetDomainsSearchMore({ results: response });
              this.setState({ hasMore: true });
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              this.props.actionShowSnackbar({
                message: this.props.t(
                  "_something_went_wrong_please_try_again_later"
                ),
                variant: "error",
              });
              console.log(error);
              // this.props.actionShowSnackbar({ message: error.message, variant: "error" });
            });
        }, 1000);
      }
    } else if (this.state.searchData.search.class.value === "case") {
      if (
        (page - 1) * 20 >
        Object.keys(this.props.casesReducer.results).length
      ) {
        this.setState({ hasMore: false });
      } else {
        setTimeout(() => {
          this.setState({ hasMore: false });
          this.setState({ isLoading: true });
          CasesService.getCasesSearch({
            param: this.state.searchApiParams,
            pageNum: page,
            filter: this.props.filterReducer.searchFilterId,
          })
            .then((response) => {
              this.props.actionGetCasesSearchMore({ results: response });
              this.setState({ hasMore: true });
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              this.props.actionShowSnackbar({
                message: this.props.t(
                  "_something_went_wrong_please_try_again_later"
                ),
                variant: "error",
              });
              console.log(error);
              // this.props.actionShowSnackbar({ message: error.message, variant: "error" });
            });
        }, 1000);
      }
    } else {
      if (
        (page - 1) * 20 >
        Object.keys(this.props.mediaReducer.results).length
      ) {
        this.setState({ hasMore: false });
      } else {
        setTimeout(() => {
          this.setState({ hasMore: false });
          this.setState({ isLoading: true });
          MediaService.getMediaSearch({
            param: this.state.searchApiParams,
            pageNum: page,
            filter: this.props.filterReducer.searchFilterId,
          })
            .then((response) => {
              this.props.actionGetMediaSearchMore({ results: response });
              this.setState({ hasMore: true });
              this.setState({ isLoading: false });
            })
            .catch((error) => {
              console.log(error);
              this.props.actionShowSnackbar({
                message: this.props.t(
                  "_something_went_wrong_please_try_again_later"
                ),
                variant: "error",
              });
              // this.props.actionShowSnackbar({ message: error.message, variant: "error" });
            });
        }, 1000);
      }
    }
  };

  handleDetailsOpened = (domainId) => {
    this.setState({ detailsOpenedFor: domainId });
  };

  populateSearch() {
    var list = [];
    var that = this;
    if (this.state.searchData.search.class.value === "domain") {
      _.forOwn(this.props.domainsReducer.results, function (value, key) {
        list.push(
          <DomainCard
            detailsOpen={key === that.state.detailsOpenedFor}
            onDetailsOpened={that.handleDetailsOpened}
            domainId={key}
            key={key}
            page="search"
          />
        );
      });
    } else if (this.state.searchData.search.class.value === "case") {
      _.forOwn(this.props.casesReducer.results, function (value, key) {
        list.push(
          <CaseCard
            detailsOpen={key === that.state.detailsOpenedFor}
            onDetailsOpened={that.handleDetailsOpened}
            caseId={key}
            key={key}
            page="search"
          />
        );
      });
    } else {
      _.forOwn(this.props.mediaReducer.results, function (value, key) {
        list.push(<MediaItem mediaId={key} key={key} data={value} />);
      });
    }
    return list;
  }

  render() {
    const { classes } = this.props;

    if (this.state.searchData.search.class.value === "domain") {
      if (
        this.props.domainsReducer == null ||
        this.props.domainsReducer.results === {} ||
        this.props.domainsReducer.results == null ||
        Object.keys(this.props.domainsReducer).length === 0
      )
        return (
          <div className={classes.root}>
            <LoadingBg
              pageType="domains"
              message={this.state.lbgMessage}
              animated={this.state.lbgAnimated}
            />
          </div>
        );
      else if (
        this.props.domainsReducer &&
        this.props.domainsReducer.total === 0
      )
        return (
          <div className={classes.root}>
            <LoadingBg
              pageType="domains"
              message={this.props.t("_no_data_found")}
              animated={false}
            />
          </div>
        );
      else {
      }
    } else if (this.state.searchData.search.class.value === "case") {
      if (
        this.props.casesReducer == null ||
        this.props.casesReducer.results === {} ||
        this.props.casesReducer.results == null ||
        Object.keys(this.props.casesReducer).length === 0
      )
        return (
          <div className={classes.root}>
            <LoadingBg
              pageType="cases"
              message={this.state.lbgMessage}
              animated={this.state.lbgAnimated}
            />
          </div>
        );
      else if (this.props.casesReducer && this.props.casesReducer.total === 0)
        return (
          <div className={classes.root}>
            <LoadingBg
              pageType="cases"
              message={this.props.t("_no_data_found")}
              animated={false}
            />
          </div>
        );
      else {
      }
    } else if (this.state.searchData.search.class.value === "media") {
      if (
        this.props.mediaReducer == null ||
        this.props.mediaReducer.results === {} ||
        this.props.mediaReducer.results == null ||
        Object.keys(this.props.mediaReducer).length === 0
      )
        return (
          <div className={classes.root}>
            <LoadingBg
              pageType="media"
              message={this.state.lbgMessage}
              animated={this.state.lbgAnimated}
            />
          </div>
        );
      else if (this.props.mediaReducer && this.props.mediaReducer.total === 0)
        return (
          <div className={classes.root}>
            <LoadingBg
              pageType="media"
              message={this.props.t("_no_data_found")}
              animated={false}
            />
          </div>
        );
      else {
      }
    } else {
      return <></>;
    }

    var paseStart = 1;
    if (this.state.searchData.search.class.value === "domain") {
      paseStart = Math.floor(
        Object.keys(this.props.domainsReducer.results).length / 20
      );
    } else if (this.state.searchData.search.class.value === "case") {
      paseStart = Math.floor(
        Object.keys(this.props.casesReducer.results).length / 20
      );
    } else if (this.state.searchData.search.class.value === "media") {
      paseStart = Math.floor(
        Object.keys(this.props.mediaReducer.results).length / 20
      );
    } else {
      paseStart = 1;
    }

    return (
      <div className={classes.root}>
        <InfiniteScroll
          pageStart={paseStart}
          loadMore={this.loadMore}
          hasMore={this.state.hasMore}
          loader={
            <div className={classes.loader} key={0}>
              <LinearProgress color="secondary" />
            </div>
          }
        >
          {this.populateSearch()}
        </InfiniteScroll>
        {!this.state.hasMore && this.state.isLoading && (
          <div className={classes.loader} key={1}>
            <LinearProgress color="secondary" />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    domainsReducer: state.domainsReducer,
    casesReducer: state.casesReducer,
    mediaReducer: state.mediaReducer,
    filterReducer: state.filterReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      setNavbar1,
      setNavbar2,
      setNavbar3,
      setToolbar,

      actionGetDomainsSearch: getDomainsSearch,
      actionGetCasesSearch: getCasesSearch,
      actionGetMediaSearch: getMediaSearch,

      actionRemoveDomains: removeDomains,
      actionRemoveCases: removeCases,
      actionRemoveMedia: removeMedia,

      actionGetMediaSearchMore: getMediaSearchMore,
      actionGetDomainsSearchMore: getDomainsSearchMore,
      actionGetCasesSearchMore: getCasesSearchMore,

      actionSetSearch: setSearch,
      actionRemoveSearch: removeSearch,
      actionShowSnackbar: showSnackbar,
      actionInitCurrentFilterType: initCurrentFilterType,
      actionUpdateCurrentFilterId: updateCurrentFilterId,
    },
    dispatch
  );
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(Search)
  )
);
