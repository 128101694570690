const styles = (theme) => ({
  tblContentCell: {
    borderBottom: "none",
  },

  tblHeader: {
    borderBottom: "none",
    fontSize: 17,
    fontWeight: "bold",
  },
  inputTitle: {
    color: "gray",
    paddingTop: -20,
    display: "inline-block",
    position: "relative",
    top: 5,
  },
  actionsContainer: {
    marginTop: 22,
    marginBottom: -12,
  },
});

export default styles;
