const Config = [
  {
    id: 1,
    value: 'draft',
    name: 'draft',
    display: 'draft'
  },
  {
    id: 2,
    value: 'inresearch',
    name: 'in_research',
    display: 'documentation_in_progress'
  },
  {
    id: 3,
    value: 'review',
    name: 'review',
    display: 'review'
  },
  {
    id: 4,
    value: 'activationpending',
    name: 'activation_pending',
    display: 'activation_pending'
  },
  {
    id: 5,
    value: 'activeclaim',
    name: 'active_claim',
    display: 'active_claim'
  },
  {
    id: 10,
    value: 'closed',
    name: 'closed',
    display: 'closed'
  },
  {
    id: 11,
    value: 'canceled',
    name: 'canceled',
    display: 'canceled'
  }
];

export default Config;
