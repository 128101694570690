import {
  CASE_CREATION_BUTTON,
  CASE_START_BUTTON,
  CASE_CONFIRM_BUTTON,
  LEGAL_BUTTON,
  TERMINATE_CASE_BUTTON,
  ACTIVATE_CASE_BUTTON,
  REVERT_DOMAIN_BUTTON,
  WHITELIST_BUTTON,


} from '@Types/cardButtonTypes';

const Config = {

  'domain_status_1': { // normal
    CASE_CREATION_BUTTON,
    LEGAL_BUTTON,
    WHITELIST_BUTTON
  },
  'domain_status_2': { // legal
    REVERT_DOMAIN_BUTTON
  },
  'domain_status_3': { // whitelist
    REVERT_DOMAIN_BUTTON
  }, 


  'case_status_1': { // draft
    CASE_START_BUTTON,
    TERMINATE_CASE_BUTTON,
  },
  'case_status_2': { // inresearch

  },
  'case_status_3': { // _review
    CASE_CONFIRM_BUTTON,
    TERMINATE_CASE_BUTTON,
  },
  'case_status_4': { // _activation pending
    ACTIVATE_CASE_BUTTON,
    TERMINATE_CASE_BUTTON
  },
  'case_status_5': { // activeclaim
    TERMINATE_CASE_BUTTON
  },
  'case_status_10': { // closed

  },
  'case_status_11': { // terminated
  
  }
};

export default Config;