import { red } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {
    display: "flex",
  },

  subfilterSelect: {},
  subfilterFormControl: {
    width: 150,
    paddingRight: 10,
    // paddingLeft: 10
  },
  subfilterFormControlFullLine: {
    width: 420,
    paddingRight: 10,
  },
  subfilterSelectInput: {},
  subfilterSelectInputFullLine: {
    width: "100%",
  },

  none: {
    display: "none",
  },
  operationTypeSelect: {},
  operationTypeFormControl: {
    paddingRight: 10,
  },
  operationTypeSelectInput: {
    maxWidth: "115px",
    minWidth: "115px"
  },

  ruleValueTextFieldContainer: {},
  ruleValueTextMultiFieldContainer: { display: "inline-block", width: 160 },
  ruleValueFormControl: {
    width: 150,
    paddingRight: 10,

    flexDirection: "row",
    verticalAlign: "top",
  },
  ruleValueTextField: {},
  ruleValueTextFieldDays: {
    maxWidth: "70px",
  },
  ruleValueTextFieldLeft: { width: "10%" },
  ruleValueTextFieldRight: { width: "10%" },
  ruleValueSeperator: {
    verticalAlign: "middle",
    marginLeft: 12,
    marginRight: 20,
    marginTop: 6,
    display: "inline-block ",
  },
  removeIconBtnLeft: {
    left: -98,
  },
  removeIcon: {
    color: red[500],
  },

  ruleValueFilterSelectInput: {},
  ruleValueTagInput: {},
  ruleValueEnumInput: {},
});

export default styles;
