import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root: {

  },
  tblContainerDocs:{
    height: 280,
    overflowY: "scroll",
    overflowX: "hidden",
  },
  tblDocs: {
    border:"1px solid #EEE",
    cursor: "default"
  },
  rowDocs: {
    cursor: "pointer",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    }
  },
  rowDocFrist:{
    width: 20,
    padding: "0px 0px 0px 20px !important",
    textAlign: "center"
  },
  rowDocSecond:{
    "& p":{
      fontWeight: "bold",
      fontSize:12,
      color:grey[600],
      textOverflow: "ellipsis",
      overflow: "hidden",
      width: 215, 
      whiteSpace: "nowrap",
      margin:0
    }
  },
  uploadInput: {
    display: 'none',
  },
  uploadDocButton:{
    float:'right',
    marginTop: 20
  },
  docTypeRoot:{
    
  },
  fileName:{
    width:150,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 14,
    paddingLeft: 15
  },
  downloadIcon:{
    fontSize: 16  
  },
  docDownloadLink:{
    textDecoration: "none"
  }
});


export default styles;