const Config = [
  {
    "name": "finding",
    "value": "domain",
    "display": "findings"
  },
  {
    "name": "case",
    "value": "case",
    "display": "cases"
  },
  {
    "name": "media",
    "value": "media",
    "display": "images"
  }
];

export default Config;