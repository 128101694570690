import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { translate } from 'react-i18next';
import styles from './styles';

import DialogPopup from '@Components/DialogPopup';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class TerminateCaseButton extends Component {
  constructor(props) {
    super();

    this.actionClicked = this.actionClicked.bind(this);
  }

  state = {
    open: false,
  };

  actionClicked(){
    this.props.onActionClicked(this.props.cardData.type, this.props.actionType, null);
    this.setState({ open: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  render() {
    const { classes, t} = this.props;

    return (
      <div className={classes.root}>
        <Button variant="contained"  color="default" className={classes.button} onClick={this.handleClickOpen}>
          {t("_cancel_case")}          
        </Button>

        <DialogPopup open={this.state.open} onClose={this.handleClose} >
          <DialogTitle>
            {t("_confirmation_required")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_do_you_really_want_to_cancel_this_case")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t("_cancel")}
            </Button>
            <Button onClick={this.actionClicked} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>

      </div>
    );
  }

}

TerminateCaseButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(TerminateCaseButton));