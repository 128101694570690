import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Settings from "@material-ui/icons/Settings";
import Button from "@material-ui/core/IconButton";
import { translate } from "react-i18next";
import styles from "./styles";

class SettingBadge extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Button title={t("_settings")} className={classes.button}>
          <Settings className={classes.mainIcon} />
        </Button>
      </div>
    );
  }
}

SettingBadge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default translate("translations")(withStyles(styles)(SettingBadge));
