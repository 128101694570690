import React from "react";
import Slide from "@material-ui/core/Slide";
import dateFormat from "dateformat";
import _ from "lodash";
import store from "@Core/store";
import { INVALIDATE_USER } from "@Types/actionTypes";
import { CDNurl } from "@Configs/globalConfig";

const initialSelectedFilters = { domain: "0", case: "0", media: "0" };
const operatorCountryConfig = require("@Configs/operatorCountryConfig");

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 4);
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function transitionUp(props) {
  return <Slide direction="up" {...props} />;
}

export function convertObjToArray(obj) {
  var list = [];
  _.forOwn(obj, function (value, key) {
    list.push({ ...value, key: key });
  });
  return list;
}

export function logout() {
  localStorage.removeItem("Token");
  localStorage.removeItem("Id");
  localStorage.removeItem("UserName");
  localStorage.removeItem("Name");
  localStorage.removeItem("FirstName");
  localStorage.removeItem("LastName");
  localStorage.removeItem("Email");
  localStorage.removeItem("CultureID");
  localStorage.removeItem("CustomerID");
  localStorage.removeItem("AvatarID");
  localStorage.removeItem("AvatarImage");
  localStorage.removeItem("IAT");
  localStorage.removeItem("CD");
  localStorage.removeItem("RoleID");
  localStorage.removeItem("posCases");
  localStorage.removeItem("posDomains");
  localStorage.removeItem("posMedia");
  localStorage.removeItem("media_info_config");
  localStorage.removeItem("SelectedFilters");
  localStorage.removeItem("isSearch");
  localStorage.removeItem("ownParty");
}

export function numberToLocaleString(number) {
  if (!number && number !== 0) return;
  let locale = localStorage.getItem("i18nextLng");
  if (!locale) locale = "en-US";
  if (locale === "de") locale = "de-DE";

  return number.toLocaleString(locale, { minimumFractionDigits: 2 });
}

export function generateAvatar(avatarId) {
  if (avatarId) localStorage.setItem("AvatarID", avatarId);
  var image =
    "" +
    CDNurl +
    localStorage.getItem("AvatarID") +
    "?dt=" +
    Date.now() +
    "&t=" +
    localStorage.getItem("Token");
  return image;
}

export function formatDomainName(domainName, maxLength = 32) {
  var shortend = "";
  if (domainName.length >= maxLength) {
    shortend = "..." + domainName.substr(domainName.length - 29);
  } else {
    shortend = domainName;
  }

  return shortend;
}

export function formatDatetime(rawDatetime, fullShort) {
  if (!rawDatetime) return "";
  /**
   * @type {Date}
   */
  let theDate = null;

  if (typeof rawDatetime === "string") theDate = new Date(rawDatetime);
  if (typeof rawDatetime === "object") theDate = rawDatetime;

  let lang = localStorage.getItem("i18nextLng");
  if (lang === "de-DE" || lang === "de") {
    if (fullShort === "short") {
      return theDate.toLocaleTimeString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    } else if (fullShort === "medium-short") {
      return theDate.toLocaleTimeString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
      });
    } else if (fullShort === "medium") {
      return theDate.toLocaleTimeString("de-DE", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
      });
    } else {
      return theDate.toLocaleTimeString("de-DE", {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "numeric",
        weekday: "long",
        second: "2-digit",
        minute: "2-digit",
      });
    }
  } else {
    if (fullShort === "short") {
      return dateFormat(rawDatetime, "d.m.yyyy");
    } else if (fullShort === "medium-short") {
      return dateFormat(rawDatetime, "dd.mm.yyyy, h:MM TT");
    } else if (fullShort === "medium") {
      return dateFormat(rawDatetime, "mmmm dS, yyyy, h:MM TT");
    } else {
      return dateFormat(rawDatetime, "dddd, mmmm dS, yyyy, h:MM:ss TT");
    }
  }
}

export function replaceBrTags(note) {
  if (!note) return;
  var formatted = "";
  formatted = note.replace(/<br \/>/g, "\r\n");
  formatted = formatted.replace(/<br\/>/g, "\r\n");
  return formatted;
}

export function decodeEntities(str) {
  // this prevents any overhead from creating the object each time
  var element = document.createElement("div");

  function decodeHTMLEntities(str) {
    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return decodeHTMLEntities(str);
}

export function isEmptyObject(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function mapTagsToSelectOption(tags) {
  return convertObjToArray(tags).map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));
}

export function mapSelectOptionToTag(selectOption) {
  return {
    id: selectOption.value,
    name: selectOption.label,
  };
}

export function handleForbiddenError(status) {
  var authReducer = store.getState().authReducer;
  if (authReducer.validated === true && status === 403) {
    store.dispatch({ type: INVALIDATE_USER });
  }
}

export function updatedSelectedFilters(type, filterId) {
  if (localStorage.getItem("SelectedFilters") == null) {
    localStorage.setItem(
      "SelectedFilters",
      JSON.stringify(initialSelectedFilters)
    );
  }

  var selectedFilters = JSON.parse(localStorage.getItem("SelectedFilters"));
  if (
    !_.has(selectedFilters, "domain") ||
    !_.has(selectedFilters, "case") ||
    !_.has(selectedFilters, "media")
  ) {
    selectedFilters = initialSelectedFilters;
  }

  // selectedFilters[store.getState().filterReducer.currentFilterType] = store.getState().filterReducer.currentfilterId;
  selectedFilters[type] = filterId !== 0 ? filterId.toUpperCase() : filterId;
  localStorage.setItem("SelectedFilters", JSON.stringify(selectedFilters));
}

export function getSelectedFilters() {
  if (localStorage.getItem("SelectedFilters") == null) {
    localStorage.setItem(
      "SelectedFilters",
      JSON.stringify(initialSelectedFilters)
    );
  }

  try {
    var selectedFilters = JSON.parse(localStorage.getItem("SelectedFilters"));
  } catch (error) {
    selectedFilters = initialSelectedFilters;
  }

  if (
    !_.has(selectedFilters, "domain") ||
    !_.has(selectedFilters, "case") ||
    !_.has(selectedFilters, "media")
  ) {
    selectedFilters = initialSelectedFilters;
  }

  const filterReducer = store.getState().filterReducer;
  if (filterReducer.filters != null) {
    if (
      selectedFilters["domain"] !== "0" &&
      filterReducer.filters[selectedFilters["domain"]] === undefined
    ) {
      selectedFilters["domain"] = "0";
    }
    if (
      selectedFilters["case"] !== "0" &&
      filterReducer.filters[selectedFilters["case"]] === undefined
    ) {
      selectedFilters["case"] = "0";
    }
    if (
      selectedFilters["media"] !== "0" &&
      filterReducer.filters[selectedFilters["media"]] === undefined
    ) {
      selectedFilters["media"] = "0";
    }
  }

  localStorage.setItem("SelectedFilters", JSON.stringify(selectedFilters));
  return selectedFilters;
}

export function getIndexBy(arr, name, value) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][name] === value) {
      return i;
    }
  }
  return -1;
}

export function hasFilterEmptyValues(filter) {
  if (filter.name === null || filter.name.trim() === "") {
    return true;
  }
  for (var key in filter.rules) {
    //check filter property and filter operation
    if (
      filter.rules[key].subfilterId === null ||
      filter.rules[key].subfilterId === "" ||
      filter.rules[key].operationId === null ||
      filter.rules[key].operationId === "" ||
      filter.rules[key].operationId === 0 ||
      filter.rules[key].operationId === "0" ||
      filter.rules[key].ruleValue === null
    )
      return true;

    //check filter value
    //depending on filter property different conditions must be met
    if (
      parseInt(filter.rules[key].operationId) !== 66 &&
      parseInt(filter.rules[key].operationId) !== 70
    ) {
      // the operation is not tag -> no-tag and is not special.
      // the value must be set
      if (filter.rules[key].ruleValue === "") return true;
    }

    // check valid value for special filter 2
    if (filter.rules[key].subfilterId === 113) {
      if (/^[a-z]-[a-z]$/.test(filter.rules[key].ruleValue) === false) {
        return true;
      }
    }
  }

  return false;
}

export function hasFilterDuplicateName(filters, newName) {
  var count = 0;
  for (var key in filters) {
    if (filters[key].name === newName) count++;
    if (count >= 2) return true;
  }
  return false;
}

export function isFilterInUse(allFilters, filterId) {
  for (var filtersKey in allFilters) {
    for (var key in allFilters[filtersKey].rules) {
      if (
        allFilters[filtersKey].rules[key].ruleValue === filterId &&
        allFilters[filtersKey].rules[key].operationId === "5"
      )
        return true;
    }
  }
  return false;
}

export function isFilterCircular(targetFilterRules, currentfilterId) {
  for (var key in targetFilterRules) {
    if (
      targetFilterRules[key].ruleValue === currentfilterId &&
      targetFilterRules[key].operationId === "5"
    )
      return true;
  }
  return false;
}

export function findTagById(tagId, allTags) {
  return [_.find(allTags, ["id", tagId])];
}

export function shortenString(string, length) {
  if (!string) return string;
  var shortend = "";
  if (string.length >= length) {
    shortend = string.substr(0, length) + "...";
  } else {
    shortend = string;
  }

  return shortend;
}

export function isNull(value) {
  if (
    value == null ||
    typeof value == "undefined" ||
    value === undefined ||
    value === ""
  ) {
    return true;
  } else {
    return false;
  }
}

export function isZero(value) {
  if (value === "0" || value === 0 || value == null) {
    return true;
  } else {
    return false;
  }
}

export function resetAllScroll() {
  localStorage.setItem("posDomains", -300);
  localStorage.setItem("posCases", -300);
}

export function showMediaTags() {
  var toShow = JSON.parse(localStorage.getItem("media_info_config"))[
    "show_media_tags"
  ];
  return toShow;
}

export function showMediaNotes() {
  var toShow = JSON.parse(localStorage.getItem("media_info_config"))[
    "show_media_notes"
  ];
  return toShow;
}

export function atou(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function countryCodeToFullName(countryCode) {
  var retval = "";

  if (operatorCountryConfig.default[countryCode])
    retval = operatorCountryConfig.default[countryCode].fullname;

  return retval;
}
