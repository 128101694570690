const Config = {
  // loginServer: "../MockData/login",
  // loginServer: "http://portalnetwork.pixray.com:18000/v1",
  loginServer: 'https://auth-api.pixray.com/v1',
  // loginServer: 'http://localhost:8778/v1',

  //casesServer: "../MockData/cases",
  // casesServer: "http://portalnetwork.pixray.com:8799/v1",
  casesServer: ' https://case-api.pixray.com/v1',
  //casesServer: "http://localhost:38799/v1",

  //domainsServer: "../MockData/domains",
  // domainsServer: "http://portalnetwork.pixray.com:8779/v1",
  domainsServer: 'https://domain-api.pixray.com/v1',
  //domainsServer: "http://localhost:8789/v1",

  //findingsServer: "../MockData/findings",
  // findingsServer: "http://portalnetwork.pixray.com:18004/v1",
  findingsServer: 'https://finding-api.pixray.com/v1',
  //findingsServer: "http://localhost:8899/v1",

  //mediaServer: "../MockData/media",
  // mediaServer: "http://portalnetwork.pixray.com:18001/v1",
  mediaServer: 'https://media-api.pixray.com/v1',
  //mediaServer: "http://localhost:28779/v1",

  // userSever: "../MockData/user",
  userServer: 'https://customer-api.pixray.com/v1',
  //userServer: "http://localhost:8999/v1",

  generalServer: '../MockData/general',
};

export default Config;
