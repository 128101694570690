import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import caseCreationButton from './CaseCreationButton';
import CaseStartButton from './CaseStartButton';
import CaseConfirmButton from './CaseConfirmButton';
import LegalActionButton from './LegalActionButton';
import LegalButton from './LegalButton';
import PostLicencingButton from './PostLicencingButton';
import AccountPostLicencingButton from './AccountPostLicencingButton';
import PushToDocumentationButton from './PushToDocumentationButton';
import WhitelistButton from './WhitelistButton';
import RevertDomainButton from './RevertDomainButton';
import TerminateCaseButton from './TerminateCaseButton';
import ActivateCaseButton from './ActivateCaseButton';
import _ from 'lodash'
import Config from '@Configs/cardButtonsConfig';
import ConfigAction from '@Configs/cardButtonsActionConfig';

import styles from './styles'

class CardButtons extends Component {
  constructor(props) {
    super();

    this.actionClicked = this.actionClicked.bind(this);
  }

  buttons = {
    CASE_CREATION_BUTTON: caseCreationButton,
    CASE_START_BUTTON: CaseStartButton,
    CASE_CONFIRM_BUTTON: CaseConfirmButton,
    LEGAL_ACTION_BUTTON: LegalActionButton,
    LEGAL_BUTTON: LegalButton,
    POST_LICENCING_BUTTON: PostLicencingButton,
    TERMINATE_CASE_BUTTON: TerminateCaseButton,
    ACTIVATE_CASE_BUTTON: ActivateCaseButton,
    WHITELIST_BUTTON: WhitelistButton,
    REVERT_DOMAIN_BUTTON: RevertDomainButton,
    ACCOUNTING_POST_LICENCING : AccountPostLicencingButton,
    PUSH_TO_DOCUMENTATION : PushToDocumentationButton,
    
  }

  actionClicked(cardType, buttonType, data){
    var nextStatus;
    if(data && data.isStart){
      nextStatus = data.status;
    }else{
      nextStatus = ConfigAction[cardType][buttonType].replace(cardType + '_status_' ,'');  
    }
    
    this.props.onActionClicked(nextStatus, data);
  }

  pupulateButtons(){
    var list = [];

    const cardData = this.props.cardData; 
    const buttons = this.buttons;

    _.forEach(Config[this.props.cardData.type + '_status_' + this.props.cardData.status ], function(btn, i){
      
      if(this.props.cardData.newFindings !== 0 || i === 'WHITELIST_BUTTON' || i === 'REVERT_DOMAIN_BUTTON'){
        const MyBtn = buttons[i];
        
        if(this.props.cardData.statusType && this.props.cardData.statusType === "lawyer" && btn === "activate_case_button" ){
          // ignore
        }else{
          list.push(<MyBtn key={i + '_' + cardData.id } actionType={i} cardData={cardData} onActionClicked={this.actionClicked}/>);
        }
        
      }

    }.bind(this));

    return list;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.pupulateButtons()}
      </div>
    );
  }

}

CardButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(CardButtons));