import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { showSnackbar } from "@Actions/snackbarActions";
import UserService from "@Services/User";
import { hasFilterEmptyValues, hasFilterDuplicateName } from "@Utils/helpers";
import {
  updateCurrentFilterId,
  addFilter,
  removeFilter,
  updateCurrentFilterOriginalData,
  updateSaved,
  revertFilterData,
  initFilters,
  reorderFilters,
  updateIsNew,
  updateSearchFilterId,
} from "@Actions/filterActions";
import styles from "./styles";
import ListItem from "./ListItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgray" : "inherit",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({});

class FilterList extends Component {
  constructor(props) {
    super();
    this.totalFilters = 0;
    this.filterToGo = 0;
  }

  state = {
    openSave: false,
  };

  componentDidMount() {}

  handleCloseSave = () => {
    this.setState({ openSave: false });
    this.props.actionUpdateSaved(false);
  };

  handleDiscardSave = () => {
    if (this.props.filterReducer.isNew) {
      var data = {
        filterId: this.props.filterReducer.currentFilterId,
      };
      this.props.actionRemoveFilter(data);
    } else {
      this.props.actionRevertFilterData();
    }
    this.props.actionUpdateIsNew(false);
    this.props.actionUpdateSaved(true);
    this.setState({ openSave: false });

    //go to the filter that user is trying to switch to
    this.props.actionUpdateCurrentFilterId(this.filterToGo);
    this.props.actionUpdateCurrentFilterOriginalData(this.filterToGo);
  };

  handleAcceptSave = () => {
    if (
      hasFilterDuplicateName(
        this.props.filterReducer.filters,
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ].name
      )
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_duplicate_filter_name"),
        variant: "warning",
      });
      this.setState({ openSave: false });
      return false;
    }

    if (
      hasFilterEmptyValues(
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ]
      )
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_you_have_empty_fields_filter_cannot_be_saved"),
        variant: "warning",
      });
      this.setState({ openSave: false });
      return false;
    }

    UserService.addFilter(
      this.props.filterReducer.filters[this.props.filterReducer.currentFilterId]
    )
      .then((response) => {
        this.setState({ openSave: false });

        // replcce the temp filter
        var newfilterData =
          this.props.filterReducer.filters[
            this.props.filterReducer.currentFilterId
          ];
        newfilterData.id = response.toUpperCase();
        this.props.actionRemoveFilter({
          filterId: this.props.filterReducer.currentFilterId,
        });
        this.props.actionAddFilter(newfilterData);

        // go to the selected filter
        this.props.actionUpdateCurrentFilterId(this.filterToGo);
        this.props.actionUpdateCurrentFilterOriginalData(this.filterToGo);
        this.props.actionUpdateSaved(true);
        this.props.actionUpdateIsNew(false);
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  handleAddFilter = () => {
    var data = this.generateNewFilterData("new_" + Date.now());
    this.props.actionAddFilter(data);
    this.props.actionUpdateCurrentFilterId(data.id);
    this.props.actionUpdateSaved(false);
    this.props.actionUpdateIsNew(true);
  };

  generateNewFilterData(id) {
    var data = {
      id: id,
      type: this.props.filterReducer.currentFilterType,
      name: "New filter",
      allorany: "all",
      isPrivate: false,
      rules: {},
    };
    return data;
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // same place
    if (result.source.index === result.destination.index) return;

    //get ids of the element
    var myData = Object.keys(this.props.filterReducer.filters).map((key) => {
      return this.props.filterReducer.filters[key];
    });

    myData = myData.filter(
      (x) => x.type === this.props.filterReducer.currentFilterType
    );

   
    const [reorderedItem] = myData.splice(result.source.index, 1);
    myData.splice(result.destination.index, 0, reorderedItem);

    // myData has not the right order
    let orderIds = myData.map((item, index) => {
      return { id: item.id, orderValue: index };
    });
    this.props.actionReorderFilters(orderIds);

    UserService.reorderFilters(orderIds)
      .then((response) => {
        UserService.getFilters()
          .then((payload) => {
            this.props.actionInitFilter(payload);
          })
          .catch((err) => {
            console.error("Failed to reinit filter order", err);
            this.props.actionShowSnackbar({
              message: this.props.t("_error_loading_filters") + "!",
              variant: "error",
            });
          });
      })
      .catch((err) => {
        console.error("Failed to swap filters", err);
        this.props.actionShowSnackbar({
          message: this.props.t("_error_loading_filters") + "!",
          variant: "error",
        });
      });
  };

  populateList = () => {
    var filterList = [];

    var myData = Object.keys(this.props.filterReducer.filters).map((key) => {
      return this.props.filterReducer.filters[key];
    });
    myData = myData.filter(
      (x) => x.type === this.props.filterReducer.currentFilterType
    ).sort((a,b) => {return a.orderValue - b.orderValue});
    myData.map(
      function (filter, i) {
        if (this.props.filterReducer.currentFilterType === filter.type) {
          filterList.push(
            <Draggable key={filter.name} draggableId={filter.name} index={i}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <ListItem filter={filter} />
                </div>
              )}
            </Draggable>
          );
        }
      }.bind(this)
    );

    return filterList;
  };

  render() {
    const { classes, t } = this.props;
    var myData = Object.keys(this.props.filterReducer.filters).map((key) => {
      return this.props.filterReducer.filters[key];
    });
    myData = myData.filter(
      (x) => x.type === this.props.filterReducer.currentFilterType
    ).sort((a,b) => {return a.orderValue - b.orderValue});
    const totalFilters = myData.length;
    return (
      <div className={classes.root}>
        <div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable ignoreContainerClipping droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {this.populateList()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {totalFilters === 0 ? (
            <div className={classes.noFilterDefined}>
              {t("_no_filter_is_defined")}
            </div>
          ) : (
            <></>
          )}
          <Button
            className={classes.addFilterButton}
            variant="contained"
            color="primary"
            size="medium"
            onClick={this.handleAddFilter}
            disabled={this.props.filterReducer.saved ? false : true}
            title={
              this.props.filterReducer.saved
                ? t("_add_new_filter")
                : t("_you_cannot_add_new_filter_if_there_are_unsaved_changes")
            }
          >
            <AddIcon className={classes.addIcon} />
            {t("_add_new_filter")}
          </Button>
        </div>

        <DialogPopup open={this.state.openSave} onClose={this.handleCloseSave}>
          <DialogTitle>{t("_confirmation_required")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_there_are_unsaved_changes_do_you_want_to_save_them")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseSave}>{t("_cancel")}</Button>
            <Button onClick={this.handleDiscardSave} color="secondary">
              {t("_no")}
            </Button>
            <Button onClick={this.handleAcceptSave} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

FilterList.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    filterReducer: state.filterReducer,
    navbarReducer: state.navbarReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionUpdateCurrentFilterId: updateCurrentFilterId,
      actionAddFilter: addFilter,
      actionRemoveFilter: removeFilter,
      actionUpdateCurrentFilterOriginalData: updateCurrentFilterOriginalData,
      actionUpdateSaved: updateSaved,
      actionRevertFilterData: revertFilterData,
      actionUpdateIsNew: updateIsNew,
      actionUpdateSearchFilterId: updateSearchFilterId,
      actionShowSnackbar: showSnackbar,
      actionInitFilter: initFilters,
      actionReorderFilters: reorderFilters,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(FilterList)));
