import {
  GET_CASE_MESSAGES,
  MARK_MESSAGE_AS_READ,
  ADD_CASE_MESSAGE,
} from '@Types/actionTypes';

export function getCaseMessages(data) {
  //data:
  // caseId
  // messages
  return {
    type: GET_CASE_MESSAGES,
    payload: data,
  };
}

export function markMessageAsRead(data) {
  //data:
  // caseId
  // messageId
  return {
    type: MARK_MESSAGE_AS_READ,
    payload: data,
  };
}

export function addCaseMessage(data) {
  // data:
  // caseId
  // reciver
  // replyId
  // message

  return {
    type: ADD_CASE_MESSAGE,
    payload: data,
  };
}
