const Config = {
  "domain":[
    {
      "name": "exif/iptc",
      "value": "exif_iptc",
      "display": "exif_iptc_fields"
    },
    {
      "name": "search image",
      "value": "search_image",
      "display": "search_image"
    },
    {
      "name": "tag",
      "value": "tag",
      "display": "domain_tags"
    },
    {
      "name": "url",
      "value": "url",
      "display": "domain_url"
    },
    {
      "name": "domain_note",
      "value": "domain_note", 
      "display": "domain_note"
    }
  ],
  "case":[
    {
      "name": "case number",
      "value": "case_number",
      "display": "case_number"
      // external and internal
    },
    {
      "name": "contact data",
      "value": "contact_data" ,
      "display": "contact_data" 
      // imprint , whois , others
    },
    {
      "name": "note",
      "value": "note",
      "display": "notes"
    },
    {
      "name": "search image",
      "value": "search_image",
      "display": "search_image"
    },
    {
      "name": "tag",
      "value": "tag",
      "display": "case_tags"
    },
    {
      "name": "url",
      "value": "url",
      "display": "domain_url"
    },
  ],
  "media": [
    {
      "name": "artist",
      "value": "artist",
      "display": "artist"
    },
    {
      "name": "exif/iptc",
      "value": "exif_iptc",
      "display": "exif_iptc_fields"
    },
    {
      "name": "file name",
      "value": "file_name",
      "display": "file_name"
    },
    {
      "name": "tag",
      "value": "tag",
      "display": "image_tags"
    }
  ]
};

export default Config;
