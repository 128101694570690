import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import MoreIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";

import DialogPopup from "@Components/DialogPopup";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Button from "@material-ui/core/Button";
import Forward from "@material-ui/icons/Forward";
import { Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Finding from "@Components/Finding";
import _ from "lodash";
import { CASES } from "@Types/panelTypes";
import Config from "@Configs/cardStatusConfig";
import { translate } from "react-i18next";
import { getFindingsCase } from "@Actions/findingsActions";
import { getCase, updateCase } from "@Actions/casesActions";
import FindingsService from "@Services/Findings";
import CasesService from "@Services/Cases";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
  formatDatetime,
  replaceBrTags,
  decodeEntities,
  isNull,
  numberToLocaleString,
} from "@Utils/helpers";

import styles from "./styles";

class CasePanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupOpen: false,
      expanded: this.props.pageType === "case" ? true : false,
      caseSummaryInfo: {},
      caseSummaryNotes: [],
    };
  }

  handleClickOpen = () => {
    this.setState({ popupOpen: true });
  };

  handleClose = () => {
    this.setState({ popupOpen: false });
  };

  toggle = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  componentDidMount = () => {
    var data = {
      domainId: this.props.domainId,
      pageNum: 1,
      caseId: this.props.caseId,
    };

    FindingsService.getFindingsCase(data)
      .then((response) => {
        this.props.actionGetFindingsCase({
          results: response,
          domainId: data.domainId,
        });
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });

    CasesService.getCase(data)
      .then((response) => {
        // console.log(data)
        if (!this.props.casesReducer || !this.props.casesReducer.results)
          this.props.actionGetCase({ total: 1, results: response });
        else
          this.props.actionUpdateCase({
            caseId: response.info.caseId,
            results: response,
          });
        this.setState({
          caseSummaryInfo: response.info,
          caseSummaryNotes: response.notes,
        });
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
  };

  populateResults() {
    // console.log(this.props)

    var list = [];
    const caseId = this.props.caseId;
    const caseState =
      this.props.casesReducer &&
      this.props.casesReducer.results[caseId] &&
      this.props.casesReducer.results[caseId].info.status;
    var currData = this.props.findingsReducer.cases[caseId].results;
    const images =
      this.props.casesReducer &&
      this.props.casesReducer.results[caseId] &&
      this.props.casesReducer.results[caseId].images;

    var i = 0;
    _.forOwn(
      currData,
      function (value, key) {
        i++;
        if (images == null) return;
        let imageInfo = images.filter((x) => x.id === key)[0];
        var data = {
          findingId: currData[key].id,
          domainId: this.props.domainId,
          caseId: caseId,
          type: CASES,
          parentType: this.props.pageType,
          info: this.state.caseSummaryInfo,
          statusChangeReason: imageInfo && imageInfo.statusChangeReason,
          key: key,
        };
        list.push(
          <Finding key={key} data={data} caseState={caseState} counter={i} />
        );
      }.bind(this)
    );
    return list;
  }

  populateNotes() {
    const { classes } = this.props;
    var noteList = [];

    if (isNull(this.state.caseSummaryNotes)) return;

    for (var i = 0; i < this.state.caseSummaryNotes.length; i++) {
      noteList.push(
        <TableRow className={classes.rowNotes} key={i}>
          <TableCell>
            {decodeEntities(replaceBrTags(this.state.caseSummaryNotes[i].note))}
            <p className={classes.noteTitle}>
              {this.state.caseSummaryNotes[i].owner} -{" "}
              {formatDatetime(this.state.caseSummaryNotes[i].date, "short")}
            </p>
          </TableCell>
        </TableRow>
      );
    }

    return noteList;
  }

  render() {
    const { classes, caseId, t } = this.props;

    if (
      this.props.findingsReducer.cases == null ||
      this.props.findingsReducer.cases[caseId] == null
    )
      return <div></div>;

    var currPanel = this.props.findingsReducer.cases[caseId];

    return (
      <div className={classes.root}>
        <ExpansionPanel
          className={classnames(
            classes.panelRoot,
            classes[
              currPanel.info.abortionReason
                ? "DarkOrangeStatus"
                : Config["case_status_" + currPanel.info.status].color +
                  "Status"
            ]
          )}
          expanded={this.state.expanded}
          onChange={() => this.toggle()}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <p className={classes.headingTitle}>{t("_case")}</p>
            <p className={classes.headingStatus}>
              {t("_status")}:{" "}
              <b>
                {t(
                  "_" + Config["case_status_" + currPanel.info.status].display
                )}
              </b>{" "}
            </p>

            {this.props.pageType === "case" ? (
              <p className={classes.headingRef}>{currPanel.info.refNumber}</p>
            ) : (
              <Link to={"/case/" + caseId} className={classes.caseLink}>
                <Button
                  className={classes.caseLinkBtn}
                  variant="contained"
                  color="default"
                  size="small"
                  onClick={this.handleClickOpen}
                >
                  <Forward className={classes.rightIcon} />
                  <p className={classes.headingRefBtn}>
                    {currPanel.info.refNumber}
                  </p>
                </Button>
              </Link>
            )}
          </ExpansionPanelSummary>

          {this.props.pageType === "case" ? (
            <div></div>
          ) : (
            <ExpansionPanelDetails>
              <Table className={classes.tableCaseDetails}>
                <TableBody>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.tbdRowLeft}>
                      {t("_collection_partner")}
                    </TableCell>
                    <TableCell className={classes.tbdRowRight}>
                      {this.state.caseSummaryInfo.collectionPartner
                        ? this.state.caseSummaryInfo.collectionPartner
                        : "Not Set"}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.tbdRowLeft}>
                      {t("_payment_on_case")}
                    </TableCell>
                    <TableCell className={classes.tbdRowRight}>
                      {this.state.caseSummaryInfo.casePayment
                        ? numberToLocaleString(
                            this.state.caseSummaryInfo.casePayment
                          ) + " €"
                        : numberToLocaleString(0) + " €"}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.tbdRowLeft}>
                      {t("_total_amount_asked")}
                    </TableCell>
                    <TableCell className={classes.tbdRowRight}>
                      {this.state.caseSummaryInfo.paymentRequested
                        ? numberToLocaleString(
                            this.state.caseSummaryInfo.paymentRequested
                          ) + " €"
                        : numberToLocaleString(0) + " €"}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.row}>
                    <TableCell className={classes.tbdRowLeft}>
                      {t("_case_creation_date")}
                    </TableCell>
                    <TableCell className={classes.tbdRowRight}>
                      {this.state.caseSummaryInfo.createdOn
                        ? formatDatetime(
                            this.state.caseSummaryInfo.createdOn,
                            "full"
                          )
                        : "N/A"}
                    </TableCell>
                  </TableRow>
                  {typeof this.state.caseSummaryNotes[0] === "undefined" ? (
                    <></>
                  ) : (
                    <TableRow className={classes.row}>
                      <TableCell className={classes.tbdRowLeft}>
                        {t("_notes")}
                      </TableCell>
                      <TableCell className={classes.tbdRowRight}>
                        <div className={classes.noteContainer}>
                          <div className={classes.noteText}>
                            {decodeEntities(
                              replaceBrTags(this.state.caseSummaryNotes[0].note)
                            )}
                          </div>
                          <p className={classes.noteTitle}>
                            {this.state.caseSummaryNotes[0].owner} -{" "}
                            {formatDatetime(
                              this.state.caseSummaryNotes[0].date,
                              "short"
                            )}
                          </p>
                          <IconButton
                            className={classes.notMoreButton}
                            color="primary"
                            onClick={this.handleClickOpen}
                          >
                            <MoreIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          )}
          <ExpansionPanelDetails className={classes.content}>
            {this.populateResults()}
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <DialogPopup open={this.state.popupOpen} onClose={this.handleClose}>
          <DialogTitle>{t("_notes")}</DialogTitle>
          <DialogContent className={classes.contentContainer}>
            <Table className={classes.tableNotes}>
              <TableBody>{this.populateNotes()}</TableBody>
            </Table>
          </DialogContent>
        </DialogPopup>
      </div>
    );
  }
}

CasePanel.propTypes = {
  classes: PropTypes.object.isRequired,
  caseId: PropTypes.string.isRequired,
  domainId: PropTypes.string.isRequired,
  pageType: PropTypes.oneOf(["case", "domain"]).isRequired,
};

function mapStateToProps(state) {
  return {
    casesReducer: state.casesReducer,
    domainsReducer: state.domainsReducer,
    findingsReducer: state.findingsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionGetFindingsCase: getFindingsCase,
      actionGetCase: getCase,
      actionUpdateCase: updateCase,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(CasePanel)
  )
);
