import { AUTH_USER, VALIDATE_USER, UNAUTH_USER, INVALIDATE_USER } from '@Types/actionTypes';

const authReducer = (state = {validated: false,authenticated: false}, action) => {
  switch (action.type) {
    case AUTH_USER:
      state = {validated:true, authenticated: true}
      break;
    case UNAUTH_USER:
      state = {validated:false, authenticated: false}
      break;
    case VALIDATE_USER:
      state = {...state, validated: true}
      break;   
    case INVALIDATE_USER:
      state = {...state, validated: false}
      break;        
    default:
      break;
  }
  return state;
};

export default authReducer;