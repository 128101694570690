const styles = (theme) => ({
  root: {
    // padding:10
  },
  formControl: {
    width: "100%",
    marginBottom: 10,
  },
  card: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: 350,
    height: 300,
  },
  cardContent: {
    padding: 25,
  },
  button: {
    textTransform: "initial",
    fontSize: 14,
    height: 40,
    minWidth: 150,
    width: "100%",
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent",
    },
  },
  textField: {
    width: "100%",
  },
  cardImageContainer: {
    backgroundColor: "#d7d7d7",
    textAlign: "center",
    padding: 20,
  },
  forgot_password_button: {
    minWidth: "inherit",
    fontSize: "12px",
    fontWeight: "inherit",
    textTransform: "inherit",
    padding: "0px 0px",
    color: "gray",
  },
});

export default styles;
