const styles = theme => ({
  root:{
    // backgroundColor:grey[100],
  },
  updateBtn:{
    float:"right"
  },
  updateProgress:{
    margin: "5px 0"
  },
  tagInput:{
    zIndex:10,
    // height:150
  }, 
});


export default styles;