const Config = {
  "0": {
    "id": 0,
    "display": true,
    "name": "Thumbnail Image Width",
    "displayName": "thumbnail_image_width"
  },
  "1": {
      "id": 1,
      "display": true,
      "name": "Thumbnail Image Height",
      "displayName": "thumbnail_image_height"
  },
  "2": {
      "id": 2,
      "display": true,
      "name": "Bits Per Sample",
      "displayName": "bits_per_sample"
  },
  "3": {
      "id": 3,
      "display": true,
      "name": "Photometric Interpretation",
      "displayName": "photometric_interpretation"
  },
  "4": {
      "id": 4,
      "display": true,
      "name": "Make",
      "displayName": "make"
  },
  "5": {
      "id": 5,
      "display": true,
      "name": "Model",
      "displayName": "model"
  },
  "6": {
      "id": 6,
      "display": true,
      "name": "Orientation",
      "displayName": "orientation"
  },
  "7": {
      "id": 7,
      "display": true,
      "name": "Samples Per Pixel",
      "displayName": "samples_per_pixel"
  },
  "8": {
      "id": 8,
      "display": true,
      "name": "X Resolution",
      "displayName": "x_resolution"
  },
  "9": {
      "id": 9,
      "display": true,
      "name": "Y Resolution",
      "displayName": "y_resolution"
  },
  "10": {
      "id": 10,
      "display": true,
      "name": "Resolution Unit",
      "displayName": "resolution_unit"
  },
  "11": {
      "id": 11,
      "display": true,
      "name": "Software",
      "displayName": "software"
  },
  "12": {
      "id": 12,
      "display": true,
      "name": "Date/Time",
      "displayName": "date/time"
  },
  "13": {
      "id": 13,
      "display": true,
      "name": "YCbCr Positioning",
      "displayName": "ycbcr_positioning"
  },
  "14": {
      "id": 14,
      "display": true,
      "name": "Reference Black/White",
      "displayName": "reference_black/white"
  },
  "15": {
      "id": 15,
      "display": true,
      "name": "Exposure Time",
      "displayName": "exposure_time"
  },
  "16": {
      "id": 16,
      "display": true,
      "name": "F-Number",
      "displayName": "f-number"
  },
  "17": {
      "id": 17,
      "display": true,
      "name": "ISO Speed Ratings",
      "displayName": "iso_speed_ratings"
  },
  "18": {
      "id": 18,
      "display": true,
      "name": "Date/Time Original",
      "displayName": "date/time_original"
  },
  "19": {
      "id": 19,
      "display": true,
      "name": "Date/Time Digitized",
      "displayName": "date/time_digitized"
  },
  "20": {
      "id": 20,
      "display": true,
      "name": "Shutter Speed Value",
      "displayName": "shutter_speed_value"
  },
  "21": {
      "id": 21,
      "display": true,
      "name": "Aperture Value",
      "displayName": "aperture_value"
  },
  "22": {
      "id": 22,
      "display": true,
      "name": "Exposure Bias Value",
      "displayName": "exposure_bias_value"
  },
  "23": {
      "id": 23,
      "display": true,
      "name": "Max Aperture Value",
      "displayName": "max_aperture_value"
  },
  "24": {
      "id": 24,
      "display": true,
      "name": "Metering Mode",
      "displayName": "metering_mode"
  },
  "25": {
      "id": 25,
      "display": true,
      "name": "Light source",
      "displayName": "light_source"
  },
  "26": {
      "id": 26,
      "display": true,
      "name": "Flash",
      "displayName": "flash"
  },
  "27": {
      "id": 27,
      "display": true,
      "name": "Focal Length",
      "displayName": "focal_length"
  },
  "28": {
      "id": 28,
      "display": true,
      "name": "Color Space",
      "displayName": "color_space"
  },
  "29": {
      "id": 29,
      "display": true,
      "name": "Exif Image Width",
      "displayName": "exif_image_width"
  },
  "30": {
      "id": 30,
      "display": true,
      "name": "Exif Image Height",
      "displayName": "exif_image_height"
  },
  "31": {
      "id": 31,
      "display": true,
      "name": "Exposure Index",
      "displayName": "exposure_index"
  },
  "32": {
      "id": 32,
      "display": true,
      "name": "Sensing Method",
      "displayName": "sensing_method"
  },
  "33": {
      "id": 33,
      "display": true,
      "name": "Custom Rendered",
      "displayName": "custom_rendered"
  },
  "34": {
      "id": 34,
      "display": true,
      "name": "Exposure Mode",
      "displayName": "exposure_mode"
  },
  "35": {
      "id": 35,
      "display": true,
      "name": "White balance mode",
      "displayName": "white_balance_mode"
  },
  "36": {
      "id": 36,
      "display": true,
      "name": "Digital Zoom Ratio",
      "displayName": "digital_zoom_ratio"
  },
  "37": {
      "id": 37,
      "display": true,
      "name": "Focal Length in 35mm Film",
      "displayName": "focal_length_in_35mm_film"
  },
  "38": {
      "id": 38,
      "display": true,
      "name": "Scene Capture Type",
      "displayName": "scene_capture_type"
  },
  "39": {
      "id": 39,
      "display": true,
      "name": "Gain Control",
      "displayName": "gain_control"
  },
  "40": {
      "id": 40,
      "display": true,
      "name": "Contrast",
      "displayName": "contrast"
  },
  "41": {
      "id": 41,
      "display": true,
      "name": "Saturation",
      "displayName": "saturation"
  },
  "42": {
      "id": 42,
      "display": true,
      "name": "Sharpness",
      "displayName": "sharpness"
  },
  "43": {
      "id": 43,
      "display": true,
      "name": "Compression",
      "displayName": "compression"
  },
  "44": {
      "id": 44,
      "display": true,
      "name": "Thumbnail Offset",
      "displayName": "thumbnail_offset"
  },
  "45": {
      "id": 45,
      "display": true,
      "name": "Thumbnail Length",
      "displayName": "thumbnail_length"
  },
  "46": {
      "id": 46,
      "display": true,
      "name": "Directory Version",
      "displayName": "directory_version"
  },
  "47": {
      "id": 47,
      "display": true,
      "name": "Date Created",
      "displayName": "date_created"
  },
  "48": {
      "id": 48,
      "display": true,
      "name": "Time Created",
      "displayName": "time_created"
  },
  "49": {
      "id": 49,
      "display": true,
      "name": "Exposure Program",
      "displayName": "exposure_program"
  },
  "50": {
      "id": 50,
      "display": true,
      "name": "Sub-Sec Time",
      "displayName": "sub-sec_time"
  },
  "51": {
      "id": 51,
      "display": true,
      "name": "Focal Plane X Resolution",
      "displayName": "focal_plane_x_resolution"
  },
  "52": {
      "id": 52,
      "display": true,
      "name": "Focal Plane Y Resolution",
      "displayName": "focal_plane_y_resolution"
  },
  "53": {
      "id": 53,
      "display": true,
      "name": "Focal Plane Resolution Unit",
      "displayName": "focal_plane_resolution_unit"
  },
  "54": {
      "id": 54,
      "display": true,
      "name": "Planar Configuration",
      "displayName": "planar_configuration"
  },
  "55": {
      "id": 55,
      "display": true,
      "name": "Artist",
      "displayName": "artist"
  },
  "56": {
      "id": 56,
      "display": true,
      "name": "By-line",
      "displayName": "by-line"
  },
  "57": {
      "id": 57,
      "display": true,
      "name": "Object Name",
      "displayName": "object_name"
  },
  "58": {
      "id": 58,
      "display": true,
      "name": "Compressed Bits Per Pixel",
      "displayName": "compressed_bits_per_pixel"
  },
  "59": {
      "id": 59,
      "display": true,
      "name": "Macro mode",
      "displayName": "macro_mode"
  },
  "60": {
      "id": 60,
      "display": true,
      "name": "Self timer delay",
      "displayName": "self_timer_delay"
  },
  "61": {
      "id": 61,
      "display": true,
      "name": "Quality",
      "displayName": "quality"
  },
  "62": {
      "id": 62,
      "display": true,
      "name": "Flash mode",
      "displayName": "flash_mode"
  },
  "63": {
      "id": 63,
      "display": true,
      "name": "Continuous drive mode",
      "displayName": "continuous_drive_mode"
  },
  "64": {
      "id": 64,
      "display": true,
      "name": "Focus mode 1",
      "displayName": "focus_mode_1"
  },
  "65": {
      "id": 65,
      "display": true,
      "name": "Record Mode",
      "displayName": "record_mode"
  },
  "66": {
      "id": 66,
      "display": true,
      "name": "Image size",
      "displayName": "image_size"
  },
  "67": {
      "id": 67,
      "display": true,
      "name": "Easy shooting mode",
      "displayName": "easy_shooting_mode"
  },
  "68": {
      "id": 68,
      "display": true,
      "name": "Digital zoom",
      "displayName": "digital_zoom"
  },
  "69": {
      "id": 69,
      "display": true,
      "name": "ISO",
      "displayName": "iso"
  },
  "70": {
      "id": 70,
      "display": true,
      "name": "Focus type",
      "displayName": "focus_type"
  },
  "71": {
      "id": 71,
      "display": true,
      "name": "Lens type",
      "displayName": "lens_type"
  },
  "72": {
      "id": 72,
      "display": true,
      "name": "Long focal length",
      "displayName": "long_focal_length"
  },
  "73": {
      "id": 73,
      "display": true,
      "name": "Short focal length",
      "displayName": "short_focal_length"
  },
  "74": {
      "id": 74,
      "display": true,
      "name": "Focal units per mm",
      "displayName": "focal_units_per_mm"
  },
  "75": {
      "id": 75,
      "display": true,
      "name": "Max aperture",
      "displayName": "max_aperture"
  },
  "76": {
      "id": 76,
      "display": true,
      "name": "Min aperture",
      "displayName": "min_aperture"
  },
  "77": {
      "id": 77,
      "display": true,
      "name": "Flash activity",
      "displayName": "flash_activity"
  },
  "78": {
      "id": 78,
      "display": true,
      "name": "Focus mode 2",
      "displayName": "focus_mode_2"
  },
  "79": {
      "id": 79,
      "display": true,
      "name": "AESetting",
      "displayName": "aesetting"
  },
  "80": {
      "id": 80,
      "display": true,
      "name": "Image stabilization",
      "displayName": "image_stabilization"
  },
  "81": {
      "id": 81,
      "display": true,
      "name": "Display aperture",
      "displayName": "display_aperture"
  },
  "82": {
      "id": 82,
      "display": true,
      "name": "Zoom wource width",
      "displayName": "zoom_wource_width"
  },
  "83": {
      "id": 83,
      "display": true,
      "name": "Zoom target width",
      "displayName": "zoom_target_width"
  },
  "84": {
      "id": 84,
      "display": true,
      "name": "Spot metering mode",
      "displayName": "spot_metering_mode"
  },
  "85": {
      "id": 85,
      "display": true,
      "name": "Photo effect",
      "displayName": "photo_effect"
  },
  "86": {
      "id": 86,
      "display": true,
      "name": "Manual flash output",
      "displayName": "manual_flash_output"
  },
  "87": {
      "id": 87,
      "display": true,
      "name": "Color tone",
      "displayName": "color_tone"
  },
  "88": {
      "id": 88,
      "display": true,
      "name": "Camera State 1",
      "displayName": "camera_state_1"
  },
  "89": {
      "id": 89,
      "display": true,
      "name": "Focal type",
      "displayName": "focal_type"
  },
  "90": {
      "id": 90,
      "display": true,
      "name": "Focal plane X-size",
      "displayName": "focal_plane_x-size"
  },
  "91": {
      "id": 91,
      "display": true,
      "name": "Focal plane Y-size",
      "displayName": "focal_plane_y-size"
  },
  "92": {
      "id": 92,
      "display": true,
      "name": "Canon Focal Length",
      "displayName": "canon_focal_length"
  },
  "93": {
      "id": 93,
      "display": true,
      "name": "Auto ISO",
      "displayName": "auto_iso"
  },
  "94": {
      "id": 94,
      "display": true,
      "name": "Base ISO",
      "displayName": "base_iso"
  },
  "95": {
      "id": 95,
      "display": true,
      "name": "Measured EV",
      "displayName": "measured_ev"
  },
  "96": {
      "id": 96,
      "display": true,
      "name": "Target aperture",
      "displayName": "target_aperture"
  },
  "97": {
      "id": 97,
      "display": true,
      "name": "Target exposure time",
      "displayName": "target_exposure_time"
  },
  "98": {
      "id": 98,
      "display": true,
      "name": "Exposure compensation",
      "displayName": "exposure_compensation"
  },
  "99": {
      "id": 99,
      "display": true,
      "name": "White balance",
      "displayName": "white_balance"
  },
  "100": {
      "id": 100,
      "display": true,
      "name": "Slow shutter",
      "displayName": "slow_shutter"
  },
  "101": {
      "id": 101,
      "display": true,
      "name": "Sequence number",
      "displayName": "sequence_number"
  },
  "102": {
      "id": 102,
      "display": true,
      "name": "Optical zoom code",
      "displayName": "optical_zoom_code"
  },
  "103": {
      "id": 103,
      "display": true,
      "name": "Control mode",
      "displayName": "control_mode"
  },
  "104": {
      "id": 104,
      "display": true,
      "name": "AF point used",
      "displayName": "af_point_used"
  },
  "105": {
      "id": 105,
      "display": true,
      "name": "Flash bias",
      "displayName": "flash_bias"
  },
  "106": {
      "id": 106,
      "display": true,
      "name": "Auto exposure bracketing",
      "displayName": "auto_exposure_bracketing"
  },
  "107": {
      "id": 107,
      "display": true,
      "name": "AEB bracket value",
      "displayName": "aeb_bracket_value"
  },
  "108": {
      "id": 108,
      "display": true,
      "name": "Flash guide number",
      "displayName": "flash_guide_number"
  },
  "109": {
      "id": 109,
      "display": true,
      "name": "Subject distance",
      "displayName": "subject_distance"
  },
  "110": {
      "id": 110,
      "display": true,
      "name": "Focus distance lower",
      "displayName": "focus_distance_lower"
  },
  "111": {
      "id": 111,
      "display": true,
      "name": "FNumber",
      "displayName": "fnumber"
  },
  "112": {
      "id": 112,
      "display": true,
      "name": "Bulb duration",
      "displayName": "bulb_duration"
  },
  "113": {
      "id": 113,
      "display": true,
      "name": "Camera type",
      "displayName": "camera_type"
  },
  "114": {
      "id": 114,
      "display": true,
      "name": "Auto rotate",
      "displayName": "auto_rotate"
  },
  "115": {
      "id": 115,
      "display": true,
      "name": "ND filter",
      "displayName": "nd_filter"
  },
  "116": {
      "id": 116,
      "display": true,
      "name": "Self timer 2",
      "displayName": "self_timer_2"
  },
  "117": {
      "id": 117,
      "display": true,
      "name": "Flash output",
      "displayName": "flash_output"
  },
  "118": {
      "id": 118,
      "display": true,
      "name": "Camera State 2",
      "displayName": "camera_state_2"
  },
  "119": {
      "id": 119,
      "display": true,
      "name": "Canon AF Info",
      "displayName": "canon_af_info"
  },
  "120": {
      "id": 120,
      "display": true,
      "name": "Image type",
      "displayName": "image_type"
  },
  "121": {
      "id": 121,
      "display": true,
      "name": "Firware version",
      "displayName": "firware_version"
  },
  "122": {
      "id": 122,
      "display": true,
      "name": "Image number",
      "displayName": "image_number"
  },
  "123": {
      "id": 123,
      "display": true,
      "name": "Owner name",
      "displayName": "owner_name"
  },
  "124": {
      "id": 124,
      "display": true,
      "name": "Canon Model ID",
      "displayName": "canon_model_id"
  },
  "125": {
      "id": 125,
      "display": true,
      "name": "Canon Camera Info",
      "displayName": "canon_camera_info"
  },
  "126": {
      "id": 126,
      "display": true,
      "name": "Interoperability Index",
      "displayName": "interoperability_index"
  },
  "127": {
      "id": 127,
      "display": true,
      "name": "Related Image Width",
      "displayName": "related_image_width"
  },
  "128": {
      "id": 128,
      "display": true,
      "name": "Related Image Length",
      "displayName": "related_image_length"
  },
  "129": {
      "id": 129,
      "display": true,
      "name": "Caption/Abstract",
      "displayName": "caption/abstract"
  },
  "130": {
      "id": 130,
      "display": true,
      "name": "Copyright Notice",
      "displayName": "copyright_notice"
  },
  "131": {
      "id": 131,
      "display": true,
      "name": "City",
      "displayName": "city"
  },
  "132": {
      "id": 132,
      "display": true,
      "name": "Writer/Editor",
      "displayName": "writer/editor"
  },
  "133": {
      "id": 133,
      "display": true,
      "name": "Supplemental Category(s)",
      "displayName": "supplemental_category(s)"
  },
  "134": {
      "id": 134,
      "display": true,
      "name": "Credit",
      "displayName": "credit"
  },
  "135": {
      "id": 135,
      "display": true,
      "name": "Province/State",
      "displayName": "province/state"
  },
  "136": {
      "id": 136,
      "display": true,
      "name": "Country/Primary Location",
      "displayName": "country/primary_location"
  },
  "137": {
      "id": 137,
      "display": true,
      "name": "Original Transmission Reference",
      "displayName": "original_transmission_reference"
  },
  "138": {
      "id": 138,
      "display": true,
      "name": "Release Time",
      "displayName": "release_time"
  },
  "139": {
      "id": 139,
      "display": true,
      "name": "Category",
      "displayName": "category"
  },
  "140": {
      "id": 140,
      "display": true,
      "name": "Originating Program",
      "displayName": "originating_program"
  },
  "141": {
      "id": 141,
      "display": true,
      "name": "By-line Title",
      "displayName": "by-line_title"
  },
  "142": {
      "id": 142,
      "display": true,
      "name": "Release Date",
      "displayName": "release_date"
  },
  "143": {
      "id": 143,
      "display": true,
      "name": "Source",
      "displayName": "source"
  },
  "144": {
      "id": 144,
      "display": true,
      "name": "Image Description",
      "displayName": "image_description"
  },
  "145": {
      "id": 145,
      "display": true,
      "name": "Color Mode",
      "displayName": "color_mode"
  },
  "146": {
      "id": 146,
      "display": true,
      "name": "Quality & File Format",
      "displayName": "quality_&_file_format"
  },
  "147": {
      "id": 147,
      "display": true,
      "name": "Sharpening",
      "displayName": "sharpening"
  },
  "148": {
      "id": 148,
      "display": true,
      "name": "AF Type",
      "displayName": "af_type"
  },
  "149": {
      "id": 149,
      "display": true,
      "name": "Flash Sync Mode",
      "displayName": "flash_sync_mode"
  },
  "150": {
      "id": 150,
      "display": true,
      "name": "White Balance Fine",
      "displayName": "white_balance_fine"
  },
  "151": {
      "id": 151,
      "display": true,
      "name": "ISO Selection",
      "displayName": "iso_selection"
  },
  "152": {
      "id": 152,
      "display": true,
      "name": "Image Adjustment",
      "displayName": "image_adjustment"
  },
  "153": {
      "id": 153,
      "display": true,
      "name": "Adapter",
      "displayName": "adapter"
  },
  "154": {
      "id": 154,
      "display": true,
      "name": "Manual Focus Distance",
      "displayName": "manual_focus_distance"
  },
  "155": {
      "id": 155,
      "display": true,
      "name": "Noise Reduction",
      "displayName": "noise_reduction"
  },
  "156": {
      "id": 156,
      "display": true,
      "name": "Sub-Sec Time Original",
      "displayName": "sub-sec_time_original"
  },
  "157": {
      "id": 157,
      "display": true,
      "name": "Sub-Sec Time Digitized",
      "displayName": "sub-sec_time_digitized"
  },
  "158": {
      "id": 158,
      "display": true,
      "name": "Author (Win)",
      "displayName": "author_(win)"
  },
  "159": {
      "id": 159,
      "display": true,
      "name": "Copyright",
      "displayName": "copyright"
  },
  "160": {
      "id": 160,
      "display": true,
      "name": "Headline",
      "displayName": "headline"
  },
  "161": {
      "id": 161,
      "display": true,
      "name": "Special Instructions",
      "displayName": "special_instructions"
  },
  "162": {
      "id": 162,
      "display": true,
      "name": "Keywords",
      "displayName": "keywords"
  },
  "163": {
      "id": 163,
      "display": true,
      "name": "Quality Mode",
      "displayName": "quality_mode"
  },
  "164": {
      "id": 164,
      "display": true,
      "name": "Brightness Value",
      "displayName": "brightness_value"
  },
  "165": {
      "id": 165,
      "display": true,
      "name": "Subject Location",
      "displayName": "subject_location"
  },
  "166": {
      "id": 166,
      "display": true,
      "name": "GPS Latitude Ref",
      "displayName": "gps_latitude_ref"
  },
  "167": {
      "id": 167,
      "display": true,
      "name": "GPS Latitude",
      "displayName": "gps_latitude"
  },
  "168": {
      "id": 168,
      "display": true,
      "name": "GPS Longitude Ref",
      "displayName": "gps_longitude_ref"
  },
  "169": {
      "id": 169,
      "display": true,
      "name": "GPS Longitude",
      "displayName": "gps_longitude"
  },
  "170": {
      "id": 170,
      "display": true,
      "name": "GPS Altitude Ref",
      "displayName": "gps_altitude_ref"
  },
  "171": {
      "id": 171,
      "display": true,
      "name": "GPS Altitude",
      "displayName": "gps_altitude"
  },
  "172": {
      "id": 172,
      "display": true,
      "name": "GPS Time-Stamp",
      "displayName": "gps_time-stamp"
  },
  "173": {
      "id": 173,
      "display": true,
      "name": "GPS Img Direction Ref",
      "displayName": "gps_img_direction_ref"
  },
  "174": {
      "id": 174,
      "display": true,
      "name": "GPS Img Direction",
      "displayName": "gps_img_direction"
  },
  "175": {
      "id": 175,
      "display": true,
      "name": "Image Unique ID",
      "displayName": "image_unique_id"
  },
  "176": {
      "id": 176,
      "display": true,
      "name": "Urgency",
      "displayName": "urgency"
  },
  "177": {
      "id": 177,
      "display": true,
      "name": "Metering methode",
      "displayName": "metering_methode"
  },
  "178": {
      "id": 178,
      "display": true,
      "name": "Serial number",
      "displayName": "serial_number"
  },
  "179": {
      "id": 179,
      "display": true,
      "name": "Custom functions shutter auto exposure",
      "displayName": "custom_functions_shutter_auto_exposure"
  },
  "180": {
      "id": 180,
      "display": true,
      "name": "Custom functions mirror lockup",
      "displayName": "custom_functions_mirror_lockup"
  },
  "181": {
      "id": 181,
      "display": true,
      "name": "Custom functions TV AV and exposure level",
      "displayName": "custom_functions_tv_av_and_exposure_level"
  },
  "182": {
      "id": 182,
      "display": true,
      "name": "Custom functions AF assist light",
      "displayName": "custom_functions_af_assist_light"
  },
  "183": {
      "id": 183,
      "display": true,
      "name": "Custom functions speed in AV mode",
      "displayName": "custom_functions_speed_in_av_mode"
  },
  "184": {
      "id": 184,
      "display": true,
      "name": "Custom functions bracketting",
      "displayName": "custom_functions_bracketting"
  },
  "185": {
      "id": 185,
      "display": true,
      "name": "Custom functions shutter curtain sync",
      "displayName": "custom_functions_shutter_curtain_sync"
  },
  "186": {
      "id": 186,
      "display": true,
      "name": "Custom functions AF stop",
      "displayName": "custom_functions_af_stop"
  },
  "187": {
      "id": 187,
      "display": true,
      "name": "Custom functions fill flash reduction",
      "displayName": "custom_functions_fill_flash_reduction"
  },
  "188": {
      "id": 188,
      "display": true,
      "name": "Serial Number Format",
      "displayName": "serial_number_format"
  },
  "189": {
      "id": 189,
      "display": true,
      "name": "Original Decision Data Offset",
      "displayName": "original_decision_data_offset"
  },
  "190": {
      "id": 190,
      "display": true,
      "name": "Canon File Info",
      "displayName": "canon_file_info"
  },
  "191": {
      "id": 191,
      "display": true,
      "name": "Tone curve",
      "displayName": "tone_curve"
  },
  "192": {
      "id": 192,
      "display": true,
      "name": "Sharpness frequency",
      "displayName": "sharpness_frequency"
  },
  "193": {
      "id": 193,
      "display": true,
      "name": "Sensor red level",
      "displayName": "sensor_red_level"
  },
  "194": {
      "id": 194,
      "display": true,
      "name": "Sensor blue level",
      "displayName": "sensor_blue_level"
  },
  "195": {
      "id": 195,
      "display": true,
      "name": "White balance red",
      "displayName": "white_balance_red"
  },
  "196": {
      "id": 196,
      "display": true,
      "name": "White balance blue",
      "displayName": "white_balance_blue"
  },
  "197": {
      "id": 197,
      "display": true,
      "name": "Color temperature",
      "displayName": "color_temperature"
  },
  "198": {
      "id": 198,
      "display": true,
      "name": "Picture style",
      "displayName": "picture_style"
  },
  "199": {
      "id": 199,
      "display": true,
      "name": "Digital gain",
      "displayName": "digital_gain"
  },
  "200": {
      "id": 200,
      "display": true,
      "name": "WB Shift AB",
      "displayName": "wb_shift_ab"
  },
  "201": {
      "id": 201,
      "display": true,
      "name": "WB Shift GM",
      "displayName": "wb_shift_gm"
  },
  "202": {
      "id": 202,
      "display": true,
      "name": "Proccessing Info",
      "displayName": "proccessing_info"
  },
  "203": {
      "id": 203,
      "display": true,
      "name": "VRD Offset",
      "displayName": "vrd_offset"
  },
  "204": {
      "id": 204,
      "display": true,
      "name": "Sensor width",
      "displayName": "sensor_width"
  },
  "205": {
      "id": 205,
      "display": true,
      "name": "Sensor height",
      "displayName": "sensor_height"
  },
  "206": {
      "id": 206,
      "display": true,
      "name": "Sensor left border",
      "displayName": "sensor_left_border"
  },
  "207": {
      "id": 207,
      "display": true,
      "name": "Sensor top border",
      "displayName": "sensor_top_border"
  },
  "208": {
      "id": 208,
      "display": true,
      "name": "Sensor right border",
      "displayName": "sensor_right_border"
  },
  "209": {
      "id": 209,
      "display": true,
      "name": "Sensor bottom border",
      "displayName": "sensor_bottom_border"
  },
  "210": {
      "id": 210,
      "display": true,
      "name": "Black mask left border",
      "displayName": "black_mask_left_border"
  },
  "211": {
      "id": 211,
      "display": true,
      "name": "Black mask top border",
      "displayName": "black_mask_top_border"
  },
  "212": {
      "id": 212,
      "display": true,
      "name": "Black mask right border",
      "displayName": "black_mask_right_border"
  },
  "213": {
      "id": 213,
      "display": true,
      "name": "Black mask bottom border",
      "displayName": "black_mask_bottom_border"
  },
  "214": {
      "id": 214,
      "display": true,
      "name": "Sensor Info",
      "displayName": "sensor_info"
  },
  "215": {
      "id": 215,
      "display": true,
      "name": "Color Balance 1 to 4",
      "displayName": "color_balance_1_to_4"
  },
  "216": {
      "id": 216,
      "display": true,
      "name": "Color Info",
      "displayName": "color_info"
  },
  "217": {
      "id": 217,
      "display": true,
      "name": "GPS Version ID",
      "displayName": "gps_version_id"
  },
  "218": {
      "id": 218,
      "display": true,
      "name": "Canon AF Info 2",
      "displayName": "canon_af_info_2"
  },
  "219": {
      "id": 219,
      "display": true,
      "name": "Internal Serial Number",
      "displayName": "internal_serial_number"
  },
  "220": {
      "id": 220,
      "display": true,
      "name": "Custom Functions 2",
      "displayName": "custom_functions_2"
  },
  "221": {
      "id": 221,
      "display": true,
      "name": "Black Level",
      "displayName": "black_level"
  },
  "222": {
      "id": 222,
      "display": true,
      "name": "White Point",
      "displayName": "white_point"
  },
  "223": {
      "id": 223,
      "display": true,
      "name": "Primary Chromaticities",
      "displayName": "primary_chromaticities"
  },
  "224": {
      "id": 224,
      "display": true,
      "name": "Keyword (Win)",
      "displayName": "keyword_(win)"
  },
  "225": {
      "id": 225,
      "display": true,
      "name": "YCbCr Coefficients",
      "displayName": "ycbcr_coefficients"
  },
  "226": {
      "id": 226,
      "display": true,
      "name": "Custom functions menu button return",
      "displayName": "custom_functions_menu_button_return"
  },
  "227": {
      "id": 227,
      "display": true,
      "name": "Custom functions set button function",
      "displayName": "custom_functions_set_button_function"
  },
  "228": {
      "id": 228,
      "display": true,
      "name": "Title (Win)",
      "displayName": "title_(win)"
  },
  "229": {
      "id": 229,
      "display": true,
      "name": "Subject (Win)",
      "displayName": "subject_(win)"
  },
  "230": {
      "id": 230,
      "display": true,
      "name": "Comments (Win)",
      "displayName": "comments_(win)"
  },
  "231": {
      "id": 231,
      "display": true,
      "name": "New Subfile Type",
      "displayName": "new_subfile_type"
  },
  "232": {
      "id": 232,
      "display": true,
      "name": "Strip Offsets",
      "displayName": "strip_offsets"
  },
  "233": {
      "id": 233,
      "display": true,
      "name": "Rows Per Strip",
      "displayName": "rows_per_strip"
  },
  "234": {
      "id": 234,
      "display": true,
      "name": "Strip Byte Counts",
      "displayName": "strip_byte_counts"
  },
  "235": {
      "id": 235,
      "display": true,
      "name": "User Comment",
      "displayName": "user_comment"
  },
  "236": {
      "id": 236,
      "display": true,
      "name": "Date Stamp Mode",
      "displayName": "date_stamp_mode"
  },
  "237": {
      "id": 237,
      "display": true,
      "name": "My Colors",
      "displayName": "my_colors"
  },
  "238": {
      "id": 238,
      "display": true,
      "name": "Firmware Revision",
      "displayName": "firmware_revision"
  },
  "239": {
      "id": 239,
      "display": true,
      "name": "Color",
      "displayName": "color"
  },
  "240": {
      "id": 240,
      "display": true,
      "name": "Flash Strength",
      "displayName": "flash_strength"
  },
  "241": {
      "id": 241,
      "display": true,
      "name": "Macro",
      "displayName": "macro"
  },
  "242": {
      "id": 242,
      "display": true,
      "name": "Focus Mode",
      "displayName": "focus_mode"
  },
  "243": {
      "id": 243,
      "display": true,
      "name": "Slow Synchro",
      "displayName": "slow_synchro"
  },
  "244": {
      "id": 244,
      "display": true,
      "name": "Picture Mode",
      "displayName": "picture_mode"
  },
  "245": {
      "id": 245,
      "display": true,
      "name": "Makernote Unknown 1",
      "displayName": "makernote_unknown_1"
  },
  "246": {
      "id": 246,
      "display": true,
      "name": "Continuous Taking Or Auto Bracketting",
      "displayName": "continuous_taking_or_auto_bracketting"
  },
  "247": {
      "id": 247,
      "display": true,
      "name": "Makernote Unknown 2",
      "displayName": "makernote_unknown_2"
  },
  "248": {
      "id": 248,
      "display": true,
      "name": "Blur Warning",
      "displayName": "blur_warning"
  },
  "249": {
      "id": 249,
      "display": true,
      "name": "Focus Warning",
      "displayName": "focus_warning"
  },
  "250": {
      "id": 250,
      "display": true,
      "name": "AE Warning",
      "displayName": "ae_warning"
  },
  "251": {
      "id": 251,
      "display": true,
      "name": "Thumbnail dimensions",
      "displayName": "thumbnail_dimensions"
  },
  "252": {
      "id": 252,
      "display": true,
      "name": "Thumbnail size",
      "displayName": "thumbnail_size"
  },
  "253": {
      "id": 253,
      "display": true,
      "name": "White balance bias",
      "displayName": "white_balance_bias"
  },
  "254": {
      "id": 254,
      "display": true,
      "name": "White balance 2",
      "displayName": "white_balance_2"
  },
  "255": {
      "id": 255,
      "display": true,
      "name": "Object distance",
      "displayName": "object_distance"
  },
  "256": {
      "id": 256,
      "display": true,
      "name": "Flash distance",
      "displayName": "flash_distance"
  },
  "257": {
      "id": 257,
      "display": true,
      "name": "Self timer",
      "displayName": "self_timer"
  },
  "258": {
      "id": 258,
      "display": true,
      "name": "Time zone",
      "displayName": "time_zone"
  },
  "259": {
      "id": 259,
      "display": true,
      "name": "Enhancement",
      "displayName": "enhancement"
  },
  "260": {
      "id": 260,
      "display": true,
      "name": "Filter",
      "displayName": "filter"
  },
  "261": {
      "id": 261,
      "display": true,
      "name": "White balance 1",
      "displayName": "white_balance_1"
  },
  "262": {
      "id": 262,
      "display": true,
      "name": "Related Sound File",
      "displayName": "related_sound_file"
  },
  "263": {
      "id": 263,
      "display": true,
      "name": "Time Zone Offset",
      "displayName": "time_zone_offset"
  },
  "264": {
      "id": 264,
      "display": true,
      "name": "Auto Flash Mode",
      "displayName": "auto_flash_mode"
  },
  "265": {
      "id": 265,
      "display": true,
      "name": "White Balance RB Coefficients",
      "displayName": "white_balance_rb_coefficients"
  },
  "266": {
      "id": 266,
      "display": true,
      "name": "Tone Compensation",
      "displayName": "tone_compensation"
  },
  "267": {
      "id": 267,
      "display": true,
      "name": "Lens",
      "displayName": "lens"
  },
  "268": {
      "id": 268,
      "display": true,
      "name": "Colour Mode",
      "displayName": "colour_mode"
  },
  "269": {
      "id": 269,
      "display": true,
      "name": "Camera Hue Adjustment",
      "displayName": "camera_hue_adjustment"
  },
  "270": {
      "id": 270,
      "display": true,
      "name": "Exposure Sequence Number",
      "displayName": "exposure_sequence_number"
  },
  "271": {
      "id": 271,
      "display": true,
      "name": "Predictor",
      "displayName": "predictor"
  },
  "272": {
      "id": 272,
      "display": true,
      "name": "Super Macro",
      "displayName": "super_macro"
  },
  "273": {
      "id": 273,
      "display": true,
      "name": "Exif Version",
      "displayName": "exif_version"
  },
  "274": {
      "id": 274,
      "display": true,
      "name": "Face Detect 2",
      "displayName": "face_detect_2"
  },
  "275": {
      "id": 275,
      "display": true,
      "name": "Tone",
      "displayName": "tone"
  },
  "276": {
      "id": 276,
      "display": true,
      "name": "Face Detect 1",
      "displayName": "face_detect_1"
  },
  "277": {
      "id": 277,
      "display": true,
      "name": "GPS Map Datum",
      "displayName": "gps_map_datum"
  },
  "278": {
      "id": 278,
      "display": true,
      "name": "Minimum sample value",
      "displayName": "minimum_sample_value"
  },
  "279": {
      "id": 279,
      "display": true,
      "name": "Maximum sample value",
      "displayName": "maximum_sample_value"
  },
  "280": {
      "id": 280,
      "display": true,
      "name": "Document Name",
      "displayName": "document_name"
  },
  "281": {
      "id": 281,
      "display": true,
      "name": "Page name",
      "displayName": "page_name"
  },
  "282": {
      "id": 282,
      "display": true,
      "name": "IPTC/NAA",
      "displayName": "iptc/naa"
  },
  "283": {
      "id": 283,
      "display": true,
      "name": "GPS Satellites",
      "displayName": "gps_satellites"
  },
  "284": {
      "id": 284,
      "display": true,
      "name": "GPS Status",
      "displayName": "gps_status"
  },
  "285": {
      "id": 285,
      "display": true,
      "name": "GPS Measure Mode",
      "displayName": "gps_measure_mode"
  },
  "286": {
      "id": 286,
      "display": true,
      "name": "GPS DOP",
      "displayName": "gps_dop"
  },
  "287": {
      "id": 287,
      "display": true,
      "name": "YCbCr Sub-Sampling",
      "displayName": "ycbcr_sub-sampling"
  },
  "288": {
      "id": 288,
      "display": true,
      "name": "Flash Intensity",
      "displayName": "flash_intensity"
  },
  "289": {
      "id": 289,
      "display": true,
      "name": "Makernote Unknown 3",
      "displayName": "makernote_unknown_3"
  },
  "290": {
      "id": 290,
      "display": true,
      "name": "Makernote Unknown 4",
      "displayName": "makernote_unknown_4"
  },
  "291": {
      "id": 291,
      "display": true,
      "name": "Makernote Unknown 5",
      "displayName": "makernote_unknown_5"
  },
  "292": {
      "id": 292,
      "display": true,
      "name": "Makernote Unknown 6",
      "displayName": "makernote_unknown_6"
  },
  "293": {
      "id": 293,
      "display": true,
      "name": "Makernote Unknown 7",
      "displayName": "makernote_unknown_7"
  },
  "294": {
      "id": 294,
      "display": true,
      "name": "Makernote Unknown 8",
      "displayName": "makernote_unknown_8"
  },
  "295": {
      "id": 295,
      "display": true,
      "name": "Color Balance",
      "displayName": "color_balance"
  },
  "296": {
      "id": 296,
      "display": true,
      "name": "Preview Image Info",
      "displayName": "preview_image_info"
  },
  "297": {
      "id": 297,
      "display": true,
      "name": "GPS Speed Ref",
      "displayName": "gps_speed_ref"
  },
  "298": {
      "id": 298,
      "display": true,
      "name": "GPS Speed",
      "displayName": "gps_speed"
  },
  "299": {
      "id": 299,
      "display": true,
      "name": "FlashPix Version",
      "displayName": "flashpix_version"
  },
  "300": {
      "id": 300,
      "display": true,
      "name": "Special Mode",
      "displayName": "special_mode"
  },
  "301": {
      "id": 301,
      "display": true,
      "name": "Jpeg Quality",
      "displayName": "jpeg_quality"
  },
  "302": {
      "id": 302,
      "display": true,
      "name": "Firmware Version",
      "displayName": "firmware_version"
  },
  "303": {
      "id": 303,
      "display": true,
      "name": "Pict Info",
      "displayName": "pict_info"
  },
  "304": {
      "id": 304,
      "display": true,
      "name": "Bracket",
      "displayName": "bracket"
  },
  "305": {
      "id": 305,
      "display": true,
      "name": "Focus Distance",
      "displayName": "focus_distance"
  },
  "306": {
      "id": 306,
      "display": true,
      "name": "Zoom",
      "displayName": "zoom"
  },
  "307": {
      "id": 307,
      "display": true,
      "name": "Macro Focus",
      "displayName": "macro_focus"
  },
  "308": {
      "id": 308,
      "display": true,
      "name": "Color Matrix",
      "displayName": "color_matrix"
  },
  "309": {
      "id": 309,
      "display": true,
      "name": "Red Bias",
      "displayName": "red_bias"
  },
  "310": {
      "id": 310,
      "display": true,
      "name": "Blue Bias",
      "displayName": "blue_bias"
  },
  "311": {
      "id": 311,
      "display": true,
      "name": "Sharpness Factor",
      "displayName": "sharpness_factor"
  },
  "312": {
      "id": 312,
      "display": true,
      "name": "Color Control",
      "displayName": "color_control"
  },
  "313": {
      "id": 313,
      "display": true,
      "name": "Valid Bits",
      "displayName": "valid_bits"
  },
  "314": {
      "id": 314,
      "display": true,
      "name": "Coring Filter",
      "displayName": "coring_filter"
  },
  "315": {
      "id": 315,
      "display": true,
      "name": "Final Width",
      "displayName": "final_width"
  },
  "316": {
      "id": 316,
      "display": true,
      "name": "Final Height",
      "displayName": "final_height"
  },
  "317": {
      "id": 317,
      "display": true,
      "name": "Compression Ratio",
      "displayName": "compression_ratio"
  },
  "318": {
      "id": 318,
      "display": true,
      "name": "Spectral Sensitivity",
      "displayName": "spectral_sensitivity"
  },
  "319": {
      "id": 319,
      "display": true,
      "name": "Custom functions sensor cleaning",
      "displayName": "custom_functions_sensor_cleaning"
  },
  "320": {
      "id": 320,
      "display": true,
      "name": "Personal Functions",
      "displayName": "personal_functions"
  },
  "321": {
      "id": 321,
      "display": true,
      "name": "Personal Function Values",
      "displayName": "personal_function_values"
  },
  "322": {
      "id": 322,
      "display": true,
      "name": "ISO sensitivity",
      "displayName": "iso_sensitivity"
  },
  "323": {
      "id": 323,
      "display": true,
      "name": "Spatial Frequency Response",
      "displayName": "spatial_frequency_response"
  },
  "324": {
      "id": 324,
      "display": true,
      "name": "GPS Track Ref",
      "displayName": "gps_track_ref"
  },
  "325": {
      "id": 325,
      "display": true,
      "name": "GPS Track",
      "displayName": "gps_track"
  },
  "326": {
      "id": 326,
      "display": true,
      "name": "Flash Energy",
      "displayName": "flash_energy"
  },
  "327": {
      "id": 327,
      "display": true,
      "name": "AF point selected",
      "displayName": "af_point_selected"
  },
  "328": {
      "id": 328,
      "display": true,
      "name": "Data Dump",
      "displayName": "data_dump"
  },
  "329": {
      "id": 329,
      "display": true,
      "name": "CCD Sensitivity",
      "displayName": "ccd_sensitivity"
  },
  "330": {
      "id": 330,
      "display": true,
      "name": "Focus",
      "displayName": "focus"
  },
  "331": {
      "id": 331,
      "display": true,
      "name": "Fisheye Converter",
      "displayName": "fisheye_converter"
  },
  "332": {
      "id": 332,
      "display": true,
      "name": "Fill Order",
      "displayName": "fill_order"
  },
  "333": {
      "id": 333,
      "display": true,
      "name": "Sub IFDs",
      "displayName": "sub_ifds"
  },
  "334": {
      "id": 334,
      "display": true,
      "name": "Image Quality (2)",
      "displayName": "image_quality_(2)"
  },
  "335": {
      "id": 335,
      "display": true,
      "name": "Focussing Mode",
      "displayName": "focussing_mode"
  },
  "336": {
      "id": 336,
      "display": true,
      "name": "Compressed Image Size",
      "displayName": "compressed_image_size"
  },
  "337": {
      "id": 337,
      "display": true,
      "name": "Thumbnail Offset (2)",
      "displayName": "thumbnail_offset_(2)"
  },
  "338": {
      "id": 338,
      "display": true,
      "name": "Image Quality (1)",
      "displayName": "image_quality_(1)"
  },
  "339": {
      "id": 339,
      "display": true,
      "name": "File Source",
      "displayName": "file_source"
  },
  "340": {
      "id": 340,
      "display": true,
      "name": "Scene Type",
      "displayName": "scene_type"
  },
  "341": {
      "id": 341,
      "display": true,
      "name": "GPS Dest Latitude Ref",
      "displayName": "gps_dest_latitude_ref"
  },
  "342": {
      "id": 342,
      "display": true,
      "name": "GPS Dest Latitude",
      "displayName": "gps_dest_latitude"
  },
  "343": {
      "id": 343,
      "display": true,
      "name": "GPS Dest Longitude Ref",
      "displayName": "gps_dest_longitude_ref"
  },
  "344": {
      "id": 344,
      "display": true,
      "name": "GPS Dest Longitude",
      "displayName": "gps_dest_longitude"
  },
  "345": {
      "id": 345,
      "display": true,
      "name": "TIFF/EP Standard ID",
      "displayName": "tiff/ep_standard_id"
  },
  "346": {
      "id": 346,
      "display": true,
      "name": "Image Width",
      "displayName": "image_width"
  },
  "347": {
      "id": 347,
      "display": true,
      "name": "Image Height",
      "displayName": "image_height"
  },
  "348": {
      "id": 348,
      "display": true,
      "name": "Original Manufacturer Model",
      "displayName": "original_manufacturer_model"
  },
  "349": {
      "id": 349,
      "display": true,
      "name": "Recording Mode",
      "displayName": "recording_mode"
  },
  "350": {
      "id": 350,
      "display": true,
      "name": "Components Configuration",
      "displayName": "components_configuration"
  },
  "351": {
      "id": 351,
      "display": true,
      "name": "GPS Dest Bearing",
      "displayName": "gps_dest_bearing"
  },
  "352": {
      "id": 352,
      "display": true,
      "name": "GPS Dest Distance",
      "displayName": "gps_dest_distance"
  },
  "353": {
      "id": 353,
      "display": true,
      "name": "Quality Level",
      "displayName": "quality_level"
  },
  "354": {
      "id": 354,
      "display": true,
      "name": "ISO Speed",
      "displayName": "iso_speed"
  },
  "355": {
      "id": 355,
      "display": true,
      "name": "Noise",
      "displayName": "noise"
  },
  "356": {
      "id": 356,
      "display": true,
      "name": "Custom Functions 1D",
      "displayName": "custom_functions_1d"
  },
  "357": {
      "id": 357,
      "display": true,
      "name": "Tone Curve Table",
      "displayName": "tone_curve_table"
  },
  "358": {
      "id": 358,
      "display": true,
      "name": "Sharpness Table",
      "displayName": "sharpness_table"
  },
  "359": {
      "id": 359,
      "display": true,
      "name": "Sharpness Freq Table",
      "displayName": "sharpness_freq_table"
  },
  "360": {
      "id": 360,
      "display": true,
      "name": "White Balance Table",
      "displayName": "white_balance_table"
  },
  "361": {
      "id": 361,
      "display": true,
      "name": "Canon Flags",
      "displayName": "canon_flags"
  },
  "362": {
      "id": 362,
      "display": true,
      "name": "Modified Info",
      "displayName": "modified_info"
  },
  "363": {
      "id": 363,
      "display": true,
      "name": "Tone Curve Matching",
      "displayName": "tone_curve_matching"
  },
  "364": {
      "id": 364,
      "display": true,
      "name": "White Balance Matching",
      "displayName": "white_balance_matching"
  },
  "365": {
      "id": 365,
      "display": true,
      "name": "GPS Dest Bearing Ref",
      "displayName": "gps_dest_bearing_ref"
  },
  "366": {
      "id": 366,
      "display": true,
      "name": "GPS Dest Distance Ref",
      "displayName": "gps_dest_distance_ref"
  },
  "367": {
      "id": 367,
      "display": true,
      "name": "Transfer Function",
      "displayName": "transfer_function"
  },
  "368": {
      "id": 368,
      "display": true,
      "name": "JPEG Proc",
      "displayName": "jpeg_proc"
  },
  "369": {
      "id": 369,
      "display": true,
      "name": "Interoperability Version",
      "displayName": "interoperability_version"
  },
  "370": {
      "id": 370,
      "display": true,
      "name": "CCD ISO sensitivity",
      "displayName": "ccd_iso_sensitivity"
  },
  "371": {
      "id": 371,
      "display": true,
      "name": "Image History",
      "displayName": "image_history"
  },
  "372": {
      "id": 372,
      "display": true,
      "name": "Device Setting Description",
      "displayName": "device_setting_description"
  },
  "373": {
      "id": 373,
      "display": true,
      "name": "Capture Mode",
      "displayName": "capture_mode"
  },
  "374": {
      "id": 374,
      "display": true,
      "name": "Thresholding",
      "displayName": "thresholding"
  },
  "375": {
      "id": 375,
      "display": true,
      "name": "Camera Id",
      "displayName": "camera_id"
  },
  "376": {
      "id": 376,
      "display": true,
      "name": "CFA Pattern",
      "displayName": "cfa_pattern"
  },
  "377": {
      "id": 377,
      "display": true,
      "name": "Makernote Version",
      "displayName": "makernote_version"
  },
  "378": {
      "id": 378,
      "display": true,
      "name": "Camera Settings (1)",
      "displayName": "camera_settings_(1)"
  },
  "379": {
      "id": 379,
      "display": true,
      "name": "Inter Color Profile",
      "displayName": "inter_color_profile"
  },
  "380": {
      "id": 380,
      "display": true,
      "name": "Raw Data Offset",
      "displayName": "raw_data_offset"
  },
  "381": {
      "id": 381,
      "display": true,
      "name": "CFA Repeat Pattern Dim",
      "displayName": "cfa_repeat_pattern_dim"
  },
  "382": {
      "id": 382,
      "display": true,
      "name": "Tile Width",
      "displayName": "tile_width"
  },
  "383": {
      "id": 383,
      "display": true,
      "name": "Tile Length",
      "displayName": "tile_length"
  },
  "384": {
      "id": 384,
      "display": true,
      "name": "Dust Removal Data",
      "displayName": "dust_removal_data"
  }
};

export default Config;