import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { setToolbar } from "@Actions/toolbarActions"
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { initCurrentFilterType  }  from "@Actions/filterActions";
import { setNavbar1, setNavbar2, setNavbar3 }  from "@Actions/navbarActions";
import { removeSearch } from "@Actions/searchActions";
import styles from './styles';

class Stats extends Component {
  constructor(props) {
    super(props);
    this.props.actionRemoveSearch();
    this.props.hideLoading();
    this.props.showLoading(); 
    this.props.setToolbar(3);
    this.props.actionInitCurrentFilterType('0');
    this.props.setNavbar1({name: this.props.t("_stats"), link: "/stats"});
    this.props.setNavbar2(null);
    this.props.setNavbar3(null);
  }

  componentDidMount(){
    setTimeout(()=>this.props.hideLoading(),200);
    window.scrollTo(0,0);
  }


  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <img className={classes.img}  src={require('@Assets/images/stats.png')}  alt="stats" />
      </div>
    );
  }
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {
      showLoading, 
      hideLoading,
      setToolbar,
      setNavbar1,
      setNavbar2,
      setNavbar3,

      actionRemoveSearch: removeSearch, 

      actionInitCurrentFilterType: initCurrentFilterType
    }, dispatch);
}

export default connect(null,matchDispatchToProps)(translate("translations")(withStyles(styles)(Stats)));