import {
  GET_DOMAINS,
  GET_DOMAINS_MORE,
  GET_DOMAINS_SEARCH,
  GET_DOMAINS_SEARCH_MORE,
  REMOVE_DOMAINS,
  REMOVE_DOMAIN,
  GET_DOMAIN,
  UPDATE_DOMAIN,
  CREATE_DOMAINS_CASE,
  REMOVE_DOMAINS_TAG,
  ADD_DOMAINS_TAG,
  ADD_DOMAINS_NOTE,
  UPDATE_DOMAINS_STATUS,
  UPDATE_DOMAIN_FINDING_COUNT,
  ADD_DOMAIN_FINDING_NOTE,
  DELETE_DOMAINS_NOTE,
} from "@Types/actionTypes";

export function getDomains(data) {
  // data:
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_DOMAINS,
    payload: data,
  };
}

export function getDomainsMore(data) {
  // data:
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_DOMAINS_MORE,
    payload: data,
  };
}

export function getDomainsSearch(data) {
  // data:
  // param
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_DOMAINS_SEARCH,
    payload: data,
  };
}

export function getDomainsSearchMore(data) {
  // data:
  // param
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_DOMAINS_SEARCH_MORE,
    payload: data,
  };
}

export function getDomain(data) {
  // data:
  // domainId
  // results

  return {
    type: GET_DOMAIN,
    payload: data.results,
  };
}

export function updateDomain(data) {
  // data:
  // domainId
  // results

  return {
    type: UPDATE_DOMAIN,
    payload: data,
  };
}

export function createDomainsCase() {
  // data:
  // domainId
  // data:

  return {
    type: CREATE_DOMAINS_CASE,
    payload: null,
  };
}

export function removeDomains() {
  return {
    type: REMOVE_DOMAINS,
    payload: null,
  };
}

export function removeDomain(data) {
  // data:
  // domainId
  return {
    type: REMOVE_DOMAIN,
    payload: data,
  };
}

export function addDomainsTag(data) {
  // data:
  // tag
  //     name
  //     id
  // domainId

  return {
    type: ADD_DOMAINS_TAG,
    payload: data,
  };
}

export function removeDomainsTag(data) {
  // data:
  // tag
  //     name
  //     id
  // domainId

  return {
    type: REMOVE_DOMAINS_TAG,
    payload: data,
  };
}

export function addDomainsNote(data) {
  // data:
  // note
  // domainId

  return {
    type: ADD_DOMAINS_NOTE,
    payload: data,
  };
}

export function deleteDomainsNote(data) {
  return {
    type: DELETE_DOMAINS_NOTE,
    payload: data,
  };
}

export function updateDomainsStatus(data) {
  // data:
  // domainId
  // status
  // data:
  //   autoToCollectionPartner
  //   autoToResearch
  //   findings: [{id, searchNumber, targetImage}]
  //   collectionPartner: <id>
  //   type: post, collectionPartner

  return {
    type: UPDATE_DOMAINS_STATUS,
    payload: data,
  };
}

export function updateDomainFindingCount(data, count) {
  // data:
  // findingId
  // domainId
  // type
  // count

  return {
    type: UPDATE_DOMAIN_FINDING_COUNT,
    payload: { ...data, count },
  };
}

export function addDomainFindingNote(data) {
  // data :
  // domainId
  // note: {...}

  return {
    type: ADD_DOMAIN_FINDING_NOTE,
    payload: data,
  };
}
