import { 
  GET_MEDIA,
  GET_MEDIA_MORE, 
  GET_MEDIA_SEARCH, 
  GET_MEDIA_SEARCH_MORE,
  REMOVE_MEDIA,
  DELETE_MEDIA,
  ADD_MEDIA_TAG,
  REMOVE_MEDIA_TAG,
  UPDATE_MEDIA_STATUS,
} from '@Types/actionTypes';

export function getMedia(data){
  // data:
  // results 
      // total
      // reults
  // pageNum

  return {
    type : GET_MEDIA,
    payload : data
  }
}

export function getMediaMore(data){
  // data:
  // results 
      // total
      // reults
  // pageNum

  return {
    type : GET_MEDIA_MORE,
    payload : data
  }
}

export function getMediaSearch(data){
  // data:
  // results 
      // total
      // reults
  // param
  // pageNum

  return {
    type : GET_MEDIA_SEARCH,
    payload : data
  }
}

export function getMediaSearchMore(data){
  // data:
  // results 
      // total
      // reults
  // param
  // pageNum

  return {
    type : GET_MEDIA_SEARCH_MORE,
    payload : data
  }
}

export function removeMedia(){
  return {
    type : REMOVE_MEDIA,
    payload : null
  }
}


export function deleteMedia(data){
  // data:
  // mediaId
  
  return {
    type : DELETE_MEDIA,
    payload : data
  }
}

export function addMediaTag(data){
  // data:
  // tag
  //     name
  //     id
  // mediaId
  
  return {
    type : ADD_MEDIA_TAG,
    payload : data
  }
}

export function removeMediaTag(data){
  // data:
  // tag
  //     name
  //     id
  // mediaId

  return {
    type : REMOVE_MEDIA_TAG,
    payload : data
  }
}


export function updateMediaStatus(data){
  
  // Data : {mediaId:string,status:number}
  return {
    type: UPDATE_MEDIA_STATUS,
    payload: data
  }
}