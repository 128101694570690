const styles = theme => ({
  root:{
    // backgroundColor:grey[100],
  },
  updatePasswordBtn:{
    float:"right"
  },
  updateProgress:{
    margin: "5px 0"
  },
  username:{
    display:"none"
  }
});


export default styles;