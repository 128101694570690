import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Slider from '@material-ui/lab/Slider';
import { translate } from 'react-i18next';

var autoCmpare = null;
class CompareMedia extends React.Component {
  constructor(props) {
    super();

    this.state = {
      direction: 1,
      sliderValue: 1,
      opacity: 1
    };

    autoCmpare = setInterval(() => {
      if(this.state.opacity > 130){
        this.setState({ direction : -1});
      }

      if(this.state.opacity < -30){
        this.setState({ direction : 1});
      }

      this.setState({ opacity : this.state.opacity + (10 * this.state.direction) });
    }, 80);
  }

  componentWillUnmount = () => {
    clearInterval(autoCmpare);
  }

  handleSliderChange = (event, sliderValue) => {
    this.setState({ sliderValue, opacity: sliderValue });
    clearInterval(autoCmpare);
  };


  render() {
    const { classes } = this.props;

    return (
        <div className={classes.root}> 
          <div className={classes.imagesContainer}>
            <div className={classes.imageDiv}>
              <img src={this.props.image1} alt="Original"/>
            </div>
            <div className={classes.imageDiv}>
              <img src={this.props.image2} alt="Found" style={{opacity:1-this.state.opacity/100}}/>
            </div>
          </div>
          <div className={classes.sliderContainer}>
            <div className={classes.slider}>
              <Slider classes={{root:classes.sliderRoot}} value={this.state.sliderValue} onChange={this.handleSliderChange} />
            </div>
          </div>
        </div>
    );
  }

}

CompareMedia.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(CompareMedia));