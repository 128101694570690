import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import MoreIcon from "@material-ui/icons/MoreHoriz";
import { translate } from "react-i18next";
import DialogPopup from "@Components/DialogPopup";

import {
  IconButton,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@material-ui/core";

import {
  formatDatetime,
  decodeEntities,
  replaceBrTags,
} from "@Utils/helpers.js";

import styles from "./styles";

class CardNote extends Component {
  constructor(props) {
    super(props);

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  state = {
    isHovering: false,
    popupOpen: false,
    newNote: "",
    noteToDelete: null,
    deleteNoteConfirmationPopupOpen: false,
  };

  handleClickOpen = () => {
    this.setState({ popupOpen: true });
  };

  handleClose = () => {
    this.setState({ popupOpen: false });
  };

  handleMouseEnter() {
    this.setState({ isHovering: true });
  }

  handleMouseLeave() {
    this.setState({ isHovering: false });
  }

  handleNewNoteChange = (event) => {
    this.setState({
      newNote: event.target.value,
    });
  };

  handleAddNote = () => {
    if (this.state.newNote.trim() === "") return;
    this.props.onNoteAdded(this.state.newNote);
    this.setState({
      newNote: "",
    });
  };

  handleDeleteNote = (id) => {
    this.setState({ deleteNoteConfirmationPopupOpen: true, noteToDelete: id });
  };

  handleDeleteNoteConfirmed = () => {
    this.props.onNoteDeleted(this.state.noteToDelete);
    this.setState({
      deleteNoteConfirmationPopupOpen: false,
      noteToDelete: null,
    });
  };

  handleDeleteNoteClosed = () => {
    this.setState({
      deleteNoteConfirmationPopupOpen: false,
      noteToDelete: null,
    });
  };

  getNotesOrEmpty = () => {
    if (this.props.notes) {
      let notes = this.props.notes;
      notes = notes.map((note, index) => {
        return (
          <div key={index}>
            <div>{formatDatetime(note.date, "short") + " - " + note.owner}</div>
            <div>{decodeEntities(replaceBrTags(note.note))}</div>
            <br />
          </div>
        );
      });
      return notes;
    }

    return "";
  };

  keyPress = (event) => {
    if (event.keyCode === 13) {
      // enter
      this.handleAddNote();
    }
  };

  render() {
    const { classes, t } = this.props;
    return (
      <div
        className={classes.root}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className={classes.notesContainer}>{this.getNotesOrEmpty()}</div>
        {this.state.isHovering && (
          <IconButton
            className={classes.button}
            color="primary"
            onClick={this.handleClickOpen}
          >
            <MoreIcon />
          </IconButton>
        )}
        <DialogPopup
          open={this.state.popupOpen}
          onClose={this.handleClose}
          maxWidth="md"
        >
          <DialogTitle>{t("_notes")}</DialogTitle>
          <DialogContent className={classes.contentContainer}>
            <Table className={classes.table}>
              <TableBody>
                {this.props.notes.map((row) => {
                  return (
                    <TableRow className={classes.row} key={row.id}>
                      <TableCell
                        style={{
                          overflowWrap: "break-word",
                          maxWidth: "694px",
                        }}
                      >
                        <b>{row.type && row.type + ": "}</b>{" "}
                        {decodeEntities(replaceBrTags(row.note))}
                        <p className={classes.noteTitle}>
                          {row.owner} - {formatDatetime(row.date, "short")}
                        </p>
                        <p className={classes.noteDeleteContainer}>
                          <Button
                            onClick={() => this.handleDeleteNote(row.id)}
                            className={classes.noteDelete}
                          >
                            {t("_delete")}
                          </Button>
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions className={classes.actionSection}>
            {this.props.canAddNotes && (
              <>
                <TextField
                  className={classes.tbAddNote}
                  fullWidth
                  multiline
                  rowsMax={6}
                  placeholder={t("_add_note")}
                  value={this.state.newNote}
                  id="note"
                  // onKeyDown={this.keyPress}
                  onChange={this.handleNewNoteChange}
                />
                <Button
                  onClick={this.handleAddNote}
                  color="primary"
                  className={classes.btnAddNote}
                >
                  {t("_add_note")}
                </Button>
              </>
            )}
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.deleteNoteConfirmationPopupOpen}
          onClose={this.handleDeleteNoteClosed}
          maxWidth="md"
        >
          <DialogTitle>
            {t("_do_you_really_want_to_delete_this_note")}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleDeleteNoteClosed} color="secondary">
              {t("_cancel")}
            </Button>
            <Button onClick={this.handleDeleteNoteConfirmed} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

CardNote.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.array.isRequired,
  onNoteAdded: PropTypes.func.isRequired,
};

export default withStyles(styles)(translate("translations")(CardNote));
