import {
  GET_CASES,
  GET_CASES_MORE,
  GET_CASES_SEARCH,
  GET_CASES_SEARCH_MORE,
  GET_CASE,
  UPDATE_CASE,
  REMOVE_CASES,
  REMOVE_CASES_TAG,
  ADD_CASES_TAG,
  ADD_CASES_NOTE,
  DELETE_CASES_NOTE,
  UPDATE_CASES_STATUS,
  ADD_CASES_DOC,
  UPDATE_CASES_COLLECTION_PARTNER,
  UPDATE_CASES_FEES,
  UPDATE_CASE_FINDING_LICENCE_FEE,
  GET_CASE_FEES,
  DECREASE_UNREAD_COUNT,
} from "@Types/actionTypes";
import store from "@Core/store";

export function getCases(data) {
  // data:
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_CASES,
    payload: data,
  };
}

export function getCasesMore(data) {
  // data:
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_CASES_MORE,
    payload: data,
  };
}

export function getCasesSearch(data) {
  // data:
  // param
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_CASES_SEARCH,
    payload: data,
  };
}

export function getCasesSearchMore(data) {
  // data:
  // param
  // results
  // total
  // reults
  // pageNum

  return {
    type: GET_CASES_SEARCH_MORE,
    payload: data,
  };
}

export function getCase(data) {
  // data:
  // caseId
  // results
  return {
    type: GET_CASE,
    payload: data.results,
  };
}

export function updateCase(data) {
  // data:
  // caseId
  // results

  return {
    type: UPDATE_CASE,
    payload: data,
  };
}

export function removeCases() {
  return {
    type: REMOVE_CASES,
    payload: null,
  };
}

export function removeCasesTag(data) {
  // data:
  // tag
  //     name
  //     id
  // caseId

  return {
    type: REMOVE_CASES_TAG,
    payload: data,
  };
}

export function addCasesTag(data) {
  // data:
  // tag
  //     name
  //     id
  // caseId

  return {
    type: ADD_CASES_TAG,
    payload: data,
  };
}

export function addCasesNote(data) {
  // data:
  // note
  // caseId

  return {
    type: ADD_CASES_NOTE,
    payload: data,
  };
}

export function deleteCasesNote(data) {
  // data:
  // id
  // caseId

  return {
    type: DELETE_CASES_NOTE,
    payload: data,
  };
}

export function updateCasesStatus(data) {
  // data:
  // caseId
  // status
  // data : []
  return {
    type: UPDATE_CASES_STATUS,
    payload: data,
  };
}

export function addCasesDoc(data) {
  // data:
  // caseId
  // data :
  //   documentType: "Document type 3"
  //   documentTypeId: 2
  //   file: File {name: "Anmeldung.jpg", lastModified: 1497446386000, size: 59386 ...}
  //   fileName: "Anmeldung.jpg"
  //   fileSize: "58 KB"
  //   uploadedAt : ...

  return {
    type: ADD_CASES_DOC,
    payload: data,
  };
}

export function updateCasesCollectionPartner(data) {
  // data:
  // caseId
  // data:
  //    id
  //    name

  return {
    type: UPDATE_CASES_COLLECTION_PARTNER,
    payload: data,
  };
}

export function updateCasesFees(data) {
  // data:
  // caseId
  // data:
  //    licenceFee
  //    researchFee
  //    totalPaymentRequested
  var newState = store.getState().casesReducer;
  newState.results[data.caseId].fees = data.data;

  return {
    type: UPDATE_CASES_FEES,
    payload: data,
  };
}

export function updateCaseFindingLicenceFee(data) {
  //data:
  // caseId
  // data:
  // licenceFee
  // caseResultID
  // findingId
  return {
    type: UPDATE_CASE_FINDING_LICENCE_FEE,
    payload: data,
  };
}

export function getCaseFees(data) {
  //data:
  // caseId
  // fees:
  // licenceFee
  // caseResultID
  // findingId
  return {
    type: GET_CASE_FEES,
    payload: data,
  };
}

export function decreaseUnreadCount(data) {
  return { type: DECREASE_UNREAD_COUNT, payload: data };
}
