import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { translate } from 'react-i18next';
import i18n from '@Core/i18n';
import styles from './styles';

const langs = [
  {value : 0, name : 'en', display: 'ENGLISH'},
  {value : 1, name : 'de', display: 'DEUTSCH' },
];

class LangSelector extends Component {
  constructor(props) {
    super();

    this.state = langs[0];

    setTimeout(() => {
      if(i18n.language === "DE" || i18n.language === "de"){
        this.setState({value : 1});
      }
    },10);
  }

  handleChange(event, name) {
    this.setState({ [name]: event.target.value });
    i18n.changeLanguage(langs[event.target.value]['name']);
  };

  generateLangItems(){
    var list = [];
    for(var i=0; i<langs.length; i++){
      list.push(
        <MenuItem value={langs[i]['value']} key={i}>{langs[i]['display']}</MenuItem>
      );
    }
    return list;
  }

  render() {
    const { classes } = this.props;
    return (
        <Select
          value={this.state.value}
          className={classes.dropDown}
          onChange={(event) => this.handleChange(event,'value')}
        >
          {this.generateLangItems()}
        </Select>

    );
  }
}

LangSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(LangSelector));