const styles = theme => ({
  root:{
    // backgroundColor:grey[100],
  },
  img:{
    width:900
  }
});


export default styles;