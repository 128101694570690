import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyDown from '@material-ui/icons/KeyboardArrowDown';
import KeyUp from '@material-ui/icons/KeyboardArrowUp';
import CaseMessages from './components/CaseMessages';

import _ from 'lodash';

import styles from '../styles';

const CaseMessagesContainer = ({
  classes,
  t,
  casesReducer,
  messages,
  searchReducer,
  page,
  caseId,
}) => (
  <div className={classes.container}>
    <CaseMessages caseId={caseId} data={messages} />
    {page === 'case' &&
      _.size(casesReducer.results) > 1 &&
      searchReducer.search.class == null && (
        <Link to="/cases">
          <Button color="primary" className={classes.showMore}>
            {t('_show_less')}
            <KeyUp className={classes.rightIcon} />
          </Button>
        </Link>
      )}
    {(page === 'cases' || page === 'search') && page !== 'case' && (
      <Link to={'/case/' + caseId}>
        <Button color="primary" className={classes.showMore}>
          {t('_show_more')}
          <KeyDown className={classes.rightIcon} />
        </Button>
      </Link>
    )}
  </div>
);

function mapStateToProps(state, ownProps) {
  return {
    casesReducer: state.casesReducer,
    messages: state.caseMessagesReducer.messages[ownProps.caseId],
    searchReducer: state.searchReducer,
  };
}

export default withStyles(styles)(
  translate('translations')(connect(mapStateToProps)(CaseMessagesContainer))
);
