import React, { Component } from "react";
import CaseCard from "@Components/cards/CaseCard";
import CasePanel from "@Components/panels/CasePanel";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setToolbar } from "@Actions/toolbarActions";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { showSnackbar } from "@Actions/snackbarActions";
import CasesService from "@Services/Cases";
import { getCase } from "@Actions/casesActions";
import { initCurrentFilterType } from "@Actions/filterActions";
import { setNavbar1, setNavbar2, setNavbar3 } from "@Actions/navbarActions";
import { isEmptyObject } from "@Utils/helpers";
import LoadingBg from "@Components/LoadingBg";
import Base64 from "base-64";
import { removeSearch } from "@Actions/searchActions";

import styles from "./styles";

class Case extends Component {
  constructor(props) {
    super(props);

    props.hideLoading();
    props.showLoading();
    props.actionInitCurrentFilterType("0");

    if (props.searchReducer.search.class != null) {
      props.setToolbar(false);
      props.setNavbar1({
        name:
          this.props.t("_search") +
          ": " +
          props.searchReducer.search.class.name +
          " - " +
          props.searchReducer.search.subclass.name +
          " - " +
          props.searchReducer.search.value,
        link: "/search/" + Base64.encode(JSON.stringify(props.searchReducer)),
      });
    } else {
      props.setToolbar(1);
      props.setNavbar1({ name: this.props.t("_cases"), link: "/cases?r" });
    }
    props.setNavbar2({
      name: props.t("_case"),
      link: "/case/" + this.props.match.params.id,
    });
    props.setNavbar3(null);
  }

  state = {
    lbgMessage: "",
    lbgAnimated: true,
  };

  componentDidMount() {
    console.log(this.props.casesReducer);
    var caseId = this.props.match.params.id;
    if (
      this.props.casesReducer === null ||
      typeof this.props.casesReducer.results[caseId] === "undefined"
    ) {
      CasesService.getCase({ caseId })
        .then((response) => {
          if (isEmptyObject(response)) {
            this.setState({
              lbgAnimated: false,
              lbgMessage: this.props.t("_no_data_found"),
            });
          } else {
            this.props.actionGetCase({ results: response, caseId });
            this.props.setNavbar2({
              name:
                this.props.t("_case") +
                " " +
                this.props.casesReducer.results[caseId].info.refNumber,
              link: "/case/" + caseId,
            });
          }
          this.props.hideLoading();
        })
        .catch((error) => {
          this.props.hideLoading();
          this.setState({ lbgAnimated: false, lbgMessage: error.message });
          this.props.actionShowSnackbar({
            message: error.message,
            variant: "error",
          });
        });
    } else {
      setTimeout(() => this.props.hideLoading(), 200);
      this.props.setNavbar2({
        name:
          this.props.t("_case") +
          " " +
          this.props.casesReducer.results[caseId].info.refNumber,
        link: "/case/" + caseId,
      });
    }
    window.scrollTo(0, -300);
  }

  componentDidUpdate() {}

  render() {
    const { classes } = this.props;
    const caseId = this.props.match.params.id;

    if (
      this.props.casesReducer === null ||
      typeof this.props.casesReducer.results[caseId] === "undefined"
    ) {
      return (
        <div>
          <LoadingBg
            pageType="case"
            message={this.state.lbgMessage}
            animated={this.state.lbgAnimated}
          />
        </div>
      );
    }

    const domainId = this.props.casesReducer.results[caseId].info.domain;
    var selectedIndex = this.props.location.search === "?m" ? 4 : null;
    return (
      <div className={classes.root}>
        <CaseCard caseId={caseId} page="case" selectdedIndex={selectedIndex} />
        <CasePanel domainId={domainId} caseId={caseId} pageType={"case"} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    casesReducer: state.casesReducer,
    searchReducer: state.searchReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      setToolbar,
      setNavbar1,
      setNavbar2,
      setNavbar3,

      actionRemoveSearch: removeSearch,

      actionGetCase: getCase,
      actionInitCurrentFilterType: initCurrentFilterType,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

Case.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(Case)));
