import {
  grey,
  red,
  green,
  purple,
  blue,
  lightGreen,
  orange,
} from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    width: '100%',
    fontSize: 14,
    textAlign: 'center',
    margin: '0 0 20px 0',
    padding: '10px 0',
    lineHeight: 3,
    fontWeight: 300,
    borderBottom: '1px solid #eee',
    cursor: 'default',
  },

  redStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      red[100] +
      ' 12%, ' +
      grey[50] +
      ' 12%, ' +
      grey[50] +
      ' 100%)',
  },
  blueStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      blue[100] +
      ' 12%, ' +
      grey[50] +
      ' 12%, ' +
      grey[50] +
      ' 100%)',
  },
  purpleStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      purple[100] +
      ' 12%, ' +
      grey[50] +
      ' 12%, ' +
      grey[50] +
      ' 100%)',
  },
  lightGreenStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      lightGreen[100] +
      ' 12%, ' +
      grey[50] +
      ' 12%, ' +
      grey[50] +
      ' 100%)',
  },
  greenStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      green[100] +
      ' 12%, ' +
      grey[50] +
      ' 12%, ' +
      grey[50] +
      ' 100%)',
  },
  orangeStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      orange[100] +
      ' 12%, ' +
      grey[50] +
      ' 12%, ' +
      grey[50] +
      ' 100%)',
  },
  DarkOrangeStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      orange[400] +
      ' 12%, ' +
      grey[50] +
      ' 12%, ' +
      grey[50] +
      ' 100%)',
  },
});

export default styles;
