import request from "@Utils/api";
import api from "@Configs/apiConfig";

function getFindingsDomain(data) {
  // data :
  // pageNum
  // domainId
  return request({
    url: api.findingsServer + "/finding/by_domain/" + encodeURIComponent(data.domainId),
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getFindingsCase(data) {
  // data :
  // pageNum
  // domainId
  // caseId
  return _getFindingsCaseInternal(data);
  // return request({
  //   url: api.findingsServer + '/finding/by_domain/' + data.domainId,
  //   method: 'GET',
  //   headers: {
  //     'x-access-token': localStorage.getItem('Token'),
  //   }
  // });
}

async function _getFindingsCaseInternal(data) {
  var caseResponse = await request({
    url: api.casesServer + "/case/" + data.caseId,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
  var findingsResponse = await request({
    url: api.findingsServer + "/finding/by_case/" + data.caseId,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
  return {
    info: caseResponse.info,
    results: findingsResponse,
  };
}

function getFindingsHistory(data) {
  // data :
  // doaminId
  // findingId
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/history",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getFindingsInfo(data) {
  // data :
  // doaminId
  // findingId
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/info",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getFindingFindings(data) {
  // data :
  // doaminId
  // findingId
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/findings",
    //url: '../MockData/findings/findingfindings.json',
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function moveFindingsToNew(data) {
  // data :
  // domainId
  // data: findingId
  // from
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/type",
    data: "new",
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "content-type": "text/plain",
    },
  });
}

function moveFindingsToTrash(data) {
  // data :
  // domainId
  // data: findingId
  // from
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/type",
    data: "trash",
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "content-type": "text/plain",
    },
  });
}

function moveFindingsToLegal(data) {
  // data :
  // domainId
  // data: findingId
  // from
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/type",
    data: "legal",
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "content-type": "text/plain",
    },
  });
}

function deleteFinding(data) {
  // data :
  // domainId
  // data: findingId
  return request({
    url: api.findingsServer + "/finding/" + data.findingId,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function deleteFindingsByMedia(data) {
  // data :
  // mediaId
  return request({
    url: api.findingsServer + "/finding/by_media/" + data.mediaId,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getFindingOverlay(data) {
  // data:
  // data:findingId
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/overlay",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addFindingNote(data) {
  // data:
  // note
  // domainId
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/notes/",
    data: { note: data.note.note, type: data.note.type },
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function getFindingNotes(data) {
  // mediaId
  return request({
    url: api.findingsServer + "/finding/" + data.findingId + "/notes",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function moveFindingBackToCase(data) {
  // data:
  // caseId
  // caseResultID
  return request({
    url:
      api.casesServer +
      "/case/" +
      data.caseId +
      "/case_results/" +
      data.findingDetails.caseResultID +
      "/set_active",
    data: data,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCasesOfSearchTargetByFindingID(id) {
  return request({
    url: api.findingsServer + "/finding/" + id + "/searchtarget/cases",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

const Findings = {
  getFindingsDomain,
  getFindingsCase,
  getFindingsHistory,
  getFindingsInfo,
  getFindingFindings,
  moveFindingsToNew,
  moveFindingsToTrash,
  moveFindingsToLegal,
  deleteFinding,
  deleteFindingsByMedia,
  getFindingOverlay,
  addFindingNote,
  getFindingNotes,
  moveFindingBackToCase,
  getCasesOfSearchTargetByFindingID,
};

export default Findings;
