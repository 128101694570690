import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconInfo from "@material-ui/icons/InfoOutlined";
import IconDone from "@material-ui/icons/DoneAll";
import IconDesc from "@material-ui/icons/Description";
import IconVerify from "@material-ui/icons/VerifiedUser";
import IconCase from "@material-ui/icons/Gavel";
import IconFees from "@material-ui/icons/EuroSymbol";
import IconDelete from "@material-ui/icons/Delete";
import IconUpload from "@material-ui/icons/CloudUpload";
import IconReceipt from "@material-ui/icons/Receipt";
import IconRemoveW from "@material-ui/icons/RotateRight";
import { formatDatetime } from "@Utils/helpers";
import { translate } from "react-i18next";
import { Link } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Config from "@Configs/logsConfig";
import styles from "./styles";

class LogItem extends Component {
  constructor(props) {
    super();
  }

  icons = {
    IconInfo: IconInfo,
    IconDone: IconDone,
    IconDesc: IconDesc,
    IconVerify: IconVerify,
    IconCase: IconCase,
    IconFees: IconFees,
    IconDelete: IconDelete,
    IconUpload: IconUpload,
    IconReceipt: IconReceipt,
    IconRemoveW: IconRemoveW,
  };

  handleClick = () => {};

  generateContent = () => {
    const { classes, data, t } = this.props;

    var title = t("_" + Config["log_type_" + data.logType].display);
    var subTitle = "n/a";
    var location = "#";
    var Icon = this.icons.IconInfo;
    var colorClass = "redIcon";
    var userName = data.userName;
    var datetime = formatDatetime(data.date, "medium-short");
    var refValue = data.value !== undefined ? data.value : "n/a";

    switch (data.logType) {
      case 1: // domain whitelisted
        subTitle = t("_domain") + ": " + refValue;
        location = "/domain/" + encodeURIComponent(data.parentId);
        Icon = this.icons.IconDone;
        colorClass = "blueIcon";
        break;
      case 2: // domain set as legal
        subTitle = t("_domain") + ": " + refValue;
        location = "/domain/" + encodeURIComponent(data.parentId);
        Icon = this.icons.IconVerify;
        colorClass = "greenIcon";
        break;
      case 3: // case created
        subTitle = t("_case_number") + ": " + refValue;
        location = "/case/" + data.parentId;
        Icon = this.icons.IconCase;
        colorClass = "greenIcon";
        break;
      case 4: // case state changes
        subTitle = t("_case_number") + ": " + refValue;
        location = "/case/" + data.parentId;
        Icon = this.icons.IconInfo;
        colorClass = "greyIcon";
        break;
      case 5: // case payment entered
        subTitle = t("_case_number") + ": " + refValue;
        location = "/case/" + data.parentId;
        Icon = this.icons.IconFees;
        colorClass = "blueIcon";
        break;
      case 6: // case fees changed'
        subTitle = t("_case_number") + ": " + refValue;
        location = "/case/" + data.parentId;
        Icon = this.icons.IconReceipt;
        colorClass = "blueIcon";
        break;
      case 7: // case document uploaded
        subTitle = t("_case_number") + ": " + refValue;
        location = "/case/" + data.parentId;
        Icon = this.icons.IconDesc;
        colorClass = "blueIcon";
        break;
      case 8: // media uploaded
        subTitle = refValue + " " + t("_image_added");
        location = "/media";
        Icon = this.icons.IconUpload;
        colorClass = "greenIcon";
        break;
      case 9: // finding deleted
        subTitle = t("_domain") + ": " + refValue;
        location = "/domain/" + encodeURIComponent(data.parentId);
        Icon = this.icons.IconDelete;
        colorClass = "redIcon";
        break;
      case 10: // domain removed from whitelist
        subTitle = t("_domain") + ": " + refValue;
        location = "/domain/" + encodeURIComponent(data.parentId);
        Icon = this.icons.IconRemoveW;
        colorClass = "redIcon";
        break;
      default:
        break;
    }

    return (
      <div className={classes.linkRoot}>
        <Link to={location} className={classes.logLink}>
          <ListItem button className={classes.listItemMain}>
            <ListItemIcon>
              <Icon className={classes[colorClass]} />
            </ListItemIcon>
            <ListItemText
              primary={title}
              secondary={
                <span>
                  <Tooltip
                    title={subTitle}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <span className={classes.subTitle}>{subTitle}</span>
                  </Tooltip>
                  <span className={classes.dateText}>
                    {userName}, {datetime}
                  </span>
                </span>
              }
              classes={{ primary: classes.title }}
              className={classes.listItemText}
            />
          </ListItem>
        </Link>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return <div className={classes.root}>{this.generateContent()}</div>;
  }
}

LogItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(LogItem));
