import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { translate } from 'react-i18next';
import Button from '@material-ui/core/Button';
import DialogPopup from '@Components/DialogPopup';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { mapTagsToSelectOption, mapSelectOptionToTag } from '@Utils/helpers';
import styles from './styles';

class TagInput extends Component {
  constructor(props) {
    super();
  }

  state = {
    open: false,
    newAddedTag: {value: "", label: ""}
  }

  filterAllTagsByLabel = () => {
    var results = [];
    results = _.differenceBy(this.props.tags, this.props.value, 'name');
    return results
  }

  actionClicked = () => {
    this.props.onTagsChanged("create-option", mapSelectOptionToTag(this.state.newAddedTag));
    this.setState({ open: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  handleChange = (newValue, actionMeta) => {

    // console.group('Value Changed');
    // console.log(newValue);
    // console.log("action:");
    // console.log(actionMeta);
    // console.groupEnd();

    if(this.props.isMulti){
      if(actionMeta.action === "create-option"){
        this.setState({ open: true, newAddedTag: {value: "new_tg_id", label: newValue[newValue.length -1].label} });
      }else{
        if(actionMeta.action === "select-option"){
          this.props.onTagsChanged("select-option", mapSelectOptionToTag(actionMeta.option))
        }else{ // remove-value
          this.props.onTagsChanged("remove-option", mapSelectOptionToTag(actionMeta.removedValue))
        }
      }
    }else{
      if(actionMeta.action === "create-option"){
        this.setState({ open: true, newAddedTag: {value: "new_tg_id", label: newValue.label} });
      }else{
        if(actionMeta.action === "select-option"){
          this.props.onTagsChanged("select-option", mapSelectOptionToTag(newValue))
        }else{ // remove-value
          this.props.onTagsChanged("remove-option", mapSelectOptionToTag(actionMeta.removedValue))
        }
      }

    }
  
  };


  render() {

    var customStyles = {
      control: (base, state) => ({
        ...base,
        boxShadow: "none",border: "none",
        height: this.props.isMulti? "auto":32,
        minHeight: this.props.isMulti? "auto":32,
        maxHeight: 80, overflowY: "scroll",
        borderRadius: 0, backgroundColor: "transparent",
        borderBottom: state.isFocused? "2px solid darkblue" : "1px solid #AAA",
        '&:hover': {
          borderBottom: "2px solid #333"
        }
      }),
      multiValue: base => ({
        ...base,
        backgroundColor: "#ddd",
        fontSize:14
      }),
      menuPortal: base => ({ 
        ...base, 
        zIndex: 9999 
      }),
      placeholder: base => ({
        ...base, 
        fontSize: 13
      })
    };

    const props = {
      name:"tags",
      label:this.props.t("_select_tag"),
      styles:customStyles,
      menuPortalTarget: document.body,
      menuPosition: 'absolute',
      isClearable: false,
      components: { DropdownIndicator:() => null, IndicatorSeparator:() => null },
      backspaceRemovesValue: false,
      onChange: this.handleChange,
      options: mapTagsToSelectOption(this.filterAllTagsByLabel()),
      value: mapTagsToSelectOption(this.props.value),
      isMulti: this.props.isMulti?true:false,
      placeholder: this.props.placeholder || this.props.t("_select_tag"),
      noOptionsMessage:() => this.props.noOptionsMessage || this.props.t("_no_tags_yet")
    };

    return (
      <>
      {this.props.isCreatable? 
        <CreatableSelect {...props} />
        :
        <Select {...props} />
      }
      
        <DialogPopup open={this.state.open} onClose={this.handleClose} >
          <DialogTitle>
            {this.props.t("_create_tag")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.props.t("_do_you_want_to_create")} <b>"{this.state.newAddedTag.label}"</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {this.props.t("_cancel")}
            </Button>
            <Button onClick={this.actionClicked} color="primary">
              {this.props.t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </>
    );
  }
}

TagInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onTagsChanged: PropTypes.func.isRequired,
};

function mapStateToProps(state){
  return {

  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {

    }, dispatch);
}

export default withStyles(styles)(translate("translations")(connect(mapStateToProps,matchDispatchToProps)(TagInput)));