import request from '@Utils/api'
import api from '@Configs/apiConfig';

function getLogs() {
  return request({
    url:    api.generalServer + '/logs.json',
    method: 'GET'
  });
}

function getNotify() {
  return request({
    url:    api.generalServer + '/notify.json',
    method: 'GET'
  });
}

function updateLogs(data) {
  return request({
    url:    api.generalServer + '/temp.json',
    method: 'GET'
  });
}

function updateNotify(data) {
  return request({
    url:    api.generalServer + '/temp.json',
    method: 'GET'
  });
}

const General = {
  getLogs, getNotify, updateLogs, updateNotify
}

export default General;
