
const styles = theme => ({
  root: {
    width: '100%',
    zIndex: 1299
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    padding:0
  },
  flex: {
    flex: 1,
  },

  imgStyle: {
    height: 25,
    marginRight:25,
    marginLeft:25
  },
  toolbar:{
    padding:0
  },
  link:{
    textDecoration:'none',
    marginLeft:100
  },
  linkMedia:{
    marginLeft: "30px !important",
    textDecoration:'none'    
  }
});

export default styles;
