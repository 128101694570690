import React, { useState } from 'react';
import { translate } from 'react-i18next';
import './styles.css';

import SendIcon from '@material-ui/icons/Send';
const ReplyMessage = ({
  t,
  replyMessageId,
  caseId,
  senderParty,
  handleSendMessage,
  handleCloseReply,
}) => {
  const [message, setMessage] = useState('');
  const handelSendReplyMessage = () => {
    handleSendMessage(senderParty, message, caseId, replyMessageId);
    handleCloseReply();
  };

  return (
    <form
      className="reply-message-container"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="subjct">
        <span className="reciver">{t("_answerbar_answer")} @{senderParty}</span>
        <button
          onClick={handleCloseReply}
          className="cancel-button"
          type="submit"
        >
          {t('_cancel')}
        </button>
      </div>
      <div className="reply-message-input-container">
        <input
          className="reply-message-input"
          type="text"
          name="message"
          placeholder={t('_write_a_message')}
          onChange={(event) => setMessage(event.target.value)}
          required
        />
        <button
          className="reply-send-button"
          type="submit"
          value="Submit"
          onClick={handelSendReplyMessage}
        >
          <SendIcon className="svg_icons_send" />
        </button>
      </div>
    </form>
  );
};

export default translate('translations')(ReplyMessage);
