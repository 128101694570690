import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from '@Core/router';
import '@Styles/index.css';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import store from '@Core/store';
import { AUTH_USER } from '@Types/actionTypes';

const topMenuTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    legendary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    }
  },
});

const token = localStorage.getItem('Token');
if (token) {
  store.dispatch({type: AUTH_USER});
}

const root = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={topMenuTheme}>
      <AppRouter />
    </MuiThemeProvider>
  </Provider>, root
);

