import { GET_NOTIFY, ADD_NOTIFY, REMOVE_NOTIFY } from '@Types/actionTypes';

const notifyReducer = (state = null, action) => {

  switch (action.type) {
    
    case GET_NOTIFY:
      state = action.payload;
      break;
    
    case ADD_NOTIFY:
      state = [action.payload, ...state];
      break; 

    case REMOVE_NOTIFY:
      state = action.payload;
      break; 

    default:
      break;
  }
  
  return state;
};

export default notifyReducer;