import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    padding: 10,
    paddingTop: 90,
    // width: "100%",
    textAlign: "center"
  },
  messageStyle:{
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: "bold",
    backgroundColor: grey[500],
    color: "transparent",
    textShadow: "-1px 2px 5px rgba(255,255,255,0.6)",
    "-webkit-background-clip": "text",
       "-moz-background-clip": "text",
            "background-clip": "text"
  },
  imageStyle:{
    width: 130,
    opacity: 0.9
  },


  normalMessage:{
    fontSize: 20
  },
  normalImage:{
    width: 130
  },

  smallMessage:{
    fontSize: 12
  },
  smallImage:{
    width: 80
  }

});

export default styles;