const Config = {
  "domain" : [
    {
      value: "add_tag",
      name: "_add_tags",
      display: "add_tag"
    },
    {
      value: "remove_tag",
      name: "_remove_tags",
      display: "remove_tag",
    },
    // {
    //   value: "add_note",
    //   name: "Add note",
    // }
  ],
  "case": [
    {
      display: "add_tag",
      value: "add_tag",
      name: "_add_tags"
    },
    {
      display: "remove_tag",
      value: "remove_tag",
      name: "_remove_tags"
    },
    // {
    //   value: "add_note",
    //   name: "Add note",
    // }
  ],
  "media": [
    {
      display: "add_tag",
      value: "add_tag",
      name: "_add_tags"
    },
    {
      display: "remove_tag",
      value: "remove_tag",
      name: "_remove_tags"
    },
    // {
    //   value: "add_note",
    //   name: "Add note",
    // },
    {
      display: "delete_pictures_and_results",
      value: "delete_pictures_and_results",
      name: "Delete pictures and results"
    },
    {
      display: "remove_picture_from_search",
      value: "remove_picture_from_search",
      name: "Remove picture from search"
    },
    {
      display: "add_picture_to_the_search",
      value: "add_picture_to_the_search",
      name: "Add picture to the search"
    }
  ]
}

export default Config;