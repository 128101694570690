import { grey, blue } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {},
  card: {
    width: "100%",
  },
  cardContent: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
  },
  imageLeftSide: {
    paddingTop: 5,
    width: 40,
  },
  compareIcon: {
    cursor: "pointer",
    color: grey[800],
  },
  externalLinkIcon: {
    textDecoration: "none",
    cursor: "pointer",
    color: grey[800],
  },
  infoIcon: {
    cursor: "pointer",
    color: grey[800],
  },

  noteIcon: {
    cursor: "pointer",
  },

  table: {
    height: 100,
    overflow: "scroll",
  },
  row: {
    whiteSpace: "pre-line",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  noteTitle: {
    textAlign: "right",
    fontSize: 10,
    fontWeight: "bold",
  },

  tbAddNote: {
    float: "left",
    marginLeft: 20,
  },
  btnAddNote: {
    float: "right",
    width: 150,
  },

  imageContainer: {
    width: 450,
  },
  imageMain: {},
  imageFound: {},
  tooltip: {
    maxWidth: "none",
  },
  detailsContainer: {
    fontSize: 14,
    width: "100%",
    padding: "0 15px",
    lineHeight: "1.5",
  },
  domainLink: {
    textDecoration: "none",
    cursor: "pointer",
    marginTop: 7,
    display: "block",
  },

  findingUrl: {
    overflow: "hidden",
    fontSize: 12,
    maxWidth: 350,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: blue[800],
  },
  findingTooltipTitle: {
    fontSize: 14,
    color: grey[600],
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: "bold",
  },
  buttonsContainer: {
    width: 428,
  },

  noPadding: {
    backgroundColor: grey[200],
    padding: "2px !important",
    overflowY: "inherit !important",
    minHeight: 500,
  },

  imageLoadingContainer: {
    marginTop: 70,
  },

  tblContainerDetails: {
    height: 500,
    overflow: "scroll",

    overflowX: "hidden",
    overflowY: "scroll",
    scrollbarWidth: "auto !important",
    scrollbarColor: "inherit",
    scrollbarGutter: "inherit",
    paddingRight: "5px",
    "&::-webkit-scrollbar": {
      width: "5px !important",
      display: "inherit !important",
      backgroundColor: "white !important",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "0px !important",
      display: "inherit !important",
      width: "10px !important"
    },
  },

  tblDetails: {
    border: "1px solid #EEE",
    lineBreak: "anywhere"
  },
  rowrowDeatils: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowDeatilsFrist: {
    // width: 20,
    padding: "5px !important",
    // textAlign: "center"
  },
  rowDeatilsSecond: {
    "& b": {
      fontSize: 13,
      color: grey[600],
    },
  },
  imageCount: {
    fontSize: 12,
    cursor: "pointer",
    textDecoration: "underline",
    // color: blue[800]
  },

  imageStyle: {
    // width:100,
    // height:60,
    // display: 'flex',
    // flexWrap: 'wrap',
    // display:"inline-block",
    maxHeight: 100,
    maxWidth: 100,
    // paddingLeft:10
  },

  linkBlock: {
    width: 330,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: 12,
    padding: 5,
  },

  counter: {
    width: 10,
    textAlign: "left",
    "& p": {
      padding: 0,
      margin: 0,
      fontSize: 14,
    },
  },
  searchImageDetails: {
    paddingTop: 5,
  },
  searchImageDetailsGray: {
    color: "gray",
  },
  searchImageDetailsRed: {
    color: "red",
  },

  popupLink: {
    whiteSpace: "nowrap",
  },
});

export default styles;
