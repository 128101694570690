import {grey} from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    margin: "0px 10px",
  },
  mainIcon:{
    width:25,
    color: '#616161',
  },
  button:{
    minWidth:0,
    color: grey[50],
    // paddingTop:12
  }
});


export default styles;