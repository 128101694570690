import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FindingsService from "@Services/Findings";
import { translate } from "react-i18next";
import Button from "@material-ui/core/Button";
import { Paper, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import styles from "./styles";
import { updateDomain, removeDomain } from "@Actions/domainsActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import _ from "lodash";
import { showSnackbar } from "@Actions/snackbarActions";
import { getFindingsNew } from "@Actions/findingsActions";

class CaseStartButton extends Component {
  constructor(props) {
    super();
    this.handleStart = this.handleStart.bind(this);
  }

  state = {
    popupOpen: false,
    isCreating: false,
    dropdownOpen: false,
    collectionPartnerList: [],

    route: "later", //  (collectionpartnerguid,sales,later)

    reviewAfterDocumentation: false,
    skipDocumentation: false,
  };

  handleStart = () => {
    this.setState((state) => ({ isCreating: true }));

    var nextStatus;
    if (this.state.skipDocumentation) {
      if (this.state.reviewAfterDocumentation) {
        nextStatus = "3"; // review
      } else {
        nextStatus = "4"; // activation_pending
      }
    } else {
      nextStatus = "2"; // documentation_in_progress
    }

    var data = {
      // findings: this.state.findings,
      skipDocumentation: this.state.skipDocumentation,
      reviewAfterDocumentation: this.state.reviewAfterDocumentation,
      route: this.state.route,
      isStart: true,
      status: nextStatus,
    };

    this.props.onActionClicked(
      this.props.cardData.type,
      this.props.actionType,
      data
    );

    this.setState({ isCreating: false, popupOpen: false });
    this.props.actionShowSnackbar({
      message: this.props.t("_case_started_successfully") + "!",
      variant: "success",
    });
  };

  handleDropdownChange = (event) => {
    this.setState({ route: event.target.value });
  };
  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };
  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handlePopupOpen = () => {
    var data = {
      caseId: this.props.cardData.id,
    };

    FindingsService.getFindingsDomain(data)
      .then((response) => {
        var arr = _.filter(_.values(response.results), { type: "new" });
        this.setState({ findingList: arr });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });

    this.setState({
      collectionPartnerList: this.props.globalReducer.collectionPartners,
      route: this.props.cardData.caseInfo.route,
      reviewAfterDocumentation:
        this.props.cardData.caseInfo.reviewAfterDocumentation,
      skipDocumentation: this.props.cardData.caseInfo.skipDocumentation,
    });

    this.setState({ popupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ popupOpen: false, isCreating: false, findings: [] });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={this.handlePopupOpen}
        >
          {t("_start")}
        </Button>

        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.popupOpen}
          onClose={this.handlePopupClose}
        >
          <DialogTitle>{t("_start")}</DialogTitle>

          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.actionRoot}>
              <div className={classes.collectionPartnerSelect}>
                <p className={classes.routeTitle}>{t("_route_to")}</p>
                <form autoComplete="off">
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="type-select">
                      {t("_select_route")}
                    </InputLabel>
                    <Select
                      disabled={this.state.isCreating ? true : false}
                      className={classes.selectInput}
                      open={this.state.dropdownOpen}
                      onClose={this.handleDropdownClose}
                      onOpen={this.handleDropdownOpen}
                      value={this.state.route}
                      onChange={this.handleDropdownChange}
                      inputProps={{
                        name: "select_route",
                        id: "select_route-select",
                      }}
                    >
                      <MenuItem key={-2} value={"later"}>
                        {""}
                      </MenuItem>
                      <MenuItem key={-1} value={"sales"}>
                        {t("_sales_team")}
                      </MenuItem>
                      {this.state.collectionPartnerList.map((row, index) => {
                        return (
                          <MenuItem key={index} value={row.id}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </form>
              </div>

              <div className={classes.switchSelect}>
                {localStorage.getItem("ShowSkipDocumentation") === "true" && (
                  <div className={classes.switchContainer}>
                    <FormControlLabel
                      classes={{ label: classes.switchLabel }}
                      control={
                        <Switch
                          className={classes.switch}
                          classes={{
                            checked: classes.checked,
                            bar: classes.bar,
                          }}
                          disabled={this.state.isCreating}
                          checked={this.state.skipDocumentation}
                          onChange={(event, checked) =>
                            this.setState({ skipDocumentation: checked })
                          }
                        />
                      }
                      label={t("_skip_documentation")}
                    />
                  </div>
                )}
                <div className={classes.switchContainer}>
                  <FormControlLabel
                    classes={{ label: classes.switchLabel }}
                    control={
                      <Switch
                        className={classes.switch}
                        classes={{
                          checked: classes.checked,
                          bar: classes.bar,
                        }}
                        disabled={this.state.isCreating}
                        checked={this.state.reviewAfterDocumentation}
                        onChange={(event, checked) =>
                          this.setState({ reviewAfterDocumentation: checked })
                        }
                      />
                    }
                    label={t("_review_after_documentation")}
                  />
                </div>
                <div className={classes.switchContainer}>
                  <Paper
                    className={classes.infoPaper}
                    style={{
                      visibility: this.state.reviewAfterDocumentation
                        ? "hidden"
                        : "visible",
                      display: this.state.reviewAfterDocumentation
                        ? "none"
                        : "inline-flex",
                    }}
                  >
                    <InfoIcon className={classes.customInfoIcon}></InfoIcon>
                    <Typography>{t("_skip_review_info")}</Typography>
                  </Paper>
                </div>
              </div>
            </div>
            <LinearProgress
              className={classes.createProgress}
              color="secondary"
              style={{
                visibility: this.state.isCreating ? "visible" : "hidden",
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handlePopupClose}
              color="secondary"
              disabled={this.state.isCreating ? true : false}
            >
              {t("_cancel")}
            </Button>
            <div>
              <Button
                color="primary"
                onClick={this.handleStart}
                disabled={
                  this.state.isCreating || this.state.route === "later"
                    ? true
                    : false
                }
              >
                {t("_start")}
              </Button>
            </div>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

CaseStartButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    globalReducer: state.globalReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionGetFindingsNew: getFindingsNew,
      actionShowSnackbar: showSnackbar,
      actionuUpdateDomain: updateDomain,
      actionRemoveDomain: removeDomain,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(CaseStartButton)
  )
);
