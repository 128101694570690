import {
  GET_COLLECTION_PARTNERS,
  GET_USERS,
  GET_CASE_STATES,
  GET_CASE_TYPES,
  UPDATE_USER_AVATAR
} from '@Types/actionTypes';
import { generateAvatar } from '@Utils/helpers';

const authReducer = (state = { collectionPartners: [], users: [], caseStates: [], userInfo: { avatar: "" }, caseTypes: [] }, action) => {
  switch (action.type) {
    case GET_COLLECTION_PARTNERS:
      state = { ...state, collectionPartners: action.payload }
      break;

    case GET_USERS:
      Object.keys(action.payload).forEach(function (key) {
        action.payload[key].name = action.payload[key].firstName + ' ' + action.payload[key].lastName;
      });
      state = { ...state, users: action.payload }
      break;

    case GET_CASE_STATES:
      state = { ...state, caseStates: action.payload }
      break;

    case GET_CASE_TYPES:
      state = { ...state, caseTypes: action.payload }
      break;

    case UPDATE_USER_AVATAR:
      state = { ...state, userInfo: { ...state.userInfo, avatar: generateAvatar(action.payload) } }
      break;

    default:
      break;
  }
  return state;
};

export default authReducer;