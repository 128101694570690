import { blue } from '@material-ui/core/colors';

const styles = theme => ({
  loadingBar: {
    backgroundColor: blue[500],
    height: 3,
    position: "absolute",
  }
});


export default styles;