import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LogsOpen from "@material-ui/icons/VerticalSplit";
import LogsClose from "@material-ui/icons/ViewList";
import Button from "@material-ui/core/IconButton";
import styles from "./styles";
import { toggleLogs } from "@Actions/logsActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-i18next";

class LogToggleButton extends Component {
  toggleClicked = () => {
    this.props.actionToggleLogs();
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root} data-id="toggleBtn">
        <Button
          title={t("_toggle_log")}
          className={classes.button}
          onClick={this.toggleClicked}
          data-id="toggleBtn"
        >
          {!this.props.logsReducer.toggle ? (
            <LogsOpen className={classes.mainIcon} data-id="toggleBtn" />
          ) : (
            <LogsClose className={classes.mainIcon} data-id="toggleBtn" />
          )}
        </Button>
      </div>
    );
  }
}

LogToggleButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    logsReducer: state.logsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionToggleLogs: toggleLogs,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(LogToggleButton)));
