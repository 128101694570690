import {
  grey,
  red,
  green,
  purple,
  blue,
  lightGreen,
  orange,
} from '@material-ui/core/colors';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 5,
    color: grey[800],
  },
  headingTitle: {
    width: 80,
    fontSize: 16,
    color: blue[900],
    margin: '10px 5px 0px 5px',
    fontWeight: 600,
  },
  headingStatus: {
    fontSize: 14,
    margin: '10px 5px 0px 15px',
    width: 250,
  },

  headingRef: {
    // fontWeight:300,
    width: 300,
    fontSize: 14,
    margin: 5,
    marginLeft: 15,
  },
  headingRefBtn: {
    margin: '0px 5px 0px 5px',
    fontSize: 12,
  },

  caseLinkBtn: {
    boxShadow: 'none',
  },
  caseLink: {
    textDecoration: 'none',
  },

  headingCount: {
    width: 50,
    fontSize: 16,
    margin: 5,
    marginLeft: 15,
  },

  panelRoot: {
    backgroundColor: grey[100],
  },
  content: {
    display: 'block',
  },

  tableCaseDetails: {
    marginLeft: 100,
    maxWidth: 720,
  },
  tbdRowLeft: {
    fontWeight: 'bold',
    width: 160,
  },
  tbdRowRight: {},

  tableNotes: {
    height: 100,
    overflow: 'scroll',
  },
  rowNotes: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  noteContainer: {
    position: 'relative',
  },
  noteTitle: {
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 'bold',
    paddingTop: 5,
  },
  noteText: {
    padding: '7px 0px',
  },
  notMoreButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  redStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      red[100] +
      ' 110px, ' +
      grey[50] +
      ' 110px, ' +
      grey[50] +
      ' 100%)',
  },
  blueStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      blue[100] +
      ' 110px, ' +
      grey[50] +
      ' 110px, ' +
      grey[50] +
      ' 100%)',
  },
  purpleStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      purple[100] +
      ' 110px, ' +
      grey[50] +
      ' 110px, ' +
      grey[50] +
      ' 100%)',
  },
  lightGreenStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      lightGreen[100] +
      ' 110px, ' +
      grey[50] +
      ' 110px, ' +
      grey[50] +
      ' 100%)',
  },
  greenStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      green[100] +
      ' 110px, ' +
      grey[50] +
      ' 110px, ' +
      grey[50] +
      ' 100%)',
  },
  orangeStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      orange[100] +
      ' 110px, ' +
      grey[50] +
      ' 110px, ' +
      grey[50] +
      ' 100%)',
  },
  DarkOrangeStatus: {
    backgroundImage:
      'linear-gradient( to right, ' +
      orange[400] +
      ' 110px, ' +
      grey[50] +
      ' 110px, ' +
      grey[50] +
      ' 100%)',
  },
});

export default styles;
