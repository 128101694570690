const styles = (theme) => ({
  root: {
    margin: "0 0 10px 0",
  },
  button: {
    textTransform: "initial",
    fontSize: 14,
    height: 40,
    minWidth: 150,
    width: "100%",
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent",
    },
  },
  moreButton: {
    minWidth: 5,
    fontSize: 12,
  },
  input: {
    display: "none",
  },
  findingListRoot: {
    height: 240,
    marginTop: 10,
    overflowY: "scroll",
    border: "1px solid #eee",
    // marginBottom: 10
  },
  collectionPartnerListRoot: {
    margin: "0px 25px",
  },

  heading: {
    fontSize: 17,
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },

  imageContainer: {
    // width:100,
    // height:60,
    // display: 'flex',
    // flexWrap: 'wrap',
    // display:"inline-block",
    maxHeight: 100,
    maxWidth: 60,
    paddingLeft: 10,
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  gridListSelected: {
    backgroundColor: theme.palette.secondary.light,
    // width: 500,
    // height: 450,
  },

  switchSelect: {
    marginTop: 15,
  },

  switchLabel: {
    fontSize: 14,
    display: "block",
  },

  optionFormControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },

  selectAllContainer: {
    height: 30,
    display: "block",
  },
  selectAllText: {
    lineHeight: "35px",
    display: "inline-block",
  },
  selectAllBtn: {
    display: "inline-block",
    float: "right",
  },
  findingDetailsCOntainer: {
    width: 300,
    paddingLeft: 30,
  },
  findingNumber: {
    fontSize: 14,
    maxWidth: 250,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  findingFindingUrl: {
    display: "block",
    fontSize: 12,

    width: 100,
  },
  findingPageUrl: {
    display: "block",
    fontSize: 12,
    width: 100,
    paddingTop: 3,
  },
  itemUrlLinkContainer: {
    paddingTop: 5,
    fontSize: 12,
    display: "block",
    marginBottom: 5,
  },
  itemUrlLink: {
    display: "inline-block",
    color: "blue",
  },
  iptcData: {
    fontSize: 12,
    marginTop: 2,
  },
  tags: {
    marginTop: 5,
    fontSize: 12,
  },
  routeTitle: {
    marginBottom: 10,
    fontSize: 18,
  },
  infoPaper: {
    backgroundColor: "rgb(232 244 253)",
    color: "rgb(13 60 97)",
    boxShadow: "none",
    padding: 17,
    fontSize: 12,
    alignItems: "center",
  },
  customInfoIcon: {
    paddingRight: 17,
    margin: 0,
  },
  abortedCasesWarning: {
    marginTop: "20px",
    marginBottom: "20px",
    lineHeight: "30px",
  },
  contentRoot:{
    maxWidth: 690
  }
});

export default styles;
