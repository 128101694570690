const Config = {
  "domains":[
    "Findings are on the way"
  ],
  "cases":[
    "Cases are on the way"
  ],
  "media":[
    "lots of media are on the way",
    "Fact: You have aloOoOt of media",
  ],
  "stats":[
    "Time travelling.."
  ],
  "settings":[
    "Time travelling.."
  ],
  "search":[
    "Time travelling.."
  ],
  "general":[
    "Time travelling.."
  ],
  "case":[
    "Time travelling.."
  ],
  "domain":[
    "Time travelling.."
  ]
};

export default Config;