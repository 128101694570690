
const styles = theme => ({
  textField: {
    backgroundColor: "#FFFFFF",
    color: '#424242',
    minWidth:280,
    padding:"5px 10px 5px 0",
    underline:'#FFFFFF',
    content: 'transparent',
  },
  iconContainer:{
    paddingLeft: 10,
  },
  iconContainerWithClass:{
    paddingLeft: 10,
    paddingRight: 100,
  },

  iconContainerWithSubclass1:{
    paddingLeft: 10,
    paddingRight: 190,
  },
  iconContainerWithSubclass2:{
    paddingLeft: 10,
    paddingRight: 210,
  },
  iconContainerWithSubclass3:{
    paddingLeft: 10,
    paddingRight: 230,
  },
  iconContainerWithSubclass4:{
    paddingLeft: 10,
    paddingRight: 250,
  },

  iconSearch:{
    display: "block",
    height:20
  },
  menu:{
    top:40,
  },
  menuRoot:{
    padding:0
  },
  menuPaper:{
     backgroundColor:"#FFFFFF"
  },
  menuItem:{
    width:250
  },

  chip: {
    margin: 0,
    position: "absolute",
    top: 19,
    left: 270,
    width: 90,
    height: 25
  },

  labelText:{
    fontSize: 12,
    paddingLeft: 12,
    paddingRight: 20,
    width: 40,
  },

  labelText1:{
    fontSize: 12,
    paddingLeft: 12,
    paddingRight: 20,
    width: 40,
  },
  labelText2:{
    fontSize: 12,
    paddingLeft: 12,
    paddingRight: 20,
    width: 60,
  },
  labelText3:{
    fontSize: 12,
    paddingLeft: 12,
    paddingRight: 20,
    width: 80,
  },
  labelText4:{
    fontSize: 12,
    paddingLeft: 12,
    paddingRight: 20,
    width: 100,
  },

  deleteIcon:{
    width:18
  },

  chipSub1: {
    margin: 0,
    position: "absolute",
    top: 19,
    left: 386,
    width: 90,
    height: 25
  },
  chipSub2: {
    margin: 0,
    position: "absolute",
    top: 19,
    left: 386,
    width: 110,
    height: 25
  },
  chipSub3: {
    margin: 0,
    position: "absolute",
    top: 19,
    left: 386,
    width: 130,
    height: 25
  },
  chipSub4: {
    margin: 0,
    position: "absolute",
    top: 19,
    left: 386,
    width: 150,
    height: 25
  },
});


export default styles;