import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { translate } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "@material-ui/core/Select";
import CasesService from "@Services/Cases";
import MenuItem from "@material-ui/core/MenuItem";
import { showSnackbar } from "@Actions/snackbarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

/* 
  country: "country"
      lastName: "lastName"
      address2: "address2"
      city: "city"
      address1: "address1"
      companyName: "companyName"
      postalCode: "postalCode"
      mobile: "mobile"
      addressAdditionalInfo: "addressAdditionalInfo"
      source: 6.02745618307040320615897144307382404804229736328125
      title: 0.80082819046101150206595775671303272247314453125
      firstName: "firstName"
      phone: "phone"
      personAdditionalInformation: "personAdditionalInformation"
      imprintUrl: "imprintUrl"
      position: "position"
      fax: "fax"
      email: "email"

*/

/*
{
  "domainId": "vg-sh.de",
  "domainUrl": "http://vg-sh.de/",
  "imprintUrl": null,
  "lastReachedAt": "2021-02-04T12:44:29.633Z",
  "CaseDomainInfo_address": {
    "name": "asdf",
    "addressLine1": null,
    "addressLine2": null,
    "postalCode": null,
    "city": null,
    "country": null,
    "addressType": "NotSet"
  },
  "CaseDomainInfo_contactPerson": {
    "position": null,
    "title": 0,
    "firstName": null,
    "lastName": null,
    "emailAddress": "a@email.de",
    "phone": null,
    "mobile": null,
    "fax": null
  }
}
*/
class DomainInfoEditor extends Component {
  constructor(props) {
    super();
    if (props.data && props.data.CaseDomainInfo_address) {
      var cdi_a = props.data.CaseDomainInfo_address;
      this.state.country = cdi_a.country ? cdi_a.country : "";
      this.state.address2 = cdi_a.addressLine2 ? cdi_a.address2 : "";
      this.state.city = cdi_a.city ? cdi_a.city : "";
      this.state.address1 = cdi_a.addressLine1 ? cdi_a.addressLine1 : "";
      this.state.companyName = cdi_a.name ? cdi_a.name : "";
      this.state.postalCode = cdi_a.postalCode ? cdi_a.postalCode : "";
    }

    if (props.data && props.data.CaseDomainInfo_contactPerson) {
      var cdi_p = props.data.CaseDomainInfo_contactPerson;
      this.state.lastName = cdi_p.lastName ? cdi_p.lastName : "";
      this.state.mobile = cdi_p.mobile ? cdi_p.mobile : "";
      this.state.title = cdi_p.title ? cdi_p.title : 0;
      this.state.firstName = cdi_p.firstName ? cdi_p.firstName : "";
      this.state.phone = cdi_p.phone ? cdi_p.phone : "";
      this.state.position = cdi_p.position ? cdi_p.position : "";
      this.state.fax = cdi_p.fax ? cdi_p.fax : "";
      this.state.email = cdi_p.emailAddress ? cdi_p.emailAddress : "";
    }

    this.state.imprintUrl = props.data.imprintUrl ? props.data.imprintUrl : "";
  }
  state = {
    country: "",
    lastName: "",
    address2: "",
    city: "",
    address1: "",
    companyName: "",
    postalCode: "",
    mobile: "",
    addressAdditionalInfo: "",
    source: 1,
    title: 0,
    firstName: "",
    phone: "",
    personAdditionalInformation: "",
    imprintUrl: "",
    position: "",
    fax: "",
    email: "",

    dropdownOpen: false,
    dropdownSourceOpen: false,
    isSaving: false,
  };

  handleSave = () => {
    this.setState({ isSaving: true });
    var data = { ...this.state };
    CasesService.updateDomainInformation(this.props.caseId, data)
      .then((response) => {
        this.props.onDomainInformationUpdated();
        this.props.onClose();
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Table className={classes.tbl}>
          <TableBody>
            <TableRow className={classes.tblRowContent}>
              <TableCell colSpan={2} className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_contact_details_page")}:&nbsp;
                  </span>
                  <br />
                  <TextField
                    className={classes.textField}
                    value={this.state.imprintUrl}
                    id="imprintUrl"
                    fullWidth
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ imprintUrl: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowHeader}>
              <TableCell className={classes.tblHeader}>
                {t("_address_details")}
              </TableCell>
              <TableCell className={classes.tblHeader}>
                {t("_person_details")}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_company_name")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.companyName}
                    id="company_name"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ companyName: event.target.value })
                    }
                  />
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_position")}:&nbsp;
                  </span>

                  <TextField
                    className={classes.textField}
                    value={this.state.position}
                    id="position"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ position: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_address1")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.address1}
                    id="address1"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ address1: event.target.value })
                    }
                  />
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_title")}:&nbsp;
                  </span>
                  <Select
                    className={classes.selectInput}
                    open={this.state.dropdownOpen}
                    onClose={() => this.setState({ dropdownOpen: false })}
                    onOpen={() => this.setState({ dropdownOpen: true })}
                    value={this.state.title}
                    onChange={(event) =>
                      this.setState({ title: event.target.value })
                    }
                  >
                    <MenuItem key={1} value={1}>
                      {t("_mr")}
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      {t("_mrs")}
                    </MenuItem>
                  </Select>
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_address2")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.address2}
                    id="address2"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ address2: event.target.value })
                    }
                  />
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_first_name")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.firstName}
                    id="first_name"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ firstName: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_postal")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.postalCode}
                    id="postal"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ postalCode: event.target.value })
                    }
                  />
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_last_name")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.lastName}
                    id="last_name"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ lastName: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_city")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.city}
                    id="city"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ city: event.target.value })
                    }
                  />
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_email")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.email}
                    id="email"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_country")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.country}
                    id="country"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ country: event.target.value })
                    }
                  />
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_phone")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.phone}
                    id="phone"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ phone: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_address_additional_info")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.addressAdditionalInfo}
                    id="address_additional_info"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({
                        addressAdditionalInfo: event.target.value,
                      })
                    }
                  />
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_mobile")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.mobile}
                    id="mobile"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ mobile: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div></div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>{t("_fax")}:&nbsp;</span>
                  <TextField
                    className={classes.textField}
                    value={this.state.fax}
                    id="fax"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({ fax: event.target.value })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div></div>
              </TableCell>
              <TableCell className={classes.tblContentCell}>
                <div>
                  <span className={classes.inputTitle}>
                    {t("_person_additional_info")}:&nbsp;
                  </span>
                  <TextField
                    className={classes.textField}
                    value={this.state.personAdditionalInformation}
                    id="person_additional_info"
                    // onKeyDown={this.keyPress}
                    onChange={(event) =>
                      this.setState({
                        personAdditionalInformation: event.target.value,
                      })
                    }
                  />
                </div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tblRowContent}>
              <TableCell className={classes.tblContentCell}>
                <div>
                  {" "}
                  <span className={classes.inputTitle}>
                    {t("_source")}:&nbsp;
                  </span>
                  <Select
                    className={classes.selectInput}
                    open={this.state.dropdownSourceOpen}
                    onClose={() => this.setState({ dropdownSourceOpen: false })}
                    onOpen={() => this.setState({ dropdownSourceOpen: true })}
                    value={this.state.source}
                    disabled={true}
                    onChange={(event) =>
                      this.setState({ source: event.target.value })
                    }
                  >
                    <MenuItem key={1} value={1}>
                      {t("_inprint")}
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                      {t("_nic")}
                    </MenuItem>
                    <MenuItem key={3} value={3}>
                      {t("_other")}
                    </MenuItem>
                  </Select>
                </div>
              </TableCell>
              <TableCell className={classes.tblContentCell}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <DialogActions className={classes.actionsContainer}>
          <Button
            onClick={this.handleClose}
            color="secondary"
            disabled={this.state.isSaving}
          >
            {t("_cancel")}
          </Button>
          <div>
            <Button
              color="primary"
              onClick={this.handleSave}
              disabled={this.state.isSaving}
            >
              {t("_save")}
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(null, matchDispatchToProps)(DomainInfoEditor)
  )
);
