const styles = (theme) => ({
  root: {
    fontSize: "14px",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  leftside: {
    margin: "20px",
  },
  rightside: {
    minWidth: "500px",
    margin: "20px",
    flexGrow:8
  },
  paper: {
    border: "10px solid white",
    borderRadius: "4px",
    marginBottom: "10px",
    width:"100%"
  },
  paper300: {
    width: "295px",
    padding: "15px",
  },
  flexColumns:{
    display:"flex"
  },
  w200:{
    width:"200px",
    minWidth:"200px"
  },
  logoutBtn: {
    marginTop: 10,
    width: 290,
    color: "#f50057",
    backgroundColor: "#fff",
    border: "1px solid #f50057",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#f50057",
      border: "1px solid transparent",
    },
  },
  updateInfoBtn: {
    float: "right",
  },
  updateProgress: {
    margin: "5px 0",
  },
  username: {
    display: "none",
  },
});

export default styles;
