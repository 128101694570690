const styles = theme => ({
  root:{
    // backgroundColor:grey[100],
  },
  imageContainer: {
    width:290,
    height:290,
    display: 'flex',
    flexWrap: 'wrap',
    margin: "10px 0 5px 0",
    backgroundColor: theme.palette.background.paper,
    border: "2px inset #8f8f8f80"
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  changeAvatarBtn:{
    width: 290,
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent"
    }
  },
  input: {
    display: "none"
  },
  uploadProgress:{
    // margin: "10px",
    marginRight: 10
  }
});


export default styles;