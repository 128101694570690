const styles = theme => ({
  root:{
    // backgroundColor:grey[100],
    marginTop:35
  },
  mainContainer: {
    paddingTop: 140,
    left:350,
    maxWidth: 1450,
    display: 'flex',
    position: 'relative',
  },
  contentContainer: {
    width: 'calc(100vw - 420px)',
    maxWidth: 1450,
    minHeight: "100vh",
  },
  rightMenuContainer: {
   
    minWidth: 300,
    zIndex:1299 // 1300 is for modals
  },
  rightMenuContainerShow: {
    display: "block !important",
    zIndex:1299 // 1300 is for modals
  },
  '@media (max-width: 1220px)': {
    rightMenuContainer: {
      display: "none",
    },
    mainContainer: {
      width:900
    }
  }
});


export default styles;
