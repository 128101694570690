const styles = (theme) => ({
  root: {},
  rootPaper: {
    fontSize: 16,
    minHeight: "460px",
    maxHeight: "460px",
  },
  mainIcon: {
    width: 25,
    color: "#616161",
    verticalAlign: "middle",
    marginTop: -3,
  },
  leftFirst: {
    marginTop: 16,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
  },
  leftOthers: {
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    cursor: "pointer",
  },
  leftBottom: {
    cursor: "pointer",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "10px",
    bottom: "10px",
    position: "absolute",
  },
  right: {
    marginTop: 0,
    marginLeft: 0,
    marginBottom: 0,
    marginRight: 0,
    minWidth: "650px",
    overflowY: "auto",
    height: "460px",
  },
  borderRight: {
    borderRight: "2px solid #e8e8e8",
    height: "460px",
    minWidth: "235px"
  },
  listItemLeft: {
    maxWidth: "145px",
    overflowX: "hidden",
    minWidth: "132px",
  },
  listItemRigth: {
    textAlign: "right",
    width: "100%",
    paddingRight: 15,
  },
  itemCard: {
    boxShadow: "none",
    borderBottom: "2px solid #e8e8e8",
    marginTop: "5px",
    paddingBottom: "7px",
    borderRadius: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#fafafa",
      color: "#2680eb",
    },
  },
  itemCardIcon: {
    verticalAlign: "top",
    paddingRight: "12px",
    paddingLeft: "12px",
  },
  itemCardHeader: {},
  itemCardCase: {
    marginTop: "5px",
  },
  itemCardDescription: {
    fontSize: "12px",
    marginTop: "5px",
  },
  itemCardFooter: {
    fontSize: "9px",
    fontWeight: "bold",
    marginTop: "10px",
  },
});

export default styles;
