import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { showSnackbar } from "@Actions/snackbarActions";
// import Grid from '@material-ui/core/Grid';

import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

import Button from "@material-ui/core/Button";
import CasesService from "@Services/Cases";
import LoadingBg from "@Components/LoadingBg";
import { translate } from "react-i18next";
import styles from "./styles";
import CasePaymentTypes from "@Configs/casePaymentTypes";
import { isNull, formatDatetime } from "@Utils/helpers.js";

class CasePayment extends Component {
  constructor(props) {
    super();

    this.handleAddPayment = this.handleAddPayment.bind(this);
  }

  state = {
    bookedOn: "",
    // invoiceNumber:"",
    paymentSubject: "",
    paymentAmount: "",
    dateBilled: "",
    intBillingNumber: "",

    popupOpen: false,
    isAdding: false,
    type: "",
    dropdownOpen: false,
  };

  componentDidMount() {}

  handleDropdownChange = (event) => {
    this.setState({ type: event.target.value }, function () {});
  };

  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };

  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };

  handlePopupOpen = () => {
    this.setState({ popupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ popupOpen: false, type: "", isAdding: false, theFile: {} });
  };

  handleTextFieldChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleAddPayment() {
    if (
      !this.state.bookedOn ||
      this.state.bookedOn == null ||
      this.state.bookedOn === ""
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_payment_date_must_be_set"),
        variant: "warning",
      });
      return;
    }

    if (
      !this.state.paymentAmount ||
      this.state.paymentAmount == null ||
      this.state.paymentAmount === "" ||
      parseFloat(this.state.paymentAmount) <= 0
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t(
          "_payment_amount_must_be_set_and_be_more_than_zero"
        ),
        variant: "warning",
      });
      return;
    }

    this.setState({ isAdding: true });

    var data = {
      caseId: this.props.caseId,
      paymentType: CasePaymentTypes[this.state.type].name,
      paymentTypeId: CasePaymentTypes[this.state.type].id,
      bookedOn: this.state.bookedOn,
      // "invoiceNumber": this.state.invoiceNumber,
      paymentSubject: this.state.paymentSubject,
      paymentAmount: this.state.paymentAmount,
      dateBilled: this.state.dateBilled,
      intBillingNumber: this.state.intBillingNumber,
    };

    CasesService.addCasesPayment(data)
      .then((response) => {
        this.props.onPaymentAdded(data, true);
        this.setState({
          bookedOn: "",
          // invoiceNumber:"",
          paymentSubject: "",
          paymentAmount: "",
          dateBilled: "",
          intBillingNumber: "",

          popupOpen: false,
          isAdding: false,
          type: "",
          dropdownOpen: false,
        });
      })
      .catch((error) => {
        this.setState({ isAdding: false, popupOpen: false });
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });

    this.setState({ isAdding: false, popupOpen: false });
  }

  render() {
    const { classes, data, t } = this.props;
    if (this.props.data == null) {
      return (
        <div>
          <LoadingBg
            pageType="general"
            size="small"
            animated={false}
            message={t("_failed_to_load_resources")}
          />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <p className={classes.feeAndPaymentHeadline}>{t("_Payments")}</p>
        <table className={classes.tblGeneral}>
          <tbody>
            <tr>
              <td>
                <b>{t("_total_amount_payed")}</b>
              </td>
              {isNull(data.total) ? (
                <td className={classes.tdLeft}> : {t("_not_set")}</td>
              ) : (
                <td className={classes.tdLeft}>
                  {" "}
                  : {parseFloat(data.total).toFixed(2)} EUR
                </td>
              )}
            </tr>
            {isNull(data.openPaymentAmount) ||
            data.openPaymentAmount === "0" ||
            data.openPaymentAmount === 0 ? (
              <tr>
                <td></td>
                <td></td>
              </tr>
            ) : (
              <tr>
                <td>
                  <b>{t("_amount_outstanding")}</b>
                </td>
                <td className={classes.tdLeft}>
                  {" "}
                  : {parseFloat(data.openPaymentAmount).toFixed(2)} EUR
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className={classes.tblContainerPayments}>
          <Table className={classes.tblPayments}>
            <TableBody>
              {data.payments &&
                data.payments.map((row) => {
                  return (
                    <TableRow
                      className={classes.rowPayments}
                      key={row.invoiceNumber}
                    >
                      <TableCell className={classes.rowPaymentsFrist}>
                        <b>{row.paymentSubject}</b>
                        <br />
                        {row.intBillingNumber}
                      </TableCell>
                      <TableCell className={classes.rowPaymentsSecond}>
                        <b>{parseFloat(row.paymentAmount).toFixed(2)} EUR</b>
                        <br />
                        {formatDatetime(row.dateBilled, "medium-short")}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {isNull(data.licenseFee) ? ( // TODO: Condition for ACTIVE CLAIM state only
          <Button
            onClick={this.handlePopupOpen}
            color="primary"
            className={classes.addPaymentButton}
          >
            {t("_record_payment")}
          </Button>
        ) : (
          <Button
            onClick={this.handlePopupOpen}
            color="primary"
            className={classes.addPaymentButton}
          >
            {t("_record_payment")}
          </Button>
        )}

        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.popupOpen}
          onClose={this.handlePopupClose}
        >
          <DialogTitle>{t("_record_payment")}</DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.paymentTypeRoot}>
              <br />
              <TextField
                value={this.state.dateBilled}
                fullWidth
                id="dateBilled"
                label={t("_invoice_date")}
                type="date"
                // defaultValue="2017-05-24"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={this.handleTextFieldChange("dateBilled")}
                // margin="normal"
              />
              <TextField
                value={this.state.intBillingNumber}
                fullWidth
                id="intBillingNumber"
                label={t("_invoice_number")}
                className={classes.textField}
                margin="dense"
                onChange={this.handleTextFieldChange("intBillingNumber")}
              />
              <TextField
                value={this.state.bookedOn}
                fullWidth
                id="bookedOn"
                label={t("_payment_date")}
                type="date"
                // defaultValue="2017-05-24"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
                onChange={this.handleTextFieldChange("bookedOn")}
              />
              <InputLabel
                className={classes.inputLabel}
                htmlFor="paymentAmount"
              >
                {t("_paid_amount")}
              </InputLabel>
              <Input
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                id="paymentAmount"
                value={this.state.paymentAmount}
                // onChange={this.handleChange('amount')}
                startAdornment={
                  <InputAdornment position="start">EUR</InputAdornment>
                }
                className={classes.textField}
                margin="dense"
                onChange={this.handleTextFieldChange("paymentAmount")}
              />

              <form autoComplete="off">
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  margin="normal"
                >
                  <InputLabel htmlFor="type-select">
                    {t("_payment_type")}
                  </InputLabel>
                  <Select
                    disabled={this.state.isAdding ? true : false}
                    className={classes.selectInput}
                    open={this.state.dropdownOpen}
                    onClose={this.handleDropdownClose}
                    onOpen={this.handleDropdownOpen}
                    value={this.state.type}
                    onChange={this.handleDropdownChange}
                    inputProps={{
                      name: "type",
                      id: "type-select",
                    }}
                    margin="dense"
                  >
                    {CasePaymentTypes.map((row, index) => {
                      return (
                        <MenuItem key={row.id} value={index}>
                          {t(row.display)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </form>
            </div>
            {/* <TextField
                value={this.state.invoiceNumber}
                fullWidth
                id="invoiceNumber"
                label="Invoice Number"
                className={classes.textField}
                margin="dense"
                onChange={this.handleTextFieldChange('invoiceNumber')}
              /> */}
            <TextField
              value={this.state.paymentSubject}
              fullWidth
              id="paymentSubject"
              label={t("_payment_description")}
              multiline
              rows="1"
              // defaultValue="Default Value"
              className={classes.textField}
              margin="dense"
              onChange={this.handleTextFieldChange("paymentSubject")}
            />

            <br />
            <LinearProgress
              className={classes.addProgress}
              color="secondary"
              style={{ visibility: this.state.isAdding ? "visible" : "hidden" }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handlePopupClose}
              color="secondary"
              disabled={this.state.isAdding ? true : false}
            >
              {t("_cancel")}
            </Button>
            <Button
              onClick={this.handleAddPayment}
              color="primary"
              disabled={
                this.state.isAdding ||
                this.state.type === "" ||
                this.state.selectedFileName === t("_no_file_selected")
                  ? true
                  : false
              }
            >
              {t("_record_payment")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

CasePayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(connect(null, matchDispatchToProps)(CasePayment))
);

