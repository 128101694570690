import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import styles from './styles';
import { bindActionCreators } from 'redux';

import CasesService from '@Services/Cases';
import FindingService from '@Services/Findings';
import { updateCase } from '@Actions/casesActions';

class MoveBackToCaseButton extends Component {
  constructor(props) {
    super();
  }
  actionClicked = (data) => {
    try {
      FindingService.moveFindingBackToCase(data)
        .then((response) => {
          CasesService.getCase(data)
            .then((response) => {
              this.props.updateCase({
                results: response,
                caseId: response.info.caseId,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { classes, t, data } = this.props;
    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => this.actionClicked(data)}
        >
          {t('_move_back_to_case')}
        </Button>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { findingsReducer: state.findingsReducer };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateCase,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate('translations')(
    connect(mapStateToProps, mapDispatchToProps)(MoveBackToCaseButton)
  )
);
