import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered"
import DialogPopup from "@Components/DialogPopup";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isNull } from "@Utils/helpers.js";
import styles from "./styles";
import {
  Button,
  DialogActions,
  DialogTitle,
  Table,
  TextField,
} from "@material-ui/core";
import {
  updateCaseFindingLicenceFee,
  getCaseFees,
} from "@Actions/casesActions";
import CaseService from "@Services/Cases";

class FindingFees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editClaimCalculatorOpen: false,
      editClaimDirectOpen: false,
      newFeeValue: 0,
      licenceFee: this.props.data.findingDetails.licence_fee,
      showClaimCalculationOpen: false,
    };
  }

  handleOpenEditClaimCalculatorDialog = () => {
    this.setState({ editClaimCalculatorOpen: true });
  };

  handleOpenEditClaimDirectDialog = () => {
    this.setState({ editClaimDirectOpen: true });
  };

  handleOpenShowClaimCalculatorDialog = () => {
    this.setState({ showClaimCalculationOpen: true });
  };

  updateReducerFees = () => {
    CaseService.getCaseFees({ caseId: this.props.caseId })
      .then((response) => {
        response.collectionPartnerFee = response.lawyerFee;
        response.collectionPartnersPartOfSettlementSum =
          response.lawyersPartOfSettlementSum;
        this.props.actionGetCaseFees({
          caseId: this.props.caseId,
          fees: response,
        });
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
  };

  handleCloseEditClaimCalculatorDialog = () => {
    CaseService.updateCaseFindingLicenceCalculation({
      caseId: this.props.caseId,
      caseResultId: this.props.data.findingDetails.caseResultID,
      findingId: this.props.data.findingId,
    })
      .then((response) => {
        this.setState({
          editClaimCalculatorOpen: false,
          licenceFee: response.licenseFee,
        });

        //update reducer
        this.props.actionUpdateCaseFindingLicenceFee({
          caseId: this.props.caseId,
          findingId: this.props.data.findingId,
          caseResultId: this.props.data.findingDetails.caseResultID,
          value: response.licenseFee,
        });
        this.updateReducerFees();
        // need to get the latest results here
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });

    this.setState({ editClaimCalculatorOpen: false });
  };

  handleCloseEditClaimDirectDialog = () => {
    this.setState({ editClaimDirectOpen: false });
  };

  handleCloseShowClaimCalculatorDialog = () => {
    this.setState({ showClaimCalculationOpen: false });
  };

  handleSaveClaimDirect = () => {
    // console.log(this.props.data);
    // update in case service --> in response fire reducer action
    CaseService.updateCaseFindingLicenceFee({
      caseId: this.props.caseId,
      findingId: this.props.data.findingId,
      caseResultId: this.props.data.findingDetails.caseResultID,
      value: this.state.newFeeValue,
    })
      .then((response) => {
        this.setState({ licenceFee: this.state.newFeeValue });

        this.props.actionUpdateCaseFindingLicenceFee({
          caseId: this.props.caseId,
          findingId: this.props.data.findingId,
          caseResultId: this.props.data.findingDetails.caseResultID,
          value: this.state.newFeeValue,
        });
        this.handleCloseEditClaimDirectDialog();
        this.updateReducerFees();
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
  };

  handleEditFeeDirectChange = (event) => {
    if (!event.target.value) this.setState({ newFeeValue: 0 });

    let trimmedVal = event.target.value.trim();
    if (this.isNumeric(trimmedVal) === true)
      this.setState({ newFeeValue: parseFloat(event.target.value).toString() });
    else return;
  };

  isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  iframe = () => {
    return {
      __html:
        '<iframe style="border: none" src="https://calculator.pixray.com?cId=' +
        this.props.data.findingDetails.caseResultID +
        '" width = "440" height = "650" ></iframe > ',
    };
  };

  iframeReadOnly = () => {
    return {
      __html:
        '<iframe style="border: none" src="https://calculator.pixray.com?cId=' +
        this.props.data.findingDetails.caseResultID +
        '&ro=true" width = "440" height = "650" ></iframe > ',
    };
  };

  render() {
    const { classes, status, t } = this.props;
    return (
      <div>
        <div className={classes.feesContainer}>
          <table className={classes.tblGeneral}>
            <tbody>
              <tr>
                <td className={classes.trLeft}>{t("_license_fee")}</td>
              </tr>
              <tr>
                {isNull(this.state.licenceFee) ||
                this.state.licenceFee === 0 ||
                this.state.licenceFee === "0" ? (
                  <td className={classes.trRight}>0.00 EUR</td>
                ) : (
                  <td className={classes.trRight}>
                    {this.state.licenceFee} EUR
                  </td>
                )}
              </tr>
              <tr>
                <td>
                  <br />
                </td>
              </tr>
              <tr>
                {status === 4 || status === "4" ? (
                  <td className={classes.editContainer}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <EditIcon
                              onClick={this.handleOpenEditClaimDirectDialog}
                              className={classes.editClaim}
                            />
                          </td>
                          <td>
                            <span
                              onClick={this.handleOpenEditClaimDirectDialog}
                            >
                              {t("_edit_claim_direct")}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <EditIcon
                              onClick={this.handleOpenEditClaimCalculatorDialog}
                              className={classes.editClaim}
                            />
                          </td>
                          <td>
                            <span
                              onClick={this.handleOpenEditClaimCalculatorDialog}
                            >
                              {t("_edit_claim_calculator")}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                ) : status === 5 || status === "5" ? (
                  <td className={classes.editContainer}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <FormatListNumberedIcon
                              onClick={this.handleOpenShowClaimCalculatorDialog}
                              className={classes.editClaim}
                            />
                          </td>
                          <td>
                            <span
                              onClick={this.handleOpenShowClaimCalculatorDialog}
                            >
                              {t("_show_calculation")}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                ) : (
                  <td className={classes.editContainer}>
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <EditIcon
                              onClick={this.handleOpenEditClaimDirectDialog}
                              className={classes.editClaim}
                            />
                          </td>
                          <td>
                            <span
                              onClick={this.handleOpenEditClaimDirectDialog}
                            >
                              {t("_set_price")}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <EditIcon
                              onClick={this.handleOpenEditClaimCalculatorDialog}
                              className={classes.editClaim}
                            />
                          </td>
                          <td>
                            <span
                              onClick={this.handleOpenEditClaimCalculatorDialog}
                            >
                              {t("_open_calculator")}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
        <DialogPopup
          open={this.state.editClaimDirectOpen}
          onClose={this.handleCloseEditClaimDirectDialog}
          maxWidth="md"
        >
          <DialogTitle>{t("_set_price")}</DialogTitle>
          {/* <DialogContent>{t("_set_licence_fee_description")}</DialogContent> */}
          <TextField
            id="standard-number"
            label={t("_license_fee")}
            type="number"
            className={classes.tbEditFeeDirect}
            value={this.state.newFeeValue}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleEditFeeDirectChange}
            variant="outlined"
          />
          <DialogActions className={classes.actionSection}>
            <Button onClick={this.handleCloseEditClaimDirectDialog}>
              {t("_cancel")}
            </Button>
            <Button
              onClick={this.handleSaveClaimDirect}
              className={classes.btnSave}
            >
              {t("_save")}
            </Button>
          </DialogActions>
        </DialogPopup>


        <DialogPopup
          open={this.state.editClaimCalculatorOpen}
          onClose={this.handleCloseEditClaimCalculatorDialog}
          maxWidth="lg"
        >
          <div
            className={classes.iframeContainer}
            dangerouslySetInnerHTML={this.iframe()}
          />
        </DialogPopup>


        <DialogPopup
          open={this.state.showClaimCalculationOpen}
          onClose={this.handleCloseShowClaimCalculatorDialog}
          maxWidth="lg"
        >
          <div
            className={classes.iframeContainer}
            dangerouslySetInnerHTML={this.iframeReadOnly()}
          />
        </DialogPopup>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    licenceFee: state.licenceFee,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionUpdateCaseFindingLicenceFee: updateCaseFindingLicenceFee,
      actionGetCaseFees: getCaseFees,
    },
    dispatch
  );
}

FindingFees.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(FindingFees)
  )
);
