import React from 'react';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import randomTexts from '@Configs/loadingMsgConfig';
import styles from './styles';

class LoadingBg extends React.Component {
  constructor(props){
    super();
  }

  render() {
    const { classes } = this.props;

    let animated = this.props.animated !== undefined ? this.props.animated: true;
    let message = this.props.message !== undefined && this.props.message !== ""? this.props.message: 
        randomTexts[[this.props.pageType]][Math.floor(Math.random() * randomTexts[this.props.pageType].length)]; 
    let size = this.props.size !== undefined ?  this.props.size: "normal";

    return (
      <div className={classes.root}>
        {animated?
        (<img className={classNames(classes.imageStyle, classes[size + 'Image'] )} src={require('@Assets/images/loading.gif')} alt="" />):
        (<img className={classNames(classes.imageStyle, classes[size + 'Image'] )} src={require('@Assets/images/loading.png')} alt="" />)}
        <p className={classNames(classes.messageStyle, classes[size + 'Message'] )}>{message}</p>
      </div>
      
    );
  }
}

LoadingBg.propTypes = {
  classes: PropTypes.object.isRequired,
  pageType: PropTypes.oneOf(['domains', 'cases', 'domain', 'case', 'media', 'stats', 'settings', 'search', 'general' ]).isRequired,
  size: PropTypes.oneOf([ 'small', 'normal' ]),
  animated: PropTypes.bool,
  message: PropTypes.string
};

export default withStyles(styles)(translate("translations")(LoadingBg));