import request from '@Utils/api'
import api from '@Configs/apiConfig';

function getUserData(id) {
  return request({
    url: api.userServer + '/userdata.json',
    method: 'GET'
  });
}

function updatePassword(data) {
  return request({
    url: api.userServer + '/userdata.json',
    method: 'GET'
  });
}


function updateAvatar(data) {
  // data = file object
  var formData = new FormData();
  formData.append("file", data);
  return request({
    url: api.userServer + '/User/' + localStorage.getItem('Id') + '/avatar',
    method: 'POST',
    data: formData,
    headers: {
      'x-access-token': localStorage.getItem('Token'),
      'content-type': 'multipart/form-data'
    }
  });
}

function updateInfo(data) {
  return request({
    url: api.userServer + '/userdata.json',
    method: 'GET'
  });
}


const Setting = {
  getUserData, updatePassword, updateAvatar, updateInfo //, update, delete, etc. ...
}

export default Setting;
