import {
  GET_DOMAINS,
  GET_DOMAINS_MORE,
  GET_DOMAINS_SEARCH,
  GET_DOMAINS_SEARCH_MORE,
  GET_DOMAIN,
  UPDATE_DOMAIN,
  CREATE_DOMAINS_CASE,
  REMOVE_DOMAINS,
  REMOVE_DOMAIN,
  REMOVE_DOMAINS_TAG,
  ADD_DOMAINS_TAG,
  ADD_DOMAINS_NOTE,
  UPDATE_DOMAINS_STATUS,
  UPDATE_DOMAIN_FINDING_COUNT,
  ADD_DOMAIN_FINDING_NOTE,
  DELETE_DOMAINS_NOTE,
} from "@Types/actionTypes";
import _ from "lodash";
import { getIndexBy } from "@Utils/helpers";

const domainsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_DOMAINS:
      state = null;
      state = Object.assign({}, state, action.payload.results);
      break;

    case GET_DOMAINS_MORE:
      if (action.payload.results.total !== 0)
        state = {
          total: action.payload.results.total,
          results: _.merge(state.results, action.payload.results.results),
        };
      break;

    case GET_DOMAINS_SEARCH:
      state = null;
      state = Object.assign({}, state, action.payload.results);
      break;

    case GET_DOMAINS_SEARCH_MORE:
      state = {
        total: action.payload.results.total,
        results: _.merge(state.results, action.payload.results.results),
      };
      break;

    case GET_DOMAIN:
      state = { total: 1, results: {} };
      state.results[action.payload.info.domainId] = action.payload;
      break;

    case UPDATE_DOMAIN:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.domainId]: action.payload.results,
        },
      };
      break;

    case CREATE_DOMAINS_CASE:
      state = action.payload;
      break;

    case REMOVE_DOMAINS:
      state = null;
      break;

    case REMOVE_DOMAIN:
      var newResults = state.results;
      delete newResults[action.payload.domainId];

      state = { ...state, total: state.total - 1, results: newResults };

      break;

    case ADD_DOMAINS_TAG:
      // var updatedTagsA = state.results[action.payload.domainId].tags;
      // updatedTagsA.push(action.payload.tag);
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.domainId]: {
            ...state.results[action.payload.domainId],
            tags: [
              ...state.results[action.payload.domainId].tags,
              action.payload.tag,
            ],
          },
        },
      };
      break;

    case REMOVE_DOMAINS_TAG:
      const index = getIndexBy(
        state.results[action.payload.domainId].tags,
        "id",
        action.payload.tag.id
      );

      let newArray = state.results[action.payload.domainId].tags.slice();
      newArray.splice(index, 1);

      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.domainId]: {
            ...state.results[action.payload.domainId],
            tags: newArray,
          },
        },
      };
      break;

    case ADD_DOMAINS_NOTE:
      var newNote = {
        id: new Date(),
        note: action.payload.note,
        date: new Date().toISOString(),
        owner:
          localStorage.getItem("FirstName") +
          " " +
          localStorage.getItem("LastName"),
        type: "domain",
      };

      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.domainId]: {
            ...state.results[action.payload.domainId],
            notes: [newNote, ...state.results[action.payload.domainId].notes],
          },
        },
      };

      console.log(state);
      break;
    case DELETE_DOMAINS_NOTE:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.domainId]: {
            ...state.results[action.payload.domainId],
            notes: state.results[action.payload.domainId].notes.filter(
              (x) => x.id !== action.payload.id
            ),
          },
        },
      };
      break;

    case UPDATE_DOMAINS_STATUS:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.domainId]: {
            ...state.results[action.payload.domainId],
            info: {
              ...state.results[action.payload.domainId].info,
              status: action.payload.status,
            },
          },
        },
      };
      break;

    case UPDATE_DOMAIN_FINDING_COUNT:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.domainId]: {
            ...state.results[action.payload.domainId],
            info: {
              ...state.results[action.payload.domainId].info,
              newFound:
                state.results[action.payload.domainId].info.newFound +
                action.payload.count,
            },
          },
        },
      };
      break;

    case ADD_DOMAIN_FINDING_NOTE:
      if (state && state.results[action.payload.domainId])
        state = {
          ...state,
          results: {
            ...state.results,
            [action.payload.domainId]: {
              ...state.results[action.payload.domainId],
              notes: [
                action.payload.note,
                ...state.results[action.payload.domainId].notes,
              ],
            },
          },
        };

      break;

    default:
      break;
  }

  return state;
};

export default domainsReducer;
