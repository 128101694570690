import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CDNurl } from '@Configs/globalConfig';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './styles';

class FindingImages extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.imageContainer}>
          <div className={classes.singleImageContainer}>
            <LazyLoadImage src={CDNurl + this.props.imagesData.targetImage + '?h=120&t=' + localStorage.getItem('Token')} alt="" className={classes.imageStyle} />
          </div>
          <div className={classes.singleImageContainer}>
            <LazyLoadImage src={CDNurl + this.props.imagesData.foundImage + '?h=120&t=' + localStorage.getItem('Token')} alt="" className={classes.imageStyle} />
          </div>
        </div>
      </div>
    );
  }

}

FindingImages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(FindingImages));