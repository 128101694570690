import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { translate } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import SnackAlert from "@Components/SnackAlert";
import { transitionUp, atou } from "@Utils/helpers.js";

import ProgressBar from "@Components/ProgressBar";
import LoginService from "@Services/Login";
import { Redirect } from "react-router-dom";
import { authenticate, validate } from "@Actions/authActions";
import crypto from "crypto";
// import base64 from 'base-64';

import styles from "./styles";
import DialogPopup from "@Components/DialogPopup";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import UserService from "@Services/User";

class Login extends Component {
  constructor(props) {
    super(props);

    this.passInput = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      username: localStorage.getItem("LoginName"),
      email: "",
      password: "",
      showPassword: false,

      snackMessage: "",
      snackOpen: false,
      snackVariant: "success",

      forgotPasswortModalOpen: false,
      loading: false,
      sendButtonActive: false,
    };
  }

  handleCloseSnack = () => {
    this.setState({ snackOpen: false });
  };

  getRedirectPath() {
    const locationState = this.props.location.state;
    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname; // redirects to referring url
    } else {
      return "/";
    }
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
    if (prop === "email") {
      if (!isEmailValid(event.target.value)) {
        this.setState({ sendButtonActive: false });
      } else {
        this.setState({ sendButtonActive: true });
      }
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  getIVAndSecret = () => {
    var pw = this.state.password;
    var sha = crypto
      .createHash("sha256")
      .update(pw + pw)
      .digest("hex");
    var firstHalf = sha.substring(0, 32);
    var iv = crypto.randomBytes(16);
    let cipher = crypto.createCipheriv(
      "aes-256-cbc",
      firstHalf.toString("binary"),
      iv
    );
    let encrypted = cipher.update(this.state.password, "utf8", "base64");
    encrypted += cipher.final("base64");
    return { iv: iv.toString("hex"), secret: encrypted };
  };

  handleClick = () => {
    if (
      this.state.username.trim() === "" ||
      this.state.password.trim() === ""
    ) {
      this.setState({
        open: true,
        transitionUp,
        alertMessage: this.props.t("_username_or_password_cant_be_empty"),
        variant: "warning",
      });
      return;
    }

    this.props.showLoading();
    LoginService.authenticate(this.state.username, this.state.password)
      .then((response) => {
        if (response.token === "isLocal") {
          localStorage.setItem("Token", "testToken");
          localStorage.setItem("FirstName", "Jon");
          localStorage.setItem("LastName", "Snow");
        } else {
          var userData = JSON.parse(atou(response.token.split(".")[1]));
          // check if we have to redirect
          if (response.mustRedirect && response.mustRedirect === true) {
            var ivAndSecret = this.getIVAndSecret();
            window.location.href =
              "https://ap.pixray.com/externallogin" +
              "?email=" +
              encodeURIComponent(userData.email) +
              "&iv=" +
              encodeURIComponent(ivAndSecret.iv) +
              "&s=" +
              encodeURIComponent(ivAndSecret.secret);

            return;
          }
          let toStore = localStorage.getItem("i18nextLng");

          localStorage.clear();
          if (toStore) localStorage.setItem("i18nextLng", toStore);
          localStorage.setItem("LoginName", this.state.username);
          localStorage.setItem("ownParty", userData.ownParty);
          localStorage.setItem("Token", response.token);
          localStorage.setItem("Id", userData.userid);
          localStorage.setItem("UserName", userData.username);
          localStorage.setItem("Name", userData.Name);
          localStorage.setItem("FirstName", userData.userFirstName);
          localStorage.setItem("LastName", userData.userLastName);
          localStorage.setItem("Email", userData.email);
          localStorage.setItem("CultureID", userData.cultureId);
          localStorage.setItem("CustomerID", userData.customerID);
          localStorage.setItem("AvatarID", userData.avatarID);
          localStorage.setItem("AvatarImage", "");
          localStorage.setItem("IAT", userData.iat);
          localStorage.setItem("CD", userData.cd);
          localStorage.setItem("RoleID", userData.userRoleID);
          localStorage.setItem(
            "ShowSkipDocumentation",
            userData.showSkipDocumentationEnabled
          );
          localStorage.setItem(
            "media_info_config",
            JSON.stringify(userData.media_info_config)
          );

          localStorage.setItem(
            "SelectedFilters",
            JSON.stringify({ domain: 0, case: 0, media: 0 })
          );
        }

        this.props.actionAuthenticate();
        this.props.hideLoading();
        window.location.href = "/domains";
      })
      .catch((error) => {
        this.setState({
          snackOpen: true,
          snackMessage: error.message,
          snackVariant: "error",
        });
        setTimeout(() => this.props.hideLoading(), 200);
      });
  };

  handleKeyPress = (event, inputName) => {
    if (event.key === "Enter") {
      if (inputName === "username") {
        this.passInput.current.focus();
      } else {
        this.handleClick(transitionUp);
      }
    }
  };

  handleForgotPassword = () => {
    this.setState({
      forgotPasswortModalOpen: true,
      email: this.state.username,
    });
    if (isEmailValid(this.state.username))
      this.setState({ sendButtonActive: true });
  };

  handlePopupForgotPasswortOK = () => {
    this.setState({ loading: true });
    UserService.resetPassword(this.state.email)
      .then((response) => {
        this.setState({ loading: false, forgotPasswortModalOpen: false });
        this.setState({
          snackOpen: true,
          snackMessage: this.props.t(
            "_reset_password_success"
          ),
          snackVariant: "success",
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.setState({
          snackOpen: true,
          snackMessage: this.props.t(
            "_something_went_wrong_please_try_again_later"
          ),
          snackVariant: "error",
        });
      });
  };

  render() {
    const { classes, t } = this.props;

    return this.props.authReducer.authenticated &&
      localStorage.getItem("Token") != null ? (
      <Redirect
        to={{
          pathname: this.getRedirectPath(),
          state: { from: this.props.location },
        }}
      />
    ) : (
      <div className={classes.root}>
        <ProgressBar />

        <Card className={classes.card}>
          <div className={classes.cardImageContainer}>
            <img
              className={classes.imgStyle}
              src={require("@Assets/images/logo.png")}
              alt=""
            />
          </div>

          <CardContent className={classes.cardContent}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="username">{t("_username")}</InputLabel>
              <Input
                id="username"
                value={this.state.username}
                onChange={this.handleChange("username")}
                onKeyPress={(e) => this.handleKeyPress(e, "username")}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="password">{t("_password")}</InputLabel>
              <Input
                id="password"
                inputRef={this.passInput}
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                onKeyPress={(e) => this.handleKeyPress(e, "password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPasssword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <div>
                <Button
                  className={classes.forgot_password_button}
                  onClick={this.handleForgotPassword}
                >
                  {t("_forgot_password")}
                </Button>
              </div>
            </FormControl>
            <br />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleClick}
            >
              {t("_login")}
            </Button>
          </CardContent>
        </Card>

        <DialogPopup
          open={this.state.forgotPasswortModalOpen}
          onClose={this.handleforgotPasswortPopupClose}
        >
          <DialogTitle>{t("_forgot_password")}</DialogTitle>

          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.forgot_password_text_container}>
              {t("_click_ok_to_send_password_reset_mail")}
            </div>
            <div style={{ marginTop: "20px" }}>
              <Input
                id="email"
                value={this.state.email}
                onChange={this.handleChange("email")}
                onKeyPress={(e) => this.handleKeyPress(e, "email")}
                style={{ width: "280px" }}
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                this.setState({ forgotPasswortModalOpen: false });
              }}
              color="secondary"
            >
              {t("_cancel")}
            </Button>
            {this.state.loading && (
              <>
                <div style={{ minWidth: "71px", textAlign: "center" }}>
                  <CircularProgress style={{ width: "20px", height: "20px" }} />{" "}
                </div>
              </>
            )}

            {!this.state.loading && (
              <Button
                onClick={this.handlePopupForgotPasswortOK}
                color="primary"
                disabled={!this.state.sendButtonActive}
              >
                {t("_send")}
              </Button>
            )}
          </DialogActions>
        </DialogPopup>

        <SnackAlert
          open={this.state.snackOpen}
          onClose={this.handleCloseSnack}
          message={this.state.snackMessage}
          variant={this.state.snackVariant}
        />
      </div>
    );
  }
}

///////////// following code is copied from    //////////////////////////
var emailRegex =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

function isEmailValid(email) {
  if (!email) return false;

  if (email.length > 254) return false;

  var valid = emailRegex.test(email);
  if (!valid) return false;

  // Further checking of some things regex can't handle
  var parts = email.split("@");
  if (parts[0].length > 64) return false;

  var domainParts = parts[1].split(".");
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  )
    return false;

  return true;
}
/////////////////////////////////////////////////////////////////////////

function mapStateToProps(state) {
  return {
    authReducer: state.authReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading: showLoading,
      hideLoading: hideLoading,

      actionAuthenticate: authenticate,
      actionValidate: validate,
    },
    dispatch
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(Login)
  )
);
