import { grey } from "@material-ui/core/colors";
const styles = (theme) => ({
  root: {
    margin: 0,
  },
  button: {
    textTransform: "initial",
    fontSize: 14,
    height: 40,
    minWidth: 150,
    width: "100%",
    color: "#676767",
    backgroundColor: "#fff",
    border: "0px solid #676767",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#676767",
      border: "1px solid transparent",
    },
  },
  moreButton: {
    minWidth: 5,
    fontSize: 12,
  },
  input: {
    display: "none",
  },

  tblContainerDetails: {
    height: 500,
    overflow: "scroll",
  },
  tblDetails: {
    border: "1px solid #EEE",
  },
  rowrowDeatils: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowDeatilsFrist: {
    // width: 20,
    padding: "5px !important",
    // textAlign: "center"
  },
  rowDeatilsSecond: {
    "& b": {
      fontSize: 13,
      color: grey[600],
    },
  },
  nowrap: {
    display: "inline",
    whiteSpace: "nowrap",
  },

  alertPaper: {
    boxShadow: "none",
    borderRadius: "9px",
    background: "#e5f6fd",
    padding: "16px 16px 16px 60px",
    color: "rgb(1, 67, 97)",
    display: "-webkit-box",
    marginTop: "30px"
  },
  alertIcon: {
    display: "list-item",
    position: "relative",
    left: "-36px",
    color: "#03a9f4",
    top: "20%",
  },
});

export default styles;
