import {
  GET_FINDINGS_NEW,
  GET_FINDINGS_TRASH,
  GET_FINDINGS_LEGAL,
  GET_FINDINGS_CASE,
  REMOVE_NEW_FINDINGS,
  UPDATE_FINDINGS_CASE_STATUS,
  MOVE_FINDINGS_TO_NEW,
  MOVE_FINDINGS_TO_TRASH,
  MOVE_FINDINGS_TO_LEGAL,
  DELETE_FINDING,
  MOVE_FINDING_BACK_TO_CASE,
} from '@Types/actionTypes';

export function getFindingsNew(data) {
  // data:
  // results
  // pageNum

  return {
    type: GET_FINDINGS_NEW,
    payload: data,
  };
}

export function getFindingsTrash(data) {
  // data:
  // results
  // pageNum

  return {
    type: GET_FINDINGS_TRASH,
    payload: data,
  };
}

export function getFindingsLegal(data) {
  // data:
  // results
  // pageNum

  return {
    type: GET_FINDINGS_LEGAL,
    payload: data,
  };
}

export function getFindingsCase(data) {
  // data:
  // results
  // pageNum

  return {
    type: GET_FINDINGS_CASE,
    payload: data,
  };
}

export function removeNewFindings() {
  return {
    type: REMOVE_NEW_FINDINGS,
    payload: null,
  };
}

export function updateFindingsCaseStatus(data) {
  // data:
  // caseId
  // status
  // data : []

  return {
    type: UPDATE_FINDINGS_CASE_STATUS,
    payload: data,
  };
}

export function moveFindingsToNew(data) {
  // data :
  // domainId
  // findingId
  // type

  return {
    type: MOVE_FINDINGS_TO_NEW,
    payload: data,
  };
}

export function moveFindingsToTrash(data) {
  // data :
  // domainId
  // findingId
  // type

  return {
    type: MOVE_FINDINGS_TO_TRASH,
    payload: data,
  };
}

export function moveFindingsToLegal(data) {
  // data :
  // domainId
  // findingId
  // type

  return {
    type: MOVE_FINDINGS_TO_LEGAL,
    payload: data,
  };
}

export function deleteFinding(data) {
  // data :
  // domainId
  // findingId

  return {
    type: DELETE_FINDING,
    payload: null,
  };
}

export function moveFindingBackToCase(data) {
  //data:
  // caseId
  return {
    type: MOVE_FINDING_BACK_TO_CASE,
    payload: data,
  };
}
