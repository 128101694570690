

import { GET_NOTIFY, ADD_NOTIFY } from '@Types/actionTypes';
import GeneralService from '@Services/General';
// import store from '@Core/store';

export function getNotify(){
  return dispatch => {
    GeneralService.getNotify().then(
      (response) => {
        dispatch({
          type : GET_NOTIFY,
          payload : response
        });
      }
    );
  }
}


export function addNotify(data){
  var newData = data;
  
  // var newState = store.getState().logsReducer;
  // newState.unshift(data);
  return {
    type : ADD_NOTIFY,
    payload : newData
  }
}

