const styles = theme => ({
  root: {
    zIndex: 100, // 1300 is for modals
    flexGrow: 1,
    maxWidth: 300,
    minWidth: 300,
    height:"100vh",
    position:"fixed",
    // overflowY:"scroll",
    overflowX:"hidden",
    top:64,
    right:0,
    overflowY: "scroll",
    borderRadius: 0
  },
  demo: {
    background: theme.palette.background.paper,
    marginBottom:80
  },
  listMain:{
    
  },
  logLink:{
    textDecoration:"none",
  },
  dateText:{
    textAlign:"left",
  },
  rightMenuContainer: {
     minWidth: 300,
    zIndex:100, // 1300 is for modals
    display:"none"
  },
  rightMenuContainerShow: {
    display: "block !important",
    zIndex:100 // 1300 is for modals
  }
});

export default styles;