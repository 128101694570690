import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import {transitionUp} from '@Utils/helpers.js'
class DialogPopup extends Dialog {

  render() {
    const { classes } = this.props;

    return (
        <Dialog 
          TransitionComponent={transitionUp}
          classes={{paper:classes.paper}} 
          {...this.props} 
        >
          {this.props.children}
        </Dialog>
    );
  }

}

DialogPopup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogPopup);