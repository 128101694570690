import request from "@Utils/api";
import api from "@Configs/apiConfig";
import _ from "lodash";

function getCases(data) {
  // data :
  // pageNum
  // filter
  var filterQuery =
    data.filter === "0" || data.filter === 0 || data.filter == null
      ? ""
      : "&f=" + data.filter;

  return request({
    url:
      api.casesServer + "/case?ps=20&p=" + parseInt(data.pageNum) + filterQuery,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function getAllCasesCount() {
  return request({
    url: api.casesServer + "/case?co=true",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCasesSearch(data) {
  // data :
  // pageNum
  // param
  // filter
  var filterQuery =
    data.filter === "0" || data.filter === 0 || data.filter == null
      ? ""
      : "&f=" + data.filter;

  return request({
    url:
      api.casesServer +
      "/search_case?ps=20&p=" +
      parseInt(data.pageNum) +
      "&s=" +
      data.param +
      filterQuery,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function getCase(data) {
  // data :
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getAllCaseTags() {
  return request({
    url: api.casesServer + "/case/tags",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addCasesTag(data) {
  // data:
  // tag
  //     name
  //     id
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/tags/",
    data: data.tag,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function removeCasesTag(data) {
  // data:
  // tag
  //     name
  //     id
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/tags/" + data.tag.id,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addCasesNote(data) {
  // data:
  // note
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/notes/",
    data: { note: data.note },
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function deleteCasesNote(data) {
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/notes/" + data.id,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCaseDocuments(data) {
  // data:
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/documents",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCaseMessages(data) {
  // data:
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/messages",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addCaseMessage(data) {
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/messages/",
    data: {
      receiverParty: data.receiver,
      replyID: data.messageId,
      message: data.message,
    },
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCaseDomain(data) {
  // data:
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/domain_info",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCaseFees(data) {
  // data:
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/fees",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCasepayments(data) {
  // data:
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/payments",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function uploadCasesDoc(data) {
  // data:
  // caseId: this.props.caseId,
  // file: this.state.theFile,
  // fileSize: bytesToSize(this.state.theFile.size),
  // fileName: this.state.theFile.name,
  // documentTypeId: this.state.type,
  var formData = new FormData();
  formData.append("file", data.file);
  formData.append("documentTypeID", data.documentTypeId);
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/documents",
    method: "POST",
    data: formData,
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "content-type": "multipart/form-data",
    },
  });
}

function getCasesPaymentTypes(data) {
  // data:
  // caseId
  return request({
    url: "../MockData/cases/paymenttypes.json",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addCasesPayment(data) {
  // data:
  // caseId
  // data:
  //    paymentType
  //    paymentTypeId
  //    bookedOn
  //    invoiceNumber
  //    paymentSubject
  //    paymentAmount
  //    dateBilled
  //    intBillingNumber
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/payments",
    data: { data: data },
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCollectionPartnerList() {
  return request({
    url: api.casesServer + "/lawyers",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateCasesCollectionPartner(data) {
  // data:
  // caseId
  // data:
  //    id
  //    name
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/collection_partner",
    data: data,
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateCasesFees(data) {
  // data:
  // caseId
  // data:
  // licenceFee
  // collectionPartnerFee
  // researchFee
  // totalPaymentRequested
  // settlementSum
  // collectionPartnersPartOfSettlementSum
  return request({
    data: { data: data },
    url: api.casesServer + "/case/" + data.caseId + "/fees/",
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateCaseFindingLicenceFee(data) {
  //data:
  //caseId
  //caseResultId
  //value
  // casreResultId
  var qData = {
    caseID: data.caseId,
    caseResultId: data.caseResultId,
    value: data.value,
  };
  return request({
    data: { data: qData },
    url:
      api.casesServer +
      "/case/" +
      data.caseId +
      "/case_results/" +
      data.caseResultId +
      "/licence_fee",
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateCaseFindingLicenceCalculation(data) {
  //data:
  //caseId
  //findingId
  //caseResultId:
  var qData = {
    caseID: data.caseId,
    caseResultId: data.caseResultId,
    value: data.value,
  };
  return request({
    data: { data: qData },
    url:
      api.casesServer +
      "/case/" +
      data.caseId +
      "/case_results/" +
      data.caseResultId +
      "/update_calculation",
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateCasesStatus(data) {
  // data:
  // caseId
  // status
  // data : []
  var payload;
  data.data == null
    ? (payload = { status: data.status })
    : (payload = { ..._.omit(data.data, "isStart") });
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/status",
    data: payload,
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCaseHistory(data) {
  // data:
  // caseId
  return request({
    url: api.casesServer + "/case/" + data.caseId + "/history",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function casesBulkAction(data) {
  // data:
  // tag
  //     name
  //     id
  // type     -> remove_tags, add_tags
  // serach   -> search object || null
  // filterId -> filter id || null
  if (data.data.s === null) data.data.s = "";
  return request({
    url: api.casesServer + "/bulk_action/",
    data: data,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function markingAsRead(data) {
  console.log(data, "request");
  // data:
  // caseId
  // messageId
  return request({
    url: api.casesServer + `/case/${data.caseID}/messages/${data.id}/markDone`,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getCaseTodos() {
  return request({
    url: api.casesServer + "/case_todos",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateDomainInformation(caseid, data) {
  return request({
    url: api.casesServer + "/case/" + caseid + "/domain_info",
    method: "POST",
    data: data,
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

const Cases = {
  getCases,
  getCasesSearch,
  getCase,
  getCaseDocuments,
  getCaseDomain,
  getCaseFees,
  getCasepayments,
  addCasesTag,
  removeCasesTag,
  addCasesNote,
  deleteCasesNote,
  uploadCasesDoc,
  getCasesPaymentTypes,
  addCasesPayment,
  getCollectionPartnerList,
  updateCasesCollectionPartner,
  updateCasesFees,
  updateCasesStatus,
  getCaseHistory,
  getAllCaseTags,
  casesBulkAction,
  updateCaseFindingLicenceFee,
  updateCaseFindingLicenceCalculation,
  getCaseMessages,
  addCaseMessage,
  markingAsRead,
  getAllCasesCount,
  getCaseTodos,
  updateDomainInformation,
};

export default Cases;
