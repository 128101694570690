import { GET_LOGS, ADD_LOGS, REMOVE_LOGS, TOGGLE_LOGS } from '@Types/actionTypes';

const logsReducer = (state = {toggle:false, data:[]}, action) => {

  switch (action.type) {
    
    case GET_LOGS:
      state = {...state, data: action.payload}
      break;
    
    case ADD_LOGS:
      state = {...state, data:[...action.payload, ...state.data]}
      break; 

    case REMOVE_LOGS:
      state = {...state, data:[]}
      break; 
    
    case TOGGLE_LOGS:
      state = {...state, toggle:!state.toggle}
      break;

    default:
      break;
  }
  
  return state;
};

export default logsReducer;