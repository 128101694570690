import { grey } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {
    heigth: "100%",
  },
  tblContainerPayments: {
    height: 220,
    overflow: "scroll",
  },
  tblPayments: {
    border: "1px solid #EEE",
  },
  rowPayments: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowPaymentsFrist: {
    // width: 20,
    padding: "5px !important",
    // textAlign: "center"
  },
  rowPaymentsSecond: {
    "& b": {
      fontSize: 15,
      color: grey[600],
    },
  },
  tblGeneral: {
    fontSize: 15,
    marginBottom: 20,
    paddingTop: 10
  },
  tdLeft: {
    width: 100,
  },
  addPaymentButton: {
    position: "absolute",
    bottom: 1,
    right: 1,
  },
  textField: {},
  inputLabel: {
    fontSize: 14,
  },
  feeAndPaymentHeadline: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    margin: 0,
  },
});

export default styles;
