import { red, grey, blue } from "@material-ui/core/colors";

const styles = (theme) => ({
  container: {},
  infoContainerMessage: {
    position: "relative",
    background: "white",
    overflowY: "scroll",
    width: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    // marginLeft: "15px",
    // marginRight: "15px",
    overflow: "visible",
    minWidth: "814px",
  },
  tabBar: {
    zIndex: 1,
    backgroundColor: grey[50],
  },
  tooltip: {
    maxWidth: "none",
  },
  details: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    padding: 5,
  },

  tblGeneral: {
    width: "100%",
    fontSize: 14,
    borderSpacing: 0,
    borderBottom: "1px solid #999",
    paddingBottom: 10,
    color: grey[800],
  },
  tdTop: {
    fontWeight: 600,
    paddingTop: 10,
    fontSize: 15,
  },

  tdTopPointer: {
    fontWeight: 600,
    paddingTop: 10,
    fontSize: 15,
  },
  tdRight: {
    width: 470,
  },
  tdOneLine: {
    paddingTop: 10,
    fontSize: 15,
  },

  collectionPartnerContainer: {
    cursor: "pointer",
    position: "relative",
  },
  collectionPartnerContainerNoEdit: {
    cursor: "defult",
    position: "relative",
  },

  historyContainer: {
    cursor: "pointer",
    position: "relative",
  },

  editIcon: {
    position: "absolute",
    right: 0,
    width: 16,
    marginTop: -5,
    color: blue[700],
  },

  historyIcon: {
    position: "absolute",
    right: 0,
    width: 16,
    marginTop: -5,
    color: blue[700],
  },

  imageContainer: {
    padding: 15,
    width: 310,
  },
  infoContainer: {
    backgroundColor: grey[100],
    padding: 15,
    width: "100%",
    borderLeft: "1px solid #cccccc",
  },
  buttonContainer: {
    position: "relative",
    padding: 15,
    width: 350,
    borderLeft: "1px solid #cccccc",
  },
  cardSectionTitle: {
    fontWeight: "bold",
    fontSize: 14,
    color: red[500],
  },
  middleSection: {
    overflow: "visible",
  },

  showMore: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },

  tblContainerHistory: {
    height: 300,
    overflow: "scroll",
  },
  tabTitle: {
    minWidth: 150,
  },
  historyTitle: {
    fontSize: 16,
  },

  tblContainerDetails: {
    height: 500,
    overflow: "scroll",
  },
  tblDetails: {
    border: "1px solid #EEE",
  },
  rowDeatils: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowDeatilsFrist: {
    // width: 20,
    // textAlign: "center"
    padding: "4px 6px 4px 24px",
  },
  rowDeatilsSecond: {
    "& b": {
      fontSize: 15,
      color: grey[600],
    },
  },
  nowrap: {
    display: "inline",
    whiteSpace: "nowrap",
  },
  caseTitle: {
    maxWidth: 220,
    textOverflow: "ellipsis",
    overflow: "hidden",
    Width: 180,
    whiteSpace: "nowrap",
  },
  messageTab: {
    display: "inline-flex",
    alignItems: "center",
  },
  unreadMessages: {
    borderRadius: "50%",
    width: "12px",
    height: "12px",
    padding: "7px",

    background: "#f50057",
    color: "#fff",
    textAlign: "center",

    fontSize: "15px",
    lineHeight: "15px",
    fontWeight: 600,

    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",

    marginLeft: "5px",
  },
});

export default styles;
