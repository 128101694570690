import { AUTH_USER, VALIDATE_USER, UNAUTH_USER, INVALIDATE_USER } from '@Types/actionTypes';

export function authenticate(){
  return {
    type : AUTH_USER,
    payload : true
  }
}

export function validate(){
  return {
    type : VALIDATE_USER,
    payload : true
  }
}

export function unAuthenticate(){
  return {
    type : UNAUTH_USER,
    payload : false
  }
}

export function inValidate(){
  return {
    type : INVALIDATE_USER,
    payload : false
  }
}