const Config = [
  
  {
    "id": 1,
    "name": "Full",
    "display": "_Full"
  },
  {
    "id": 2,
    "name": "Installment",
    "display": "_Installment"
  },
  {
    "id": 3,
    "name": "Partly",
    "display": "_Partly"
  }
]

export default Config;