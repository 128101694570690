import styles from "./styles";

import React, { Component } from "react";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { ListItemIcon, MenuItem, ListItemText } from "@material-ui/core";
import { bindActionCreators } from "redux";
import FilterIcon from "@material-ui/icons/BookmarkBorder";
import FilterIconSelected from "@material-ui/icons/Bookmark";
import { shortenString } from "@Utils/helpers";
import {
    updateCurrentFilterId,
    addFilter,
    removeFilter,
    updateCurrentFilterOriginalData,
    updateSaved,
    revertFilterData,
    initFilters,
    swapFilterOrder,
    updateIsNew,
    updateSearchFilterId,
  } from "@Actions/filterActions";
  import { showSnackbar } from "@Actions/snackbarActions";


class ListItem extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  handleFilterClicked = (id) => {
    this.filterToGo = id;
    if (this.props.filterReducer.currentFilterId !== id) {
      if (this.props.filterReducer.saved) {
        this.props.actionUpdateCurrentFilterId(this.filterToGo);
        this.props.actionUpdateCurrentFilterOriginalData(this.filterToGo);
        this.forceUpdate();
      } else {
        this.setState({ openSave: true });
      }
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <MenuItem
        className={classes.menuItem}
        key={this.props.filter.id}
        selected={
          this.props.filterReducer.currentFilterId === this.props.filter.id
            ? true
            : false
        }
        onClick={() => this.handleFilterClicked(this.props.filter.id)}
      >
        <ListItemIcon className={classes.icon}>
          {this.props.filterReducer.currentFilterId === this.props.filter.id ? (
            <FilterIconSelected color="primary" />
          ) : (
            <FilterIcon />
          )}
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.primary }}
          inset
          primary={shortenString(this.props.filter.name, 22)}
        />
        
      </MenuItem>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterReducer: state.filterReducer,
  };
}


function matchDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        actionUpdateCurrentFilterId: updateCurrentFilterId,
        actionAddFilter: addFilter,
        actionRemoveFilter: removeFilter,
        actionUpdateCurrentFilterOriginalData: updateCurrentFilterOriginalData,
        actionUpdateSaved: updateSaved,
        actionRevertFilterData: revertFilterData,
        actionUpdateIsNew: updateIsNew,
        actionUpdateSearchFilterId: updateSearchFilterId,
        actionShowSnackbar: showSnackbar,
        actionInitFilter: initFilters,
        actionSwapFilters: swapFilterOrder,
      },
      dispatch
    );
  }

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(translate("translations")(withStyles(styles)(ListItem)));
