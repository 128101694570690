import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// import Logger from "redux-logger";
import thunk from 'redux-thunk';

import { loadingBarReducer } from 'react-redux-loading-bar';

import authReducer from '@Reducers/authReducer';
import domainsReducer from '@Reducers/domainsReducer';
import casesReducer from '@Reducers/casesReducer';
import findingsReducer from '@Reducers/findingsReducer';
import mediaReducer from '@Reducers/mediaReducer';
import logsReducer from '@Reducers/logsReducer';
import notifyReducer from '@Reducers/notifyReducer';
import toolbarReducer from '@Reducers/toolbarReducer';
import filterReducer from '@Reducers/filterReducer';
import navbarReducer from '@Reducers/navbarReducer';
import searchReducer from '@Reducers/searchReducer';
import tagsReducer from '@Reducers/tagsReducer';
import snackbarReducer from '@Reducers/snackbarReducer';
import globalReducer from '@Reducers/globalReducer';
import caseMessagesReducer from '@Reducers/caseMessagesReducer';
import caseTodoReducer from '@Reducers/caseTodoReducer';

const appRreducer = combineReducers({
  loadingBar: loadingBarReducer,
  authReducer,
  domainsReducer,
  casesReducer,
  findingsReducer,
  mediaReducer,
  logsReducer,
  notifyReducer,
  toolbarReducer,
  filterReducer,
  navbarReducer,
  searchReducer,
  tagsReducer,
  snackbarReducer,
  globalReducer,
  caseMessagesReducer,
  caseTodoReducer
});

const store = createStore(
  appRreducer,
  {},
  composeWithDevTools(
    applyMiddleware(
      // Logger,
      thunk
    )
  )
);

export default store;
