import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { showSnackbar } from "@Actions/snackbarActions";
import { getCaseTodos } from "@Actions/caseTodosActions";
import Button from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";

import CheckBox from "@material-ui/icons/CheckBoxOutlined";

import caseService from "@Services/Cases";
import CaseTodoMenu from "./CaseTodoMenu";

class CaseTodosBadge extends Component {
  constructor(props) {
    super();
    this.state = {
      menuOpen: false,
      anchorEl: null,
    };
  }

  componentDidMount = () => {
    // get data and update reducer
    caseService
      .getCaseTodos()
      .then((response) => {
        // update reducer
        this.props.actionGetCaseTodos(response);
      })
      .catch((err) => {
        //log and call snackbar
        this.props.actionShowSnackbar({
          message: err.message || this.props.t("_failed_to_load_case_todos"),
          variant: "error",
        });
        console.log(err);
      });
  };

  handleCloseMenu = () => {
    this.setState({ menuOpen: false });
  };

  handleOpenMenu = (event) => {
    this.setState({ menuOpen: true, anchorEl: event.currentTarget });
  };

  render() {
    const { classes, caseTodoReducer, t } = this.props;
    const { anchorEl } = this.state;
    return (
      <>
        <div className={classes.root}>
          <Button
            title={t("_case_todos")}
            onClick={(ev) => this.handleOpenMenu(ev)}
            className={classes.button}
          >
            <CheckBox className={classes.mainIcon}></CheckBox>
            <div
              style={{
                visibility:
                  caseTodoReducer.todos.length === 0 ? "hidden" : "inherit",
              }}
              className={classes.number}
            >
              {caseTodoReducer.todos.length}
            </div>
          </Button>
        </div>

        <Menu
          id="case-todo-menu"
          className={classes.menu}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.menuOpen}
          onClose={this.handleCloseMenu}
          disableAutoFocusItem
          PaperProps={{
            style: { border: "2px solid #e8e8e8", borderRadius: "0px" },
          }}
          MenuListProps={{ disablePadding: true }}
        >
          <CaseTodoMenu onClose={this.handleCloseMenu} />
        </Menu>
      </>
    );
  }
}

CaseTodosBadge.propTypes = {
  classes: PropTypes.object.isRequired,
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionShowSnackbar: showSnackbar,
      actionGetCaseTodos: getCaseTodos,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    caseTodoReducer: state.caseTodoReducer,
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(CaseTodosBadge)));
