import { SET_NAVBAR1, SET_NAVBAR2, SET_NAVBAR3 } from '@Types/actionTypes';

const navbarReducer = 
(state = 
    {
      navbar1: { name: "Home" , link: "/domains"}, 
      navbar2: null, 
      navbar3: null
    }, 
 action) => {

  switch (action.type) {
    
    case SET_NAVBAR1:
      state = {...state, navbar1: action.payload};
      break; 
    
    case SET_NAVBAR2:
      state = {...state, navbar2: action.payload};
      break; 

    case SET_NAVBAR3:
      state = {...state, navbar3: action.payload};
      break; 

    default:
      break;
  }
  
  return state;
};

export default navbarReducer;