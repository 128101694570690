import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import MoveToNewButton from './MoveToNewButton';
import MoveToTrashButton from './MoveToTrashButton';
import MoveToLegalButton from './MoveToLegalButton';
import MoveBackToCASE from './MoveBackToCaseButton';
import ActionsButton from './ActionsButton';
import FindingFees from './FindingFees';

import WarningComponent from './WarningComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import styles from './styles';
import Config from '@Configs/findingButtonsConfig';

class FindingActions extends Component {
  constructor(props) {
    super();
  }

  // var data = {
  //   id : key,
  //   type : CASE,
  //   caseId : caseId
  // };
  buttons = {
    ACTION_BUTTON: ActionsButton,
    MOVE_TO_TRASH_BUTTON: MoveToTrashButton,
    MOVE_TO_LEGAL_BUTTON: MoveToLegalButton,
    MOVE_TO_NEW_BUTTON: MoveToNewButton,
  };

  pupulateButtons() {
    const { data, caseId, status } = this.props;
    const buttons = this.buttons;
    var list = [];
    // this means that it is domain
    if (caseId == null) {
      _.forEach(Config[data.type], function (btn, i) {
        const MyBtn = buttons[i];
        list.push(<MyBtn key={i + '_' + data.id} data={data} />);
      });
    } else {
      // this is case panel
      if (data.statusChangeReason) {
        return (
          <>
            <WarningComponent
              message={data.statusChangeReason}
              {...this.props}
            />
            <MoveBackToCASE data={data} />
          </>
        );
      } else {
        if (
          status === 1 ||
          status === 3 ||
          status === 4 ||
          status === 5 ||
          status === '1' ||
          status === '3' ||
          status === '4' ||
          status === '5'
        ) {
          return (
            <FindingFees
              status={status}
              caseId={caseId}
              data={data}
            ></FindingFees>
          );
        } else {
          return <></>;
        }
      }
    }

    return list;
  }
  render() {
    const { classes } = this.props;

    return <div className={classes.root}>{this.pupulateButtons()}</div>;
  }
}

FindingActions.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    // data: {
    //   ...ownProps.data,
    //   statusChangeReason: state.findingsReducer.cases[ownProps.caseId],
    // },
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withStyles(styles)(
  translate('translations')(
    connect(mapStateToProps, matchDispatchToProps)(FindingActions)
  )
);
