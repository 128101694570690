import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogPopup from "@Components/DialogPopup";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DomainInfoEditor from "./DomainInfoEditor";
import LoadingBg from "@Components/LoadingBg";
import styles from "./styles";
import { translate } from "react-i18next";
import { isNull, formatDatetime } from "@Utils/helpers.js";
import _ from "lodash";

class CaseDomainInfo extends Component {
  constructor(props) {
    super();
  }
  state = {
    editDomainInfoPopupOpen: false,
  };

  handleEditDomainInfoPopupOpen = () => {
    this.setState({ editDomainInfoPopupOpen: true });
  };
  handleEditDomainInfoPopupClose = () => {
    this.setState({ editDomainInfoPopupOpen: false });
  };
  handleDomainInfoUpdated = () => {
    this.props.onDomainInformationUpdated();
  };

  render() {
    const { classes, data, caseState, t } = this.props;
    if (this.props.data == null) {
      return (
        <div>
          <LoadingBg
            pageType="general"
            size="small"
            animated={false}
            message={t("_failed_to_load_resources") + "!"}
          />
        </div>
      );
    }

    var address = data.CaseDomainInfo_address;
    var contactPerson = data.CaseDomainInfo_contactPerson;
    return (
      <div className={classes.root}>
        <table className={classes.tblGeneral}>
          <tbody>
            <tr style={{ verticalAlign: "top" }}>
              <td>
                <tr>
                  <td className={classes.trLeft}>
                    {t("_contact_details_page")}
                  </td>
                </tr>
                <tr>
                  <td className={classes.trRight}>
                    {data.imprintUrl ? data.imprintUrl : "--"}
                  </td>
                </tr>
              </td>
              <td>
                <tr>
                  <td className={classes.trLeft}>{t("_last_investigated")}</td>
                </tr>
                <tr>
                  <td className={classes.trRight}>
                    {formatDatetime(data.lastReachedAt, "medium-short")}
                  </td>
                </tr>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <hr></hr>
              </td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <td>
                {isNull(address) || _.isEmpty(address) ? (
                  <></>
                ) : (
                  <>
                    <td>
                      <tr>
                        <td className={classes.trLeft}>{t("_company_name")}</td>
                      </tr>
                      <tr>
                        <td className={classes.trRight}>
                          {address.name ? address.name : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.trLeft}>{t("_address1")}</td>
                      </tr>
                      <tr>
                        <td className={classes.trRight}>
                          {address.addressLine1 ? address.addressLine1 : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.trLeft}>{t("_address2")}</td>
                      </tr>
                      <tr>
                        <td className={classes.trRight}>
                          {address.addressLine2 ? address.addressLine2 : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.trLeft}>{t("_postal")}</td>
                      </tr>
                      <tr>
                        <td className={classes.trRight}>
                          {address.postalCode ? address.postalCode : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.trLeft}>{t("_city")}</td>
                      </tr>
                      <tr>
                        <td className={classes.trRight}>
                          {address.city ? address.city : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.trLeft}>{t("_country")}</td>
                      </tr>
                      <tr>
                        <td className={classes.trRight}>
                          {address.country ? address.country : "--"}
                        </td>
                      </tr>
                    </td>
                  </>
                )}
              </td>
              <td>
                {isNull(contactPerson) || _.isEmpty(contactPerson) ? (
                  <></>
                ) : (
                  <>
                    <tr>
                      <td className={classes.trLeft}>{t("_contact_person")}</td>
                    </tr>
                    <tr>
                      <td className={classes.trRight}>
                        {!contactPerson.firstName && !contactPerson.lastName
                          ? "--"
                          : contactPerson.firstName +
                            " " +
                            contactPerson.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.trLeft}>{t("_email")}</td>
                    </tr>
                    <tr>
                      <td className={classes.trRight}>
                        {contactPerson.emailAddress
                          ? contactPerson.emailAddress
                          : "--"}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.trLeft}>{t("_phone")}</td>
                    </tr>
                    <tr>
                      <td className={classes.trRight}>
                        {contactPerson.phone ? contactPerson.phone : "--"}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.trLeft}>{t("_mobile")}</td>
                    </tr>
                    <tr>
                      <td className={classes.trRight}>
                        {contactPerson.mobile ? contactPerson.mobile : "--"}
                      </td>
                    </tr>
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {
          //draft or review
          (caseState === 3 || caseState === 1) 
          && ["307164E4-E823-41B8-B555-1811B55A1783",
            "5391878B-10E3-4626-B154-188606F2DE80",
            "BD0C9C33-2658-43DC-8C2F-1B8DE25BB0DE",
            "494DAFF1-299E-48E0-94F7-1D389339C272",
            "DA2CA510-7E81-44B5-8EF4-4FA5164BD320",
            "35E282D8-9167-43FF-AAE4-678F9D6EA787",
            "F55F9C6F-9169-4CF1-9C0C-7C5F749F5D73",
            "57550F35-86DD-4E89-8EDB-8705EBB932C5",
            "A05FF2CB-6954-4247-B5E7-8C30AC03BF1E",
            "26008010-0EC9-4D99-9444-94BBAFB72D38",
            "2F8D97D9-E907-497D-88E3-95064D9E5C39",
            "68D56E49-8980-4883-B812-AAE3BF50795C",
            "0B7B1456-D70F-4A50-82B3-B7512900127C",
            "6A5722EC-FFB3-41E9-A2B4-BAB5901BC71E",
            "E298C05F-C651-4315-9612-CDE7CBF23132",
            "B2890770-7D77-4276-869A-D7450B3A4A4C",
            "A62EEC7C-47FA-4AEB-9B90-FBD306A7444F"].indexOf(localStorage.getItem("CustomerID").toUpperCase()) >= 0 
          && (
            <div>
              <Button
                onClick={this.handleEditDomainInfoPopupOpen}
                color="primary"
                className={classes.editButton}
              >
                {t("_edit_domain_infos")}
              </Button>
            </div>
          )
        }

        <DialogPopup
          open={this.state.editDomainInfoPopupOpen}
          onClose={this.handleEditDomainInfoPopupClose}
        >
          <DialogTitle>{t("_domain_info_editor")}</DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <DomainInfoEditor
              data={data}
              caseId={this.props.caseId}
              onClose={this.handleEditDomainInfoPopupClose}
              onDomainInformationUpdated={this.handleDomainInfoUpdated}
            />
          </DialogContent>
        </DialogPopup>
      </div>
    );
  }
}

CaseDomainInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(CaseDomainInfo));
