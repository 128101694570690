const Config = {

  'log_type_1':{
    id: 1,
    name: 'domain_whitelisted',
    display: 'domain_whitelisted',
    title: 'Domain whitelisted',
    goto: 'domain'
  },
  'log_type_2':{
    id: 2,
    name: 'domain_set_as_legal',
    display: 'domain_set_as_legal',
    title: 'Domain set as legal',
    goto: 'domain'
  },
  'log_type_3':{
    id: 3,
    name: 'case_created',
    display: 'case_created',
    title: 'Case created',
    goto: 'case'
  },
  'log_type_4':{
    id: 4,
    name: 'case_state_changed',
    display: 'case_state_changed',
    title: 'Case state changed',
    goto: 'case'
  },
  'log_type_5':{
    id: 5,
    name: 'case_payment_entered',
    display: 'case_paid',
    title: 'Case payment entered',
    goto: 'case'
  },
  'log_type_6':{
    id: 6,
    name: 'case_fees_changed',
    display: 'case_fees_changed',
    title: 'Case fees changed',
    goto: 'case'
  },
  'log_type_7':{
    id: 7,
    name: 'case_document_uploaded',
    display: 'case_document_uploaded',
    title: 'Case documents updated',
    goto: 'case'
  },
  'log_type_8':{
    id: 8,
    name: 'media_uploaded',
    display: 'image_uploaded',
    title: 'New media added',
    goto: 'media'
  },
  'log_type_9':{
    id: 9,
    name: 'finding_deleted',
    display: 'finding_deleted',
    title: 'Finding deleted',
    goto: 'domain'
  },
  'log_type_10':{
    id: 10,
    name: 'domain_removed_from_whitelist',
    display: 'domain_removed_from_whitelist',
    title: 'Domain removed from whitelist',
    goto: 'domain'
  }
};

export default Config;
