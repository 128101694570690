const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
    marginLeft:20
    // margin: "15px 10px",
  },
  button:{
    textAlign:"left",
    minWidth:200,
    maxWidth:200,
    width:"100%",
    color: '#616161',
    padding:"3px 10px 3px 3px",
    margin:"0 15px"
  },
  avatar: {
    borderRadius: 100, 
    width: 38,
    height: 38,
  },

  infoContainer:{
    textAlign: "left",
    paddingLeft:10
  },
  avatarName:{
    fontSize:10,
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth:135,
    textTransform: "initial"
  },
  avatarDetails:{
    fontSize:10,
    overflow: "hidden",
    whiteSpace: "nowrap",
    maxWidth:135,
    textOverflow:"ellipsis",
    textTransform: "initial"
  }
});


export default styles;
