import {
  GET_CASE_MESSAGES,
  ADD_CASE_MESSAGE,
  MARK_MESSAGE_AS_READ,
} from '@Types/actionTypes';

const defaultState = {
  messages: {},
};
const caseMessagesReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CASE_MESSAGES:
      state = {
        ...state,
        messages: {
          ...state.messages,
          [action.payload.caseId]: action.payload.messages,
        },
      };
      break;
    case ADD_CASE_MESSAGE:
      state = {
        ...state,
      };
      break;

    case MARK_MESSAGE_AS_READ:
      state = {
        ...state,
      };
      break;

    default:
      break;
  }
  return state;
};

export default caseMessagesReducer;
