const Config = {
  0:{
    id:0,
    name: 'None',
    serverName: 'None', // SearchTargetStateNone
    displayName: 'pending',
    display: true
  },
  1:{
    id: 1,
    name: 'Uploaded',
    serverName: 'Uploaded', // SearchTargetStateUploaded
    displayName: 'pending',
    display: true
  },
  2:{
    id: 2,
    name: 'Added for Processing',
    serverName: 'AddProcessing', // SearchTargetStateUploadedQueued
    displayName: 'pending',
    display: true
  },
  3:{
    id: 3,
    name: 'Added',
    serverName: 'Added', // SearchTargetStateTreeAdded
    displayName: 'searching',
    display: true
  },
  4:{
    id: 4,
    name: 'Deleted',
    serverName: 'Deleted', // SearchTargetStateDeleted
    displayName: 'deleting',
    display: false
  },
  5:{
    id: 5,
    name: 'Not Searchable',
    serverName: 'NotSearchable', // SearchTargetStateNotAddable 
    displayName: 'failed',
    display: true
  },
  6:{
    id: 6,
    name: 'To be Delete',
    serverName: 'ToDelete', // SearchTargetStateDeletionInitialized
    displayName: 'deleting',
    display: true
  },
  7:{
    id: 7,
    name: 'Deletion in Process',
    serverName: 'DeletionProcessing', // SearchTargetStateDeletionProcessing
    displayName: 'deleting',
    display: true
  },
  8:{
    id: 8,
    name: 'Remove from Search',
    serverName: 'RemoveFromSearch', // SearchTargetStateDoNotSearchInitiated
    displayName: 'paused',
    display: true
  },
  9:{
    id: 9,
    name: 'Processing to Remove from Search',
    serverName: 'RemoveFromSearchProcessing', // SearchTargetStateDoNotSearchProcessing
    displayName: 'paused',
    display: true
  },
  10:{
    id: 10,
    name: 'Not Searched',
    serverName: 'NotSearched', // SearchTargetStateDoNotSearch
    displayName: 'paused',
    display: true
  },
  11:{
    id: 11,
    name: 'Hidden',
    serverName: 'Hidden', // SearchTargetStateHidden
    displayName: 'deleting',
    display: false
  },
  12:{
    id: 12,
    name: 'Add Error',
    serverName: 'AddError', // Set on upload failed
    displayName: 'failed',
    display: true
  },
  13:{
    id: 13,
    name: 'Remove Error',
    serverName: 'RemoveError',
    displayName: 'failed',
    display: true
  },
  14:{
    id: 14,
    name: 'Delete Error',
    serverName: 'DeleteError',
    displayName: 'failed',
    display: true
  },
  15:{
    id: 15,
    name: 'Add Internaly',
    serverName: 'InternalAdd',
    displayName: 'pending',
    display: false
  },
  16:{
    id: 16,
    name: 'Processing to Add Internaly',
    serverName: 'InternalAddProcessing',
    displayName: 'pending',
    display: false
  },
  17:{
    id: 17,
    name: 'Internaly Added',
    serverName: 'InternalAdded',
    displayName: 'searching',
    display: false
  },
  18:{
    id: 18,
    name: 'Internal Add Error',
    serverName: 'InternalAddError',
    displayName: 'failed',
    display: false
  },
  19:{
    id: 19,
    name: 'Internal Delete',
    serverName: 'InternalDelete',
    displayName: 'deleting',
    display: false
  },
  20:{
    id: 20,
    name: 'Internal Delete Processing',
    serverName: 'InternalDeleteProcessing',
    displayName: 'deleting',
    display: false
  },
  21:{
    id: 21,
    name: 'Internal Deleted',
    serverName: 'InternalDeleted',
    displayName: 'deleting',
    display: false
  },
  22:{
    id: 22,
    name: 'Internal Delete Error',
    serverName: 'InternalDeleteError',
    displayName: 'failed',
    display: false
  },
  23:{
    id: 23,
    name: 'To Hide',
    serverName: 'ToHide',
    displayName: 'paused',
    display: false
  },
  24:{
    id: 24,
    name: 'Hiding Processing',
    serverName: 'HidingProcessing',
    displayName: 'paused',
    display: false
  },
  25:{
    id: 25,
    name: 'Hide Error',
    serverName: 'HideError',
    displayName: 'failed',
    display: false
  },
  26:{
    id: 26,
    name: 'Internal Rescale',
    serverName: 'InternalRescale',
    displayName: 'pending',
    display: false
  },
  27:{
    id: 27,
    name: 'Internal Rescale Done',
    serverName: 'InternalRescaleDone',
    displayName: 'pending',
    display: false
  },
  28:{
    id: 28,
    name: 'Internal Rescale Error',
    serverName: 'InternalRescaleError',
    displayName: 'pending',
    display: false
  },
  100:{
    id: 100,
    name: 'Final Deletion Failed',
    serverName: 'FinalDeletionFailed',
    displayName: 'deleting',
    display: false
  }
};

export default Config;