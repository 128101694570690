import { SET_TOOLBAR } from '@Types/actionTypes';

const toolbarReducer = (state = false, action) => {

  switch (action.type) {
    
    case SET_TOOLBAR:
      state = action.payload;
      break; 

    default:
      break;
  }
  
  return state;
};

export default toolbarReducer;