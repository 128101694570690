import { translate } from "react-i18next";
import { connect } from "react-redux";
import React from "react";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { transitionUp } from "@Utils/helpers.js";
import UserService from "@Services/User";

import styles from "./styles";
import { Redirect } from "react-router-dom";

const {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Button,
} = require("@material-ui/core");
const {
  VisibilityOff,
  Visibility,
  CheckCircleOutline,
} = require("@material-ui/icons");
const { Component } = require("react");

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.passInput = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      error_mesage: null,

      password: "",
      password_check: "",
      showPassword: false,

      idValidated: false,
      tempToken: "",
      userid: "",
      passwords_matching: false,
      redirect: false,
    };
  }

  componentDidMount = () => {
    // check if given id is value
    var id =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    
    // if id is empty, redirect to login
    if (id === "") this.setState({ redirect: true });
    else
      UserService.validateResetPasswordID(id)
        .then((resp) => {
          this.setState({
            idValidated: true,
            tempToken: resp.token,
            userid: resp.userid,
          });
        })
        .catch((err, response) => {
          // check error code
          //404 not found --> token not found
          if (err && err.response && err.response.data) {
            if (err.response.data.code === 404) {
              this.setState({
                error_mesage: this.props.t("_invalid_pw_reset_token"),
              });
            }
            //403 foorbidden --> token found but not valid any more
            if (err.response.data.code === 403) {
              this.setState({
                error_mesage: this.props.t("_outdated_pw_reset_token"),
              });
            } //500 error
            if (err.response.data.code === 500) {
              this.setState({
                error_mesage: this.props.t(
                  "_something_went_wrong_please_try_again_later"
                ),
              });
            }
          } else {
            this.setState({
              error_mesage: this.props.t(
                "_something_went_wrong_please_try_again_later"
              ),
            });
          }
        });
  };

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value }, () => {
      if (
        this.state.password !== "" &&
        this.state.password === this.state.password_check &&
        this.state.password.length >= 4
      )
        this.setState({ passwords_matching: true });
      else this.setState({ passwords_matching: false });
    });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleClickShowPasssword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleKeyPress = (event, inputName) => {
    if (event.key === "Enter") {
      if (inputName === "username") {
        this.passInput.current.focus();
      } else {
        this.handleClick(transitionUp);
      }
    }
  };

  handleClick = () => {
    UserService.setNewPassword(this.state.userid,this.state.tempToken, this.state.password)
      .then((resp) => {
        this.setState({ redirect: true });
      })
      .catch((err) => {
        console.log("Set new password failed:", err);
        this.setState({ error_mesage: this.props.t("_set_password_failed") });
      });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <>
        {this.state.error_mesage ? (
          <>
            <Card className={classes.card}>
              <div className={classes.cardImageContainer}>
                <img
                  className={classes.imgStyle}
                  src={require("@Assets/images/logo.png")}
                  alt=""
                />
              </div>

              <CardContent className={classes.cardContentError}>
                {this.state.error_mesage}
              </CardContent>
            </Card>
          </>
        ) : (
          <>
            {this.state.redirect ? (
              <>
                <Redirect
                  to={{
                    pathname: "/login",
                    state: {
                      from: this.props.location,
                    },
                  }}
                ></Redirect>
              </>
            ) : (
              <>
                {" "}
                <Card className={classes.card}>
                  <div className={classes.cardImageContainer}>
                    <img
                      className={classes.imgStyle}
                      src={require("@Assets/images/logo.png")}
                      alt=""
                    />
                  </div>

                  <CardContent className={classes.cardContent}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="password">
                        {t("_new_password")}
                      </InputLabel>
                      <Input
                        id="password"
                        inputRef={this.passInput}
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password}
                        onChange={this.handleChange("password")}
                        onKeyPress={(e) => this.handleKeyPress(e, "password")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={this.handleClickShowPasssword}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="password_check">
                        {t("_password_check")}
                      </InputLabel>
                      <Input
                        id="password_check"
                        inputRef={this.passInput}
                        type={this.state.showPassword ? "text" : "password"}
                        value={this.state.password_check}
                        onChange={this.handleChange("password_check")}
                        onKeyPress={(e) =>
                          this.handleKeyPress(e, "password_check")
                        }
                        endAdornment={
                          <>
                            {" "}
                            {this.state.passwords_matching ? (
                              <CheckCircleOutline
                                className={classes.check_ok}
                                color="black"
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        }
                      />
                    </FormControl>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleClick}
                      disabled={!this.state.passwords_matching}
                    >
                      {t("_save")}
                    </Button>
                  </CardContent>
                </Card>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    authReducer: state.authReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(ResetPassword)
  )
);
