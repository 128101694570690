const Config = [
    {
      id: 1,
      value: '1',
      name: 'not set',
      display: 'not_set'
    },
    {
      id: 2,
      value: '2',
      name: 'collection partner',
      display: 'collection_partner'
    },
    {
      id: 3,
      value: '3',
      name: 'sales team',
      display: 'sales_team'
    }
  ];
  
  export default Config;