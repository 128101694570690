const Config = {
  'domain_status_1':{
    id: 1,
    color: 'blue',
    display: 'action_required',
    name: 'action_required'
  },
  'domain_status_2':{
    id: 2,
    color: 'lightGreen',
    display: 'legal',
    name: 'legal'
  },
  'domain_status_3':{
    id: 3,
    color: 'lightGreen',
    display: 'domain_removed_from_search',
    name: 'domain_removed_from_search'
  },

  'case_status_1':{
    id: 1,
    color: 'blue',
    display: 'draft',
    name: 'draft'
  },
  'case_status_2':{
    id: 2,
    color: 'lightGreen',
    display: 'in_research',
    name: 'in_research'
  },
  'case_status_3':{
    id: 3,
    color: 'green',
    display: 'review',
    name: 'review'
  },
  'case_status_4':{
    id: 4,
    color: 'orange',
    display: 'activation_pending',
    name: 'pursuit'
  },
  'case_status_5':{
    id: 5,
    color: 'orange',
    display: 'active_claim',
    name: 'active_claim'
  },
  'case_status_10':{
    id: 10,
    color: 'purple',
    display: 'closed',
    name: 'closed'
  },
  'case_status_11':{
    id: 11,
    color: 'red',
    display: 'canceled',
    name: 'aborted'
  },

};

export default Config;