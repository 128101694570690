import { red } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {},
  input: {
    display: "none",
  },
  uploadButton: {
    textTransform: "initial",
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent",
    },
    "&:disabled": {
      border: "1px solid #2680eb2e",
    },
  },
  closeButton: {
    textTransform: "initial",
    color: "#f50057",
    backgroundColor: "#fff",
    border: "1px solid #f50057",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#f50057",
      border: "1px solid transparent",
    },
    marginRight: 15,
  },
  menuButton: {
    textTransform: "initial",
    color: "#464646",
    backgroundColor: "#fff",
    border: "1px solid #464646",
    right: 15,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#464646",
      border: "1px solid transparent",
    },
  },
  selectButton: {
    float: "right",
    marginRight: 20,
  },
  selectTitle: {
    paddingTop: 7,
    fontSize: 18,
    fontWeight: 400,
    display: "inline-flex",
    verticalAlign: "middle",
    "& p": {
      margin: 0,
      padding: 0,
      paddingLeft: 20,
      paddingTop: 3,
    },
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  gridRoot: {
    marginTop: 20,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: 880,
    padding: 10,
    marginBottom: 20,
    maxHeight: "calc(100vh - 170px)",
    boxSizing: "border-box",
  },
  icon: {
    color: red[700],
  },

  tileBarRoot: {
    height: 50,
    paddingBottom: 5,
  },
  tileBarTitle: {
    fontSize: 11,
  },
  tileBarSubtitle: {
    fontSize: 10,
  },

  gridTitle: {
    paddingTop: "10px !important",
    marginBottom: "15px !important",
    height: "55px !important",
  },
  gridTitleText: {
    fontSize: 16,
  },

  tagListRoot: {
    flexGrow: 1,
    width: 500,
    overflow: "visible",
    minHeight: 50,
    // backgroundColor:grey[50],
  },
  contentRoot: {
    overflow: "visible",
  },
  uploadStatus: {
    textAlign: "center",
  },
});

export default styles;
