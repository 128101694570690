import request from "@Utils/api";
import api from "@Configs/apiConfig";
import base64 from "base-64";

function authenticate(username, password) {
  const token = base64.encode(username + ":" + password);

  return request({
    url: api.loginServer + "/login",
    // url: api.loginServer + '/login.json',
    method: "POST",
    // method: 'GET',
    data: token,
    headers: {
      "Content-Type": "text/plain",
    },
  });
}

function getImpersonationToken(targetUserMail) {
  return request({
    url: api.loginServer + "/impersonate",
    method: "POST",
    data: { impersonationTarget: targetUserMail },
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

const Login = {
  authenticate,
  getImpersonationToken,
};

export default Login;
