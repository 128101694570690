import {
  INIT_FILTERS,
  INIT_SUBFILTERS,
  ADD_FILTER,
  REMOVE_FILTER,
  UPDATE_FILTER,
  UPDATE_FILTER_NAME,
  UPDATE_FILTER_ALLORANY,
  UPDATE_FILTER_ISPRIVATE,
  ADD_FILTER_RULE,
  REMOVE_FILTER_RULE,
  UPDATE_FITER_RULE_SUBFILTER,
  UPDATE_FITER_RULE_OPERATION,
  UPDATE_FITER_RULE_VALUE,
  INIT_CURRENT_FILTER_ID,
  UPDATE_CURRENT_FILTER_ID,
  UPDATE_SEARCH_FILTER_ID,
  INIT_CURRENT_FILTER_TYPE,
  UPDATE_CURRENT_FILTER_OIGINAL_DATA,
  REVERT_FILTER_DATA,
  UPDATE_SAVED,
  UPDATE_ISNEW,
  SWAP_FILTER_ORDER,
  UPDATE_FILTER_COUNT,
  REORDER_FILTERS,
} from "@Types/actionTypes";
import { getSelectedFilters } from "@Utils/helpers";

const filterReducer = (
  state = {
    filters: null,
    subfilters: null,
    currentFilterId: null,
    currentFilterType: null,
    currentFilterOriginalData: null,
    searchFilterId: "0",
    saved: true,
    isNew: false,
  },
  action
) => {
  switch (action.type) {
    case INIT_FILTERS:
      state.filters = action.payload;
      break;

    case INIT_SUBFILTERS:
      state.subfilters = action.payload;
      break;

    case ADD_FILTER:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.id]: action.payload,
        },
      };
      break;

    case REMOVE_FILTER:
      var newFiltersListRemove = state.filters;
      delete newFiltersListRemove[action.payload.filterId];
      state = { ...state, filters: newFiltersListRemove, currentFilterId: "0" };
      // check if we have to remove the filter from local storage

      if (localStorage.getItem("SelectedFilters")) {
        let storageObject = JSON.parse(localStorage.getItem("SelectedFilters"));
        if (
          storageObject.domain.toLowerCase() ===
          action.payload.filterId.toLowerCase()
        ) {
          storageObject.domain = "0";
          localStorage.setItem(
            "SelectedFilters",
            JSON.stringify(storageObject)
          );
        }
        if (
          storageObject.case.toLowerCase() ===
          action.payload.filterId.toLowerCase()
        ) {
          storageObject.case = "0";
          localStorage.setItem(
            "SelectedFilters",
            JSON.stringify(storageObject)
          );
        }
        if (
          storageObject.media.toLowerCase() ===
          action.payload.filterId.toLowerCase()
        ) {
          storageObject.media = "0";
          localStorage.setItem(
            "SelectedFilters",
            JSON.stringify(storageObject)
          );
        }
      }
      break;

    case UPDATE_CURRENT_FILTER_OIGINAL_DATA:
      // filterid
      state = {
        ...state,
        currentFilterOriginalData: state.filters[action.payload],
      };
      break;

    case REVERT_FILTER_DATA:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [state.currentFilterOriginalData.id]: state.currentFilterOriginalData,
        },
      };
      break;

    case UPDATE_FILTER:
      state.filters = action.payload;
      break;

    case UPDATE_FILTER_NAME:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            name: action.payload.name,
          },
        },
      };
      break;

    case UPDATE_FILTER_ALLORANY:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            allorany: action.payload.allorany,
          },
        },
      };
      break;

    case UPDATE_FILTER_ISPRIVATE:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            isPrivate: action.payload.isPrivate,
          },
        },
      };
      break;

    case UPDATE_SAVED:
      state = { ...state, saved: action.payload };
      break;

    case UPDATE_ISNEW:
      state = { ...state, isNew: action.payload };
      break;

    case ADD_FILTER_RULE:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            rules: {
              ...state.filters[action.payload.filterId].rules,
              [action.payload.filterRuleData.id]: action.payload.filterRuleData,
            },
          },
        },
      };
      break;

    case REMOVE_FILTER_RULE:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            rules: {
              ...state.filters[action.payload.filterId].rules,
              [action.payload.filterRuleId]: null,
            },
          },
        },
      };

      delete state.filters[action.payload.filterId].rules[
        action.payload.filterRuleId
      ];
      break;

    case UPDATE_FITER_RULE_SUBFILTER:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            rules: {
              ...state.filters[action.payload.filterId].rules,
              [action.payload.filterRuleId]: {
                ...state.filters[action.payload.filterId].rules[
                  action.payload.filterRuleId
                ],
                subfilterId: action.payload.subfilterId,
              },
            },
          },
        },
      };
      break;

    case UPDATE_FITER_RULE_OPERATION:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            rules: {
              ...state.filters[action.payload.filterId].rules,
              [action.payload.filterRuleId]: {
                ...state.filters[action.payload.filterId].rules[
                  action.payload.filterRuleId
                ],
                operationId: action.payload.operationId,
              },
            },
          },
        },
      };
      break;

    case UPDATE_FITER_RULE_VALUE:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterId]: {
            ...state.filters[action.payload.filterId],
            rules: {
              ...state.filters[action.payload.filterId].rules,
              [action.payload.filterRuleId]: {
                ...state.filters[action.payload.filterId].rules[
                  action.payload.filterRuleId
                ],
                ruleValue: action.payload.ruleValue,
              },
            },
          },
        },
      };
      break;

    case INIT_CURRENT_FILTER_ID:
      var value = getSelectedFilters()[state.currentFilterType];
      state = {
        ...state,
        currentFilterId: value,
        currentFilterOriginalData: state.filters[value],
      };
      break;

    case UPDATE_CURRENT_FILTER_ID:
      state = { ...state, currentFilterId: action.payload };
      break;

    case UPDATE_SEARCH_FILTER_ID:
      state = { ...state, searchFilterId: action.payload };
      break;

    case INIT_CURRENT_FILTER_TYPE:
      state = { ...state, currentFilterType: action.payload };
      break;

    case UPDATE_FILTER_COUNT:
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.id]: {
            ...state.filters[action.payload.id],
            resultCount: action.payload.count,
            resultCountLastUpdate: new Date().getTime().toString(),
          },
        },
      };
      break;
    case REORDER_FILTERS:
      action.payload.sort((a, b) => {
        return a.orderValue - b.orderValue;
      });
      for (const item of action.payload) {
        var toSet = state.filters[item.id];
        toSet.orderValue = item.orderValue;
        state = {
          ...state,
          filters: {
            ...state.filters,
            [item.id]: toSet,
          },
        };
      }
      break;
    case SWAP_FILTER_ORDER:
      var new1 = state.filters[action.payload.id2];
      var new2 = state.filters[action.payload.id1];
      new1.orderValue = state.filters[action.payload.id1].orderValue;
      new2.orderValue = state.filters[action.payload.id2].orderValue;
      state = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.id1]: new1,
          [action.payload.id2]: new2,
        },
      };
      break;
    default:
      break;
  }

  return state;
};

export default filterReducer;
