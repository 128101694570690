import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,

  },
  iframeContainer: {
    textAlign: 'center'
  },
  tblGeneral: {
    width: '100%',
    fontSize: 11,
    borderSpacing: 0,
    // borderBottom: '1px solid #999',
    paddingBottom: 10,
    color: grey[800]
  },
  trLeft: {
    fontWeight: 600,
    fontSize: 12,
  },
  editFeesButton: {
    float: 'right',
    marginTop: 20
  },
  editClaim: {
    display: "table-cell",
    width: 16,
    color: grey[800]
  },
  editContainer:
  {
    display: "table",
    cursor: "pointer"
  },
  actionSection: {
    paddingBottom: 10
  },
  btnSave: {
    float: 'right',
    width: 150
  },
  tbEditFeeDirect: {
    float: 'left',
    marginLeft: 20,
    marginRight: 20
  },
});


export default styles;