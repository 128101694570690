import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import { translate } from "react-i18next";
import CaseIcon from "@material-ui/icons/Assignment";
import DomainsIcon from "@material-ui/icons/Dashboard";
import MediaIcon from "@material-ui/icons/CameraAlt";
// import ChartIcon from '@material-ui/icons/InsertChart';
import FilterBar from "@Components/FilterBar";

import UserService from "@Services/User";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { initFilters, initSubfilters } from "@Actions/filterActions";
import { Link } from "react-router-dom";
import styles from "./styles";

class Toolbar extends React.Component {
  componentDidMount() {
    UserService.getFilters()
      .then((response) => {
        this.props.actionInitFilters(response);
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });

    UserService.getSubfilters()
      .then((response) => {
        this.props.actionInitSubfilters(response);
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes, toolbarReducer, t } = this.props;

    return (
      <div>
        <Paper className={classes.root}>
          <div>
            <Tooltip title="Domains" placement="right">
              <Tab
                className={classes.tab}
                to="/domains?r"
                component={Link}
                icon={
                  <List dense={true}>
                    <ListItem
                      classes={{
                        root:
                          toolbarReducer === 0
                            ? classes.listViewSelected
                            : classes.listViewLink,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={
                            toolbarReducer === 0
                              ? classes.avatarSelected
                              : classes.avatarLink
                          }
                        >
                          <DomainsIcon className={classes.icon} />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                  </List>
                }
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title="Cases" placement="right">
              <Tab
                className={classes.tab}
                to="/cases?r"
                component={Link}
                icon={
                  <List dense={true}>
                    <ListItem
                      classes={{
                        root:
                          toolbarReducer === 1
                            ? classes.listViewSelected
                            : classes.listViewLink,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={
                            toolbarReducer === 1
                              ? classes.avatarSelected
                              : classes.avatarLink
                          }
                        >
                          <CaseIcon className={classes.icon} />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                  </List>
                }
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip title={t("_media")} placement="right">
              <Tab
                className={classes.tab}
                to="/media"
                component={Link}
                icon={
                  <List dense={true}>
                    <ListItem
                      classes={{
                        root:
                          toolbarReducer === 2
                            ? classes.listViewSelected
                            : classes.listViewLink,
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          className={
                            toolbarReducer === 2
                              ? classes.avatarSelected
                              : classes.avatarLink
                          }
                        >
                          <MediaIcon className={classes.icon} />
                        </Avatar>
                      </ListItemAvatar>
                    </ListItem>
                  </List>
                }
              />
            </Tooltip>
          </div>
          {/* <Tab  to='/stats' component={Link} icon={
            <List dense={true}>
            <ListItem classes={{root: toolbarReducer === 3 ? classes.listViewSelected : classes.listViewLink }}>
              <ListItemAvatar>
                <Avatar className={ toolbarReducer === 3 ? classes.avatarSelected : classes.avatarLink }>
                  <ChartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                classes={{
                  primary: toolbarReducer === 3 ? classes.titleSelected : classes.titleLink ,
                  secondary: toolbarReducer === 3 ? classes.subTitleSelected : classes.subTitleLink 
                }}              
                primary={t("_stats")}
                secondary={t("_statistics_and_reports")}
              />
            </ListItem>
          </List>
          }/> */}
        </Paper>
        <FilterBar
          showFilter={
            this.props.filterReducer.currentFilterType === "0" ? false : true
          }
        />
      </div>
    );
  }
}

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    toolbarReducer: state.toolbarReducer,
    filterReducer: state.filterReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionInitFilters: initFilters,
      actionInitSubfilters: initSubfilters,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(Toolbar)));
