import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import styles from './styles';
import FindingsService from '@Services/Findings';
import { moveFindingsToNew }  from "@Actions/findingsActions";
import { updateDomainFindingCount, updateDomain }  from "@Actions/domainsActions";
import DomainsService from '@Services/Domains';

class MoveToNewButton extends Component {
  constructor(props) {
    super();
  }

  state = {
    btnDisabled: false,
  };


  btnClicked() {
    this.setState({btnDisabled:true});
    FindingsService.moveFindingsToNew(this.props.data).then(
      (response) => {
        this.props.actionMoveFindingsToNew(this.props.data);
        this.setState({btnDisabled:false});
        // this.props.actionUpdateDomainFindingCount(this.props.data, 1);
        DomainsService.getDomain({domainId:this.props.data.domainId}).then((response) => {
          this.props.actionuUpdateDomain({domainId: this.props.data.domainId, results: response});
        }).catch((error) => {
          // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
          console.log(error);
        });
      }).catch((error) => {
        this.setState({btnDisabled:false});
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
  }  

  render() {
    const { classes ,t} = this.props;

    return (
      <div className={classes.root}>
          <Button  disabled={this.state.btnDisabled} variant="contained"  color="primary" className={classes.button}  onClick={() => this.btnClicked()}>
          {t("_mark_as_new")}       
          </Button>
      </div>
    );
  }

}

MoveToNewButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {
    
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {
      actionMoveFindingsToNew: moveFindingsToNew,
      actionUpdateDomainFindingCount : updateDomainFindingCount,
      actionuUpdateDomain:updateDomain
    }, dispatch);
}

export default withStyles(styles)(translate("translations")(connect(mapStateToProps,matchDispatchToProps)(MoveToNewButton)));