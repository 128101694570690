import { SET_NAVBAR1, SET_NAVBAR2, SET_NAVBAR3 } from '@Types/actionTypes';

export function setNavbar1(data){
  return {
    type : SET_NAVBAR1,
    payload : data
  }
}

export function setNavbar2(data){
  return {
    type : SET_NAVBAR2,
    payload : data
  }
}

export function setNavbar3(data){
  return {
    type : SET_NAVBAR3,
    payload : data
  }
}
