import {
    GET_CASETODOS,
    UPDATE_CASETODOS
  } from '@Types/actionTypes';
  
  export function getCaseTodos(data) {
    //data:
    // "type": "message",
    // "caseid": "FDBBE65A-BF57-4B77-B571-D8121CD83E66",
    // "caseCreationDate": "2021-10-18T10:18:51.350Z",
    // "caseCreator": "Nadine Grimm",
    // "todoCrationDate": "2021-12-16T12:10:02.300Z",
    // "messageText": "Hello lawyer",
    // "messageSender": "PIXRAY Staff",
    // "messageCreationDate": "2021-12-16T12:10:02.300Z"
    // 
    return {
      type: GET_CASETODOS,
      payload: data,
    };
  }

  export function updateCaseTodos(data) {
    //data:
    // "type": "message",
    // "caseid": "FDBBE65A-BF57-4B77-B571-D8121CD83E66",
    // "caseCreationDate": "2021-10-18T10:18:51.350Z",
    // "caseCreator": "Nadine Grimm",
    // "todoCrationDate": "2021-12-16T12:10:02.300Z",
    // "messageText": "Hello lawyer",
    // "messageSender": "PIXRAY Staff",
    // "messageCreationDate": "2021-12-16T12:10:02.300Z"
    // 
    return {
      type: UPDATE_CASETODOS,
      payload: data,
    };
  }
  