import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styles from "./styles";
import { translate } from "react-i18next";
import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";

class CaseConfirmButton extends Component {
  constructor(props) {
    super();

    this.actionClicked = this.actionClicked.bind(this);
    if (!(props.casesReducer.results[props.cardData.id].images) ||
      props.casesReducer.results[props.cardData.id].images.length === 0)
      this.disabled = true;
      else
      this.disabled = false;
  }

  state = {
    open: false,
  };

  actionClicked() {
    this.props.onActionClicked(
      this.props.cardData.type,
      this.props.actionType,
      null
    );
    this.setState({ open: false });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={this.handleClickOpen}
          disabled={this.disabled}
        >
          {t("_confirm")}
        </Button>

        <DialogPopup open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>{t("_confirmation_required")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_do_you_confirm_to_start_the_case")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t("_cancel")}
            </Button>
            <Button onClick={this.actionClicked} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

CaseConfirmButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    casesReducer: state.casesReducer,
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(translate("translations")(CaseConfirmButton))
);
