const Config = {
  'pending':{
    id: 'pending',
    name: 'pending',
    display: true
  },
  'searching':{
    id: 'searching',
    name: 'searching',
    display: true
  },
  'deleting':{
    id: 'deleting',
    name: 'deleting',
    display: true
  },
  'failed':{
    id: 'failed',
    name: 'failed',
    display: true
  },
  'paused':{
    id: 'paused',
    name: 'paused',
    display: true
  }
};

export default Config;