import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root:{
    position: 'fixed',
    top: 64,
    padding: "0 0",
    height: "100%",
    width: 70,
    zIndex: 1101,
    borderRadius: 0
  },

  link:{
    textDecoration:'none'
  },
  listViewLink:{
    backgroundColor: "#FFFFFF",
    color:'#616161',
    minWidth:70,
    maxWidth:35
  },
  listViewSelected:{
    backgroundColor: "#FFFFFF",
    color:'#2680eb',
    minWidth:70,
    maxWidth:35
  },

  indicator: {
    backgroundColor: grey[800],
  },


  titleLink:{

  },
  subTitleLink:{
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'lowercase'
  },
  avatarLink:{
    backgroundColor: 'white',
    color:'#616161',
    "& :hover":{
      // backgroundColor: grey[500]
    }
  },
  icon:{
width:30,
height : 30
  },

  titleSelected:{
    color: grey[900],
    fontWeight: 'bold'
  },
  subTitleSelected:{
    color: grey[700],
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'lowercase'
  },
  avatarSelected:{
    backgroundColor: 'white',
    color:'#2680eb'
  },
  tab:{
    minWidth: "inherit"
  },
 
});


export default styles;