const styles = theme => ({
  root: {
    height:345,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
    
  },
  subheader: {
    width: '100%',
  },
  gridList:{
    width: 315
  }
});


export default styles;