import request from "@Utils/api";
import api from "@Configs/apiConfig";

function getLogs(startTime) {
  var qString = "?starttime=" + startTime;
  return request({
    url:
      api.userServer +
      "/user/" +
      localStorage.getItem("Id") +
      "/logs" +
      qString,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function getFilters() {
  return request({
    url: api.userServer + "/user/" + localStorage.getItem("Id") + "/filters",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function addFilter(data) {
  return request({
    url: api.userServer + "/user/" + localStorage.getItem("Id") + "/filters",
    data: data,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function removeFilter(filterId) {
  return request({
    url:
      api.userServer +
      "/user/" +
      localStorage.getItem("Id") +
      "/filters/" +
      filterId,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function getSubfilters() {
  return request({
    url: api.userServer + "/user/" + localStorage.getItem("Id") + "/subfilters",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function getInfo() {
  return request({
    url: api.userServer + "/user/" + localStorage.getItem("Id"),
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function updateInfo(data) {
  return request({
    url: api.userServer + "/user/" + localStorage.getItem("Id"),
    method: "PUT",
    data: data,
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function getUsersByCustomer() {
  return request({
    url: api.userServer + "/user/_by_customer",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function updatePassword(data) {
  return request({
    url: api.userServer + "/user/" + localStorage.getItem("Id") + "/password",
    method: "PUT",
    data: data,
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function updateMediaInfoConfig(data) {
  return request({
    url:
      api.userServer +
      "/user/" +
      localStorage.getItem("Id") +
      "/media_info_config",
    method: "POST",
    data: data,
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function getFilterCount(id) {
  return request({
    url:
      api.userServer +
      "/user/" +
      localStorage.getItem("Id") +
      "/filters/" +
      id +
      "/result_count",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function swapFilterOrder(id1, id2) {
  return request({
    url:
      api.userServer +
      "/user/" +
      localStorage.getItem("Id") +
      "/filters/_swap_order",
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
    data: { id1: id1, id2: id2 },
  });
}

function reorderFilters(idOrderArray) {
  return request({
    url:
      api.userServer +
      "/user/" +
      localStorage.getItem("Id") +
      "/filters/_reorder",
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
    data: idOrderArray,
  });
}

function resetPassword(email) {
  return request({
    url: api.userServer + "/user/_reset_password/" + email,
    method: "POST",
  });
}

function setNewPassword(userid, temporaryToken, password) {
  return request({
    url: api.userServer + "/user/" + userid + "/password",
    method: "PUT",
    data: password,
    headers: {
      "x-access-token": temporaryToken,
      "Content-Type": "text/plain",
    },
  });
}

function validateResetPasswordID(rpId) {
  return request({
    url: api.userServer + "/user/_check_password_reset_id",
    method: "POST",
    data: { security_id: rpId },
  });
}

const User = {
  getLogs,
  getFilters,
  getSubfilters,
  addFilter,
  removeFilter,
  getInfo,
  updateInfo,
  updatePassword,
  getUsersByCustomer,
  updateMediaInfoConfig,
  getFilterCount,
  swapFilterOrder,
  reorderFilters,
  resetPassword,
  setNewPassword,
  validateResetPasswordID,
};

export default User;
