import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Button from '@material-ui/core/Button';
import styles from './styles';

class ChangeRouteButton extends Component {
  constructor(props) {
    super();

    this.actionClicked = this.actionClicked.bind(this);
  }

  state = {
    open: false,
  };

  actionClicked(){
    this.props.onActionClicked(this.props.cardData.type, this.props.actionType, null);
  
  }

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Button variant="contained"  color="primary" className={classes.button} onClick={this.actionClicked}>
          {t("_change_route")}
        </Button>

      

      </div>
    );
  }
}

ChangeRouteButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(ChangeRouteButton));