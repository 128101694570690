import { blue } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {},
  menu: {
    top: "223px",
    width: "240px",
    position: "fixed",
    overflow: "auto",
    maxHeight: "74vh",
    left: 90,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  menuPaper: {
    border: "none",
    borderRadius: "0px",
    boxShadow: "none",
    backgroundColor: "#eeeeee",
    marginLeft: "-16px",
    top: "115px",
  },
  menuItem: {
    height: 20,
    color: "#515151",
    backgroundColor: "#e8e8e8",
    padding: "2px 0px 2px 0px",
    marginBottom: 3,
    borderRadius: 5,
  },
  listItemLeft: {
    maxWidth: "145px",
    overflowX: "hidden",
  },
  listItemRigth: {
    textAlign: "right",
    width: "100%",
    color: "#2680eb",
    paddingRight: 15
  },
  subTitle: {
    display: "inline-block",
    textOverflow: "ellipsis",
  },
  tooltip: {
    maxWidth: "none",
  },
  title: {
    top: 172,
    left: 93,
    position: "fixed",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  title_header: {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  title_header_clickable:{
    fontWeight: "bold",
    textTransform: "uppercase",
    cursor: "pointer"
  },
  title_add_Filter: {
    color: "#BBB",
    fontSize: "15px",
    fontWeight: "bold",
    marginLeft: "6px",
    marginTop: "6px",
    cursor: "pointer",
  },
  selectedFilterContainer: {
    paddingTop: 7,
    cursor: "default",
    color: "#424242",
    display: "inline-block",
    fontSize: 12,
  },

  seperator: {
    fontWeight: "bold",
    paddingRight: 8,
  },
  navbarContainer: {
    paddingTop: 7,
    cursor: "default",
    color: "#fff",
    display: "inline-block",
  },
  navbarLink: {
    color: "#424242",
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: 1,
    textDecoration: "none",
    padding: 5,
  },
  selectedFilterIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 20,
    cursor: "pointer",
  },
  removeFilterIcon: {
    cursor: "pointer",
    display: "inline-block",
    verticalAlign: "middle",
    width: 20,
    paddingLeft: 10,
  },
  selectedFilterName: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: 0,
    paddingLeft: 5,
    textTransform: "uppercase",
    cursor: "pointer",
  },
  filterDialogPaper: {
    maxWidth: "800px !important",
    minWidth: "800px !important",
    width: 800,
  },
  filterDialogContentRoot: {},
  filterContentContainer: {
    height: 400,
    display: "flex",
    flexDirection: "row",
    // backgroundColor: '#aeaeae'
  },

  filterMenuContainer: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: 0,
    paddingLeft: 5,
    paddingRight: 22,
    textTransform: "uppercase",
    cursor: "pointer",
    paddingBottom: 2,
  },
  filterMenuContainer_spaced: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: 0,
    paddingLeft: 50,
    paddingRight: 22,
    textTransform: "uppercase",
    cursor: "pointer",
    paddingBottom: 2,
  },

  bulkAction: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: 0,
    paddingLeft: 50,
    paddingRight: 22,
    textTransform: "uppercase",
    cursor: "pointer",
    paddingBottom: 2,
  },
  selectedBulkIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    width: 20,
    cursor: "pointer",
    paddingRight: 0,
  },

  dataListRoot: {
    height: 240,
    marginTop: 10,
    overflowY: "scroll",
    border: "1px solid #eee",
    // marginBottom: 10
  },
  collectionPartnerListRoot: {
    margin: "0px 25px",
  },

  heading: {
    fontSize: 17,
    flexBasis: "66.66%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },

  imageContainer: {
    // width:100,
    // height:60,
    // display: 'flex',
    // flexWrap: 'wrap',
    // display:"inline-block",
    maxHeight: 100,
    maxWidth: 60,
    paddingLeft: 10,
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  gridListSelected: {
    backgroundColor: theme.palette.secondary.light,
    // width: 500,
    // height: 450,
  },

  switchSelect: {
    marginTop: 15,
  },

  switchLabel: {
    fontSize: 14,
    display: "block",
  },

  optionFormControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },

  selectAllContainer: {
    height: 30,
    display: "block",
  },
  selectAllText: {
    lineHeight: "35px",
    display: "inline-block",
  },
  selectAllBtn: {
    display: "inline-block",
    float: "right",
  },

  tagInput: {
    zIndex: 10,
    // height:150
  },
  totalBox: {
    padding: "2px 10px",
    fontSize: 12,
    paddingTop: 4,
    minWidth: 200,
    marginRight: 10,
    borderRadius: 9,
    backgroundColor: "#2680eb",
  },

  menuRoot: {
    padding: 0,
  },
  menuItemButton: {
    paddingBottom: 10,
    textAlign: "center",
    outline: "none",
    border: "none",
  },
  rightIcon: {
    marginRight: theme.spacing.unit,
  },
  primary: {
    fontWeight: "400",
  },
  secondary: {
    color: blue[600],
  },
  addNewFilterButton: {
    margin: "0px 15px",
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent",
    },
  }
});

export default styles;
