import { SET_SEARCH, REMOVE_SEARCH } from '@Types/actionTypes';

const searchReducer = (state = {
  search: {class:null, subclass: null, value: ""}
}, action) => {

  switch (action.type) {
    
    case SET_SEARCH:
      state = {...state, search: action.payload};
      break; 
    
    case REMOVE_SEARCH:
      state = {search: {class:null, subclass: null, value: ""}};
      break;

    default:
      break;
  }
  
  return state;
};

export default searchReducer;

