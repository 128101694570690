import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { showSnackbar } from "@Actions/snackbarActions";

import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";

import Button from "@material-ui/core/Button";
import CasesService from "@Services/Cases";
import CasePayment from "../CasePayment";
import LoadingBg from "@Components/LoadingBg";
import { isNull,numberToLocaleString } from "@Utils/helpers.js";
import { translate } from "react-i18next";
import styles from "./styles";

class CaseFee extends Component {
  constructor(props) {
    super(props);

    this.handleUpdateFees = this.handleUpdateFees.bind(this);

    this.state = {
      licenceFee: this.props.data && (this.props.data.licenceFee || ""),
      collectionPartnerFee:
        this.props.data && (this.props.data.collectionPartnerFee || ""),
      researchFee: this.props.data && (this.props.data.researchFee || ""),
      totalPaymentRequested:
        this.props.data && (this.props.data.totalPaymentRequested || ""),
      settlementSum: this.props.data && (this.props.data.settlementSum || ""),
      collectionPartnersPartOfSettlementSum:
        this.props.data &&
        (this.props.data.collectionPartnersPartOfSettlementSum || ""),

      casePayments: null,
      popupOpen: false,
      isUpdating: false,
    };
  }

  handlePopupOpen = () => {
    this.setState({ popupOpen: true });
  };

  handlePopupClose = () => {
    this.setState({ popupOpen: false, isUpdating: false });
  };

  handleTextFieldChange = (name) => (event) => {
    this.setState({
      [name]: parseFloat(event.target.value),
    });
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.data)
        this.setState({
          licenceFee: this.props.data.licenceFee || "",
          collectionPartnerFee: this.props.data.collectionPartnerFee || "",
          researchFee: this.props.data.researchFee || "",
          totalPaymentRequested: this.props.data.totalPaymentRequested || "",
          settlementSum: this.props.data.settlementSum || "",
          collectionPartnersPartOfSettlementSum:
            this.props.data.collectionPartnersPartOfSettlementSum || "",
        });
    }, 2);

    this.handleUpdatePayments();
  }

  handleUpdatePayments = () => {
    CasesService.getCasepayments({ caseId: this.props.caseId })
      .then((response) => {
        this.setState({ casePayments: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };
  handleUpdateFees() {
    this.setState({ isUpdating: true });

    var data = {
      caseId: this.props.caseId,
      // "licenceFee": this.state.licenceFee === "" ? null : this.state.licenceFee,
      collectionPartnerFee:
        this.state.collectionPartnerFee === ""
          ? null
          : this.state.collectionPartnerFee,
      researchFee:
        this.state.researchFee === "" ? null : this.state.researchFee,
      totalPaymentRequested:
        this.state.totalPaymentRequested === ""
          ? null
          : this.state.totalPaymentRequested,
      settlementSum:
        this.state.settlementSum === "" ? null : this.state.settlementSum,
      collectionPartnersPartOfSettlementSum:
        this.state.collectionPartnersPartOfSettlementSum === ""
          ? null
          : this.state.collectionPartnersPartOfSettlementSum,
      lawyerFee:
        this.state.collectionPartnerFee === ""
          ? null
          : this.state.collectionPartnerFee,
      lawyersPartOfSettlementSum:
        this.state.collectionPartnersPartOfSettlementSum === ""
          ? null
          : this.state.collectionPartnersPartOfSettlementSum,
    };

    CasesService.updateCasesFees(data)
      .then((response) => {
        // console.log(response)
        this.props.onFeesUpdated(data, true);
        this.handleUpdatePayments();
        this.setState({
          popupOpen: false,
          isUpdating: false,
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
        this.setState({
          popupOpen: false,
          isUpdating: false,
        });
      });
  }

  render() {
    const { classes, status, t } = this.props;

    if (this.props.data == null) {
      return (
        <div>
          <LoadingBg
            pageType="general"
            size="small"
            animated={false}
            message={t("_failed_to_load_resources") + "!"}
          />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <table className={classes.rootTable}>
          <tbody>
            <tr style={{ border: "none" }}>
              <td
                style={{
                  borderRight: "solid 1px #d7d7d7",
                  width: "50%",
                  verticalAlign: "top",
                  height:"100%"
                }}
              >
                <div className={classes.feesContainer}>
                  <p className={classes.feeAndPaymentHeadline}>
                    {t("_Fee_Setup")}
                  </p>
                  <table className={classes.tblGeneral}>
                    <tbody>
                      <tr>
                        <td className={classes.trLeft}>{t("_license_fee")}</td>
                      </tr>
                      <tr>
                        {isNull(this.props.data.licenceFee) ||
                        this.props.data.licenceFee === 0 ||
                        this.props.data.licenceFee === "0" ? (
                          <td className={classes.trRight}>0.00 EUR</td>
                        ) : (
                          <td className={classes.trRight}>
                            {numberToLocaleString(this.props.data.licenceFee)} EUR
                          </td>
                        )}
                      </tr>
                      {isNull(this.props.data.researchFee) ? (
                        <>
                          <tr>
                            <td></td>
                          </tr>
                        </>
                      ) : (
                        <>
                          <tr>
                            <td className={classes.trLeft}>
                              {t("_documentation_and_research_fee")}
                            </td>
                          </tr>
                          <tr>
                            <td className={classes.trRight}>
                              {numberToLocaleString(this.props.data.researchFee)} EUR
                            </td>
                          </tr>
                        </>
                      )}
                      <tr>
                        <td className={classes.trLeft}>
                          {t("_total_amount_asked")}
                        </td>
                      </tr>
                      <tr>
                        {isNull(this.props.data.totalPaymentRequested) ? (
                          <td className={classes.trRight}>{t("_not_set")}</td>
                        ) : (
                          <td className={classes.trRight}>
                            {numberToLocaleString(this.state.totalPaymentRequested)} EUR
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                  {status === 4 || status === "4" ? (
                    <div></div>
                  ) : (
                    <table className={classes.tblGeneral}>
                      <tbody>
                        <tr>
                          <td className={classes.trLeft}>
                            {t("_collection_partner_fee")}
                          </td>
                        </tr>
                        <tr>
                          {isNull(this.props.data.collectionPartnersFee) ? (
                            <td className={classes.trRight}>{t("_not_set")}</td>
                          ) : (
                            <td className={classes.trRight}>
                              {numberToLocaleString(this.props.data.collectionPartnersFee)} EUR
                            </td>
                          )}
                        </tr>
                        {isNull(this.props.data.settlementSum) ? (
                          <>
                            <tr>
                              <td></td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td className={classes.trLeft}>
                                {t("_settelment_amount")}
                              </td>
                            </tr>
                            <tr>
                              <td className={classes.trRight}>
                                {numberToLocaleString(this.props.data.settlementSum)} EUR
                              </td>
                            </tr>
                          </>
                        )}
                        {isNull(
                          this.props.data.collectionPartnersPartOfSettlementSum
                        ) ? (
                          <>
                            <tr>
                              <td></td>
                            </tr>
                          </>
                        ) : (
                          <>
                            <tr>
                              <td className={classes.trLeft}>
                                {t(
                                  "_collection_partner_part_of_settelment_sum"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className={classes.trRight}>
                                {
                                  numberToLocaleString(this.props.data
                                    .collectionPartnersPartOfSettlementSum)
                                }{" "}
                                EUR
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  )}

                  {status === 4 || status === "4" ? (
                    <Button
                      onClick={this.handlePopupOpen}
                      color="primary"
                      className={classes.editFeesButton}
                    >
                      {t("_edit_claim")}
                    </Button>
                  ) : (
                    <Button
                      onClick={this.handlePopupOpen}
                      color="primary"
                      disabled
                      className={classes.editFeesButton}
                    >
                      {t("_edit_claim")}
                    </Button>
                  )}
                </div>
              </td>
              <td
                style={{ width: "50%", paddingLeft: 15, verticalAlign: "top",
                height:"100%" }}
              >
                <div style={{position:"relative",height: "100%"}}>
                  <CasePayment
                    caseId={this.props.caseId}
                    data={this.state.casePayments}
                    onPaymentAdded={this.handleUpdatePayments}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.popupOpen}
          onClose={this.handlePopupClose}
        >
          <DialogTitle>{t("_edit_claim")}</DialogTitle>
          <DialogContent classes={{ root: classes.contentRoot }}>
            <Tooltip
              title={t("_license_fee_must_be_set_per_finding")}
              placement="top"
            >
              <TextField
                value={this.state.licenceFee}
                fullWidth
                id="licenceFee"
                label={t("_license_fee")}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                disabled
                margin="dense"
                onChange={this.handleTextFieldChange("licenceFee")}
              />
            </Tooltip>
            <TextField
              value={this.state.collectionPartnerFee}
              fullWidth
              id="collectionPartnerFee"
              label={t("_collection_partner_fee")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              margin="dense"
              onChange={this.handleTextFieldChange("collectionPartnerFee")}
            />
            <TextField
              value={this.state.researchFee}
              fullWidth
              type="number"
              id="researchFee"
              label={t("_documentation_and_research_fee")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0 } }}
              margin="dense"
              onChange={this.handleTextFieldChange("researchFee")}
            />
            <TextField
              value={this.state.totalPaymentRequested}
              fullWidth
              type="number"
              id="totalPaymentRequested"
              label={t("_total_amount_asked")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0 } }}
              margin="dense"
              onChange={this.handleTextFieldChange("totalPaymentRequested")}
            />
            <TextField
              value={this.state.settlementSum}
              fullWidth
              type="number"
              id="settlementSum"
              label={t("_settlement_sum")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0 } }}
              margin="dense"
              onChange={this.handleTextFieldChange("settlementSum")}
            />
            <TextField
              value={this.state.collectionPartnersPartOfSettlementSum}
              fullWidth
              type="number"
              id="collectionPartnersPartOfSettlementSum"
              label={t("_collection_partner_part_of_settelment_sum")}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{ inputProps: { min: 0 } }}
              margin="dense"
              onChange={this.handleTextFieldChange(
                "collectionPartnersPartOfSettlementSum"
              )}
            />

            <br />
            <LinearProgress
              className={classes.addProgress}
              color="secondary"
              style={{
                visibility: this.state.isUpdating ? "visible" : "hidden",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handlePopupClose}
              color="secondary"
              disabled={this.state.isUpdating ? true : false}
            >
              {t("_cancel")}
            </Button>
            <Button
              onClick={this.handleUpdateFees}
              color="primary"
              disabled={this.state.isUpdating ? true : false}
            >
              {t("_update_claim")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

CaseFee.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(CaseFee)
  )
);
