import { SHOW_SNACKBAR, HIDE_SNACKBAR } from '@Types/actionTypes';

export function showSnackbar(data){

  return {
    type : SHOW_SNACKBAR,
    payload : data
  }
}

export function hideSnackbar(){

  return {
    type : HIDE_SNACKBAR,
    payload : null
  }
}
