import { grey, blue } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {
    minWidth: "814px",
  },

  imageContainer: {
    minWidth: 90,
    minHeight: 90,
    // display: 'flex',
    flexWrap: "wrap",
    // justifyContent: 'space-around',
    // overflow: 'scroll',
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
  },

  imageStyle: {
    // width: 50
    maxHeight: 90,
    maxWidth: 90,
    // paddingLeft:10
    display: "block",
    margin: "auto",
  },

  gridList: {
    width: 90,
    height: 90,
  },

  card: {
    width: "100%",
  },
  cardContent: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "space-around",
  },
  imageLeftSide: {
    paddingTop: 5,
    width: 40,
  },
  compareIcon: {
    cursor: "pointer",
    color: grey[800],
  },
  externalLinkIcon: {
    textDecoration: "none",
    cursor: "pointer",
    color: grey[800],
  },
  infoIcon: {
    cursor: "pointer",
    color: grey[800],
  },

  noteIcon: {
    cursor: "pointer",
    color: grey[800],
  },

  actionButton: {
    textTransform: "initial",
    fontSize: 14,
    height: 40,
    minWidth: 150,
    width: "100%",
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent",
    },
  },

  historyButton: {
    textTransform: "initial",
    fontSize: 14,
    height: 40,
    minWidth: 150,
    width: "100%",
    color: "#464646",
    backgroundColor: "#fff",
    border: "1px solid #464646",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#464646",
      border: "1px solid transparent",
    },
    marginTop: 15,
  },

  detailsContainer: {
    fontSize: 14,
    width: 200,
    padding: "0 15px",
    lineHeight: "1.5",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },

  detailsContainer2: {
    fontSize: 14,
    width: 150,
    padding: "0 15px",
    lineHeight: "1.5",
  },

  detailsContainer3: {
    fontSize: 14,
    width: 350,
    padding: "0 15px",
  },

  findingUrl: {
    overflow: "hidden",
    fontSize: 14,
    maxWidth: 350,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: blue[800],
  },
  findingTooltipTitle: {
    fontSize: 16,
    color: grey[600],
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: "bold",
  },
  buttonsContainer: {
    width: 200,
  },

  tblContainerDetails: {
    height: 500,
    overflow: "scroll",
  },
  tblDetails: {
    border: "1px solid #EEE",
  },
  rowrowDeatils: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowDeatilsFrist: {
    // width: 20,
    padding: "5px !important",
    // textAlign: "center"
  },
  rowDeatilsSecond: {
    "& b": {
      fontSize: 15,
      color: grey[600],
    },
  },
  domainLink: {
    color: grey[800],
    fontSize: 16,
    textDecoration: "none",
    cursor: "pointer !important",
    marginTop: 7,
    display: "block",
  },

  tblContainerHistory: {
    height: 300,
    overflow: "scroll",
  },

  numberOfFindingsUrl: {
    fontSize: 16,
    textDecoration: "underline",
    cursor: "pointer",
    margin: 0,
  },

  mediaState: {
    fontSize: 14,
    margin: 0,
  },

  actionSection: {
    paddingBottom: 10,
  },
  tbAddNote: {
    float: "left",
    marginLeft: 20,
  },
  btnAddNote: {
    float: "right",
    width: 150,
  },
  contentContainer: {
    maxHeight: 200,
    overflow: "scroll",
  },
  table: {
    height: 100,
    overflow: "scroll",
  },
  row: {
    whiteSpace: "pre-line",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  noteTitle: {
    textAlign: "right",
    fontSize: 12,
    fontWeight: "bold",
  },
  tooltip: {
    maxWidth: "none",
  },

  alertPaper: {
    boxShadow: "none",
    borderRadius: "9px",
    background: "#e5f6fd",
    padding: "16px 16px 16px 60px",
    color: "rgb(1, 67, 97)",
    display: "-webkit-box",
    marginTop: "30px",
  },
  alertIcon: {
    display: "list-item",
    position: "relative",
    left: "-36px",
    color: "#03a9f4",
    top: "20%",
  },
});

export default styles;
