
const styles = theme => ({
  scroll: {
    opacity: 0.3,
    backgroundColor: "#3f51b5",
    width: 40,
    height: 40,
    position: 'fixed',
    bottom: 10,
    left: 10,
    borderRadius: 5,
    border: "none",
    zIndex:99999,
    
    '&:hover' :{
      opacity: 1
    }
  }
});


export default styles;