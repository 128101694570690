import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getNotify, addNotify } from "@Actions/notifyActions";

import SettingBadge from "./SettingBadge";
import LogToggleButton from "./LogToggleButton";
import AvatarBadge from "./AvatarBadge";
import LogoutBadge from "./LogoutBadge";
import SearchBar from "@Components/SearchBar";
import CaseTodosBadge from "./CaseTodosBadge";

import ProgressBar from "@Components/ProgressBar";
import styles from "./styles";

class TopMenu extends Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    this.props.getNotify();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <ProgressBar />
          <Toolbar className={classes.toolbar}>
            <Link to="/domains" className={classes.link}>
              <img
                className={classes.imgStyle}
                src={require("@Assets/images/logo.png")}
                alt=""
              />
            </Link>
            <div className={classes.flex}>
              <SearchBar />
            </div>
            <AvatarBadge avatar={this.props.globalReducer.userInfo.avatar} />

            <Link to="/setting" className={classes.link}>
              <SettingBadge />
            </Link>

            <LogoutBadge />

            <CaseTodosBadge />
            <LogToggleButton />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

TopMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    notifyReducer: state.notifyReducer,
    globalReducer: state.globalReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNotify: getNotify,
      addNotify: addNotify,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(TopMenu));
