import { ACTION_BUTTON, MOVE_TO_TRASH_BUTTON, MOVE_TO_NEW_BUTTON, MOVE_TO_LEGAL_BUTTON } from '@Types/findingButtonTypes';

const Config = {
  'new': {
    MOVE_TO_TRASH_BUTTON,
    MOVE_TO_LEGAL_BUTTON,
    ACTION_BUTTON
  },
  'trash': {
    MOVE_TO_NEW_BUTTON,
    // ACTION_BUTTON
  },
  'legal': {
    MOVE_TO_NEW_BUTTON,
    MOVE_TO_TRASH_BUTTON
    // ACTION_BUTTON
  },
  
  'case_status_1': { // Precheck
    // ACTION_BUTTON
  },
  'case_status_2': { // Documentation in progress
    // ACTION_BUTTON
  },
  'case_status_3': { // Documentation completed
    // ACTION_BUTTON
  },
  'case_status_4': { // Post Licensing
    // ACTION_BUTTON
  },
  'case_status_5': { // Legal Action
    // ACTION_BUTTON
  },
  'case_status_6': { // Infringement Letter
    // ACTION_BUTTON
  },
  'case_status_7': { // Accounting Post Licensing
    // ACTION_BUTTON
  },
  'case_status_8': { // Accounting Legal Pursuit
    // ACTION_BUTTON
  },
  'case_status_9': { // Legal Prosecution 
    // ACTION_BUTTON
  },
  'case_status_10': { // Closed
    // ACTION_BUTTON
  },
  'case_status_11': { // Aborted
    // ACTION_BUTTON
  },
};

export default Config;