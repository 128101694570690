import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setSearch, removeSearch } from "@Actions/searchActions";
import {
  updateCurrentFilterId,
  updateSearchFilterId,
} from "@Actions/filterActions";
import { translate } from "react-i18next";
import Base64 from "base-64";
import IconSearch from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Redirect } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DomainIcon from "@material-ui/icons/Dashboard";
import CaseIcon from "@material-ui/icons/Assignment";
import MediaIcon from "@material-ui/icons/CameraAlt";
import ListIcon from "@material-ui/icons/List";
import SearchSubClassesConfig from "@Configs/searchSubclassConfig";
import SearchClassesConfig from "@Configs/searchClassConfig";
import styles from "./styles";

import { removeDomains } from "@Actions/domainsActions";
import { removeMedia } from "@Actions/mediaActions";
import { removeCases } from "@Actions/casesActions";

const emptySearch = { class: null, subclass: null, value: "" };
class SearchBar extends Component {
  constructor(props) {
    super();
    this.searchRef = React.createRef();
  }

  state = {
    anchorEl: null,
    anchorElSub: null,

    enterPressed: false,
  };

  handleOpenClass = () => {
    this.setState({ anchorEl: this.searchRef });
    this.searchRef.focus();
  };

  handleCloseClass = () => {
    this.props.actionSetSearch(emptySearch);
    this.setState({ anchorEl: null });
  };

  handleOpenSubclass = () => {
    this.setState({ anchorElSub: this.searchRef });
    this.searchRef.focus();
  };

  handleCloseSubclass = () => {
    // this.props.actionSetSearch({...this.props.searchReducer, subclass: null});
    this.setState({ anchorElSub: null });
  };

  handleInputClick = () => {
    if (this.props.searchReducer.search.class == null) {
      this.handleOpenClass();
    } else if (this.props.searchReducer.search.subclass == null) {
      this.handleOpenSubclass();
    } else {
    }
  };

  handleSelectClass = (value) => {
    this.props.actionSetSearch({ ...emptySearch, class: value });
    this.setState({ anchorEl: null });
    this.handleOpenSubclass();
    // this.searchRef.focus();
  };

  handleSelectSubclass = (value) => {
    this.props.actionSetSearch({
      ...this.props.searchReducer.search,
      subclass: value,
    });
    this.setState({ anchorElSub: null });
    // this.searchRef.focus();
  };

  keyPress = (event) => {
    if (this.props.searchReducer.search.class == null) {
      this.handleOpenClass();
    } else if (this.props.searchReducer.search.subclass == null) {
      this.handleOpenSubclass();
    } else {
    }

    if (
      event.keyCode === 13 &&
      this.props.searchReducer.search.value.trim() !== ""
    ) {
      // enter
      let searchText = this.props.searchReducer.search.value;
      if (searchText && typeof searchText === "string")
        searchText = searchText.trim();
      this.props.actionSetSearch({
        ...this.props.searchReducer.search,
        value: searchText,
      });
      this.setState({ enterPressed: true }, () => {
        // reset search filter
        if (this.props.searchReducer.search.class.value === "domain") {
          this.props.actionRemoveDomains();
        } else if (this.props.searchReducer.search.class.value === "case") {
          this.props.actionRemoveCases();
        } else {
          this.props.actionRemoveMedia();
        }

        this.props.actionUpdateSearchFilterId("0");
        this.props.actionUpdateCurrentFilterId("0");

        this.setState({ enterPressed: false });
      });
    } else if (
      event.keyCode === 8 &&
      this.props.searchReducer.search.value === ""
    ) {
      // backslash
      if (this.props.searchReducer.search.subclass !== null) {
        this.props.actionSetSearch({
          ...this.props.searchReducer.search,
          subclass: null,
        });
        this.handleOpenSubclass();
      } else {
        this.props.actionSetSearch(emptySearch);
        this.handleOpenClass();
      }
    } else {
    }
  };

  keyPressSubclass = (event) => {
    if (event.keyCode === 8) {
      // backslash
      this.handleCloseSubclass();
      this.props.actionSetSearch(emptySearch);
      this.handleOpenClass();
    }
  };

  handleInputChange = (event) => {
    this.props.actionSetSearch({
      ...this.props.searchReducer.search,
      value: event.target.value,
    });
    if (this.props.searchReducer.search.class === null) {
      this.props.actionSetSearch({ ...this.props.searchReducer, value: "" });
      this.handleOpenClass();
    } else if (this.props.searchReducer.search.subclass === null) {
      this.props.actionSetSearch({ ...this.props.searchReducer, value: "" });
      this.handleOpenSubclass();
    }
  };

  calcSubclassCssClass = () => {
    var classLenght = 1;
    if (this.props.searchReducer.search.subclass.name.length < 7) {
      classLenght = 1;
    } else if (
      this.props.searchReducer.search.subclass.name.length >= 7 &&
      this.props.searchReducer.search.subclass.name.length < 12
    ) {
      classLenght = 2;
    } else if (
      this.props.searchReducer.search.subclass.name.length >= 12 &&
      this.props.searchReducer.search.subclass.name.length < 16
    ) {
      classLenght = 3;
    } else {
      classLenght = 4;
    }
    return classLenght;
  };

  handleDeleteChip = () => {
    this.props.actionSetSearch(emptySearch);
    this.setState({ anchorEl: null, anchorElSub: null });
    this.handleOpenClass();
  };

  handleDeleteSubChip = () => {
    this.props.actionSetSearch({
      ...this.props.searchReducer.search,
      subclass: null,
      value: "",
    });
    this.setState({ anchorElSub: null });
    this.handleOpenSubclass();
  };

  render() {
    const { classes, searchReducer, t } = this.props;
    const { anchorEl, anchorElSub } = this.state;

    return (
      <div className={classes.root}>
        <Input
          id="search"
          type="search"
          autoComplete="off"
          // ref={this.searchRef}
          inputProps={{ ref: (input) => (this.searchRef = input) }}
          fullWidth
          className={classes.textField}
          startAdornment={
            <InputAdornment position="start">
              {searchReducer.search.class === null ? (
                <div className={classes.iconContainer}>
                  <IconSearch className={classes.iconSearch} />
                </div>
              ) : searchReducer.search.subclass === null ? (
                <div className={classes.iconContainerWithClass}>
                  <IconSearch className={classes.iconSearch} />
                </div>
              ) : (
                <div
                  className={
                    classes[
                      "iconContainerWithSubclass" + this.calcSubclassCssClass()
                    ]
                  }
                >
                  <IconSearch className={classes.iconSearch} />
                </div>
              )}
            </InputAdornment>
          }
          placeholder={t("_search") + "..."}
          disableUnderline={false}
          onClick={this.handleInputClick}
          onChange={this.handleInputChange}
          onKeyDown={this.keyPress}
          value={searchReducer.search.value}
        />
        <Menu
          id="class-menu"
          className={classes.menu}
          classes={{ paper: classes.menuPaper }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleCloseClass}
          onKeyDown={this.keyPressClass}
        >
          <MenuItem
            className={classes.menuItem}
            disabled
            tabIndex={-1}
            focusable={false}
          >
            <ListItemText
              classes={{ primary: classes.primary }}
              inset
              primary={t("_search_for")}
            />
          </MenuItem>

          {SearchClassesConfig.map((row, index) => {
            return (
              <MenuItem
                className={classes.menuItem}
                onClick={() => this.handleSelectClass(row)}
                key={index}
              >
                <ListItemIcon className={classes.icon}>
                  {row.value === "case" ? (
                    <CaseIcon />
                  ) : row.value === "domain" ? (
                    <DomainIcon />
                  ) : (
                    <MediaIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.primary }}
                  inset
                  primary={t("_" + row.display)}
                />
              </MenuItem>
            );
          })}
        </Menu>
        <Menu
          id="subclass-menu"
          className={classes.menu}
          classes={{ paper: classes.menuPaper }}
          anchorEl={anchorElSub}
          open={Boolean(anchorElSub)}
          onClose={this.handleCloseSubclass}
          onKeyDown={this.keyPressSubclass}
        >
          <MenuItem
            className={classes.menuItem}
            disabled
            tabIndex={-1}
            focusable={false}
          >
            <ListItemText
              classes={{ primary: classes.primary }}
              inset
              primary={t("_search_in")}
            />
          </MenuItem>

          {searchReducer.search.class !== null ? (
            SearchSubClassesConfig[searchReducer.search.class.value].map(
              (row, index) => {
                return (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => this.handleSelectSubclass(row)}
                    key={index}
                  >
                    <ListItemIcon className={classes.icon}>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.primary }}
                      inset
                      primary={t("_" + row.display)}
                    />
                  </MenuItem>
                );
              }
            )
          ) : (
            <></>
          )}
        </Menu>
        {searchReducer.search.class !== null ? (
          <Chip
            label={searchReducer.search.class.name}
            classes={{
              label: classes.labelText,
              deleteIcon: classes.deleteIcon,
            }}
            onDelete={this.handleDeleteChip}
            className={classes.chip}
          />
        ) : (
          ""
        )}
        {searchReducer.search.subclass !== null ? (
          <Chip
            label={searchReducer.search.subclass.name}
            classes={{
              label: classes["labelText" + +this.calcSubclassCssClass()],
              deleteIcon: classes.deleteIcon,
            }}
            onDelete={this.handleDeleteSubChip}
            className={classes["chipSub" + this.calcSubclassCssClass()]}
          />
        ) : (
          ""
        )}
        {this.state.enterPressed ? (
          <Redirect
            push={true}
            to={"/search/" +encodeURIComponent( Base64.encode(JSON.stringify(searchReducer)))}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

withStyles.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    searchReducer: state.searchReducer,
    filterReducer: state.filterReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionSetSearch: setSearch,

      actionRemoveSearch: removeSearch,
      actionUpdateSearchFilterId: updateSearchFilterId,
      actionUpdateCurrentFilterId: updateCurrentFilterId,

      actionRemoveDomains: removeDomains,
      actionRemoveMedia: removeMedia,
      actionRemoveCases: removeCases,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(SearchBar)));
