import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import styles from './styles';
import SnackAlert from '@Components/SnackAlert';
import { hideSnackbar } from '@Actions/snackbarActions';
import { getCollectionPartners, getUsers, getCaseStates,getCaseTypes}  from '@Actions/globalActions';
import {updateUserAvatar} from '@Actions/globalActions';
import CasesService from '@Services/Cases';
import UserService from '@Services/User';
import CaseStaus from '@Configs/caseStatusConfig';
import CaseTypes from '@Configs/caseTypesConfig';

class OverView extends React.Component {
  

  componentDidMount(){
    CasesService.getCollectionPartnerList().then(
      (response) => {
        this.props.actionGetCollectionPartners(response)
      }).catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });

    UserService.getUsersByCustomer().then(
      (response) => {
        this.props.actionGetUsers(response)
      }).catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });

    this.props.actionGetCaseStates(CaseStaus);
    this.props.actionGetCaseTypes(CaseTypes);

    this.props.actionUpdateUserAvatar(localStorage.getItem("AvatarID"));
  }
  
  render() {
    // const { classes } = this.props;
    return (
      <div>
        <SnackAlert
          open={this.props.snackbarReducer.open}
          onClose={this.props.actionHideSnackbar}
          message={this.props.snackbarReducer.message}
          variant={this.props.snackbarReducer.variant}
        />
      </div>
    );
  }
}

OverView.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {
    snackbarReducer : state.snackbarReducer,

  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {
      actionUpdateUserAvatar: updateUserAvatar,
      actionHideSnackbar: hideSnackbar,
      actionGetCollectionPartners: getCollectionPartners,
      actionGetUsers: getUsers,
      actionGetCaseStates: getCaseStates,
      actionGetCaseTypes : getCaseTypes
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withStyles(styles)(OverView));