import React, { useState } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import SendIcon from '@material-ui/icons/Send';

import './styles.css';

const SendMessage = ({ t, casesReducer, caseId, handleSendMessage,globalReducer }) => {
  const customer = casesReducer.results[caseId].info.customer;
  const collectionPartner = casesReducer.results[caseId].lawyer.name ;
  const [message, setMessage] = useState('');
  const [receiver, setReceiver] = useState('PIXRAY');
  const ownParty = localStorage.getItem('ownParty');

  return (
    <form
      className="send-message-container"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <select
        className="send-message-select"
        id="receiverParty"
        name="receiver"
        value={receiver}
        onChange={(event) => {
          setReceiver(event.target.value);
        }}
      >
        {ownParty !== 'PIXRAY' && <option value="PIXRAY">@PIXRAY</option>}
        {ownParty.toLowerCase() !== 'customer' && <option value="Customer">@{customer}</option>}
        {collectionPartner && ownParty.toLowerCase() !== 'collection partner' && (
          <option value="Collection Partner">@{collectionPartner}</option>
        )}
      </select>
      <div className="input-container">
        <input
          className="send-message-input"
          type="text"
          name="message"
          placeholder={t('_write_a_message')}
          onChange={(event) => setMessage(event.target.value)}
          required
          value={message}
        />
      </div>
      <button
        className="send-button"
        type="submit"
        value="submit"
        onClick={() =>
          handleSendMessage(receiver, message, caseId, '', () => setMessage(''))
        }
      >
        <SendIcon />
      </button>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    casesReducer: state.casesReducer,
    globalReducer : state.globalReducer
  };
}

export default translate('translations')(connect(mapStateToProps)(SendMessage));
