import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Autosuggest from 'react-autosuggest';
import styles from './styles';
import "./theme.css";


class AutoSuggest extends Component {
  constructor(props) {
    super();
      
    console.log("props.suggestions")
    console.log(props.suggestions);

    
    this.state = {
      allValues: props.suggestions,
      value: props.value,
      suggestions: []
    };
  }
  
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? this.state.allValues : 
    this.state.allValues.filter(val =>
      val.name.toLowerCase().includes(inputValue)
    );
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => (
    <div>
      {suggestion.name}
    </div>
  );

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.props.onValueChanged(newValue)
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { t, classes } = this.props;
    
    const inputProps = {
      placeholder: t("_tags"),
      value: this.state.value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        className={classes.root}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

AutoSuggest.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(translate("translations")(AutoSuggest));