import React from 'react';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';

import './style.css';

const CaseWarning = ({ caseWarning }) => {
  return (
    <div className="case-warning-containar">
      <WarningSharpIcon
        style={{
          fill: '#ffa726',
          marginRight: '10px',
        }}
      />
      {caseWarning}
    </div>
  );
};

export default CaseWarning;
