const Config = [{
    "id": "31370DA9-C5CD-4FFE-A529-0C8E05C87CE1",
    "value": "PhotographersNotice",
    "display": "Photographers Notice",
    "name": "Photographers Notice"
  },
  {
    "id": "D9D2BB9F-82A5-4660-95AD-124A2109744F",
    "value": "NachweisGewerblich",
    "display": "NachweisGewerblich",
    "name": "Nachweis Gewerblich"
  },
  {
    "id": "E5019BFB-B963-427E-A5D1-2114B20C4761",
    "value": "CollectionPartnerLawsuit",
    "display": "CollectionPartner Lawsuit",
    "name": "CollectionPartner Lawsuit"
  },
  {
    "id": "6E2CDE8E-EDF0-4EDE-9D4C-2EA6A1636F48",
    "value": "AutoScreenShot",
    "display": "Auto Screenshot",
    "name": "Auto Screenshot"
  },
  {
    "id": "991308D5-204D-4925-B742-36BA60488C1D",
    "value": "NachweisDeutscheZustaendigkeit",
    "display": "Nachweis Deutsche Zustaendigkeit",
    "name": "Nachweis Deutsche Zuständigkeit"
  },
  {
    "id": "308BA296-5250-4E9F-AE69-38C5F4A3833F",
    "value": "PictureLibraryScreenshot",
    "display": "Picture Library Screenshot",
    "name": "Picture Library Screenshot"
  },
  {
    "id": "3E74DBC1-95CE-4537-8404-467B0F2B8BA3",
    "value": "CaseReport",
    "display": "Case Report",
    "name": "Case Report"
  },
  {
    "id": "B59D6CE3-CC9B-4A34-8D49-6274050358D0",
    "value": "Default",
    "display": "Default",
    "name": "Default"
  },
  {
    "id": "0FB45DA4-DD96-49BC-8689-6465CD0BFF83",
    "value": "NachweisOestereichischeZustaendigkeit",
    "display": "Nachweis Oestereichische Zustaendigkeit",
    "name": "Nachweis Oestereichische Zuständigkeit"
  },
  {
    "id": "0FE482E4-41EC-4456-B72B-6C8E58A09749",
    "value": "SiteInformationScreenShot",
    "display": "Site Information Screenshot",
    "name": "Site Information Screenshot"
  },
  {
    "id": "1D0B40A4-1364-4E03-8DE8-6F1A385005B4",
    "value": "RecheckScreenShot",
    "display": "Recheck Screenshot",
    "name": "Recheck Screenshot"
  },
  {
    "id": "BB3C3BD5-1CB8-4286-9B1C-7573719A884D",
    "value": "CaseCalculationScreenshot",
    "display": "Case Calculation Screenshot",
    "name": "Case Calculation Screenshot"
  },
  {
    "id": "3B3A2C32-1B80-44B9-92E4-7EB923ED58EB",
    "value": "CaseCompleteReport",
    "display": "Case Complete Report",
    "name": "Case Complete Report"
  },
  {
    "id": "4045B640-9B23-4A99-8B86-80AF8AA107CB",
    "value": "HomeScreenShot",
    "display": "Home Screenshot",
    "name": "Home Screenshot"
  },
  {
    "id": "6C6AD1F7-8CC6-4EE4-BB00-8557BEBDA807",
    "value": "ScreenShotImprint",
    "display": "Screenshot Imprint",
    "name": "Screenshot Imprint"
  },
  {
    "id": "FB0D5013-9FC1-4B75-8032-938883D1E479",
    "value": "ArchiveScreenshot",
    "display": "Archive Screenshot",
    "name": "Archive Screenshot"
  },
  {
    "id": "EF66DC9D-3407-4F1F-BD39-9A37BB01C26C",
    "value": "DeadlineDocument",
    "display": "Deadline Document",
    "name": "Deadline Document"
  },
  {
    "id": "26FA62EE-0B92-4F67-BB70-BD170D2CD541",
    "value": "CaseCalculationReport",
    "display": "Case Calculation Report",
    "name": "Case Calculation Report"
  },
  {
    "id": "AE8A2641-0238-4091-88EE-C32C5DEFB7D4",
    "value": "Letter",
    "display": "Letter",
    "name": "Letter"
  },
  {
    "id": "65B75231-2AB3-4967-9C1E-CE9167E8B1BB",
    "value": "ScreenShotDenic",
    "display": "Screenshot Denic",
    "name": "Screenshot Denic"
  },
  {
    "id": "EFF26D6C-44FA-42FD-9379-DB3921772085",
    "value": "PdfResultDocument",
    "display": "Pdf Result Document",
    "name": "Pdf Result Document"
  },
  {
    "id": "D552544A-6F5E-45DB-A576-E2053D540C86",
    "value": "PdfTemplate",
    "display": "Pdf Template",
    "name": "Pdf Template"
  },
  {
    "id": "A5674614-BB50-434A-8F33-E31E9AD2F3FA",
    "value": "ManuScreenShot",
    "display": "Manu Screenshot",
    "name": "Manu Screenshot"
  },
  {
    "id": "8832F5CC-CE68-4FA2-9BBB-EEC2E43C46D1",
    "value": "ResultScreenShot",
    "display": "Result ScreenShot",
    "name": "Result ScreenShot"
  },
  {
    "id": "126CD937-C034-4BFD-B34A-F1F2101453BA",
    "value": "SearchTarget",
    "display": "Search Target",
    "name": "Search Target"
  },
  {
    "id": "529AA833-27D3-410A-8994-F9CDD8DF4134",
    "value": "CollectionPartner",
    "display": "CollectionPartner",
    "name": "CollectionPartner"
  },
  {
    "id": "2E2C2973-9B90-43FF-B666-FC9C3BF0B68D",
    "value": "NachweisSchweizerZustaendigkeit",
    "display": "Nachweis Schweizer Zustaendigkeit",
    "name": "Nachweis Schweizer Zuständigkeit"
  }
];

export default Config;