import React, { Component } from "react";
import CaseCard from "@Components/cards/CaseCard";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import _ from "lodash";
import { translate } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setToolbar } from "@Actions/toolbarActions";
import CasesService from "@Services/Cases";
import { getCases, getCasesMore, removeCases } from "@Actions/casesActions";
import {
  initCurrentFilterType,
  updateCurrentFilterId,
} from "@Actions/filterActions";
import { setNavbar1, setNavbar2, setNavbar3 } from "@Actions/navbarActions";
import { showSnackbar } from "@Actions/snackbarActions";
import { removeSearch } from "@Actions/searchActions";
import { getSelectedFilters, resetAllScroll } from "@Utils/helpers";
import LoadingBg from "@Components/LoadingBg";
import styles from "./styles";

class Cases extends Component {
  constructor(props) {
    super(props);
    this.props.actionRemoveSearch();
    this.props.hideLoading();
    this.props.showLoading();
    this.props.setToolbar(1);
    this.props.actionInitCurrentFilterType("case");
    setTimeout(() => {
      this.props.actionUpdateCurrentFilterId(getSelectedFilters()["case"]);
    }, 1000);

    this.props.setNavbar1({ name: this.props.t("_cases"), link: "/cases?r" });
    this.props.setNavbar2(null);
    this.props.setNavbar3(null);
  }

  state = {
    lbgMessage: "",
    lbgAnimated: true,
    isLoading: true,
    hasMore: false,
    isScrollReseted: false,
    detailsOpenedFor: null
  };

  componentDidMount() {
    if (
      this.props.casesReducer == null ||
      this.props.location.search === "?r" ||
      (this.props.casesReducer == null &&
        this.props.searchReducer.search.class == null)
    ) {
      this.props.actionRemoveCases();
      resetAllScroll();
      window.scrollTo(0, -300);
      CasesService.getCases({
        pageNum: 1,
        filter: getSelectedFilters()["case"],
      })
        .then((response) => {
          this.props.actionGetCases({ results: response });
          this.props.hideLoading();
          this.setState({ hasMore: true });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.setState({ hasMore: true });
          this.props.hideLoading();
          this.setState({ lbgAnimated: false, lbgMessage: error.message });
          this.props.actionShowSnackbar({
            message: error.message,
            variant: "error",
          });
        });
    } else {
      this.setState({ hasMore: true });
      this.props.hideLoading();
    }

    setTimeout(() => {
      window.scrollTo(0, parseInt(localStorage.getItem("posCases")));
    }, 1000);
  }

  componentWillUnmount() {
    localStorage.setItem("posCases", window.pageYOffset);
  }

  loadMore = (page) => {
    page = Object.keys(this.props.casesReducer.results).length / 20 + 1;
    if (
      this.props.casesReducer == null ||
      this.props.casesReducer.results === {} ||
      Object.keys(this.props.casesReducer).length === 0
    ) {
      if (this.state.isScrollReseted === false) {
        this.setState({ hasMore: true });
      }
    }

    if (this.state.isLoading) {
      return;
    }

    // if(this.props.casesReducer.results)
    if ((page - 1) * 20 > Object.keys(this.props.casesReducer.results).length) {
      this.setState({ hasMore: false });
    } else {
      this.setState({
        hasMore: false,
        isLoading: true,
        isScrollReseted: false,
      });
      CasesService.getCases({
        pageNum: page,
        filter: getSelectedFilters()["case"],
      })
        .then((response) => {
          this.props.actionGetCasesMore({ results: response });
          this.setState({ hasMore: true });
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.props.actionShowSnackbar({
            message: this.props.t(
              "_something_went_wrong_please_try_again_later"
            ),
            variant: "error",
          });
          console.log(error);
          this.setState({ hasMore: true });
          this.setState({ isLoading: false });
        });
    }
  };

  handleDetailsOpened = (caseId) =>{
    this.setState({ detailsOpenedFor: caseId });
  }

  populateCases() {
    var list = [];
    var that = this;
    _.forOwn(this.props.casesReducer.results, function (value, key) {
      list.push(
        <CaseCard
          detailsOpen={key === that.state.detailsOpenedFor}
          onDetailsOpened={that.handleDetailsOpened}
          caseId={key}
          key={key}
          page="cases"
        />
      );
    });

    return list;
  }

  render() {
    const { classes } = this.props;
    var hasReallyMore = true;
    if (this.props.casesReducer && this.props.casesReducer.results) {
      var page = Object.keys(this.props.casesReducer.results).length / 20 + 1;
      var reqPage = Math.floor(
        Object.keys(this.props.casesReducer.results).length / 20 + 1
      );
      if (page > reqPage) hasReallyMore = false;
    }
    if (
      this.props.casesReducer == null ||
      this.props.casesReducer.results === {} ||
      Object.keys(this.props.casesReducer).length === 0
    ) {
      // if(this.props.casesReducer.results === {}) this.setState({lbgAnimated : false});
      if (this.state.isScrollReseted === false) {
        this.setState({ hasMore: true, isScrollReseted: true });
      }
      return (
        <div className={classes.root}>
          <LoadingBg
            pageType="cases"
            message={this.state.lbgMessage}
            animated={this.state.lbgAnimated}
          />
        </div>
      );
    } else if (this.props.casesReducer && this.props.casesReducer.total === 0) {
      if (this.state.isScrollReseted === false) {
        this.setState({ hasMore: true, isScrollReseted: true });
      }
      return (
        <div className={classes.root}>
          <LoadingBg
            pageType="cases"
            message={this.props.t("_no_data_found")}
            animated={false}
          />
        </div>
      );
    } else {
    }

    return (
      <div className={classes.root}>
        <InfiniteScroll
          pageStart={this.props.casesReducer.results ? Math.floor(
            Object.keys(this.props.casesReducer.results).length / 20
          ): 0}
          loadMore={this.loadMore}
          hasMore={hasReallyMore}
          loader={
            <div className={classes.loader} key={0}>
              <LinearProgress color="secondary" />
            </div>
          }
        >
          {this.populateCases()}
        </InfiniteScroll>
        {!this.state.hasMore && this.state.isLoading && (
          <div className={classes.loader} key={1}>
            <LinearProgress color="secondary" />
          </div>
        )}
      </div>
    );
  }
}

Cases.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    casesReducer: state.casesReducer,
    filterReducer: state.filterReducer,
    searchReducer: state.searchReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      setToolbar,
      setNavbar1,
      setNavbar2,
      setNavbar3,

      actionRemoveSearch: removeSearch,
      actionRemoveCases: removeCases,
      actionGetCases: getCases,
      actionGetCasesMore: getCasesMore,
      actionInitCurrentFilterType: initCurrentFilterType,
      actionUpdateCurrentFilterId: updateCurrentFilterId,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(Cases)
  )
);
