import axios from 'axios';
import { handleForbiddenError } from '@Utils/helpers.js'

const client = axios.create();

const request = function (options) {
  const onSuccess = function (response) {
    console.debug('Request Successful!', response);
    return response.data;
  }

  const onError = function (error) {
    // console.error('Request Failed:', error.config);
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx

      // console.error('Status:', error.response.status);
      // console.error('Data:', error.response.data);
      // console.error('Headers:', error.response.headers);
      error.message = error.response.data.cause || error.response.statusText;
      
      if(error.response.status){
        handleForbiddenError(error.response.status);
      }
      
    } else {
      // Something else happened while setting up the request
      // triggered the error

      // console.error('Error Message:', error.message);
    }

    return Promise.reject(error);
  }

  return client(options)
    .then(onSuccess)
    .catch(onError);
}

export default request;