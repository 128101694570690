import React from "react";

import "./styles.css";

const PreviousMessageRow = ({ t,sender, receiverParty, message }) => {
  if (message.length > 35) {
    message = `${message.substring(0, 50)}...`;
  }
  return (
    <tr>
      <td colspan="2" className="previousMessage">
        <strong>
          {sender} {t("_to_receiver")}{" "}
          {receiverParty === "Customer" ? t("_customer") : receiverParty}
        </strong>{" "}
        {message}
      </td>
    </tr>
  );
};
export default PreviousMessageRow;
