import React from 'react';
import WarningSharpIcon from '@material-ui/icons/WarningSharp';
import './styles.css';

const WarningComponent = ({ message, t }) => {
  return (
    <div className="warning-container">
      <WarningSharpIcon
        style={{
          fill: '#ffa726',
        }}
      />
      <div className="warning-message">{message && t('_' + message)}</div>
    </div>
  );
};

export default WarningComponent;
