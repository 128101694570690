

const styles = theme => ({
  root:{
    minWidth: 250,
    maxWidth: 300,
    height: 390,
    maxHeight: 390,
    // paddingBottom: 200,
    fontSize: 12,
    overflowY: 'scroll',
    overflowX: 'hidden',
    borderRight: "2px solid #EAEAEA"
    // backgroundColor: '#453455'
  },

  addFilterButton:{
    position: 'absolute',
    top: 470,
    width: 252,
    borderRadius: 0,
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent"
    }
  
  },

  addIcon: {
    marginRight: theme.spacing.unit,
  },

  noFilterDefined:{
    textAlign : "center",
    width: "100%",
    fontSize: 16,
  },

  primary:{
    fontSize: 14
  }
});


export default styles;