import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import { translate } from "react-i18next";
import styles from "./styles";

import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class ActivateCaseButton extends Component {
  constructor(props) {
    super();
  }

  state = {
    open: false,
  };

  actionClicked = () => {
    this.props.onActionClicked(
      this.props.cardData.type,
      this.props.actionType,
      null
    );
    this.setState({ open: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={this.handleClickOpen}
          disabled={this.props.cardData.caseInfo.route !== "sales"}
          title={this.props.cardData.caseInfo.route !== "sales"? t("_activation_by_lawyer"):t("_activate_case") }
        >
          {t("_activate_case")}
        </Button>

        <DialogPopup open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>{t("_confirmation_required")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_do_you_confirm_activating_this_case")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t("_cancel")}
            </Button>
            <Button onClick={this.actionClicked} color="primary">
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

ActivateCaseButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
  translate("translations")(ActivateCaseButton)
);
