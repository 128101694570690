import { SET_SEARCH, REMOVE_SEARCH} from '@Types/actionTypes';

export function setSearch(data){
  return {
    type : SET_SEARCH,
    payload : data
  }
}

export function removeSearch(){
  return {
    type : REMOVE_SEARCH,
    payload : null
  }
}