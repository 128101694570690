import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

import { showSnackbar } from "@Actions/snackbarActions";
import styles from "./styles";
import FilterRule from "./FilterRule";
import DialogPopup from "@Components/DialogPopup";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import DuplicateIcon from "@material-ui/icons/FileCopy";
import SaveIcon from "@material-ui/icons/Save";
import {
  updateFilterName,
  addFilterRule,
  removeFilterRule,
  removeFilter,
  addFilter,
  updateSaved,
  revertFilterData,
  updateIsNew,
  updateFilterAllorany,
  updateFilterIsPrivate,
  updateSearchFilterId,
  updateCurrentFilterId,
} from "@Actions/filterActions";
import UserService from "@Services/User";
import {
  hasFilterEmptyValues,
  isFilterInUse,
  hasFilterDuplicateName,
} from "@Utils/helpers";
import {
  IconButton,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

class FilterCreator extends Component {
  constructor(props) {
    super();
  }

  state = {
    openDelete: false,
    alloranyOpen: false,
    openDeleteNotAllowed: false,
    openCircularRefDetected: false,
  };

  componentDidMount() {}

  handleDuplicateFilter = () => {
    const id = "new_" + Date.now();
    this.props.actionAddFilter(this.generateDuplicateData(id));
    this.props.actionUpdateCurrentFilterId(id);
    this.props.actionUpdateSaved(false);
    this.props.actionUpdateIsNew(true);
  };

  generateDuplicateData = (id) => {
    var data = {};
    data.id = id;
    data.name =
      "Copy - " +
      this.props.filterReducer.filters[this.props.filterReducer.currentFilterId]
        .name;
    data.type =
      this.props.filterReducer.filters[
        this.props.filterReducer.currentFilterId
      ].type;
    data.allorany =
      this.props.filterReducer.filters[
        this.props.filterReducer.currentFilterId
      ].allorany;
    data.isPrivate =  this.props.filterReducer.filters[
      this.props.filterReducer.currentFilterId
    ].isPrivate;
    data.rules = {};

    _.forOwn(
      this.props.filterReducer.filters[this.props.filterReducer.currentFilterId]
        .rules,
      function (rule, key) {
        console.log(rule);

        var newId = "new_rule_" + Date.now() + key;
        data.rules[newId] = { ...rule, id: newId };
      }
    );
    return data;
  };

  handleNameChange = (event) => {
    var data = {
      name: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
    };
    this.props.actionUpdateFilterName(data);
    this.props.actionUpdateSaved(false);
  };

  handleAlloranyChange = (event) => {
    var data = {
      allorany: event.target.value,
      filterId: this.props.filterReducer.currentFilterId,
    };
    this.props.actionUpdateFilterAllorany(data);
    this.props.actionUpdateSaved(false);
  };

  handleAlloranyOpen = () => {
    this.setState({ alloranyOpen: true });
  };

  handleAlloranyClose = () => {
    this.setState({ alloranyOpen: false });
  };

  handleVisibilityChanged = (event, checked) => {
    var data = {
      isPrivate: checked,
      filterId: this.props.filterReducer.currentFilterId,
    };
    this.props.actionUpdateFilterIsPrivate(data);
    this.props.actionUpdateSaved(false);
  };

  handleCloseDelete = () => {
    this.setState({ openDelete: false });
  };

  handleCloseDeleteNotAllowed = () => {
    this.setState({ openDeleteNotAllowed: false });
  };

  handleCloseCircularRefDetected = () => {
    this.setState({ openCircularRefDetected: false });
  };

  handleAcceptDelete = () => {
    const isSearch = this.props.navbarReducer.navbar1.link.includes("/search/");

    if (
      isFilterInUse(
        this.props.filterReducer.filters,
        this.props.filterReducer.currentFilterId
      )
    ) {
      //this.props.actionShowSnackbar({ message: this.props.t("_this_filter_is_referenced_in_other_filter_cant_be_deleted"), variant: "warning" });
      this.setState({ openDelete: false, openDeleteNotAllowed: true });
      return false;
    }

    UserService.removeFilter(this.props.filterReducer.currentFilterId)
      .then(() => {
        this.setState({ openDelete: false }, function () {
          var data = {
            filterId: this.props.filterReducer.currentFilterId,
          };
          this.props.actionRemoveFilter(data);
          this.props.actionUpdateSaved(true);
          this.props.actionUpdateIsNew(false);
        });

        if (isSearch) {
          this.props.actionUpdateSearchFilterId("0");
        }
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        if (error.response.status === 409)
          this.setState({ openDelete: false, openDeleteNotAllowed: true });
      });
  };

  handleSaveFilter = () => {
    if (
      hasFilterDuplicateName(
        this.props.filterReducer.filters,
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ].name
      )
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_duplicate_filter_name"),
        variant: "warning",
      });
      return false;
    }

    if (
      hasFilterEmptyValues(
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ]
      )
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_you_have_empty_fields_filter_cannot_be_saved"),
        variant: "warning",
      });
      return false;
    }

    UserService.addFilter(
      this.props.filterReducer.filters[this.props.filterReducer.currentFilterId]
    )
      .then((response) => {
        var updatedFilter =
          this.props.filterReducer.filters[
            this.props.filterReducer.currentFilterId
          ];
        let doDeleteOldFilter = false;
        let oldFilterID = null;
        if (updatedFilter.id.startsWith("new_") === false && updatedFilter.id.toUpperCase() !== response.toUpperCase()) {
          // this was an edit (not new filter) so we must delete the old
          doDeleteOldFilter = true;
          oldFilterID = updatedFilter.id;
        }

        updatedFilter.id = response.toUpperCase();

        //swap filter id
        this.props.actionRemoveFilter({
          filterId: this.props.filterReducer.currentFilterId,
        });
        this.props.actionAddFilter(updatedFilter);
        this.props.actionUpdateCurrentFilterId(response.toUpperCase());

        if (doDeleteOldFilter === true) {
          UserService.removeFilter(oldFilterID)
            .then(() => {})
            .catch((e) => {
              this.props.actionShowSnackbar({
                message: this.props.t("_editing_filter_failed"),
                variant: "warning",
              });
            });
        }

        this.props.actionUpdateSaved(true);
        this.props.actionUpdateIsNew(false);
      })
      .catch((err) => {
        if (err && err.response.status === 409) {
          this.setState({ openCircularRefDetected: true });
        }
      });
  };

  handleRemoveFilter = () => {
    this.setState({ openDelete: true });
  };

  handleOpenDeleteNotAllowed = () => {
    this.setState({ openDeleteNotAllowed: true });
  };

  handleAddRule = () => {
    if (
      hasFilterDuplicateName(
        this.props.filterReducer.filters,
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ].name
      )
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_duplicate_filter_name"),
        variant: "warning",
      });
      return false;
    }

    if (
      hasFilterEmptyValues(
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ]
      )
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t(
          "_you_have_empty_field_new_filter_cannot_be_added"
        ),
        variant: "warning",
      });
      return false;
    }

    var data = {
      filterRuleData: {
        id: "new_" + Date.now(),
        subfilterId: "0",
        operationId: "0",
        ruleValue: "",
      },
      filterId: this.props.filterReducer.currentFilterId,
    };
    this.props.actionAddFilterRule(data);
    this.props.actionUpdateSaved(false);
  };

  removeRule = (filterEnteryId) => {
    var data = {
      filterRuleId: filterEnteryId,
      filterId: this.props.filterReducer.currentFilterId,
    };
    this.props.actionRemoveFilterRule(data);
    this.props.actionUpdateSaved(false);
  };

  populateEntries = () => {
    var list = [];

    if (this.props.filterReducer.currentFilterId.toString() !== "0")
      _.forOwn(
        this.props.filterReducer.filters[
          this.props.filterReducer.currentFilterId
        ].rules,
        function (filter, key) {
          list.push(
            <FilterRule
              key={key}
              data={filter}
              handleRemoveRule={this.removeRule}
            />
          );
        }.bind(this)
      );

    return list;
  };

  render() {
    const { classes, filterReducer, t } = this.props;
    const currentfilterId = this.props.filterReducer.currentFilterId;
    const filter = filterReducer.filters[currentfilterId];

    if (currentfilterId == null || currentfilterId.toString() === "0" || !filter) {
      return (
        <div className={classes.noFilterSelected}>
          {t("_no_filter_is_selected_")}
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <FormControl className={classes.filterNameFormControl}>
          <TextField
            value={filterReducer.filters[currentfilterId].name}
            onChange={this.handleNameChange}
            id="filterName"
            label={t("_filter_name")}
            className={classes.filterNameTextField}
          />

          <div className={classes.duplicateBtnContainer}>
            <IconButton
              color="primary"
              onClick={this.handleDuplicateFilter}
              disabled={this.props.filterReducer.saved ? false : true}
              title={
                this.props.filterReducer.saved
                  ? t("_copy_filter")
                  : t("_you_cant_copy_filters_if_you_have_unsaved_changes")
              }
            >
              <DuplicateIcon className={classes.duplicateIcon} />
            </IconButton>
          </div>
          <div className={classes.saveBtnContainer}>
            <IconButton
              color="primary"
              onClick={this.handleSaveFilter}
              disabled={this.props.filterReducer.saved ? true : false}
            >
              <SaveIcon className={classes.saveIcon} />
            </IconButton>
          </div>
          <div className={classes.removeBtnContainer}>
            <IconButton color="secondary" onClick={this.handleRemoveFilter}>
              <RemoveIcon className={classes.removeIcon} />
            </IconButton>
          </div>
        </FormControl>
        <div className={classes.visibilitySwitchContainer}>
          <FormControlLabel
            classes={{ label: classes.switchLabel }}
            control={
              <Switch
                color="primary"
                className={classes.switch}
                classes={{
                  checked: classes.checked,
                  bar: classes.bar,
                }}
                checked={filterReducer.filters[currentfilterId].isPrivate}
                onChange={(event, checked) =>
                  this.handleVisibilityChanged(event, checked)
                }
              />
            }
            label={t("_filter_is_private")}
          />
        </div>
        <br />
        {_.isEmpty(this.props.filterReducer.filters[currentfilterId].rules) ? (
          <p className={classes.noFilterDefined}>
            {t("_this_filter_has_no_rules_yet")}
          </p>
        ) : (
          <></>
        )}
        <br />
        {this.populateEntries()}

        <div className={classes.addBtnContainer}>
          {Object.keys(filterReducer.filters[currentfilterId].rules).length >=
            2 && (
            <form autoComplete="off">
              <FormControl className={classes.alloranyFormControl}>
                {/* <InputLabel htmlFor="operation-type-select">Select Entity:</InputLabel> */}
                <Select
                  disabled={this.state.isCreating ? true : false}
                  className={classes.alloranyInput}
                  open={this.state.alloranyOpen}
                  onClose={this.handleAlloranyClose}
                  onOpen={this.handleAlloranyOpen}
                  value={filterReducer.filters[currentfilterId].allorany}
                  onChange={this.handleAlloranyChange}
                  inputProps={{
                    name: "operationType",
                    id: "operationType-select",
                  }}
                >
                  <MenuItem key={0} value={"all"}>
                    {t("_all_have_to_match")}
                  </MenuItem>
                  <MenuItem key={1} value={"any"}>
                    {t("_any_has_to_match")}
                  </MenuItem>
                </Select>
              </FormControl>
            </form>
          )}

          <Button
            className={classes.addRuleButton}
            variant="contained"
            color="default"
            size="medium"
            onClick={this.handleAddRule}
          >
            <AddIcon className={classes.addIcon} />
            {t("_add_rule")}
          </Button>
        </div>

        <DialogPopup
          open={this.state.openDelete}
          onClose={this.handleCloseDelete}
        >
          <DialogTitle>{t("_confirmation_required")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_are_you_sure_you_want_to_delete_this_filter")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDelete}>{t("_cancel")}</Button>
            <Button onClick={this.handleAcceptDelete} color="secondary">
              {t("_delete")}
            </Button>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.openDeleteNotAllowed}
          onClose={this.handleCloseDeleteNotAllowed}
        >
          <DialogTitle>{t("_warning")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_deletion_of_filter_not_allowed")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDeleteNotAllowed}
              color="secondary"
            >
              {t("_OK")}
            </Button>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.openCircularRefDetected}
          onClose={this.handleCloseCircularRefDetected}
        >
          <DialogTitle>{t("_warning")}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t("_circular_ref_detected")}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseCircularRefDetected}
              color="secondary"
            >
              {t("_OK")}
            </Button>
          </DialogActions>
        </DialogPopup>
      </div>
    );
  }
}

FilterCreator.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    filterReducer: state.filterReducer,
    navbarReducer: state.navbarReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionUpdateFilterName: updateFilterName,
      actionUpdateFilterAllorany: updateFilterAllorany,
      actionUpdateFilterIsPrivate: updateFilterIsPrivate,
      actionAddFilterRule: addFilterRule,
      actionRemoveFilterRule: removeFilterRule,
      actionRemoveFilter: removeFilter,
      actionAddFilter: addFilter,
      actionUpdateSaved: updateSaved,
      actionRevertFilterData: revertFilterData,
      actionUpdateIsNew: updateIsNew,
      actionUpdateSearchFilterId: updateSearchFilterId,
      actionUpdateCurrentFilterId: updateCurrentFilterId,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(FilterCreator)));
