const styles = (theme) => ({
  root: {
    position: "relative",
    borderBottom: "1px solid #AAA",
    paddingBottom: 1,
    "&:hover": {
      borderBottom: "2px solid #333",
      paddingBottom: 0,
    },
  },
  notesContainer: {
    width: "90%",
    minHeight: 55,
    maxHeight: 80,
    fontSize: 12,
    padding: "2px 8px",
    overflow: "auto",
    boxSizing: "border-box",
    maxWidth: 840,
    overflowWrap: "break-word",
  },
  Input: {
    width: "100%",
    fontSize: 12,
    marginTop: -15,
    // cursor:'default'
  },
  actionSection: {
    paddingBottom: 10,
  },
  tbAddNote: {
    float: "left",
    marginLeft: 20,
  },
  btnAddNote: {
    float: "right",
    width: 150,
  },
  button: {
    position: "absolute",
    top: 5,
    right: 0,
  },
  contentContainer: {
    maxHeight: 600,
    overflow: "scroll",
    maxWidth: 800,
  },
  table: {
    maxHeight: 400,
    overflow: "scroll",
  },
  row: {
    whiteSpace: "pre-line",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  noteTitle: {
    textAlign: "right",
    fontSize: 10,
    fontWeight: "bold",
  },
  noteDeleteContainer: {
    textAlign: "right",
    margin: "0px 0px",
    padding: "0px 0px",
  },
  noteDelete: {
    fontSize: 10,
    color: "grey",
  },
});

export default styles;
