import { grey } from "@material-ui/core/colors";

const styles = (theme) => ({
  root: {},
  feesContainer: {
    height: "100%",
    position: "relative"
  },
  tblGeneral: {
    width: "100%",
    fontSize: 13,
    borderSpacing: 0,
    // borderBottom: '1px solid #999',
    paddingBottom: 10,
    color: grey[800],
  },
  trLeft: {
    fontWeight: 600,
    paddingTop: 10,
    fontSize: 14,
  },
  trRight: {},
  editFeesButton: {
    position: "absolute",
    bottom: 1,
    right:1
  },
  rootTable: {
    width: "100%",
    borderCollapse: "collapse",
    height: "377px"
  },
  feeAndPaymentHeadline: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "1rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    margin: 0,
  },
});

export default styles;
