import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import styles from "./styles";
import { numberToLocaleString } from "@Utils/helpers";

class CardInfo extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes, cardInfoData, t } = this.props;

    return (
      <div className={classes.root}>
        <table className={classes.info}>
          <tbody>
            <tr className={classes.bold}>
              <td>{t("_new_images")}</td>
              <td>{cardInfoData.newFound}</td>
            </tr>
            <tr>
              <td>{t("_all_images")}</td>
              <td>{cardInfoData.allFound}</td>
            </tr>
            <tr className={classes.divider}>
              <td></td>
              <td></td>
            </tr>
            <tr className={classes.bold}>
              <td>{t("_open_cases")}</td>
              <td>{cardInfoData.openCases}</td>
            </tr>
            <tr>
              <td>{t("_closed_cases")}</td>
              <td>{cardInfoData.closedCases}</td>
            </tr>
            <tr>
              <td>{t("_canceled_cases")}</td>
              <td>{cardInfoData.abortedCases}</td>
            </tr>
            <tr className={classes.divider}>
              <td></td>
              <td></td>
            </tr>
            <tr className={classes.bold}>
              <td>{t("_total_payments")}</td>
              <td>{numberToLocaleString(cardInfoData.totalEarning)} €</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

CardInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(CardInfo));
