import React, { useState } from "react";
import CasesService from "@Services/Cases";
import { translate } from "react-i18next";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { updateCaseTodos } from "@Actions/caseTodosActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formatDatetime } from "../../../../../../@Utils/helpers";

import "./styles.css";
import { decreaseUnreadCount } from "@Actions/casesActions";

const CaseMessage = ({
  t,
  message,
  handleReplyMessage,
  replyMessage,
  previousMessage,
  actionUpdateCaseTodos,
  actionDecreaseUnreadCount
}) => {
  const [markAsRead, setMarkAsRead] = useState(false);

  const handleMarkingAsRead = (data) => {
    CasesService.markingAsRead(data)
      .then((response) => {
        setMarkAsRead(true);
        actionDecreaseUnreadCount({caseId : data.caseID})
        CasesService.getCaseTodos()
          .then((response) => {
            actionUpdateCaseTodos(response);
          })
          .catch((err) => {
            console.log(
              "Error while update Case Todos after review state change.",
              err
            );
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <table
      key={message.id}
      className={replyMessage ? "tblGeneralReply" : "tblGeneral"}
      //ref={index === data.length && this.lastMessageRef}
    >
      <tbody>
        {previousMessage}
        <tr className="trTitle">
          <td className="tdSender">
            {/* {' //trleft '} */}
            {previousMessage && t("_answer")} {message.sender}{" "}
            {t("_to_receiver")}{" "}
            {message.receiverParty === "Customer"
              ? t("_customer")
              : message.receiverParty}
            {(message.doneDate || markAsRead) && (
              <CheckIcon viewBox="0 0 20 20" className="svg_icons" />
            )}
          </td>
          <td className="sentDate">{formatDatetime(message.sentDate)}</td>
        </tr>
        <tr>
          <td className="tdMessage" colSpan="2">
            {message.message}
          </td>
        </tr>
        <tr className="trRight">
          <td colSpan="2" className="tdButtons">
            {message.receiverParty === message.ownParty &&
            !replyMessage &&
            !message.doneDate &&
            !markAsRead ? (
              <Button
                color="primary"
                className="link"
                onClick={() => handleMarkingAsRead(message)}
              >
                {t("_mark_as_read")}
              </Button>
            ) : (
              <Button disabled>{t("_mark_as_read")}</Button>
            )}
            {message.receiverParty === message.ownParty && !replyMessage ? (
              <Button
                color="primary"
                className="link"
                onClick={() => {
                  handleReplyMessage(message.id, message.senderParty);
                }}
              >
                {t("_reply")}
              </Button>
            ) : (
              <Button disabled>{t("_reply")}</Button>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionUpdateCaseTodos: updateCaseTodos,
      actionDecreaseUnreadCount: decreaseUnreadCount
    },
    dispatch
  );
}
export default translate("translations")(
  connect(null, matchDispatchToProps)(CaseMessage)
);
