import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { translate } from "react-i18next";
import DialogPopup from "@Components/DialogPopup";
import { withRouter } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Link from "@material-ui/core/Link";
import { showSnackbar } from "@Actions/snackbarActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import InfoOutlined from "@material-ui/icons/InfoOutlined";

import Button from "@material-ui/core/Button";

import FindingsService from "@Services/Findings";
import MediaService from "@Services/Media";
import styles from "./styles";
import { formatDatetime } from "@Utils/helpers.js";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { deleteFinding } from "@Actions/findingsActions";
import { updateDomainFindingCount } from "@Actions/domainsActions";

class ActionsButton extends Component {
  constructor(props) {
    super();
  }

  state = {
    popupHistoryOpen: false,
    history: null,

    anchorEl: null,

    openDeleteFindingPopup: false,
    openDeleteAllFindingsPopup: false,

    casesOfSearchtarget: [],
    casesOfSearchtargetDialogOpen: false,
    casesOfSearchtargetDialogLoading: false,

    deletionResult: {},
    openDeletionResultPopup: false,
  };

  handleActionClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleActionClose = () => {
    this.setState({ anchorEl: null });
  };

  handleOpenPopupDeleteImageAndFindings = () => {
    this.setState({ anchorEl: null });
    this.setState({ openDeleteAllFindingsPopup: true });
  };

  handleCloseDeleteAllFindingsPopup = () => {
    this.setState({ openDeleteAllFindingsPopup: false });
  };

  deleteAllFindingsConfirmClicked = () => {
    MediaService.deleteFindingsAndMedia(this.props.data.mediaId)
      .then((response) => {
        /*
{case_findings: 1
deleted_findings: 2
image_status: "paused"} is rretval
        */

        this.props.actionShowSnackbar({
          message: this.props.t("_all_findings_successfully_deleted"),
          variant: "success",
        });
        // open deletion info popup
        this.setState({ openDeletionResultPopup: true });
        this.setState({ deletionResult: response });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });
  };

  onDeletionResultPopupClose = () => {
    this.setState({ openDeleteAllFindingsPopup: false });
    this.setState({ openDeletionResultPopup: false });
    this.props.actionDeleteFinding(this.props.data);
    this.props.actionUpdateDomainFindingCount(this.props.data, -1);
  };

  handleNavigateToSearchImage = () => {
    let mediaID = this.props.data.findingDetails.searchNumber;
    let code = Buffer.from(
      JSON.stringify({
        search: {
          class: { name: "media", value: "media", display: "images" },
          subclass: {
            name: "file name",
            value: "file_name",
            display: "file_name",
          },
          value: '"' + mediaID + '"',
        },
      })
    ).toString("base64");
    this.props.history.push("/search/" + code);
  };

  handleOpenPopupHistory = () => {
    FindingsService.getFindingsHistory(this.props.data)
      .then((data) => {
        this.setState({ history: data });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
      });

    this.setState({ popupHistoryOpen: true, anchorEl: null });
  };

  handleClosePopupHistory = () => {
    this.setState({ popupHistoryOpen: false });
  };

  handleShowSearchtargetCases = () => {
    //clean state
    this.setState({
      casesOfSearchtarget: [],
      casesOfSearchtargetDialogOpen: true,
      casesOfSearchtargetDialogLoading: true,
    });
    this.handleActionClose();

    FindingsService.getCasesOfSearchTargetByFindingID(this.props.data.findingId)
      .then((response) => {
        //response = [{id,creator,creationDate,state}]
        // put respnse into state
        this.setState({
          casesOfSearchtarget: response,
          casesOfSearchtargetDialogLoading: false,
        });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        this.setState({
          casesOfSearchtarget: [],
          casesOfSearchtargetDialogOpen: false,
          casesOfSearchtargetDialogLoading: false,
        });
        console.log(error);
      });
  };

  handleCloseCasesOfSearchtargetDialog = () => {
    this.setState({
      casesOfSearchtargetDialogOpen: false,
      casesOfSearchtargetDialogLoading: false,
    });
  };

  generateCaseTableTable = () => {
    const { classes, t } = this.props;

    //check if we are still loading
    if (this.state.casesOfSearchtargetDialogLoading) {
      return <>Loading...</>;
    }
    let data = this.state.casesOfSearchtarget;
    return (
      <div className={classes.tblContainerHistory}>
        <Table className={classes.tblDeatils}>
          <TableBody>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFirst}>
                <b>{t("_case_number")}</b>
              </TableCell>
              <TableCell className={classes.rowDetailsFirst}>
                <b>{t("_crator")}</b>
              </TableCell>
              <TableCell className={classes.rowDetailsFirst}>
                <b>{t("_creation_date")}</b>
              </TableCell>
            </TableRow>
            {data.map((item) => (
              <TableRow className={classes.rowDeatils} key={item.id}>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    <Link href={"/case/" + item.id} className={classes.nowrap}>
                      {item.number}{" "}
                    </Link>
                  </b>
                </TableCell>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    <p className={classes.nowrap}>{item.creator}</p>
                  </b>
                </TableCell>
                <TableCell className={classes.rowDetailsSecond}>
                  {formatDatetime(item.creationDate, "short")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  generateHistoryTable = (data) => {
    const { classes, t } = this.props;
    if (data === null) {
      return <div></div>;
    }
    return (
      <div className={classes.tblContainerHistory}>
        <Table className={classes.tblDeatils}>
          <TableBody>
            <TableRow className={classes.rowDeatils}>
              <TableCell className={classes.rowDetailsFrist}>
                <b>{t("_history")}</b>
              </TableCell>
              <TableCell className={classes.rowDetailsSecond}></TableCell>
            </TableRow>
            {data.map((item) => (
              <TableRow className={classes.rowDeatils} key={item.userName}>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    <p className={classes.nowrap}>{item.userName} </p>
                  </b>
                </TableCell>
                <TableCell className={classes.rowDetailsFrist}>
                  <b>
                    <p className={classes.nowrap}>
                      {t("_" + item.action.toLowerCase())}
                    </p>
                  </b>
                </TableCell>
                <TableCell className={classes.rowDetailsSecond}>
                  {formatDatetime(item.date, "medium")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  };

  render() {
    const { classes, t } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.root}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={this.handleActionClick}
        >
          {t("_more_actions")}
        </Button>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleActionClose}
        >
          <MenuItem onClick={this.handleShowSearchtargetCases}>
            {t("_show_cases_of_searchtarget")}
          </MenuItem>
          <MenuItem onClick={this.handleOpenPopupHistory}>
            {t("_show_history")}
          </MenuItem>
          <MenuItem onClick={this.handleOpenPopupDeleteImageAndFindings}>
            {t("_delete_findings_and_searchimage")}
          </MenuItem>
          <MenuItem onClick={this.handleNavigateToSearchImage}>
            {t("_navigate_to_searchimage")}
          </MenuItem>
        </Menu>

        <DialogPopup
          open={this.state.popupHistoryOpen}
          onClose={this.handleClosePopupHistory}
        >
          <DialogContent>
            {this.generateHistoryTable(this.state.history)}
          </DialogContent>
        </DialogPopup>

        <DialogPopup
          open={this.state.openDeleteAllFindingsPopup}
          onClose={this.handleCloseDeleteAllFindingsPopup}
        >
          <DialogTitle>{t("_warning")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t(
                "_do_you_really_want_to_permanently_delete_all_findings_and_media"
              )}

              <Paper className={classes.alertPaper}>
                <InfoOutlined className={classes.alertIcon}></InfoOutlined>
                {t("_hint_delete_finding_with_cases")}
              </Paper>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDeleteAllFindingsPopup}
              color="primary"
            >
              {t("_cancel")}
            </Button>
            <Button
              onClick={this.deleteAllFindingsConfirmClicked}
              color="primary"
            >
              {t("_yes")}
            </Button>
          </DialogActions>
        </DialogPopup>

        <DialogPopup
          open={this.state.casesOfSearchtargetDialogOpen}
          onClose={this.handleCloseCasesOfSearchtargetDialog}
        >
          <DialogContent>{this.generateCaseTableTable()}</DialogContent>
        </DialogPopup>

        {this.state.openDeletionResultPopup && (
          <DialogPopup
            open={this.state.openDeletionResultPopup}
            onClose={() => this.onDeletionResultPopupClose()}
          >
            <DialogTitle>{t("_DeletionResult")}</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>{t("_Findings_deleted")}</TableCell>
                      <TableCell>
                        {this.state.deletionResult.deleted_findings}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("_Findings_in_cases")}</TableCell>
                      <TableCell>
                        {this.state.deletionResult.case_findings}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t("_search_image_state")}</TableCell>
                      <TableCell>
                        {this.state.deletionResult.image_status === "deleted"
                          ? t("_deleted")
                          : t("_removed_from_search")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </DialogContentText>
              <DialogContentText style={{marginTop: "50px"}}>
                {t("_please_reload_to_remove_domains_hint")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.onDeletionResultPopupClose()}
                color="primary"
              >
                {t("_close")}
              </Button>
            </DialogActions>
          </DialogPopup>
        )}
      </div>
    );
  }
}

ActionsButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    findingsReducer: state.findingsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionDeleteFinding: deleteFinding,
      actionUpdateDomainFindingCount: updateDomainFindingCount,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(withRouter(ActionsButton))
  )
);
