import { 
  GET_ALL_MEDIA_TAGS,
  GET_ALL_CASE_TAGS,
  GET_ALL_DOMAIN_TAGS,
  UPDATE_ALL_MEDIA_TAGS,
  UPDATE_ALL_CASE_TAGS,
  UPDATE_ALL_DOMAIN_TAGS,
  REMOVE_ALL_TAGS
} from '@Types/actionTypes';

export function getAllDomainTags(tags){
  // tags

  return {
    type : GET_ALL_DOMAIN_TAGS,
    payload : tags
  }
}

export function getAllCaseTags(tags){
  // tags

  return {
    type : GET_ALL_CASE_TAGS,
    payload : tags
  }
}

export function getAllMediaTags(tags){
  // tags

  return {
    type : GET_ALL_MEDIA_TAGS,
    payload : tags
  }
}

export function updateAllMediaTags(tag){
  // tag

  return {
    type : UPDATE_ALL_MEDIA_TAGS,
    payload : tag
  }
}


export function updateAllDomainTags(tag){
  // tag

  return {
    type : UPDATE_ALL_DOMAIN_TAGS,
    payload : tag
  }
}


export function updateAllCaseTags(tag){
  // tag

  return {
    type : UPDATE_ALL_CASE_TAGS,
    payload : tag
  }
}

export function removeAllTags(tag){
  // tag

  return {
    type : REMOVE_ALL_TAGS,
    payload : null
  }
}