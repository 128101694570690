import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Config from '@Configs/cardStatusConfig';
import classnames from 'classnames'
import { translate } from 'react-i18next';
import styles from './styles';

class CardLabel extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes, cardData, t } = this.props;
    
    return (

      <div className={classnames(classes.root, classes[Config[cardData.type + "_status_" + cardData.status].color + "Status"])}>
        {t("_" + Config[cardData.type + "_status_" + cardData.status].display)}
      </div>
    );
  }

}

CardLabel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(translate("translations")(CardLabel));