
const styles = theme => ({
  root: {

  },
  info:{
    '& td':{ 
      fontSize:14,
      width:"10%"
    }
  },
  bold:{
    fontWeight:"bold",
    fontSize: 14
  },
  divider:{
    '& td':{ 
      height:5
    }
  }
});


export default styles;