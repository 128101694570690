import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import { unAuthenticate } from '@Actions/authActions';
import { removeDomains } from '@Actions/domainsActions';
import { removeCases } from '@Actions/casesActions';
import { removeMedia } from '@Actions/mediaActions';
import { 
  getAllCaseTags, 
  getAllMediaTags, 
  getAllDomainTags,
  removeAllTags
} from "@Actions/tagsActions";

import FilterNavigation from "@Components/FilterNavigation";

import DomainsService from "@Services/Domains";
import CasesService from "@Services/Cases";
import MediaService from "@Services/Media";
import { translate } from 'react-i18next';
import TopMenu from "./TopMenu";
import LogView from "./LogView";
import Toolbar from "./Toolbar";
import OverView from "./OverView";

import Button from '@material-ui/core/Button';
import DialogPopup from '@Components/DialogPopup';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { logout } from '@Utils/helpers.js'; 
import styles from './styles';

class Layout extends Component {

  componentDidMount(){ 
    DomainsService.getAllDomainTags().then(
      (response) => {
        this.props.actionGetAllDomainTags(response);
      }).catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
    CasesService.getAllCaseTags().then(
      (response) => {
        this.props.actionGetAllCaseTags(response);
      }).catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
    MediaService.getAllMediaTags().then(
      (response) => {
        this.props.actionGetAllMediaTags(response);
      }).catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });
  }

  actionClicked = () => {
    logout();
    this.props.actionUnAuthenticate();
    this.props.actionRemoveCases();
    this.props.actionRemoveDomains();
    this.props.actionRemoveMedia();
    this.props.actionRemoveAllTags();
  };

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }


  render() {
    const { classes, t } = this.props;

    return (
      <div  className={classes.root}>
        <TopMenu toggleLogview={this.toggleLogview} />
        <Toolbar/>  
        <FilterNavigation></FilterNavigation>
        <div className={classes.mainContainer}>
          
          <div className={classes.contentContainer}>
            {this.props.children}
          </div>
          <LogView/>
          <OverView/>
        </div>

        <DialogPopup open={!this.props.authReducer.validated} onClose={this.handleClose} 
          disableBackdropClick={true}
          disableEscapeKeyDown={true} >
          <DialogTitle>
            {t("_warning")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("_your_active_session_has_expired_please_login_again")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.actionClicked} color="secondary">
              {t("_logout")}
            </Button>
          </DialogActions>
        </DialogPopup>

      </div>
    );
  }
}


Layout.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state){
  return {
    authReducer: state.authReducer
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {
      actionGetAllDomainTags: getAllDomainTags,
      actionGetAllCaseTags: getAllCaseTags,
      actionGetAllMediaTags: getAllMediaTags,
      
      actionUnAuthenticate: unAuthenticate,
      actionRemoveCases: removeCases,
      actionRemoveDomains: removeDomains,
      actionRemoveMedia: removeMedia,
      actionRemoveAllTags: removeAllTags
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(translate("translations")(withStyles(styles)(Layout)));