
import { GET_LOGS, ADD_LOGS, REMOVE_LOGS, TOGGLE_LOGS } from '@Types/actionTypes';
// import store from '@Core/store';

export function getLogs(data){
  return {
    type : GET_LOGS,
    payload : data
  }
}

export function addLog(data){
  var newData = data;
  return {
    type : ADD_LOGS,
    payload : newData
  }
}

export function removeLogs(){
  return {
    type : REMOVE_LOGS,
    payload : null
  }
}

export function toggleLogs(){
  return {
    type : TOGGLE_LOGS,
    payload : null
  }
}

