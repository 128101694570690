import { red, grey } from '@material-ui/core/colors';

const styles = theme => ({
  card: {
    display:"flex",
    flexDirection: 'column',
    // marginLeft: "15px",
    // marginRight: "15px",
    overflow:'visible',
    minWidth: "884px"
  },
  header:{
    padding:0,
    backgroundColor:grey[50],
    display: 'flex',
    flexDirection: 'row',
    textDecoration:"none",
    borderBottom: "2px solid #a4a4a4",
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    zIndex:1
  },
  tooltip: {
    maxWidth: 'none',
  },
  headerLink:{
    marginLeft:20,
    color:grey[800],
    fontWeight:500,
    letterSpacing:1.2
    // color:grey[50],
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    padding:5,
  },


  imageContainer:{
    padding: 15,
    width:310,
  },
  infoContainer:{
    padding: 15,
    width:"100%",
    borderLeft: "1px solid #ccc",
    backgroundColor: "#f5f5f5"
  },
  buttonContainer:{
    position:"relative",
    padding: 15,
    width:480,
    borderLeft: "1px solid #ccc"
  },


  cardSectionTitle:{
    fontWeight:"bold",
    fontSize:12,
    color:red[500],
  },
  
  showMore:{
    position:"absolute",
    bottom:10,
    right:10
  }
  

});


export default styles;