// Auth Actions
export const AUTH_USER = "auth_user";
export const UNAUTH_USER = "unauth_user";
export const VALIDATE_USER = "validate_user";
export const INVALIDATE_USER = "invalidate_user";

// Domains Actions
export const GET_DOMAINS = "get_domains";
export const GET_DOMAINS_MORE = "get_domains_more";
export const GET_DOMAINS_SEARCH = "get_domains_search";
export const GET_DOMAINS_SEARCH_MORE = "get_domains_search_more";
export const GET_DOMAIN = "get_domain";
export const UPDATE_DOMAIN = "update_domain";
export const CREATE_DOMAINS_CASE = "create_domains_case";
export const ADD_DOMAINS_TAG = "add_domains_tag";
export const ADD_DOMAINS_NOTE = "add_domains_note";
export const REMOVE_DOMAINS = "remove_domains";
export const REMOVE_DOMAIN = "remove_domain";
export const REMOVE_DOMAINS_TAG = "remove_domains_tag";
export const UPDATE_DOMAINS_STATUS = "update_domains_status";
export const UPDATE_DOMAIN_FINDING_COUNT = "update_doamin_finding_count";
export const ADD_DOMAIN_FINDING_NOTE = "add_domain_finding_note";
export const DELETE_DOMAINS_NOTE = "delete_domain_note";

// Cases Actions
export const GET_CASES = "get_cases";
export const GET_CASES_MORE = "get_cases_more";
export const GET_CASES_SEARCH = "get_cases_search";
export const GET_CASES_SEARCH_MORE = "get_cases_search_more";
export const GET_CASE = "get_case";
export const UPDATE_CASE = "update_case";
export const ADD_CASES_TAG = "add_cases_tag";
export const ADD_CASES_NOTE = "add_cases_note";
export const DELETE_CASES_NOTE = "delete_case_note";
export const ADD_CASES_DOC = "add_cases_doc";

export const REMOVE_CASES = "remove_cases";
export const REMOVE_CASES_TAG = "remove_cases_tag";
export const UPDATE_CASES_COLLECTION_PARTNER = "update_cases_collectionPartner";
export const UPDATE_CASES_FEES = "update_cases_fees";
export const UPDATE_CASES_STATUS = "update_cases_status";
export const UPDATE_CASE_FINDING_LICENCE_FEE =
  "update_case_finding_licence_fee";
export const GET_CASE_FEES = "get_case_fees";

// Message Actions
export const GET_CASE_MESSAGES = "get_case_messages";
export const ADD_CASE_MESSAGE = "add_case_message";
export const MARK_MESSAGE_AS_READ = "mark_message_as_read";
export const DECREASE_UNREAD_COUNT = "decrease_unread_count";

// CASETODO Actions
export const GET_CASETODOS = "get_case_todos";
export const UPDATE_CASETODOS = "update_case_todos";

// Finding Actions
export const GET_FINDINGS_CASE = "get_findings_case";
export const GET_FINDINGS_NEW = "get_findings_new";
export const GET_FINDINGS_TRASH = "get_findings_trash";
export const GET_FINDINGS_LEGAL = "get_findings_legal";
export const UPDATE_FINDINGS_CASE_STATUS = "update_findings_case_status";
export const REMOVE_NEW_FINDINGS = "remove_new_findings";
export const MOVE_FINDINGS_TO_NEW = "move_findings_to_new";
export const MOVE_FINDINGS_TO_TRASH = "move_findings_to_tarsh";
export const MOVE_FINDINGS_TO_LEGAL = "move_findings_to_legal";
export const MOVE_FINDING_BACK_TO_CASE = "move_findings_back_to_case";
export const DELETE_FINDING = "delete_finding";

// Media Actions
export const GET_MEDIA = "get_media";
export const GET_MEDIA_MORE = "get_media_more";
export const GET_MEDIA_SEARCH = "get_media_search";
export const GET_MEDIA_SEARCH_MORE = "get_media_search_more";
export const REMOVE_MEDIA = "remove_media";
export const DELETE_MEDIA = "delete_media";
export const ADD_MEDIA_TAG = "add_media_tag";
export const REMOVE_MEDIA_TAG = "remove_media_tag";
export const UPDATE_MEDIA_STATUS = "update_media_status";

// Toolbar Actions
export const SET_TOOLBAR = "set_toolbar";

// Navbar Actions
export const SET_NAVBAR1 = "set_navbar1";
export const SET_NAVBAR2 = "set_navbar2";
export const SET_NAVBAR3 = "set_navbar3";

// Logs Actions
export const GET_LOGS = "get_logs";
export const ADD_LOGS = "add_logs";
export const REMOVE_LOGS = "remove_logs";
export const TOGGLE_LOGS = "toggle_logs";

// Notify Actions
export const GET_NOTIFY = "get_notify";
export const ADD_NOTIFY = "add_notify";
export const REMOVE_NOTIFY = "remove_notify";

// Filter Actions
export const INIT_FILTERS = "get_filters";
export const INIT_SUBFILTERS = "get_subfilters";
export const ADD_FILTER = "add_filter";
export const UPDATE_CURRENT_FILTER_OIGINAL_DATA =
  "update_current_filter_original_data";
export const REVERT_FILTER_DATA = "revert_filter_data";
export const REMOVE_FILTER = "remove_filter";
export const UPDATE_FILTER = "update_filter";
export const UPDATE_FILTER_NAME = "update_filter_name";
export const UPDATE_FILTER_ALLORANY = "update_filter_allorany";
export const UPDATE_FILTER_ISPRIVATE = "update_filter_isprivate";
export const UPDATE_SAVED = "update_saved";
export const UPDATE_ISNEW = "update_isnew";
export const ADD_FILTER_RULE = "add_filter_rule";
export const REMOVE_FILTER_RULE = "remove_filter_rule";
export const UPDATE_FITER_RULE_SUBFILTER = "update_filter_rule_subfilter";
export const UPDATE_FITER_RULE_OPERATION = "update_filter_rule_operation";
export const UPDATE_FITER_RULE_VALUE = "update_filter_rule_value";
export const INIT_CURRENT_FILTER_ID = "init_current_filter_id";
export const UPDATE_CURRENT_FILTER_ID = "update_current_filter_id";
export const INIT_CURRENT_FILTER_TYPE = "init_current_filter_type";
export const UPDATE_SEARCH_FILTER_ID = "update_search_filter_id";
export const SWAP_FILTER_ORDER = "swap_filter_order";
export const REORDER_FILTERS = "reorder_filters";
export const UPDATE_FILTER_COUNT = "update_filter_count";

// Search Filters
export const SET_SEARCH = "set_search";
export const REMOVE_SEARCH = "remove_search";

// Tags Actions
export const GET_ALL_MEDIA_TAGS = "get_all_media_tags";
export const GET_ALL_CASE_TAGS = "get_all_case_tags";
export const GET_ALL_DOMAIN_TAGS = "get_all_domain_tags";
export const UPDATE_ALL_DOMAIN_TAGS = "update_all_domain_tags";
export const UPDATE_ALL_CASE_TAGS = "update_all_case_tags";
export const UPDATE_ALL_MEDIA_TAGS = "update_all_media_tags";
export const REMOVE_ALL_TAGS = "remove_all_tags";

// Snack Actions
export const SHOW_SNACKBAR = "show_snackbar";
export const HIDE_SNACKBAR = "hide_snackbar";

// Global Actions
export const GET_COLLECTION_PARTNERS = "get_collection_partners";
export const GET_USERS = "get_users";
export const GET_CASE_STATES = "get_case_states";
export const GET_CASE_TYPES = "get_case_types";
export const UPDATE_USER_AVATAR = "update_user_avatar";
