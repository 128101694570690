import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import UserService from "@Services/User";
import { showSnackbar } from "@Actions/snackbarActions";
import TagInput from "@Components/TagInput";
import styles from "./styles";
import allIptcFields from "@Configs/mediaInfoConfig";
class MediaInfoSelector extends Component {
  constructor(props) {
    super();

    var media_info_config = JSON.parse(
      localStorage.getItem("media_info_config")
    );

    this.state = {
      isUpdating: false,
      config: {
        show_media_tags: media_info_config && media_info_config.show_media_tags,
        show_media_notes:
          media_info_config && media_info_config.show_media_notes,
        show_iptc_fields:
          media_info_config && media_info_config.show_iptc_fields
            ? media_info_config.show_iptc_fields
            : [],
      },
    };
  }

  tagsChanged = (actionName, tag) => {
    if (
      this.state.config.show_iptc_fields.length >= 3 &&
      actionName !== "remove-option"
    ) {
      this.props.actionShowSnackbar({
        message: this.props.t("_you_can_select_up_to_3_options"),
        variant: "warning",
      });
      return;
    }

    var newOptions = [];
    if (actionName === "create-option") {
      // no create option here
    } else if (actionName === "remove-option") {
      newOptions = this.state.config.show_iptc_fields.filter(
        (e) => e !== tag.id
      );
      this.setState({
        config: { ...this.state.config, show_iptc_fields: newOptions },
      });
    } else {
      newOptions = this.state.config.show_iptc_fields;
      newOptions.push(parseInt(tag.id));
      this.setState({
        config: { ...this.state.config, show_iptc_fields: newOptions },
      });
    }

    return null;
  };

  handleShowMedia = (event, checked) => {
    this.setState({
      config: { ...this.state.config, show_media_tags: checked },
    });
  };

  handleShowNotes = (event, checked) => {
    this.setState({
      config: { ...this.state.config, show_media_notes: checked },
    });
  };

  convertIdsToTagObj = (fieldArr) => {
    var results = [];
    for (var i = 0; i < fieldArr.length; i++) {
      results.push({
        id: allIptcFields[fieldArr[i]].id,
        name: allIptcFields[fieldArr[i]].name,
      });
    }
    return results;
  };

  handleUpdateMediaInfoConfig = () => {
    const { t } = this.props;
    this.setState({ isUpdating: true });
    UserService.updateMediaInfoConfig(this.state.config)
      .then((response) => {
        this.setState({ isUpdating: false });
        localStorage.setItem(
          "media_info_config",
          JSON.stringify(this.state.config)
        );
        this.props.actionShowSnackbar({
          message: t("_configuration_updated_successfully!"),
          variant: "success",
        });
      })
      .catch((error) => {
        this.setState({ isUpdating: false });
        this.props.actionShowSnackbar({
          message: error.message,
          variant: "error",
        });
      });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <FormControlLabel
          classes={{ label: classes.switchLabel }}
          control={
            <Switch
              color="primary"
              className={classes.switch}
              classes={{
                checked: classes.checked,
                bar: classes.bar,
              }}
              disabled={this.state.isCreating ? true : false}
              checked={this.state.config.show_media_tags}
              onChange={(event, checked) =>
                this.handleShowMedia(event, checked)
              }
            />
          }
          label={t("_show_image_tags")}
        />
        <br />
        <FormControlLabel
          classes={{ label: classes.switchLabel }}
          control={
            <Switch
              color="primary"
              className={classes.switch}
              classes={{
                checked: classes.checked,
                bar: classes.bar,
              }}
              disabled={this.state.isCreating ? true : false}
              checked={this.state.config.show_media_notes}
              onChange={(event, checked) =>
                this.handleShowNotes(event, checked)
              }
            />
          }
          label={t("_show_image_notes")}
        />
        <br />
        <div className={classes.tagInput}>
          <TagInput
            tags={Object.values(allIptcFields)}
            value={this.convertIdsToTagObj(this.state.config.show_iptc_fields)}
            onTagsChanged={this.tagsChanged}
            isMulti={true}
            limit={3}
            noOptionsMessage={t("_no_option")}
            placeholder={t("_select_up_to_3_iptc_fields")}
            isCreatable={false}
          />
        </div>
        <LinearProgress
          className={classes.updateProgress}
          color="secondary"
          style={{ visibility: this.state.isUpdating ? "visible" : "hidden" }}
        />
        <Button
          onClick={this.handleUpdateMediaInfoConfig}
          color="primary"
          className={classes.updateBtn}
        >
          {t("_update_configuration")}
        </Button>
      </div>
    );
  }
}

MediaInfoSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(translate("translations")(MediaInfoSelector)));
