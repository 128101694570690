import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './styles';

class ProgressBar extends React.Component {
  constructor(props){
    super();
  }

  render() {
    const { classes } = this.props;

    return (
      <LoadingBar showFastActions className={classes.loadingBar}/>
    );
  }
}

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProgressBar);