import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { connect } from "react-redux";
import { getIndexBy } from "@Utils/helpers";
import Button from "@material-ui/core/Button";
import FileUpload from "@material-ui/icons/CloudUpload";
import { withRouter } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
// import Zoom from '@material-ui/core/Zoom';
import _ from "lodash";
import { translate } from "react-i18next";
import { showSnackbar } from "@Actions/snackbarActions";
import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { bytesToSize } from "@Utils/helpers.js";
import AddImageIcon from "@material-ui/icons/AddAPhoto";
import styles from "./styles";

import LinearProgress from "@material-ui/core/LinearProgress";
import TagInput from "@Components/TagInput";

import MediaService from "@Services/Media";

class UploadImage extends Component {
  constructor(props) {
    super(props);

    this.props.showLoading();

    this.onFilesSelected = this.onFilesSelected.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
  }

  state = {
    files: {},
    totalFiles: 0,
    uploadedItems: 0,
    totalUploadItems: 0,
    popupOpen: false,
    isUploading: false,

    // allTags : {},
    selectedTags: [],
  };

  tagsChanged = (actionName, tag) => {
    console.group("all current Tags");
    // console.log(updatedTags);
    console.log("---action:");
    console.log(actionName);
    console.log("---tag:");
    console.log(tag);
    console.groupEnd();

    if (actionName === "create-option") {
      this.setState({
        ...this.state,
        selectedTags: [...this.state.selectedTags, tag],
      });
    } else if (actionName === "remove-option") {
      const index = getIndexBy(this.state.selectedTags, "id", tag.id);

      let newArray = this.state.selectedTags.slice();
      newArray.splice(index, 1);

      this.setState({ ...this.state, selectedTags: newArray });
    } else {
      // select-option
      this.setState({
        ...this.state,
        selectedTags: [...this.state.selectedTags, tag],
      });
    }

    return null;
  };

  componentDidMount() {}

  handleOpenPopup = () => {
    this.setState({ popupOpen: true });
  };

  handleClosePopup = () => {
    this.setState({ popupOpen: false });
  };

  async handleUpload() {
    this.setState({ isUploading: true });
    var uploadList = [];

    _.forOwn(this.state.files, function (tile, key) {
      uploadList.push(tile.file);
    });

    var tags = this.state.selectedTags.reduce(function (a, c) {
      if (a) {
        a.push(c.name);
        return a;
      }
      return a;
    }, []);
    this.setState({ totalUploadItems: uploadList.length });
    var i = 0;
    for (const media of uploadList) {
      var uploadData = {
        file: media,
        tags: tags,
      };
      await MediaService.uploadMedia(uploadData);
      i++;
      // todo: show progress
      this.setState({ uploadedItems: i });
    }

    var fileList = {};
    var newTotal = 0;
    this.setState({ files: fileList, totalFiles: newTotal });
    this.setState({ popupOpen: false, isUploading: false });
    this.props.actionShowSnackbar({
      message: "Images Uploaded Successfully!",
      variant: "success",
    });

    setTimeout(() => this.props.history.push("/media/"), 700);
  }

  onFilesRemove(imageKey) {
    var fileList = this.state.files;
    delete fileList[imageKey];

    var newTotal = Object.keys(fileList).length;
    this.setState({ files: fileList, totalFiles: newTotal });
  }

  onFilesSelected(event) {
    var fileList = this.state.files;
    var inputFiles = event.target.files;
    var imageId;

    for (var i = 0; i < inputFiles.length; i++) {
      imageId =
        inputFiles[i].lastModified + inputFiles[i].name + inputFiles[i].size;

      if (this.state.files[imageId] === undefined) {
        fileList[imageId] = {
          file: inputFiles[i],
          fileUrl: URL.createObjectURL(inputFiles[i]),
          name: inputFiles[i].name,
          size: inputFiles[i].size,
        };
      }
    }

    var newTotal = Object.keys(fileList).length;
    this.setState({ files: fileList, totalFiles: newTotal });
  }

  generateImages() {
    const { classes, t } = this.props;
    var imagesListGrid = [];

    _.forOwn(
      this.state.files,
      function (tile, key) {
        imagesListGrid.push(
          // <Zoom in={true} key={tile.fileUrl}>
          <GridListTile key={key}>
            <img src={tile.fileUrl} alt={tile.name} />
            <GridListTileBar
              classes={{
                root: classes.tileBarRoot,
                title: classes.tileBarTitle,
                subtitle: classes.tileBarSubtitle,
              }}
              title={tile.name}
              subtitle={
                <span>
                  {t("_size")}: {bytesToSize(tile.size)}
                </span>
              }
              actionIcon={
                <IconButton
                  className={classes.icon}
                  onClick={() => this.onFilesRemove(key)}
                >
                  <Close />
                </IconButton>
              }
            />
          </GridListTile>
          // </Zoom>
        );
      }.bind(this)
    );

    return imagesListGrid;
  }

  uploadPopup() {
    const { classes, t } = this.props;

    return (
      <DialogPopup
        disableBackdropClick
        disableEscapeKeyDown
        open={this.state.popupOpen}
        onClose={this.handleClosePopup}
      >
        <DialogTitle>{t("_image_upload")}</DialogTitle>
        <DialogContent classes={{ root: classes.contentRoot }}>
          <DialogContentText>
            {t("_you_have_selected")} <b>{this.state.totalFiles}</b>{" "}
            {t("_files_for_upload")}
          </DialogContentText>
          <br />
          <div className={classes.tagListRoot}>
            <TagInput
              value={this.state.selectedTags}
              tags={this.props.tagsReducer.mediaTags}
              onTagsChanged={this.tagsChanged}
              isMulti={true}
              isCreatable={true}
            />
          </div>

          <LinearProgress
            className={classes.uploadProgress}
            color="secondary"
            style={{
              visibility: this.state.isUploading ? "visible" : "hidden",
            }}
          />
          <div
            className={classes.uploadStatus}
            style={{
              visibility: this.state.isUploading ? "visible" : "hidden",
            }}
          >
            {t("_uploaded") +
              " " +
              this.state.uploadedItems +
              " " +
              t("_of") +
              " " +
              this.state.totalUploadItems +
              " " +
              t("_items")}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleClosePopup}
            className={classes.closeButton}
            color="secondary"
            disabled={this.state.isUploading ? true : false}
          >
            {t("_cancel")}
          </Button>
          <Button
            onClick={this.handleUpload}
            className={classes.uploadButton}
            color="primary"
            disabled={this.state.isUploading ? true : false}
          >
            {t("_upload")}
          </Button>
        </DialogActions>
      </DialogPopup>
    );
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <input
          accept="image/*"
          className={classes.input}
          id="inputFile"
          multiple
          type="file"
          onChange={this.onFilesSelected}
        />

        <Grid container>
          <Grid item xs={6}>
            <div className={classes.selectTitle}>
              <AddImageIcon color="action" />
              <p>{t("_select_images_to_upload")}</p>
            </div>
          </Grid>
          <Grid item xs={3}>
            <label htmlFor="inputFile">
              <Button
                fullWidth
                variant="contained"
                className={classes.menuButton}
                color="default"
                component="span"
                size="medium"
              >
                {t("_select_images")}
              </Button>
            </label>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              onClick={this.handleOpenPopup}
              disabled={this.state.totalFiles > 0 ? false : true}
              className={classes.uploadButton}
            >
              {t("_upload")}
              <FileUpload className={classes.rightIcon} />
            </Button>
          </Grid>
        </Grid>

        <Paper className={classes.gridRoot}>
          <GridList cellHeight={200} cols={5} className={classes.gridList}>
            <GridListTile
              key="Subheader"
              cols={5}
              className={classes.gridTitle}
            >
              <ListSubheader component="div" className={classes.gridTitleText}>
                {this.state.totalFiles} {t("_files_selected")}
              </ListSubheader>
            </GridListTile>

            {this.generateImages()}
          </GridList>
        </Paper>

        {this.uploadPopup()}
      </div>
    );
  }
}

UploadImage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    mediaReducer: state.mediaReducer,
    tagsReducer: state.tagsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading: showLoading,
      hideLoading: hideLoading,
      actionShowSnackbar: showSnackbar,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(withRouter(UploadImage))));
