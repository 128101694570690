const Config = [
  {
    id: "11",
    kind: "text",
    name: "is",
    value: "is",
    display: "is",
  },
  {
    id: "12",
    kind: "text",
    name: "is not",
    value: "is_not",
    display: "is_not",
  },
  {
    id: "13",
    kind: "text",
    name: "starts with",
    value: "starts_with",
    display: "starts_with",
  },
  {
    id: "14",
    kind: "text",
    name: "ends with",
    value: "ends_with",
    display: "ends_with",
  },
  {
    id: "15",
    kind: "text",
    name: "contains",
    value: "contains",
    display: "contains",
  },
  {
    id: "21",
    kind: "number",
    name: " = ",
    value: "equals_to",
    display: "equals_to",
  },
  {
    id: "22",
    kind: "number",
    name: " ≠ ",
    value: "not_equals_to",
    display: "not_equals_to",
  },
  {
    id: "23",
    kind: "number",
    name: " > ",
    value: "grater_than",
    display: "grater_than",
  },
  {
    id: "24",
    kind: "number",
    name: " < ",
    value: "less_than",
    display: "less_than",
  },
  {
    id: "25",
    kind: "number",
    name: " >= ",
    value: "grater_or_equals_to",
    display: "grater_or_equals_to",
  },
  {
    id: "26",
    kind: "number",
    name: " <= ",
    value: "less_or_equals_to",
    display: "less_or_equals_to",
  },
  {
    id: "31",
    kind: "filter",
    name: "matches",
    value: "matches",
    display: "matches",
  },
  {
    id: "32",
    kind: "filter",
    name: "notmatches",
    value: "notmatches",
    display: "notmatches",
  },
  {
    id: "41",
    kind: "date",
    name: "after",
    value: "after",
    display: "after",
  },
  {
    id: "42",
    kind: "date",
    name: "before",
    value: "before",
    display: "before",
  },
  {
    id: "43",
    kind: "date",
    name: "on",
    value: "on",
    display: "on",
  },
  {
    id: "44",
    kind: "date",
    name: "within last days",
    value: "within last days",
    display: "within_last_days",
  },
  {
    id: "51",
    kind: "enum",
    name: "is",
    value: "is",
    display: "is",
  },
  {
    id: "52",
    kind: "enum",
    name: "is not",
    value: "is_not",
    display: "is_not",
  },
  {
    id: "61",
    kind: "tag",
    name: "is",
    value: "is",
    display: "is",
  },
  {
    id: "62",
    kind: "tag",
    name: "is not",
    value: "is_not",
    display: "is_not",
  },
  {
    id: "63",
    kind: "tag",
    name: "starts with",
    value: "starts_with",
    display: "starts_with",
  },
  {
    id: "64",
    kind: "tag",
    name: "ends with",
    value: "ends_with",
    display: "ends_with",
  },
  {
    id: "65",
    kind: "tag",
    name: "contains",
    value: "contains",
    display: "contains",
  },
  {
    id: "66",
    kind: "tag",
    name: "no tag",
    value: "no_tag",
    display: "no_tag",
  },
  {
    // this is special filter for dpa which needs no operator
    id: "70",
    kind: "special_1",
    name: "",
    value: "",
    display: "",
  },
  {
    // this is special filter for dpa which needs no operator
    id: "71",
    kind: "special_2",
    name: "is",
    value: "is",
    display: "is",
  },
];

export default Config;
