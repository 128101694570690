import request from "@Utils/api";
import api from "@Configs/apiConfig";

function getMedia(data) {
  // data :
  // pageNum
  // filter
  var filterQuery =
    data.filter === 0 || data.filter === "0" || data.filter == null
      ? ""
      : "&f=" + data.filter;
  return request({
    url:
      api.mediaServer +
      "/media?ps=20&p=" +
      parseInt(data.pageNum) +
      filterQuery,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function getAllMediaCount(data) {
  return request({
    url: api.mediaServer + "/media?co=true",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function getMediaSearch(data) {
  // data :
  // pageNum
  // param
  // filter

  var filterQuery =
    data.filter === "0" || data.filter === 0 || data.filter == null
      ? ""
      : "&f=" + data.filter;
  return request({
    // url:    api.mediaServer + '/medialist' + data.pageNum + '.json',
    url:
      api.mediaServer +
      "/search_media?ps=20&p=" +
      parseInt(data.pageNum) +
      filterQuery +
      "&s=" +
      data.param,
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "text/plain",
    },
  });
}

function uploadMedia(data) {
  // data :
  // file
  // tags []
  var formData = new FormData();
  formData.append("file", data.file);
  formData.append("tags", data.tags);
  return request({
    url: api.mediaServer + "/media",
    method: "POST",
    data: formData,
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "content-type": "multipart/form-data",
    },
  });
}

function getMediaDomains(data) {
  // data :
  // mediaId
  return request({
    url: api.mediaServer + "/media/" + data.mediaId + "/domains",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addMediaNote(data) {
  // data:
  // note
  // domainId
  return request({
    url: api.mediaServer + "/media/" + data.mediaId + "/notes/",
    data: data.note.note,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
      "Content-Type": "application/json",
    },
  });
}

function getAllMediaTags() {
  return request({
    url: api.mediaServer + "/media/tags",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function addMediaTag(data) {
  // data:
  // tag
  //     name
  //     id
  // mediaId

  return request({
    url: api.mediaServer + "/media/" + data.mediaId + "/tags/",
    data: data.tag,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function removeMediaTag(data) {
  // data:
  // tag
  //     name
  //     id
  // mediaId

  return request({
    url: api.mediaServer + "/media/" + data.mediaId + "/tags/" + data.tag.id,
    method: "DELETE",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getMediaInfo(data) {
  // data :
  // mediaId
  return request({
    url: api.mediaServer + "/media/" + data.mediaId + "/iptc",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getMediaHistory(data) {
  // data :
  // mediaId
  return request({
    url: api.mediaServer + "/media/" + data.mediaId + "/history",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function updateMediaStatus(data) {
  // data:
  // targetImage
  // status
  return request({
    url: api.mediaServer + "/media/" + data.mediaId + "/status",
    data: { status: data.status },
    method: "PUT",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getMediaNotes(mediaId) {
  // mediaId
  return request({
    url: api.mediaServer + "/media/" + mediaId + "/notes",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function getMediaTags(mediaId) {
  // mediaId
  return request({
    url: api.mediaServer + "/media/" + mediaId + "/tags",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function deleteMedia(data) {
  // data :
  // mediaId

  return request({
    url: api.generalServer + "/temp.json",
    method: "GET",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function mediaBulkAction(data) {
  // data:
  // tag
  //     name
  //     id
  // type     -> remove_tags, add_tags
  // serach   -> search object || null
  // filterId -> filter id || null

  return request({
    url: api.mediaServer + "/bulk_action/",
    data: data,
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

function deleteFindingsAndMedia(mediaId) {
  return request({
    url: api.mediaServer + "/media/" + mediaId + "/_delete_findings_and_Media",
    method: "POST",
    headers: {
      "x-access-token": localStorage.getItem("Token"),
    },
  });
}

const Media = {
  getMedia,
  getMediaSearch,
  uploadMedia,
  getMediaDomains,
  getMediaInfo,
  getMediaHistory,
  updateMediaStatus,
  deleteMedia,
  removeMediaTag,
  addMediaTag,
  addMediaNote,
  getMediaNotes,
  getAllMediaTags,
  mediaBulkAction,
  getMediaTags,
  getAllMediaCount,
  deleteFindingsAndMedia,
};

export default Media;
