import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// import Home from '@Pages/Home';
import Login from "@Pages/Login";
import ResetPassword from "@Pages/ResetPassword";
import Domains from "@Pages/Domains";
import Search from "@Pages/Search";
import Domain from "@Pages/Domain";
import Cases from "@Pages/Cases";
import Case from "@Pages/Case";
import Stats from "@Pages/Stats";
import Layout from "@Pages/Layout";
import Setting from "@Pages/Setting";
import Media from "@Pages/Media";
import RemoteLogin from "@Pages/RemoteLogin";

import grey from "@material-ui/core/colors/grey";
import { PrivateRoute } from "@Components/PrivateRoute";

const styles = (theme) => ({
  root: {
    backgroundColor: grey[200],
    minHeight: "100vh",
  },
});

class AppRouter extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/remotelogin" component={RemoteLogin} />
            <Route path="/reset_password" component={ResetPassword}/>
            <Layout>
              <Switch>
                  <PrivateRoute exact path="/" component={Domains} />
                  <PrivateRoute path="/media" component={Media} />
                  <PrivateRoute path="/setting" component={Setting} />
                  <PrivateRoute path="/stats" component={Stats} />
                  <PrivateRoute path="/domains" component={Domains} />
                  <PrivateRoute path="/domain/:id" component={Domain} />
                  <PrivateRoute path="/cases" component={Cases} />
                  <PrivateRoute path="/case/:id" component={Case} />
                  <PrivateRoute path="/search/:param" component={Search} />
                  <Redirect to="/" />
              </Switch>
            </Layout>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default withStyles(styles)(AppRouter);
