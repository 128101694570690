import { GET_CASETODOS, UPDATE_CASETODOS } from "@Types/actionTypes";

const defaultState = {
  todos: [],
};
const caseTodosReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CASETODOS:
    case UPDATE_CASETODOS: {
      state = {
        ...state,
        todos: action.payload,
      };
      break;
    }
    default:
      break;
  }
  return state;
};

export default caseTodosReducer;
