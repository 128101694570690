const styles = theme => ({
  root: {
    margin:"0 0 10px 0"
  },
  button: {
    textTransform: "initial",
    fontSize:14,
    height: 40,
    minWidth:150,
    width:"100%",
    color: "#2680eb",
    backgroundColor: "#fff",
    border: "1px solid #2680eb",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#2680eb",
      border: "1px solid transparent"
    }
  },
  moreButton:{
    minWidth:5,
    fontSize:12,
  },
  input: {
    display: 'none',
  },
});

export default styles;