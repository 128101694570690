import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root: {

  },
  tblGeneral:{
    width: '100%',
    fontSize:13,
    borderSpacing:0,
    // borderBottom: '1px solid #999',
    paddingBottom: 10,
    color:grey[800]
  },
  trLeft:{
    fontWeight:600,
    paddingTop:10,
    fontSize:14,
  },
  trRight:{
  },
  editButton:{
    float:'right',
    marginTop: 20
  }
});


export default styles;