import React, { Component } from "react";
import DomainCard from "@Components/cards/DomainCard";
import NewPanel from "@Components/panels/NewPanel";
import CasePanel from "@Components/panels/CasePanel";
import LegalPanel from "@Components/panels/LegalPanel";
import TrashPanel from "@Components/panels/TrashPanel";
import { setNavbar1, setNavbar2, setNavbar3 } from "@Actions/navbarActions";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { showLoading, hideLoading } from "react-redux-loading-bar";
import { setToolbar } from "@Actions/toolbarActions";
import _ from "lodash";
import { showSnackbar } from "@Actions/snackbarActions";
import DomainsService from "@Services/Domains";
import { getDomain } from "@Actions/domainsActions";
import { initCurrentFilterType } from "@Actions/filterActions";

import { isEmptyObject } from "@Utils/helpers";

import LoadingBg from "@Components/LoadingBg";

import { removeSearch } from "@Actions/searchActions";
import Base64 from "base-64";
import styles from "./styles";

class Domain extends Component {
  constructor(props) {
    super(props);

    this.props.hideLoading();
    this.props.showLoading();
    this.props.actionInitCurrentFilterType("0");

    if (props.searchReducer.search.class != null) {
      this.props.setToolbar(false);
      this.props.setNavbar1({
        name:
          this.props.t("_search") +
          ": " +
          props.searchReducer.search.class.name +
          " - " +
          props.searchReducer.search.subclass.name +
          " - " +
          props.searchReducer.search.value,
        link: "/search/" + Base64.encode(JSON.stringify(props.searchReducer)),
      });
    } else {
      this.props.setToolbar(0);
      this.props.setNavbar1({
        name: this.props.t("_domains"),
        link: "/domains?r",
      });
    }
    this.props.setNavbar2({
      name: this.props.t("_domain"),
      link: "/domain/" + this.props.match.params.id,
    });
    this.props.setNavbar3(null);
  }

  state = {
    lbgMessage: "",
    lbgAnimated: true,
  };

  componentDidMount() {
    /**
     * @type string
     */
    var domainId = this.props.match.params.id;

    if (
      this.props.domainsReducer == null ||
      typeof this.props.domainsReducer.results[domainId] === "undefined"
    ) {
      DomainsService.getDomain({ domainId })
        .then((response) => {
          if (domainId.includes("%")) domainId = decodeURIComponent(domainId);
          if (isEmptyObject(response)) {
            this.setState({
              lbgAnimated: false,
              lbgMessage: this.props.t("_no_data_found"),
            });
          } else {
            this.props.actionGetDomain({ results: response, domainId });
            this.props.setNavbar2({
              name:
                this.props.t("_domain") +
                " " +
                this.props.domainsReducer.results[domainId].info.domainURL,
              link: "/domain/" + domainId,
            });
          }
          this.props.hideLoading();
        })
        .catch((error) => {
          this.props.hideLoading();
          this.setState({ lbgAnimated: false, lbgMessage: error.message });
          this.props.actionShowSnackbar({
            message: error.message,
            variant: "error",
          });
        });
    } else {
      setTimeout(() => this.props.hideLoading(), 200);
      this.props.setNavbar2({
        name:
          this.props.t("_domain") +
          " " +
          this.props.domainsReducer.results[domainId].info.domainURL,
        link: "/domain/" + domainId,
      });
    }

    window.scrollTo(0, -300);
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    // this.props.removeDomainInfo();
  }

  populateFindings() {
    var domainId = this.props.match.params.id;
    if(domainId.includes("%"))
    domainId = decodeURIComponent(domainId)
    var list = [];
    list.push(<NewPanel key="new" domainId={domainId} />);

    _.forOwn(
      this.props.domainsReducer.results[domainId].cases,
      function (value, key) {
        list.push(
          <CasePanel
            domainId={domainId}
            caseId={key}
            key={key}
            pageType={"domain"}
          />
        );
      }
    );

    list.push(<LegalPanel key="legal" domainId={domainId} />);
    list.push(<TrashPanel key="trash" domainId={domainId} />);
    return list;
  }

  render() {
    const { classes } = this.props;
    const param = this.props.match.params.id;
    var domainId = param;
    if (param.includes("%")) domainId = decodeURIComponent(param);
    if (localStorage.getItem("findingDeleted") === "1") {
      console.log("hahsahshahsahs");
      this.props.actionShowSnackbar({
        message:
          "There was an update on findings, therfore the list is refreshed.",
        variant: "info",
      });
      localStorage.setItem("findingDeleted", "0");
    }

    if (
      this.props.domainsReducer == null ||
      typeof this.props.domainsReducer.results[domainId] === "undefined"
    ) {
      return (
        <div>
          <LoadingBg
            pageType="domains"
            message={this.state.lbgMessage}
            animated={this.state.lbgAnimated}
          />
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <DomainCard domainId={domainId} page="domain" />
        {this.populateFindings()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    domainsReducer: state.domainsReducer,
    searchReducer: state.searchReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      setToolbar,
      setNavbar1,
      setNavbar2,
      setNavbar3,

      actionRemoveSearch: removeSearch,
      actionGetDomain: getDomain,
      actionShowSnackbar: showSnackbar,
      actionInitCurrentFilterType: initCurrentFilterType,
    },
    dispatch
  );
}

Domain.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(Domain)));
