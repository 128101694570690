import {
  GET_MEDIA,
  GET_MEDIA_MORE,
  GET_MEDIA_SEARCH,
  GET_MEDIA_SEARCH_MORE,
  REMOVE_MEDIA,
  DELETE_MEDIA,
  ADD_MEDIA_TAG,
  REMOVE_MEDIA_TAG,
  UPDATE_MEDIA_STATUS,
} from "@Types/actionTypes";
import _ from "lodash";
import { getIndexBy } from "@Utils/helpers";

const mediaReducer = (state = null, action) => {
  switch (action.type) {
    case GET_MEDIA:
      state = null;
      state = Object.assign({}, state, action.payload.results);
      break;

    case GET_MEDIA_MORE:
      state = {
        total: action.payload.results.total,
        results: _.merge(state.results, action.payload.results.results),
      };
      break;

    case GET_MEDIA_SEARCH:
      state = null;
      state = Object.assign({}, state, action.payload.results);
      break;

    case GET_MEDIA_SEARCH_MORE:
      state = {
        total: action.payload.results.total,
        results: _.merge(state.results, action.payload.results.results),
      };
      break;

    case REMOVE_MEDIA:
      state = null;
      break;

    case DELETE_MEDIA:
      delete state.results[action.payload.mediaId];
      state = { ...state, total: state.total - 1 };
      break;

    case ADD_MEDIA_TAG:
      // var updatedTagsA = state.results[action.payload.mediaId].tags;
      // updatedTagsA.push(action.payload.tag);
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.mediaId]: {
            ...state.results[action.payload.mediaId],
            tags: [
              ...state.results[action.payload.mediaId].tags,
              action.payload.tag,
            ],
          },
        },
      };
      break;

    case REMOVE_MEDIA_TAG:
      const index = getIndexBy(
        state.results[action.payload.mediaId].tags,
        "id",
        action.payload.tag.id
      );

      let newArray = state.results[action.payload.mediaId].tags.slice();
      newArray.splice(index, 1);

      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.mediaId]: {
            ...state.results[action.payload.mediaId],
            tags: newArray,
          },
        },
      };
      break;

    case UPDATE_MEDIA_STATUS:
      state = {
        ...state,
        results: {
          ...state.results,
          [action.payload.mediaId]: {
            ...state.results[action.payload.mediaId],
            state: action.payload.state,
          },
        },
      };
      break;
    default:
      break;
  }

  return state;
};

export default mediaReducer;
