import React, { Component } from 'react';
import { translate} from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {bindActionCreators} from 'redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import {connect} from 'react-redux';
import { getFindingsLegal }  from "@Actions/findingsActions";
import FindingsService from "@Services/Findings";
import Finding from '@Components/Finding';
import _ from 'lodash';
import { LEGAL } from '@Types/panelTypes';

import styles from './styles'

class LegalPanel extends Component {
  constructor(props) {
    super();
    this.state = {
      expanded : false, 
      isLoading:false}
  }

  toggle = () => {
    this.setState({
      expanded: !this.state.expanded,
      isLoading:false
    });
  };

  componentDidMount = () => {
    var data = {
      domainId: this.props.domainId,
      pageNum: 1
    }
    this.setState({isLoading:true});
    FindingsService.getFindingsDomain(data).then(
      (response) => {
        var filteredRes = _.filter(response.results, function(item){ return item.type === "legal"; });
        response.info.findingCount = filteredRes.length;
        filteredRes = _.keyBy(filteredRes, 'id');
        response.results = filteredRes;
        this.props.actionGetFindingsLegal({results: response, domainId: data.domainId});
        this.setState({isLoading:false});
      }).catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
        this.setState({isLoading:false});
      });
  }

  populateResults(){
    
    if(_.size(this.props.findingsReducer.legal.results) === 0){
      return (<div className={this.props.classes.noNewItem}>{this.props.t("_no_new_item")}..</div>)
    }

    var list = [];
    var i=0;
    _.forOwn(this.props.findingsReducer.legal.results, 
      function(value, key) {
        i++;
        var data = {
          findingId : key,
          domainId: this.props.domainId,
          type : LEGAL,
          caseId : null,
          parentType: this.props.parentType
        };

        list.push( <Finding key={key} data={data}  counter={i}/>);
      }.bind(this)
    );
    return list;
  }

  render() {
    const { classes, t } = this.props;

    var isEmpty = false;
    if(this.props.findingsReducer.legal == null || _.size(this.props.findingsReducer.legal.results) === 0){
      isEmpty = true;
    }

    // var totalFindings = 0;

    return (
      <div className={classes.root}>
        <ExpansionPanel className={classes.panelRoot}  expanded={this.state.expanded} onChange={() => this.toggle()}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <p className={classes.headingTitle}>{t("_legal")}</p>
            <p className={classes.headingCount}>{t("_number_of_findings")}: <b>{ isEmpty? (this.state.isLoading? "..." : 0) : this.props.findingsReducer.legal.info.findingCount}</b></p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.content}>
            {isEmpty? 
            this.state.isLoading ?
            <div className={classes.loader} key={1}><LinearProgress color="secondary" /></div>
            :
            <div className={this.props.classes.noNewItem}>{t("_no_new_item")}...</div> 
            : 
            this.populateResults()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

LegalPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  domainId: PropTypes.string.isRequired,
};

function mapStateToProps(state){
  return {
    findingsReducer : state.findingsReducer
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators(
    {
      actionGetFindingsLegal: getFindingsLegal, 
    }, dispatch);
}

export default withStyles(styles)(translate("translations")(connect(mapStateToProps,matchDispatchToProps)(LegalPanel)));